import React, { Component } from 'react';
import { Textbox, Select } from "react-inputs-validation";
//import { getLang } from '../../lang/lang';
import { number } from 'prop-types';
import trussLogo from '../../../assets_global/images/truss-logo.png';
import { TranslatableText } from '../../../providers/LanguageProvider';
import { PROV_OPTIONS_LIST } from './data.js';
import { FR_PROV_OPTIONS_LIST } from './data.js';
import "react-inputs-validation/lib/react-inputs-validation.min.css";
import './style.scss';
import privacyPdf from '../../../assets_global/pdf/Truss-Beverages-Privacy-Policy---EN_May-2020.pdf';
import privacyPdf_fr from '../../../assets_global/pdf/Truss-Beverages-Privacy-Policy---FR_June-2020.pdf';
import termsPdf from '../../../assets_global/pdf/Truss-Beverages-Terms-of-Use---Website---EN_May-2020.pdf';
import termsPdf_fr from '../../../assets_global/pdf/Truss-Beverages-Terms-of-Use---Website---FR_June-2020.pdf';
import cookiePdf from '../../../assets_global/pdf/Cookies-and-Tracking-Technologies.pdf';
import cookiePdf_fr from '../../../assets_global/pdf/Cookies-and-Tracking-Technologies_FR.pdf';

class AgeForm extends Component {
    constructor(props) {
        super(props);
       
        this.state = {
          ageMin: 19,
          hasAgeError: true,
          ageErrorMsg: "",
          prov: "foo",
          hasProvError: true,
          provErrorMsg: "",
          year: "",
          hasYearError: true,
          yearErrorMsg: "",
          month: "",
          hasMonthError: true,
          monthErrorMsg: "",
          day: "",
          hasDayError: true,
          dayErrorMsg: "",
          validate: false,
          selectList: PROV_OPTIONS_LIST,
          placeholderYear: "YYYY",
          placeholderMonth: "MM",
          placeholderDay: "DD",
          theLang: props.lang,
          checked: false
          
        };
        this.validateForm = this.validateForm.bind(this);
        this.checkAge = this.checkAge.bind(this);
    }
    componentDidMount(){
        
        if (this.state.theLang === 'fr'){
            // Set the Lang State
            this.setState({
                selectList: FR_PROV_OPTIONS_LIST,
                placeholderYear: "AAAA",
                placeholderMonth: "MM",
                placeholderDay: "JJ"
            })
        }

        window.geoip2.insights(this.onSuccess, this.onError)
        
    }
    onSuccess = (location) =>{
        //console.log('loca success ',location.subdivisions[0].iso_code)
        let region = location.subdivisions[0].iso_code;
        let canadian = region.match(/^(AB|BC|MB|NB|NL|NS|NT|NU|ON|PE|QC|SK|YK)$/);

        if(canadian){
            this.setState({
                prov: region,
                hasProvError: false
            });
        }else{
            this.setState({
                prov: ""
            });
        }
        
        // alert(
        //     "Lookup successful:\n\n"
        //     + JSON.stringify(location, undefined, 4)
        // );
      }
      
    onError = (error) =>{
        // alert(
        //     "Error:\n\n"
        //     + JSON.stringify(error, undefined, 4)
        // );
       // console.log('error ', JSON.stringify(error, undefined, 4));
        this.setState({
            prov: ""
        });
    }

    toggleValidating(validate) {
        this.setState({ validate });
    }
    checkHasError() {
        const {  hasProvError, hasYearError, hasMonthError, hasDayError } = this.state;
        if ( hasProvError || hasYearError || hasMonthError || hasDayError ) {
          return true;
        } else {
          return false;
        }
    }
    validateForm(e) {
        //console.log('validating ',e);
        e.preventDefault();
        //this.toggleValidating(true);
        const {
          hasProvError,
          hasYearError,
          hasMonthError,
          hasDayError
        } = this.state;
        if (
          !hasProvError &&
          !hasYearError &&
          !hasMonthError &&
          !hasDayError 
         
        ) {
          //alert("All validated!");
          //console.log('all validated');
         
          var ageOk = this.checkAge()
        
          if (ageOk){
            this.props.handleHide(this.state.checked);
          }
          
        }

        else if (hasYearError){
            this.setState({ yearErrorMsg: "Please enter a valid year" })
        }
        else if (hasProvError){
            this.setState({ provErrorMsg: "Please enter a province"})
        }
        else if (hasMonthError){
            this.setState({ monthErrorMsg: "Please select a month"})
        }
        else if (hasDayError){
            this.setState({ dayErrorMsg: "Please select a day"})
        }
    }
    checkAge(e) {
        const {day, year, ageMin } = this.state;
        let{ month } = this.state;
        //Adjust month from input box as it's off by 1 for using the new Date() function.
        //month = parseInt(month - 1);

        //  console.log('day ',day);
        //  console.log('month ',month);
        //  console.log('year ',year);
     
        if (day === "" || day === undefined){
            //console.log('null');
            return false;
        }

        if(month === "" || month > 12){
            this.setState({hasMonthError: true});
            return false;
        }

        let today = new Date();
        let birthDate = new Date(year, month, day);
        let ageNow = today.getFullYear() - birthDate.getFullYear();
        let m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            ageNow--;
        }
        //console.log('age now', ageNow);

        if (ageNow >= ageMin){
            this.setState({
                ageErrorMsg: ""
            })
            return true;
        }else{
            this.setState({
                ageErrorMsg: "You must be of legal age"
            })
            return false;
        }
       
    } 
    handleCheck = (e) => {
        this.setState((prevState) => {
            return {  checked: !prevState.checked };
        })
    }
   
    render() {
        const { ageErrorMsg, prov, provErrorMsg, year, yearErrorMsg, month, monthErrorMsg, day, dayErrorMsg, selectList, placeholderYear, placeholderMonth, placeholderDay, validate } = this.state;
        return (
            <React.Fragment>
           
           {/*  <p>
                <TranslatableText dictionary={{ 
                    en: "Date of birth and location, please.",
                    fr: "Date de naissance et province."}}>
                </TranslatableText>
            </p>
           */}
            <form id="age-form" onSubmit={this.validateForm}>
                <h1>
                    <TranslatableText dictionary={{ en: "LOCATION &", fr: "Province et date"}}></TranslatableText><br />
                    <TranslatableText dictionary={{ en: "DATE OF BIRTH", fr: "de naissance"}}></TranslatableText>
                </h1>

                <div className="input-container">
                    <input name="check-required" id="check-required" type="checkbox" checked={ this.state.checked} onChange = {this.handleCheck} /> <label htmlFor="check-required"></label>
                    <p className="checked-text">
                        <TranslatableText dictionary={{
                            en: "Remember me for 30 days. I confirm this is not a shared device.",
                            fr: "Se souvenir de moi durant 30 jours. Je confirme que cet appareil n'est pas partagé." }}>
                        </TranslatableText>
                    </p>
                </div>

                <Select
                    tabIndex="1" //Optional.[String or Number].Default: none.
                    id={'prov'} //Optional.[String].Default: "". Input ID.
                    name={'prov'} //Optional.[String].Default: "". Input name.
                    value={prov} //Optional.[String].Default: "".
                    disabled={false} //Optional.[Bool].Default: false.
                    //validate={validate} //Optional.[Bool].Default: false. If you have a submit button and trying to validate all the inputs of your form at once, toggle it to true, then it will validate the field and pass the result via the "validationCallback" you provide.
                    classNameOptionListContainer="select-options-wrap"
                    validationCallback={res => {
                        this.setState({ hasProvError: res, validate: false })} //Optional.[Func].Default: none. Return the validation result.
                    }
                    optionList={selectList} //Required.[Array of Object(s)].Default: [].
                    
                    onChange={(prov, e) => {

                        this.setState({ prov });
                        // Get Prov & set ageMin based on prov
                        //console.log('province ',prov);
                        
                        if (prov == "") {
                                
                            this.setState({
                                provErrorMsg: "Please select a province",
                                hasProvError: true
                            });
                          return 'error';
                        }
                        let found = prov.match(/^(AB|QC)$/);
                        this.setState({
                            provErrorMsg: "",
                            hasProvError: false,
                            ageMin: found ? 18 : 19
                        });
                        return true;
                    }} //Optional.[Func].Default: () => {}. Will return the value.
                    onBlur={e => { 
                        if(this.state.prov === 'QC'){
                            this.props.handleQuebec(true)
                        }
                        else{
                            this.props.handleQuebec(false)
                        }
                     }} //Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
                    // onFocus={(e) => {console.log(e)}} //Optional.[Func].Default: none.
                    // onClick={(e) => {console.log(e)}} //Optional.[Func].Default: none.
                    // selectHtml={<div>{movieItem.name}</div>} //Optional.[Html].Default: none. The custom html that will display when user choose. Use it if you think the default html is ugly.
                    // selectOptionListItemHtml={MOVIE_OPTIONS_LIST.map(
                    //   (i, k) => {
                    //     return (
                    //       <div
                    //         key={k}
                    //         onClick={() => {
                    //           this.handleMovieChange(i.id);
                    //         }}
                    //       >
                    //         <span className="icon icon-person" />{i.name}
                    //       </div>
                    //     );
                    //   }
                    // )} //Optional.[Html].Default: none. The custom select options item html that will display in dropdown list. Use it if you think the default html is ugly.
                    validationOption={{
                        name: 'prov', //Optional.[String].Default: "". To display in the Error message. i.e Please select a ${name}.
                        check: true, //Optional.[Bool].Default: true. To determin if you need to validate.
                        required: true, //Optional.[Bool].Default: true. To determin if it is a required field.
                        showMsg: false, //Optional.[Bool].Default: true. To determin display the error message or not.
                        // locale: 'en-US', //Optional.[String].Default: "en-US". For error message display. Current options are ['zh-CN', 'en-US']; Default is 'en-US'. If your are looking for more options, you can take a look at 'window.REACT_INPUTS_VALIDATION' section, which provides the extensibility for your own locale.
                        // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']", //Optional.[String].Default: "". Show your custom error message no matter what when it has error if it is provied.
                        // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border." //Optional.[String].Default: "". Show your custom success message no matter what when it has error if it is provied.
                        // customFunc: // NOT SUPPORTED FOR SELECT INPUT
                    }}
                />
                <div className="inputbox-wrapper">
                <Textbox
                    tabIndex="2" // Optional.[String or Number].Default: -1.
                    id={"Year"} // Optional.[String].Default: "".  Input ID.
                    name="Year" // Optional.[String].Default: "". Input name.
                    type="number" // Optional.[String].Default: "text". Input type [text, password, number].
                    value={year} // Optional.[String].Default: "".
                    disabled={false} // Optional.[Bool].Default: false.
                    placeholder={placeholderYear} // Optional.[String].Default: "".
                    validate={validate} // Optional.[Bool].Default: false. If you have a submit button and trying to validate all the inputs of your form at onece, toggle it to true, then it will validate the field and pass the result via the "validationCallback" you provide.
                    validationCallback={res => {
                            this.setState({ hasNameError: res, validate: false })
                        }
                    } // Optional.[Func].Default: none. Return the validation result.
                    classNameInput="year" // Optional.[String].Default: "".
                    classNameWrapper="year-wrap" // Optional.[String].Default: "".
                    classNameContainer="" // Optional.[String].Default: "".
                    customStyleInput={{}} // Optional.[Object].Default: {}.
                    customStyleWrapper={{}} // Optional.[Object].Default: {}.
                    customStyleContainer={{}} // Optional.[Object].Default: {}.
                    onChange={(year, e) => {
                        if(year.length > 0){
                            this.setState({
                                hasYearError: true
                            })
                        }
                      this.setState({ year });
                      
                      //console.log(e);
                    }} // Required.[Func].Default: () => {}. Will return the value.
                    onBlur={e => {
                        this.checkAge()
                    }} // Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
                    // onFocus={(e) => {console.log(e);}} // Optional.[Func].Default: none.
                    // onClick={(e) => {console.log(e);}} // Optional.[Func].Default: none.
                    validationOption={{
                      name: "Year", // Optional.[String].Default: "". To display in the Error message. i.e Please enter your ${name}.
                      check: true, // Optional.[Bool].Default: true. To determin if you need to validate.
                      required: true, // Optional.[Bool].Default: true. To determin if it is a required field.
                      type: 'number', // Optional.[String].Default: "string". Validation type, options are ['string', 'number'].
                      showMsg: false, // Optional.[Bool].Default: true. To determin display the error message or not.
                      // min: 2, // Optional.[Number].Default: 0. Validation of min length when validationOption['type'] is string, min amount when validationOption['type'] is number.
                      // max: 10, // Optional.[Number].Default: 0. Validation of max length when validationOption['type'] is string, max amount when validationOption['type'] is number.
                      // length: 2, // Optional.[Number].Default: 0. Validation of exact length of the value.
                      // compare: '3', // Optional.[String].Default: "" Compare this value to 3 to see if they are equal.
                      // reg: /^\d{18}|\d{15}$/, // Optional.[Bool].Default: "" Custom regex.
                      // regMsg: 'failed in reg.test(${value})', // Optional.[String].Default: "" Custom regex error message.
                      // locale: 'en-US', // Optional.[String].Default: "en-US". For error message display. Current options are ['zh-CN', 'en-US']; Default is 'en-US'.
                      // msgOnError: "Your custom error message if you provide the validationOption['msgOnError']", // Optional.[String].Default: "" Show your custom error message no matter what when it has error if it is provied.
                      // msgOnSuccess: "Your custom success message if you provide the validationOption['msgOnSuccess']. Otherwise, it will not show, not even green border." // Optional.[String].Default: "". Show your custom success message no matter what when it has error if it is provied.
                      customFunc: res => { // Optional.[Func].Default: none. Custom function. Returns true or err message
                        if (res.length != 4) {
                            //console.log('custom func yo');
                            this.setState({
                                yearErrorMsg: "Year is not a year",
                                hasYearError: true
                            });
                          return 'Year is not a year';
                        }
                        this.setState({
                            yearErrorMsg: "",
                            hasYearError: false
                        });
                        return true;
                      }

                    }}// end validation options
                  />
                  
                  <Textbox
                    tabIndex="3" //Optional.[String or Number].Default: -1.
                    id={"Month"} //Optional.[String].Default: "".  Input ID.
                    classNameInput="month"
                    classNameWrapper="month-wrap" // Optional.[String].Default: "".
                    classNameContainer="" // Optional.[String].Default: "".
                    name="month" //Optional.[String].Default: "". Input name.
                    type="number" //Optional.[String].Default: "text". Input type [text, password, phone, number].
                    value={month} //Optional.[String].Default: "".
                    placeholder={placeholderMonth}
                    validationCallback={res => {}
                        //console.log('month v cb',res)
                        //this.setState({ hasMonthError: res, validate: false })
                    }
                    onChange={(month, e) => {
                        this.setState({ month });
                        //console.log('month change ', month);
                        if(month == 0 || month > 12){
                            this.setState({
                                monthErrorMsg: "Please select month",
                                hasMonthError: true
                            })
                        }else{
                            this.setState({
                                monthErrorMsg: "",
                                hasMonthError: false
                            })
                        }
                    }} //Required.[Func].Default: () => {}. Will return the value.
                    onBlur={(e,month) => {
                            //console.log('month blur res ',e);
                            this.checkAge();
                            
                    }} //Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
                    validationOption={{
                        name: "Month", //Optional.[String].Default: "". To display in the Error message. i.e Please enter your {name}.
                        check: true, //Optional.[Bool].Default: true. To determin if you need to validate.
                        required: true, //Optional.[Bool].Default: true. To determin if it is a required field.
                        type: "number",
                        min: 1,
                        //max: 12,
                        msgOnError: "Please provide a valid month",
                        showMsg: false, // Optional.[Bool].Default: true. To determin display the error message or not.
                        customFunc: res => {
                            //console.log('cust month res ',res);
                            if (parseInt(res) == 0 || parseInt(res).length >2 || parseInt(res) > 12) {
                                //console.log('invalid month');
                                this.setState({
                                    monthErrorMsg: "Please select a valid month",
                                    hasMonthError: true
                                });
                                this.checkAge();
                              return;
                            }
                            this.setState({
                                monthErrorMsg: "",
                                hasMonthError: false
                            });
                            return true;
                        } 
                    }}
                />

                <Textbox
                    tabIndex="4" //Optional.[String or Number].Default: -1.
                    id={"Day"} //Optional.[String].Default: "".  Input ID.
                    classNameInput="day"
                    classNameWrapper="day-wrap"
                    name="day" //Optional.[String].Default: "". Input name.
                    type="number" //Optional.[String].Default: "text". Input type [text, password, phone, number].
                    value={day} //Optional.[String].Default: "".
                    placeholder={placeholderDay}
                    validationCallback={res =>
                        this.setState({ hasMonthError: res, validate: false })
                    }
                    onChange={(day, e) => {
                        this.setState({ day });
                    
                        if(day == "" || day == 0){
                            this.setState({
                                dayErrorMsg: "Please select a valid day",
                                hasDayError: true
                            });
                        }else{
                            this.setState({
                                dayErrorMsg: "",
                                hasDayError: false
                            })
                        }
                    }} //Required.[Func].Default: () => {}. Will return the value.
                    onBlur={e => {
                        this.checkAge();
                    }} //Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
                    validationOption={{
                        name: "Day", //Optional.[String].Default: "". To display in the Error message. i.e Please enter your {name}.
                        check: true, //Optional.[Bool].Default: true. To determin if you need to validate.
                        required: true, //Optional.[Bool].Default: true. To determin if it is a required field.
                        type: "number",
                        min: 1,
                        max: 31,
                        msgOnError: "Please provide a valid day",
                        showMsg: false, // Optional.[Bool].Default: true. To determin display the error message or not.
                        customFunc: res => {
                            //console.log('custom day func');
                            //Optional.[Func].Default: none. Custom function. Returns true or err message
                            res = parseInt(res);
                            if (res <= 0 || res >= 32) {
                                this.setState({
                                    dayErrorMsg: "Please select a valid day",
                                    hasDayError: true
                                });
                                return;
                            }
                            this.setState({
                                dayErrorMsg: "",
                                hasDayError: false
                            })
                            //this.validateForm()
                            return true;
                        } 
                    }}
                />
                </div>

                  {provErrorMsg == "" ? ( "" ) : (
                        <div className="errorMsg">
                            <TranslatableText dictionary={{
                                en: "Please enter a province",
                                fr: "Veuillez inscrire votre province"}}>
                            </TranslatableText>
                        </div>
                  )}
                  {yearErrorMsg == "" ? ( "" ) : (
                        <div className="errorMsg">
                            <TranslatableText dictionary={{
                                en: "Please enter a valid year",
                                fr: "Veuillez inscrire une année valide"}}>
                            </TranslatableText>
                        </div>
                  )}
                  {monthErrorMsg == "" ? ( "" ) : (
                        <div className="errorMsg">
                            <TranslatableText dictionary={{
                                en: "Please enter a valid month",
                                fr: "Veuillez inscrire un mois"}}>
                            </TranslatableText>
                        </div>
                  )}
                  {dayErrorMsg == "" ? ( "" ) : (
                        <div className="errorMsg">
                            <TranslatableText dictionary={{
                                en: "Please enter a day",
                                fr: "Veuillez inscrire un jour"}}>
                            </TranslatableText>
                        </div>
                  )}
                  {ageErrorMsg == "" ? ( "" ) : (
                        <div className="errorMsg">
                            <TranslatableText dictionary={{
                                en: "Sorry, you must be of legal age to experience the wonders of our site.",
                                fr: "Désolé, vous devez avoir l’âge légal pour voir les merveilles sur notre site."}}>
                            </TranslatableText>
                        </div>
                  )}
               
                
                 <button
                    className={`age-submit button ${
                        this.checkHasError() ? "disabled" : "enabled"
                    }`}
                    onClick={
                        this.checkHasError()
                        ? e => {
                            e.preventDefault();
                            return;
                            }
                        : this.validateForm
                    }
                    >
                    <TranslatableText dictionary={{
                        en: "enter",
                        fr: "entrer"}}>
                    </TranslatableText>
                </button> 
                <input type="submit" style={{ display: "none" }} />
            </form>
            <p className="error hide">
                <TranslatableText dictionary={{
                    en: "Sorry, you must be of legal age to experience the wonders of our site.",
                    fr: "Désolé, vous devez avoir l’âge légal pour voir les merveilles sur notre site."}}>
                </TranslatableText>
            </p>

            <p className="legal">
                        <TranslatableText dictionary={{
                            en: "By entering this site, you agree to the website ",
                            fr: " En entrant sur ce site, vous acceptez " }}>
                        </TranslatableText>
                        <a href={ this.state.theLang == 'fr' ? termsPdf_fr : termsPdf} target="_blank">
                            <TranslatableText dictionary={{ 
                                en: "terms of use",
                                fr: "les conditions d’utilisation " }}>
                            </TranslatableText>
                        </a>
                        <TranslatableText dictionary={{ 
                                en: ", our ",
                                fr: ", du site web, la  " }}>
                            </TranslatableText>
                        <a href={ this.state.theLang == 'fr' ? privacyPdf_fr : privacyPdf} target="_blank" className="privacy" >
                            <TranslatableText dictionary={{ 
                                en: "privacy policy, ",
                                fr: "politique de confidentialité " }}>
                            </TranslatableText>
                        </a>
                        <TranslatableText dictionary={{ 
                            en: "and the use of ",
                            fr: "et l’utilisation des " }}>
                        </TranslatableText>
                        <a href={ this.state.theLang == 'fr' ? cookiePdf_fr : cookiePdf} target="_blank" className="privacy" >
                            <TranslatableText dictionary={{ 
                                en: "cookies.",
                                fr: "témoins." }}>
                            </TranslatableText>
                        </a>

                      
                        
                    </p>
                    
            </React.Fragment>
        );
    }
}

export default AgeForm;