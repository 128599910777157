import React from 'react';
import './style.scss';
import { NavLink, Link } from 'react-router-dom';
import ContainerContent from '../../templates/ContainerContent/ContainerContent';
import { LanguageConsumer } from '../../providers/LanguageProvider';
import { TranslatableText } from '../../providers/LanguageProvider';
import privacyPdf from '../../assets_global/pdf/Truss-Beverages-Privacy-Policy---EN_May-2020.pdf';
import privacyPdf_fr from '../../assets_global/pdf/Truss-Beverages-Privacy-Policy---FR_June-2020.pdf';
import termsPdf from '../../assets_global/pdf/Truss-Beverages-Terms-of-Use---Website---EN_May-2020.pdf';
import termsPdf_fr from '../../assets_global/pdf/Truss-Beverages-Terms-of-Use---Website---FR_June-2020.pdf';

const Backdrop = (props, context) => (

    <div className={`menu-backdrop ${props.show ? 'open' : null }`}>
       
            <div className="modal-menu-wrap">
                <ContainerContent>
                    <LanguageConsumer>
                        {(context) => (
                            <nav className="modal-menu">
                                <ul>
                                    <li>
                                        <NavLink to={`${context.language == 'fr' ? '/fr' : ''}/house-of-terpenes`} onClick={props.menuToggle}>House of Terpenes</NavLink>
                                    </li>
                                    <li>
                                    {/*  <NavLink to="/little-victory" onClick={props.menuToggle}>Little Victory</NavLink> */}
                                        <NavLink to={`${context.language == 'fr' ? '/fr' : ''}/little-victory`} onClick={props.menuToggle}>Little Victory</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`${context.language == 'fr' ? '/fr' : ''}/mollo`} onClick={props.menuToggle}>Mollo</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`${context.language == 'fr' ? '/fr' : ''}/veryvell`} onClick={props.menuToggle}>Veryvell</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={`${context.language == 'fr' ? '/fr' : ''}/xmg`} onClick={props.menuToggle}>XMG</NavLink>
                                    </li>
                                    {/* <li>
                                        <NavLink to={`${context.language == 'fr' ? '/fr' : ''}/flow-glow`} onClick={props.menuToggle}>Flow Glow</NavLink>
                                    </li> */}
                                    
                                    
                                </ul>
                            </nav>
                        )}
                    </LanguageConsumer>
                
                    <div className="divider"><span className="line"></span></div>
                    <nav className="menu-more-options">
                        <ul>
                       
                            <LanguageConsumer>
                                {(context) => (
                                    <React.Fragment>
                                        <li>
                                            <NavLink 
                                                to={`${context.language == 'fr' ? '/fr' : ''}/about`} 
                                                onClick={props.menuToggle}
                                            >
                                                <TranslatableText dictionary={{ 
                                                    en: "About", 
                                                    fr: "À propos" }}>
                                                </TranslatableText>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink 
                                                to={`${context.language == 'fr' ? '/fr' : ''}/faq`} 
                                                onClick={props.menuToggle}
                                            >
                                                <TranslatableText dictionary={{ 
                                                    en: "FAQ", 
                                                    fr: "FAQ" }}>
                                                </TranslatableText>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink 
                                                to={`${context.language == 'fr' ? '/fr' : ''}/where-to-buy`} 
                                                onClick={props.menuToggle}
                                            >
                                                <TranslatableText dictionary={{ 
                                                    en: "Where to buy", 
                                                    fr: "Où nous trouver" }}>
                                                </TranslatableText>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink 
                                                to={`${context.language == 'fr' ? '/fr' : ''}/subscribe`} 
                                                onClick={props.menuToggle}
                                            >
                                                <TranslatableText dictionary={{ 
                                                    en: "Subscribe", 
                                                    fr: "S’inscrire" }}>
                                                </TranslatableText>
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink 
                                                to={`${context.language == 'fr' ? '/fr' : ''}/contact`} 
                                                onClick={props.menuToggle}
                                            >
                                                <TranslatableText dictionary={{ 
                                                    en: "Contact Us", 
                                                    fr: "Contact" }}>
                                                </TranslatableText>
                                            </NavLink>
                                        </li>
                                        <li>
                                            {context.language == 'fr' ? <NavLink to="/" onClick={context.updateLanguage} data-lang="en">English</NavLink> :  <NavLink to="/fr" onClick={context.updateLanguage} data-lang="fr">Français</NavLink>}
                                        </li>
                                        <li><a href={ context.language == 'fr' ? privacyPdf_fr : privacyPdf} target="_blank">
                                            <TranslatableText dictionary={{ 
                                                en: "Privacy Policy",
                                                fr: "Confidentialité" }}>
                                            </TranslatableText></a>
                                            </li>
                                            <li><a href={ context.language == 'fr' ? termsPdf_fr : termsPdf} target="_blank">
                                                <TranslatableText dictionary={{ 
                                                    en: "Terms & Conditions",
                                                    fr: "Modalités d'utilisation" }}>
                                                </TranslatableText>
                                            </a>
                                        </li>
                                        <li className="social-buttons">
                                            <a className="link-out" href="https://www.linkedin.com/company/truss-beverages/?originalSubdomain=ca" target="_blank" ><i className="lab la-linkedin-in"></i></a>
                                            <a className="link-out" href="https://twitter.com/trussbeverages" target="_blank" ><i className="lab la-twitter"></i></a>
                                            <a className="link-out" href="https://facebook.com/trussbeverages" target="_blank"><i className="lab la-facebook-f"  ></i></a>
                                            <a className="link-out" href="https://www.instagram.com/trussbeverages/?hl=en"  target="_blank"><i className="lab la-instagram" ></i></a>
                                        </li>
                                        <li>
                                            <p className="legal">
                                                <TranslatableText dictionary={{ 
                                                    en: "© 2021 Truss Beverage Co.™, used under licence. All rights reserved. This site is intended for persons of legal age in the province in which it is accessed. ",
                                                    fr: "© 2021 Truss Beverage Co.(MC), utilisée sous licence. Tous droits réservées. Ce site est destiné aux personnes qui ont l’âge légal dans la province depuis laquelle il est accessible." }}>
                                                </TranslatableText>
                                            </p>
                                        </li>
                                    </React.Fragment>
                                )}
                            </LanguageConsumer>
                                       
                        </ul>
                        </nav>
                     
                </ContainerContent>
            </div>

    </div>

)
export default Backdrop;