import React from 'react';
import './style.scss';
import ContainerContent from '../../../../templates/ContainerContent/ContainerContent';
import { TranslatableText } from '../../../../providers/LanguageProvider';
import { LanguageConsumer } from '../../../../providers/LanguageProvider';
import * as ScrollMagic from "scrollmagic"; // Or use scrollmagic-with-ssr to avoid server rendering problems
import { TimelineMax, Linear } from "gsap"; // Also works with TweenLite and TimelineLite
import xmg_drops from '../../i/xmg_drops_product.png';

import Calendar from '../../../../components/svgIcons/Calendar';
//import Can from '../../../../components/svgIcons/Can';
import Cocktail3 from '../../../../components/svgIcons/Cocktail3';
import PotencyXmgDrops from '../../../../components/svgIcons/PotencyXmgDrops';
import Highlights from '../../../../components/svgIcons/Highlights';


class XmgDrops extends React.Component {
    constructor(props){
        super(props)
        this.controller = new ScrollMagic.Controller();
    }
    componentWillUnmount() {
        this.controller.destroy();
    }
    componentDidMount(){
        let title = document.getElementById("title-anim-4");
        let timeline = new TimelineMax();
        timeline.to(title, 1, {y:110, ease: Linear.easeNone} );
        let scene4 = new ScrollMagic.Scene({triggerElement: ".four", triggerHook: 1, duration: '100%', tweenChanges: true});
            scene4.setTween(timeline)
            .on("enter leave", this.addBottleAnimation)
            .on("progress", function (event) {
                //console.log("Scene progress changed to " + event.progress);
            })
            //.addIndicators() // add indicators (requires plugin)
            .addTo(this.controller);
    }
    addBottleAnimation = () => { 
        let bottles = document.getElementById('img-wrap-4');
        bottles.classList.add('anim');   
    }
    render() {
        return (
            <React.Fragment>
                <div className="blockwrap dark title-wrap">
                    <ContainerContent>
                        <h2>
                            <TranslatableText dictionary={{
                                en: "Cannabis Extracts",
                                fr: "Extraits de cannabis" }}>
                            </TranslatableText>
                        </h2> 
                    </ContainerContent>
                </div>
                <div className="blockwrap four dark"  id={this.props.bevId}>
                    <ContainerContent>
                        {
                            this.props.isDesktop ?
                                <div className="img-wrap " id="img-wrap-4">
                                    <img src={xmg_drops} className="xmg-drops bottle" alt="XMG™ - Drops" />
                                {/*  <img src={lv_bottle_yellow} className="bottle-yellow yellow-2 bottle abs" /> */}
                                </div>
                            :
                            null
                        }
                        <div className="info-wrap">

                            <h2 id="title-anim-4">
                                <TranslatableText dictionary={{
                                    en: "Drops for when",
                                    fr: "Des gouttes pour" }}>
                                </TranslatableText><br />
                                <TranslatableText dictionary={{
                                    en: "you want X.",
                                    fr: "X raisons." }}>
                                </TranslatableText>
                            </h2>  

                            <div id="beverage-info-4" className="beverage-info">
                                
                                <LanguageConsumer>
                                    {(context) => (
                                        <React.Fragment>
                                            {context.language !== 'fr' ? 
                                                    <h3>XMG™ Drops</h3>
                                                : 
                                                    <h3>Gouttes XMG™</h3>
                                            }
                                        </React.Fragment>
                                    )}
                                </LanguageConsumer>
                                
                                <p>
                                    <TranslatableText dictionary={{
                                        en: "Drop it and explore.",
                                        fr: "Verser et explorer." }}>
                                    </TranslatableText>
                                </p>


                                {
                                    this.props.isDesktop ?
                                        null
                                    :
                                        <div id="img-wrap-4" className="img-wrap">
                                            <img src={xmg_drops} className="xmg-drops bottle" alt="XMG™ - Tingle Drops" />
                                        {/*  <img src={vv_bottle_cbd} className="bottle-yellow yellow-2 bottle abs" /> */}
                                        </div>
                                }

                                <ul className="standard-list">
                                    <li>
                                        <PotencyXmgDrops />
                                        <span>
                                            <h4>
                                                <TranslatableText dictionary={{
                                                    en: "POTENCY",
                                                    fr: "intensité" }}>
                                                </TranslatableText>
                                            </h4>
                                            <p> 

                                                <TranslatableText dictionary={{
                                                    en: "2 drops =  ~1mg THC",
                                                    fr: "2 gouttes =  env. 1 mg THC" }}>
                                                </TranslatableText>
                                                {/* <br />
                                                <TranslatableText dictionary={{
                                                    en: "",
                                                    fr: ": ENV. 0,5 mg CBD" }}>
                                                </TranslatableText> */}
                                            </p>
                                        </span>
                                    </li>
                                    <li>
                                        <Calendar />
                                        <span>
                                            <h4>
                                                <TranslatableText dictionary={{
                                                    en: "OCCASIONS",
                                                    fr: "OCCASIONS" }}>
                                                </TranslatableText>
                                            </h4>
                                            <p>
                                                <TranslatableText dictionary={{
                                                    en: "Deep exploration. ",
                                                    fr: "Pour une exploration profonde." }}>
                                                </TranslatableText><br />
                                                <TranslatableText dictionary={{
                                                    en: "Expanding your view.",
                                                    fr: "Pour élargir vos horizons." }}>
                                                </TranslatableText>
                                            </p>
                                        </span>
                                    </li>
                                    <li>
                                        <Cocktail3 />
                                        <span>
                                            <h4>
                                                <TranslatableText dictionary={{
                                                    en: "SERVING SUGGESTION",
                                                    fr: "NOTES DE SERVICE" }}>
                                                </TranslatableText>
                                            </h4>
                                            <p>
                                                <TranslatableText dictionary={{
                                                    en: "Drop. Xplore.",
                                                    fr: "Verser. Explorer." }}>
                                                </TranslatableText>
                                            </p>
                                        </span>
                                    </li>
                                    <li>
                                        <Highlights />
                                        <span>
                                            <h4>
                                                <TranslatableText dictionary={{
                                                    en: "HIGHLIGHTS",
                                                    fr: "INFORMATIONS" }}>
                                                </TranslatableText>
                                            </h4>
                                            <p>  
                                                <TranslatableText dictionary={{
                                                    en: "Water-soluble, THC drops.",
                                                    fr: "Soluble dans l’eau. Gouttes de THC." }}>
                                                </TranslatableText><br />
                                                <TranslatableText dictionary={{
                                                    en: "X the way you like it.",
                                                    fr: "Un X comme vous l’aimez." }}>
                                                </TranslatableText>
                                            </p>
                                        </span>
                                    </li>
                                </ul>


                            </div>

                        </div>
                    </ContainerContent>
                </div>
            </React.Fragment>
        )
    }
}
export default XmgDrops;