import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const MagnifyGlass = () => {
    
    return (

        <svg  id="magnify-glass" xmlns="http://www.w3.org/2000/svg" width="22.16" height="21.629" viewBox="0 0 22.16 21.629">
            <g data-name="magnify-glass" transform="translate(-1116 -50)">
                <g id="Ellipse_371" data-name="Ellipse 371" transform="translate(1116 50)" fill="none"  className="mag-stroke" strokeWidth="2">
                <circle cx="9.5" cy="9.5" r="9.5" stroke="none"/>
                <circle  cx="9.5" cy="9.5" r="8.5" fill="none"/>
                </g>
                <g id="Path_632" data-name="Path 632" transform="translate(1132.228 64.278) rotate(45)" >
                <path className="mag-path" d="M 7.888079643249512 1.507476449012756 L 0.4999998211860657 1.507476449012756 L 0.4999998211860657 0.4999964833259583 L 7.888079643249512 0.4999964833259583 L 7.888079643249512 1.507476449012756 Z" stroke="none"/>
                <path className="mag-path" d="M 1 0.999996542930603 L 1 1.007476568222046 L 7.388079643249512 1.007476568222046 L 7.388079643249512 0.999996542930603 L 1 0.999996542930603 M 0 -3.576278686523438e-06 L 8.388079643249512 -3.576278686523438e-06 L 8.388079643249512 2.007476568222046 L 0 2.007476568222046 L 0 -3.576278686523438e-06 Z" stroke="none" />
                </g>
            </g>
        </svg>

    )
}
export default MagnifyGlass;