import React from 'react';
import './style.scss';

const PotencyXmgDrops = (props) => (
    <span className="icon">

        <svg xmlns="http://www.w3.org/2000/svg" width="48.676" height="48.649" viewBox="0 0 48.676 48.649">
            <defs>
                <clipPath id="clip-path">
                <path id="Path_995" data-name="Path 995" d="M0-54.144H48.676v-48.649H0Z" transform="translate(0 102.793)" fill="#fff"/>
                </clipPath>
            </defs>
            <g id="Group_802" data-name="Group 802" transform="translate(0 102.793)">
                <g id="Group_808" data-name="Group 808" transform="translate(0 -102.793)" clipPath="url(#clip-path)">
                <g id="Group_802-2" data-name="Group 802" transform="translate(6.923 6.921)">
                    <path id="Path_989" data-name="Path 989" d="M-19.375-38.752a17.355,17.355,0,0,1-7.073-1.514,17.289,17.289,0,0,1-9.171-9.663,17.291,17.291,0,0,1,.349-13.317,16.987,16.987,0,0,1,15.9-10.326v2.238a14.8,14.8,0,0,0-13.856,9,15.065,15.065,0,0,0-.3,11.6,15.064,15.064,0,0,0,7.991,8.42,15.062,15.062,0,0,0,11.6.3A15.067,15.067,0,0,0-5.514-50,15.185,15.185,0,0,0-13.2-70.023l.91-2.045a17.427,17.427,0,0,1,8.821,22.98,17.291,17.291,0,0,1-9.663,9.17,17.4,17.4,0,0,1-6.243,1.165" transform="translate(36.784 73.572)" fill="#fff" opacity="0.25"/>
                </g>
                <g id="Group_803" data-name="Group 803" transform="translate(24.338 6.921)">
                    <path id="Path_990" data-name="Path 990" d="M-6.866-3.95a14.478,14.478,0,0,0-6.169-1.311V-7.5a16.62,16.62,0,0,1,7.079,1.5Z" transform="translate(13.035 7.499)" fill="#fff" opacity="0.25"/>
                </g>
                <g id="Group_804" data-name="Group 804" transform="translate(0 0)">
                    <path id="Path_991" data-name="Path 991" d="M-27.047-54.143a24.177,24.177,0,0,1-9.858-2.1A24.165,24.165,0,0,1-49.72-69.75a24.167,24.167,0,0,1,.487-18.611,23.74,23.74,0,0,1,22.222-14.431v2.238a21.555,21.555,0,0,0-20.177,13.1A22.112,22.112,0,0,0-36-58.29,22.114,22.114,0,0,0-6.834-69.483,22.112,22.112,0,0,0-18.027-98.645l.91-2.045A24.167,24.167,0,0,1-4.3-87.184a24.167,24.167,0,0,1-.487,18.611,24.363,24.363,0,0,1-22.258,14.43" transform="translate(51.349 102.792)" fill="#fff" opacity="0.25"/>
                </g>
                <g id="Group_805" data-name="Group 805" transform="translate(25.492 19.752)">
                    <path id="Path_992" data-name="Path 992" d="M-2.71-9.183A2.435,2.435,0,0,0-.275-11.618v-1.177A2.6,2.6,0,0,0-.58-14.016l-1.682-3.149a.508.508,0,0,0-.448-.268.5.5,0,0,0-.447.268l-1.682,3.149a2.6,2.6,0,0,0-.306,1.222v1.177A2.435,2.435,0,0,0-2.71-9.183" transform="translate(5.145 17.434)" fill="#fff"/>
                </g>
                <g id="Group_806" data-name="Group 806" transform="translate(18.313 19.752)">
                    <path id="Path_993" data-name="Path 993" d="M-2.709-9.183A2.434,2.434,0,0,0-.274-11.618v-1.177a2.6,2.6,0,0,0-.305-1.222l-1.682-3.149a.508.508,0,0,0-.449-.268.5.5,0,0,0-.446.268l-1.682,3.149a2.6,2.6,0,0,0-.306,1.222v1.177A2.435,2.435,0,0,0-2.709-9.183" transform="translate(5.144 17.434)" fill="#fff"/>
                </g>
                <g id="Group_807" data-name="Group 807" transform="translate(24.338 0)">
                    <path id="Path_994" data-name="Path 994" d="M-10-4.616a21.074,21.074,0,0,0-8.984-1.91V-8.763a23.214,23.214,0,0,1,9.894,2.1Z" transform="translate(18.982 8.763)" fill="#fff"/>
                </g>
                </g>
            </g>
        </svg>

    </span>

)
export default PotencyXmgDrops;