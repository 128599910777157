import React from 'react';
import './style.scss';

const burgerButton = (props) => (

    <div className="btn-wrap">
        <div id="nav-icon2"  onClick={props.menuToggle} className={`btn-burger-menu ${props.show ? 'open' : null}`}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    </div>
)
export default burgerButton;