import React from 'react';
import './style.scss';
import ContainerContent from '../../../../templates/ContainerContent/ContainerContent';
import { TranslatableText } from '../../../../providers/LanguageProvider';
import { LanguageConsumer } from '../../../../providers/LanguageProvider';
import * as ScrollMagic from "scrollmagic"; // Or use scrollmagic-with-ssr to avoid server rendering problems
import { TimelineMax, Linear } from "gsap"; // Also works with TweenLite and TimelineLite
import vv_bottle_thc from '../../i/veryvell_5.png';
import Calendar from '../../../../components/svgIcons/Calendar';
import Cocktail3 from '../../../../components/svgIcons/Cocktail3';
//import PotencyLow from '../../../../components/svgIcons/PotencyLow';
import PotencyVvYawn from '../../../../components/svgIcons/PotencyVvYawn';
import Highlights from '../../../../components/svgIcons/Highlights';
import VerywellLinesStatic from '../../i/VerywellLinesStatic';

class ThcDrops extends React.Component {
    constructor(props){
        super(props)
        // basic initialization
        this.controller = new ScrollMagic.Controller();
    }
    componentWillUnmount() {
        this.controller.destroy();
    }
    componentDidMount = () => {
    
        var title = document.getElementById("title-anim-6");
        //var list = document.getElementById("beverage-info-6");
        //var imgWrap = document.getElementById("img-wrap-6");
                               
        var timeline = new TimelineMax()
        timeline.to(title, 1, {y:110, /* textShadow: "1px 1px 1px rgb(204, 46, 76)" ,*/  ease: Linear.easeNone} )
        //timeline.to(list, 1, {y:95,   ease: Linear.easeNone}, 0)
        // timeline.from(list, 1, {y:95,   ease: Linear.easeNone}, 0)
        //timeline.to(imgWrap, 1, {y:105,   ease: Linear.easeNone}, 0)
        
        // build scenes
        new ScrollMagic.Scene({triggerElement: ".six", triggerHook: 1, duration: '100%',tweenChanges: true})
            //.setPin("#pin")
            .setTween(timeline)
           // .setClassToggle(".img-wrap", "anim")
            .on("enter leave", this.addBottleAnimation)
            .on("progress", function (event) {
                //console.log("Scene progress changed to " + event.progress);
            })
            //.addIndicators() // add indicators (requires plugin)
            .addTo(this.controller);
        
        // scrollTo 

    }
    
    addBottleAnimation = () => {
        let bottles =  document.getElementById('img-wrap-6');
        bottles.classList.add('anim');   
    }
    render() {
        return (
            <div className="blockwrap six dark"  id={this.props.bevId}>
                <VerywellLinesStatic />
                <ContainerContent>
                    {   this.props.isDesktop ? 
                            <div id="img-wrap-6" className="img-wrap">
                                <img src={vv_bottle_thc} id="botl" className="bottle-yawn bottle" alt="Veryvell™ - THC Drops" />
                                {/* <img src={vv_bottle_strawberry} id="botl2" className="bottle-strawberry strawberry-2 bottle abs" /> */}
                            </div>
                    
                        : null
                    }
                    <div className="info-wrap">

                        <h2 id="title-anim-6">
                            <TranslatableText dictionary={{
                                en: "Get the drop",
                                fr: "Une goutte de" }}>
                            </TranslatableText><br />
                            <TranslatableText dictionary={{
                                en: "on rest.",
                                fr: "tranquillité." }}>
                            </TranslatableText>
                            
                        </h2>
                        <div id="beverage-info-6" className="beverage-info" >
                               
                            <LanguageConsumer>
                                {(context) => (
                                    <React.Fragment>
                                        {context.language !== 'fr' ? 
                                                <h3>Veryvell™<br /> Yawn Drops</h3>
                                            : 
                                                <h3>Veryvell™<br />Bâillement Gouttes de Boisson</h3>
                                        }
                                    </React.Fragment>
                                )}
                            </LanguageConsumer>

                            <p> 
                                <TranslatableText dictionary={{
                                    en: "Drop it and drift. ",
                                    fr: "Un goût à dormir debout." }}>
                                </TranslatableText>
                            </p>

                            {
                                this.props.isDesktop ? null
                                : 
                                <div id="img-wrap-6" className="img-wrap">
                                    <img src={vv_bottle_thc} id="botl" className="bottle-yawn bottle" alt="Veryvell - THC Drops" />
                                    {/* <img src={vv_bottle_strawberry} id="botl2" className="bottle-strawberry strawberry-2 bottle abs" /> */}
                                </div>
                            }

                            <ul id="standard-list" className="standard-list">
                                <li>
                                    <PotencyVvYawn />
                                    <span>
                                    <h4><TranslatableText dictionary={{
                                            en: "POTENCY",
                                            fr: "intensité" }}>
                                        </TranslatableText>
                                    </h4>
                                        <p>
                                            <TranslatableText dictionary={{
                                                en: "2 DROPS = ~ 1MG THC",
                                                fr: "2 gouttes = ENV. 1 mg THC" }}>
                                            </TranslatableText>
                                        </p>
                                    </span>
                                </li>
                                <li>
                                    <Calendar />
                                    <span>
                                        <h4>
                                            <TranslatableText dictionary={{
                                                en: "OCCASIONS",
                                                fr: "OCCASIONS" }}>
                                            </TranslatableText>
                                        </h4>

                                        <p>
                                            <TranslatableText dictionary={{
                                                en: "Slipping under the duvet.",
                                                fr: "En arrivant sous la couette." }}>
                                            </TranslatableText><br />  
                                            <TranslatableText dictionary={{
                                                en: "Counting sheep.",
                                                fr: "En comptant les moutons." }}>
                                            </TranslatableText>    
                                        </p>
                                    </span>
                                </li>
                                <li>
                                    <Cocktail3 />
                                    <span>
                                        <h4><TranslatableText dictionary={{
                                                en: "SERVING SUGGESTION",
                                                fr: "NOTES DE SERVICE" }}>
                                            </TranslatableText>
                                        </h4>
                                        <p>
                                            <TranslatableText dictionary={{
                                                en: "Add to your mug and drift.",
                                                fr: "Ajouter dans une tasse. S’assoupir." }}>
                                            </TranslatableText>
                                        </p>
                                    </span>
                                </li>
                                <li>
                                    <Highlights />
                                    <span>
                                        <h4><TranslatableText dictionary={{
                                            en: "HIGHLIGHTS",
                                            fr: "INFORMATIONS" }}>
                                        </TranslatableText>
                                    </h4>
                                    <p>


                                        <TranslatableText dictionary={{
                                            en: "Water-soluble with a subtle hint of  ",
                                            fr: "Soluble dans l’eau, avec une légère" }}>
                                        </TranslatableText><br />
                                        <TranslatableText dictionary={{
                                            en: "chamomile extract. Freedom to choose ",
                                            fr: "touche d’extrait de camomille." }}>
                                        </TranslatableText><br />
                                        <TranslatableText dictionary={{
                                            en: "your dose.",
                                            fr: "Libre de choisir sa dose." }}>
                                        </TranslatableText>
                                    </p>
                                    </span>
                                </li>
                            </ul>

                        </div>

                    </div>

                </ContainerContent>

            </div>
        )
    }
}
export default ThcDrops;