import React from 'react';
import './style.scss';
import trussDroplet from './i/truss-droplet.png';

const Droplet = (props) => {
    
    return(

        <div className={`droplet-wrap ${ props.show ? 'hide' : ''} ${props.currentPage}`}>
            <img src={trussDroplet} className="bounce" alt="droplet icon" />
        </div>

    )
}
export default Droplet;