import React from 'react';
import './style.scss';
import ContainerContent from '../../templates/ContainerContent/ContainerContent';
import Footer from '../../components/Footer/Footer';
import { TranslatableText } from '../../providers/LanguageProvider';
import xmgCan_1 from './i/xmg_1.png';
import xmgCan_2 from './i/xmg_2.png';
import xmg_drinks from './i/xmg-group.png';
import TropicalFruit from './scenes/TropicalFruit/TropicalFruit';
import MangoPineapple from './scenes/MangoPineapple/MangoPineapple';
import XmgDrops from './scenes/XmgDrops/XmgDrops';

class Xmg extends React.Component {
    constructor(props){
        super(props)

        this.state ={
            isDesktop: props.info.isDesktop,
        }
        
    }
    render() {

        return (
            <React.Fragment>
                
                <div className={`page-wrap`}>

                    <div className="blockwrap first">

                        <ContainerContent>

                            <span className="heading-wrap">
                                <h1>  
                                    <TranslatableText dictionary={{
                                        en: "A range of",
                                        fr: "UNE GAMME DE" }}>
                                    </TranslatableText><br />
                                    <TranslatableText dictionary={{
                                        en: "high intensity",
                                        fr: "POTIONS À INTENSITÉ" }}>
                                    </TranslatableText><br />
                                    <TranslatableText dictionary={{
                                        en: "flavoured potions.",
                                        fr: "ÉLEVÉE." }}>
                                    </TranslatableText><br />
                                    <TranslatableText dictionary={{
                                        en: "Find your X.",
                                        fr: "TROUVEZ VOTRE X." }}>
                                    </TranslatableText>
                                    
                                </h1>  
                            </span>

                            <div className="img-wrap">
                                <img src={xmgCan_1} className="bottle-tropical-fruit bottle bottle-bounce-1" alt="XMG - Tropical Fruit" />
                                <img src={xmgCan_2} className="bottle-mango-pineapple bottle abs bottle-bounce-2" alt="XMG - Mango Pineapple" /> 
                            </div>
                            
                        </ContainerContent>

                    </div>
                    <div className="blockwrap title-wrap">
                    <ContainerContent>
                        <h2>
                            <TranslatableText dictionary={{
                                en: "BEVERAGES",
                                fr: "BOISSONS" }}>
                            </TranslatableText>
                        </h2>
                    </ContainerContent>
                </div>
                    <TropicalFruit isDesktop={this.state.isDesktop} bevId="beverage-2" />
                    <MangoPineapple isDesktop={this.state.isDesktop} bevId="beverage-3" /> 
                    <XmgDrops isDesktop={this.state.isDesktop} bevId="beverage-4" />

                    <div className="blockwrap dark brandline">
                        <ContainerContent>
                            <h2>
                                <TranslatableText dictionary={{
                                    en: "What I’ll look like ",
                                    fr: "Voici à quoi je ressemble lorsque " }}>
                                </TranslatableText><br />
                                <TranslatableText dictionary={{
                                    en: "when you bring me home.",
                                    fr: "vous m'apporterez à la maison." }}>
                                </TranslatableText>
                            </h2>
                            
                            <img src={xmg_drinks} alt="XMG - Family of Beverages" />
                        </ContainerContent>
                    </div>

                    <Footer instagram="findyourxmg" addClass="dark" />

                </div>
            </React.Fragment>
        )
    }
}
export default Xmg;







 

