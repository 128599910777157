import React from 'react';
import './style.scss';
import ContainerContent from '../../../../templates/ContainerContent/ContainerContent';
import { TranslatableText } from '../../../../providers/LanguageProvider';
import { LanguageConsumer } from '../../../../providers/LanguageProvider';
import * as ScrollMagic from "scrollmagic"; // Or use scrollmagic-with-ssr to avoid server rendering problems
import { TimelineMax, Linear } from "gsap"; // Also works with TweenLite and TimelineLite
import hot_bottle_myrcene from '../../i/hot_2.png';
import hot_bottle_myrcene_badge from '../../i/hot_2_badge.png';
import hot_bottle_myrcene_badge_fr from '../../i/hot_2_badge_fr.png';
import Calendar from '../../../../components/svgIcons/Calendar';
import Cocktail4 from '../../../../components/svgIcons/Cocktail4';
import InfoIcon from '../../../../components/svgIcons/InfoIcon';
//import PotencyLow from '../../../../components/svgIcons/PotencyLow';
import PotencyHouseOt from '../../../../components/svgIcons/PotencyHouseOt';
import Highlights from '../../../../components/svgIcons/Highlights';
import TerpsLinesStatic from '../../i/TerpLinesStatic';
import flowers_1 from '../../i/flowers-1.png';
import NutritionPopUp from '../../../../components/NutritionPopUp/NutritionPopUp';

class MyrceneTonic extends React.Component {
    constructor(props){
        super(props)
        // basic initialization
        this.controller = new ScrollMagic.Controller();
    }
    componentWillUnmount() {
        this.controller.destroy();
    }
    componentDidMount() {
    
        var title = document.getElementById("title-anim");
        //var list = document.getElementById("beverage-info");
        //var imgWrap = document.getElementById("img-wrap");
                            
        var timeline = new TimelineMax()
        timeline.to(title, 1, {y:110, /* textShadow: "1px 1px 1px rgb(204, 46, 76)" ,*/  ease: Linear.easeNone} )
        //timeline.to(list, 1, {y:95,   ease: Linear.easeNone}, 0)
        // timeline.from(list, 1, {y:95,   ease: Linear.easeNone}, 0)
        //timeline.to(imgWrap, 1, {y:105,   ease: Linear.easeNone}, 0)

        // build scenes
        new ScrollMagic.Scene({triggerElement: ".second", triggerHook: 1, duration: '100%',tweenChanges: true})
            //.setPin("#pin")
            .setTween(timeline)
           // .setClassToggle(".img-wrap", "anim")
            .on("enter leave", this.addBottleAnimation)
            .on("progress", function (event) {
                //console.log("Scene progress changed to " + event.progress);
            })
            //.addIndicators() // add indicators (requires plugin)
            .addTo(this.controller);
        
        // scrollTo 

    }
    
    addBottleAnimation = () => {
        let bottles =  document.getElementById('img-wrap');
        bottles.classList.add('anim');   
    }

    render() {
        // let hot_bottle_badge;
        // this.props.lang === 'fr' ? hot_bottle_badge = hot_bottle_myrcene_badge_fr : hot_bottle_badge = hot_bottle_myrcene_badge;
        return (
            <div className="blockwrap second" id={this.props.bevId}>
                <TerpsLinesStatic />

                <div className="bg-fruits">
                    <img src={flowers_1} className="slice-1" alt="flowers red" />
                </div>

                <ContainerContent>
                    
                    {   this.props.isDesktop ? 
                            <div id="img-wrap" className="img-wrap">
                                <img src={hot_bottle_myrcene} id="botl" className="bottle-strawberry bottle" alt="House of Terpenes™ - Myrcene Tonic" />
                                <img src={hot_bottle_myrcene} id="botl2" className="bottle-strawberry strawberry-2 bottle abs" alt="House of Terpenes™ - Myrcene Tonic" />
                            </div>
                    
                        : null
                    }

                    <div className="info-wrap">
                       
                        <h2 id="title-anim">
                            <TranslatableText dictionary={{
                                en: "Earthy, hints of clove ",
                                fr: "Un goût terreux, une" }}>
                            </TranslatableText><br />
                            <TranslatableText dictionary={{
                                en: "and unexpected",
                                fr: "touche de clou de" }}>
                            </TranslatableText><br />
                            <TranslatableText dictionary={{
                                en: "spice.",
                                fr: "girofle et d’épices" }}>
                            </TranslatableText><br />
                            <TranslatableText dictionary={{
                                en: "",
                                fr: "inexplorées." }}>
                            </TranslatableText>
                        </h2>

                        <div id="beverage-info" className="beverage-info" >
                            
                            <LanguageConsumer>
                                {(context) => (
                                    <React.Fragment>
                                        {context.language !== 'fr' ? 
                                                <h3>House of Terpenes™<br /> Myrcene and Sparkling Tonic</h3>
                                            : 
                                                <h3>House of Terpenes™<br /> Tonique pétillant au myrcène</h3>
                                        }
                                    </React.Fragment>
                                )}
                            </LanguageConsumer>
                            <p>
                                <TranslatableText dictionary={{
                                    en: "Tarragon, orange peel, clove and cinnamon ",
                                    fr: "Estragon, écorce d’orange, clou de girofle et " }}>
                                </TranslatableText><br />
                                <TranslatableText dictionary={{
                                    en: "complement the earthiness of Myrcene",
                                    fr: "cannelle, voilà ce qui complète le goût" }}>
                                </TranslatableText><br />
                                <TranslatableText dictionary={{
                                    en: "flavours in this botanically sourced tonic.",
                                    fr: "terreux des saveurs de Myrcène, cette" }}>
                                </TranslatableText><br />
                                <TranslatableText dictionary={{
                                    en: "The tonic base pairs beautifully with the ",
                                    fr: "boisson tonique d’origine botanique." }}>
                                </TranslatableText><br />
                                <TranslatableText dictionary={{
                                    en: "bitterness of cannabis, allowing the",
                                    fr: "L’amertume tonique se marie parfaitement" }}>
                                </TranslatableText><br />
                                <TranslatableText dictionary={{
                                    en: "myrcene flavours to shine.",
                                    fr: " à celle du cannabis, ce qui permet aux" }}>
                                </TranslatableText><br />
                                <TranslatableText dictionary={{
                                    en: "",
                                    fr: "saveurs du myrcène de briller." }}>
                                </TranslatableText>
                            </p>

                            {
                                this.props.isDesktop ? null
                                : 
                                <div id="img-wrap" className="img-wrap">
                                    <img src={hot_bottle_myrcene} id="botl" className="bottle-strawberry bottle" alt="House of Terpenes™ -  Myrcene Tonic" />
                                    <img src={hot_bottle_myrcene} id="botl2" className="bottle-strawberry strawberry-2 bottle abs" alt="House of Terpenes™ -  Myrcene Tonic" />
                                </div>
                            }

                            <ul id="standard-list" className="standard-list">
                                <li>
                                    <PotencyHouseOt />
                                    <span>
                                    <h4><TranslatableText dictionary={{
                                            en: "POTENCY",
                                            fr: "intensité" }}>
                                        </TranslatableText>
                                    </h4>
                                        <p>
                                            <TranslatableText dictionary={{
                                                en: "1:1 MEDIUM",
                                                fr: "1:1 moyenne" }}>
                                            </TranslatableText><br />
                                            <TranslatableText dictionary={{
                                                en: "5MG THC + 5MG CBD",
                                                fr: "5 mg THC : 5 mg CBD" }}>
                                            </TranslatableText>
                                        </p>
                                    </span>
                                </li>
                                <li>
                                    <Calendar />
                                    <span>
                                        <h4>
                                            <TranslatableText dictionary={{
                                                en: "OCCASIONS",
                                                fr: "OCCASIONS" }}>
                                            </TranslatableText>
                                        </h4>

                                        <p> 
                                            <TranslatableText dictionary={{
                                                en: "Extending a dinner party.",
                                                fr: "Pour étirer un souper entre amis." }}>
                                            </TranslatableText><br />  
                                            <TranslatableText dictionary={{
                                                en: "Date nights.",
                                                fr: "Lors d’une « date »." }}>
                                            </TranslatableText>      
                                        </p>
                                    </span>
                                </li>
                                <li>
                                    <Cocktail4 />
                                    <span>
                                        <h4><TranslatableText dictionary={{
                                                en: "SERVING SUGGESTION",
                                                fr: "NOTES DE SERVICE" }}>
                                            </TranslatableText>
                                        </h4>
                                        <p>
                                            <TranslatableText dictionary={{
                                                en: "Serve on ice with a wedge of orange.",
                                                fr: "Servir sur glace avec un quartier d’orange." }}>
                                            </TranslatableText>
                                        </p>
                                    </span>
                                </li>
                                <li>
                                    <Highlights />
                                    <span>
                                        <h4><TranslatableText dictionary={{
                                                en: "HIGHLIGHTS",
                                                fr: "INFORMATIONS" }}>
                                             </TranslatableText>
                                        </h4>
                                    <p>
                                        <TranslatableText dictionary={{
                                            en: "All natural flavours.",
                                            fr: "Saveurs entièrement naturelles." }}>
                                        </TranslatableText><br />
                                        <TranslatableText dictionary={{
                                            en: "Botanically sourced terpene ",
                                            fr: "Saveurs de terpène d’origine" }}>
                                        </TranslatableText><br />
                                        <TranslatableText dictionary={{
                                            en: "flavours.",
                                            fr: "botanique." }}>
                                        </TranslatableText>
                                    </p>
                                    </span>
                                </li>
                                <li>
                                    <InfoIcon />
                                    <span>
                                        <h4>
                                            <TranslatableText dictionary={{
                                                en: "NUTRITIONAL INFORMATION",
                                                fr: "INFORMATIONS NUTRITIVE" }}>
                                            </TranslatableText>
                                        </h4>
                                        <div className="paragraph">
                                            <NutritionPopUp beverage="myrcene-tonic" />
                                        </div>
                                    </span>
                                </li>
                            </ul>


                        </div>

                    </div>

                </ContainerContent>
            </div>
        )
    }
}
export default MyrceneTonic;