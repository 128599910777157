import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const InfoIcon = (className, fill) => {
    
    return(

        <span className="icon">

            <svg className="icon-information"  xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52">
                <g id="Group_824" data-name="Group 824" transform="translate(-1618 -1169)">
                    <circle id="Ellipse_547" data-name="Ellipse 547" cx="3" cy="3" r="3" transform="translate(1641 1181)"  />
                    <rect id="Rectangle_1683" data-name="Rectangle 1683" width="6" height="20" rx="3" transform="translate(1641 1191)"  />
                    <g id="Group_823" data-name="Group 823" transform="translate(1023 -827.696)" opacity="0.345">
                        <g id="Ellipse_540" data-name="Ellipse 540" transform="translate(595 1996.696)" fill="none"   strokeWidth="2.5">
                            <circle cx="26" cy="26" r="26" stroke="none"/>
                            <circle cx="26" cy="26" r="24.75" fill="none"/>
                        </g>
                    </g>
                </g>
            </svg>

        </span>

    )
}
export default InfoIcon;

InfoIcon.propTypes = {
    className: PropTypes.string,
    fill: PropTypes.string,
};
  
InfoIcon.defaultProps = {
    className: undefined,
    fill: '#333',
};
  