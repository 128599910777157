import React from 'react';
import './style.scss';
import ContainerContent from '../../templates/ContainerContent/ContainerContent';
import { TranslatableText , LanguageConsumer } from '../../providers/LanguageProvider';
import Footer from '../../components/Footer/Footer';
import imgProducts from './i/products.png';
import imgProducts_mb from './i/products_mb.png';

const WhereToBuy = (props) => {
    const link_ON       = 'https://ocs.ca/collections/beverages';
    const link_ON_FR    = 'https://ocs.ca/collections/boissons?product=4571112539980';
    const link_QC       = 'https://www.sqdc.ca/en-CA/beverages';
    const link_QC_FR    = 'https://www.sqdc.ca/fr-CA/boissons?&fn1=InStock&fv1=en+ligne%7Cen+succursale';
    const link_NS       = 'https://www.mynslc.com/Stores';
    const link_NS_FR    = 'https://www.mynslc.com/Stores';
    const link_NB       = 'https://www.cannabis-nb.com/01/all/menu/beverages/';
    const link_NB_FR    = 'https://www.cannabis-nb.com/fr-CA/01/all/menu/beverages/';
    const link_MB       = 'https://lgcamb.ca/cannabis/store-list/';
    const link_MB_FR    = 'https://lgcamb.ca/cannabis/store-list/';
    const link_BC       = 'https://www.bccannabisstores.com/collections/beverages';
    const link_BC_FR    = 'https://www.bccannabisstores.com/collections/beverages';
    const link_PEI      = 'https://www.peicannabiscorp.com/collections/cannabis/forms-beverages';
    const link_PEI_FR   = 'https://www.peicannabiscorp.com/collections/cannabis/forms-beverages';
    const link_SK       = 'https://www.slga.com/permits-and-licences/cannabis-permits/cannabis-retailing/cannabis-retailers-in-saskatchewan';
    const link_SK_FR    = 'https://www.slga.com/permits-and-licences/cannabis-permits/cannabis-retailing/cannabis-retailers-in-saskatchewan';
    const link_AB       = 'https://www.albertacannabis.org/shop/Cannabis=aglc_cannabis-cannabis?f=format%3DBeverage%7CIced%20Tea%7CSparkling%20Beverages%7CSodas%7CDistillate%20Liquid%7CDistillate%20Powder%7CTea%20Bags%7CCoffee%20Pods';
    const link_AB_FR    = 'https://www.albertacannabis.org/shop/Cannabis=aglc_cannabis-cannabis?f=format%3DBeverage%7CIced%20Tea%7CSparkling%20Beverages%7CSodas%7CDistillate%20Liquid%7CDistillate%20Powder%7CTea%20Bags%7CCoffee%20Pods';
    const link_NF       = 'https://www.shopcannabisnl.com/collections/beverages';
    const link_NF_FR    = 'https://www.shopcannabisnl.com/collections/beverages';


    return (
 
        <div className={`page-wrap`}>
            <div className="blockwrap first">
                <ContainerContent>
                <h1>
                    <TranslatableText dictionary={{
                        en: "Where to buy.",
                        fr: "Où nous trouver" }}>
                    </TranslatableText>
                </h1>  
                
                    
                </ContainerContent>
            </div>
            <div className="blockwrap information brandline">
                <ContainerContent>
            
                <div className="locations-wrap">
                    <LanguageConsumer>
                        {(context) => (
                            
                                <ul>
                                    <li>
                                        <a href={`${context.language == 'fr' ? link_ON_FR : link_ON}`} target="_blank">
                                            <TranslatableText dictionary={{
                                                en: "Ontario",
                                                fr: "Ontario" }}>
                                            </TranslatableText>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={`${context.language == 'fr' ? link_QC_FR : link_QC}`} target="_blank">
                                            <TranslatableText dictionary={{
                                                en: "Quebec",
                                                fr: "Quebec" }}>
                                            </TranslatableText>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={`${context.language == 'fr' ? link_NS_FR : link_NS}`} target="_blank">
                                            <TranslatableText dictionary={{
                                                en: "Nova Scotia",
                                                fr: "Nouvelle-Écosse" }}>
                                            </TranslatableText>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={`${context.language == 'fr' ? link_NB_FR : link_NB}`} target="_blank">
                                            <TranslatableText dictionary={{
                                                en: "New Brunswick",
                                                fr: "Nouveau-Brunswick" }}>
                                            </TranslatableText>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={`${context.language == 'fr' ? link_MB_FR : link_MB}`} target="_blank">
                                            <TranslatableText dictionary={{
                                                en: "Manitoba",
                                                fr: "Manitoba" }}>
                                            </TranslatableText>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={`${context.language == 'fr' ? link_BC_FR : link_BC}`} target="_blank">
                                            <TranslatableText dictionary={{
                                                en: "British Columbia",
                                                fr: "Colombie Britanique" }}>
                                            </TranslatableText>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={`${context.language == 'fr' ? link_PEI_FR : link_PEI}`} target="_blank">
                                            <TranslatableText dictionary={{
                                                en: "Prince Edward Island",
                                                fr: "Ile de Prince Edward" }}>
                                            </TranslatableText>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={`${context.language == 'fr' ? link_SK_FR : link_SK}`} target="_blank">
                                            <TranslatableText dictionary={{
                                                en: "Saskatchewan",
                                                fr: "Saskatchewan" }}>
                                            </TranslatableText>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={`${context.language == 'fr' ? link_AB_FR : link_AB}`} target="_blank">
                                            <TranslatableText dictionary={{
                                                en: "Alberta",
                                                fr: "Alberta" }}>
                                            </TranslatableText>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={`${context.language == 'fr' ? link_NF_FR : link_NF}`} target="_blank">
                                            <TranslatableText dictionary={{
                                                en: "Newfoundland",
                                                fr: "Terre-Neuve" }}>
                                            </TranslatableText>
                                        </a>
                                    </li>
                                    
                                </ul>
                        
                        )}
                    </LanguageConsumer>
                </div>
                <div className="img-wrap">
                    {
                        props.info.isDesktop ?
                            <img src={imgProducts} alt="beverage products" />
                        :
                            <img src={imgProducts_mb} alt="beverage products" />
                    }
                </div>
                
                
                    
                </ContainerContent>
            </div>
            <Footer />
        </div> 

    )
}
export default WhereToBuy;