import React from 'react';
import './style.scss';
import ContainerContent from '../../templates/ContainerContent/ContainerContent';
import { TranslatableText } from '../../providers/LanguageProvider';
import cookiePdf from '../../assets_global/pdf/Cookies-and-Tracking-Technologies.pdf';
import cookiePdf_fr from '../../assets_global/pdf/Cookies-and-Tracking-Technologies_FR.pdf';

const CookiePopUp = (props) => (
    <div className={`cookie-pop-up  ${!props.show ? 'show' : 'hide'}`}>
        <ContainerContent>
            <p>
                <TranslatableText dictionary={{
                    en: "We use cookies to offer you a better experience.",
                    fr: "Nous utilisons des témoins (cookies) pour vous offrir une meilleure expérience." }}>
                </TranslatableText><br />
                <TranslatableText dictionary={{
                    en: "By clicking 'Accept Cookies' you agree to the storing of cookies on your device.",
                    fr: "En cliquant sur « Accepter les témoins », vous consentez à ce que des témoins soient déposés sur votre appareil." }}>
                </TranslatableText>&nbsp;
                <a href={ props.lang == 'fr' ? cookiePdf_fr : cookiePdf} target="_blank" className="privacy" >
                    <TranslatableText dictionary={{ 
                        en: "Learn More.",
                        fr: "Aprennez Plus." }}>
                    </TranslatableText>
                </a>
            </p>
           
            <button onClick={props.closeCookiePopUp} >
                <TranslatableText dictionary={{
                    en: "Accept Cookies.",
                    fr: " Accepter les témoins." }}>
                </TranslatableText>
            </button>
           
        </ContainerContent>
    </div>
)
export default CookiePopUp;