import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const PotencyVeryvell = (className, fill) => {
    
    return(

        <span className="icon">

            <svg xmlns="http://www.w3.org/2000/svg" width="108.57" height="108.552" viewBox="0 0 108.57 108.552">
                <g id="Group_837" data-name="Group 837" transform="translate(-317.921 -704.891)">
                    <g id="Group_781" data-name="Group 781">
                    <g id="Group_777" data-name="Group 777">
                        <g id="Group_776" data-name="Group 776">
                        <g id="Group_775" data-name="Group 775">
                            <path id="Path_710" data-name="Path 710" d="M372.315,813.443a54.743,54.743,0,0,1-11.391-1.2,54.267,54.267,0,0,1,11.282-107.348v4.877a49.393,49.393,0,1,0,10.27,1.08l1.013-4.771a54.279,54.279,0,0,1-11.174,107.366Z" fill="rgba(214,149,104,0.3)"/>
                        </g>
                        </g>
                    </g>
                    <g id="Group_780" data-name="Group 780">
                        <g id="Group_779" data-name="Group 779">
                        <g id="Group_778" data-name="Group 778">
                            <path id="Path_711" data-name="Path 711" d="M382.476,710.848a46.831,46.831,0,0,0-10.27-1.079v-4.878a51.484,51.484,0,0,1,11.283,1.186Z" fill="#d69568"/>
                        </g>
                        </g>
                    </g>
                    </g>
                    <path id="Path_712" data-name="Path 712" d="M405.379,740.142a37.512,37.512,0,0,0-33.242-19.192,36.92,36.92,0,0,0-19.193,5.142,38.386,38.386,0,0,0,9.258,70.32,38.645,38.645,0,0,0,10,1.324,38.4,38.4,0,0,0,33.18-57.594Zm-.995,27.833a33.385,33.385,0,1,1-48.94-37.553,31.7,31.7,0,0,1,16.693-4.472,32.627,32.627,0,0,1,28.912,16.692A33.165,33.165,0,0,1,404.384,767.975Z" fill="#d69568"/>
                </g>
            </svg>

        </span>

    )
}
export default PotencyVeryvell;

PotencyVeryvell.propTypes = {
    className: PropTypes.string,
    fill: PropTypes.string,
  };
  
PotencyVeryvell.defaultProps = {
    className: undefined,
    fill: '#333',
};
  
