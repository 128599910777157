import React from 'react';
import './style.scss';
import ContainerContent from '../../../../templates/ContainerContent/ContainerContent';
import lv_bottle_pink from '../../i/bottle_1.png';
import { TranslatableText } from '../../../../providers/LanguageProvider';
import { LanguageConsumer } from '../../../../providers/LanguageProvider';
import * as ScrollMagic from "scrollmagic"; // Or use scrollmagic-with-ssr to avoid server rendering problems
import { TweenMax, TimelineMax, Linear } from "gsap"; // Also works with TweenLite and TimelineLite
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import Calendar from '../../../../components/svgIcons/Calendar';
import RocksGlass from '../../../../components/svgIcons/RocksGlass';
//import PotencyLow from '../../../../components/svgIcons/PotencyLow';
import PotencyLittleV from '../../../../components/svgIcons/PotencyLittleV';
import Highlights from '../../../../components/svgIcons/Highlights';
import InfoIcon from '../../../../components/svgIcons/InfoIcon';
import Grapefruit_1 from '../../i/grapefruit-1.png';
import Grapefruit_2 from '../../i/grapefruit-2.png';
import Grapefruit_3 from '../../i/grapefruit-3.png';
import NutritionPopUp from '../../../../components/NutritionPopUp/NutritionPopUp';

ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);


class PinkBottles extends React.Component {
    constructor(props){
        super(props)
        // basic initialization
        this.controller = new ScrollMagic.Controller();
    }
    componentWillUnmount() {
        this.controller.destroy();
    }
    componentDidMount() {
    
        var title = document.getElementById("title-anim");
        //var title = document.getElementById("info-wrap");
        //var list = document.getElementById("beverage-info");
        //var imgWrap = document.getElementById("img-wrap");
                       
        var timeline = new TimelineMax()
        timeline.to(title, 1, {y:110, /* textShadow: "1px 1px 1px rgb(204, 46, 76)" ,*/  ease: Linear.easeNone} )
        //timeline.to(list, 1, {y:95,   ease: Linear.easeNone}, 0)
        // timeline.from(list, 1, {y:95,   ease: Linear.easeNone}, 0)
        //timeline.to(imgWrap, 1, {y:105,   ease: Linear.easeNone}, 0)

        // build scenes
        new ScrollMagic.Scene({triggerElement: ".second", triggerHook: 1, duration: '100%',tweenChanges: true})
            //.setPin("#pin")
            .setTween(timeline)
           // .setClassToggle(".img-wrap", "anim")
            .on("enter leave", this.addBottleAnimation)
            .on("progress", function (event) {
                //console.log("Scene progress changed to " + event.progress);
            })
            //.addIndicators() // add indicators (requires plugin)
            .addTo(this.controller);
        
        // scrollTo 

    }

    urlHash = () => {
        
    }

    addBottleAnimation = () => {
        let bottles =  document.getElementById('img-wrap');
        bottles.classList.add('anim');   
    }

    render() {
        return (
            <div className="blockwrap second" id={this.props.bevId}>

            <div className="bg-fruits">
                <img src={Grapefruit_1} className="slice-1" alt="grapefruit half" />
                <img src={Grapefruit_2} className="slice-2" alt="grapefruit slice" />
            </div>

            <ContainerContent>
                
            {
                this.props.isDesktop ? 
                    <div id="img-wrap" className="img-wrap">
                        <img src={lv_bottle_pink} id="botl" className="bottle-pink bottle" alt="Little Victory™ - Dry Grapefruit" />
                        <img src={lv_bottle_pink} id="botl2" className="bottle-pink pink-2 bottle abs" alt="Little Victory™ - Dry Grapefruit" />
                        <img src={Grapefruit_3} className="slice-3" alt="grapefruit wedge" />
                    </div>
            
                : null
            }
                
               
                <div className="info-wrap">
                    <LanguageConsumer>
                        {(context) => (
                            <React.Fragment>
                                {context.language !== 'fr' ? 
                                    <>
                                        <h2 id="title-anim">
                                            Caught yourself <br className="mobile"/>before<br className="desktop" /> hitting reply all. <br className="mobile" /> Cheers<br className="desktop" /> to that.
                                        </h2>
                                        
                                    </>
                                    : 
                                    <>
                                        <h2 id="title-anim">
                                            Vous avez vu juste à <br />temps que vous alliez <br /> répondre à tous? Ça <br className="mobile" />  mérite un toast.
                                        </h2>
                                        
                                    </>
                                }
                            </React.Fragment>
                        )}
                    </LanguageConsumer>
                    
                    <div id="beverage-info" className="beverage-info" >
                        
                        <LanguageConsumer>
                            {(context) => (
                                <React.Fragment>
                                    {context.language !== 'fr' ? 
                                            <h3>Little Victory™ Dry <br />Grapefruit</h3>
                                        : 
                                            <h3>Little Victory™<br />Boisson sec au<br /> pamplemousse</h3>
                                    }
                                </React.Fragment>
                            )}
                        </LanguageConsumer>

                        <p>   
                            <TranslatableText dictionary={{
                                en: "Turn bitter into sweet with this ",
                                fr: "L’amertume se transforme en " }}>
                            </TranslatableText><br />
                            <TranslatableText dictionary={{
                                en: "beverage that sparkles with natural ",
                                fr: "douceur avec cette bouteille de " }}>
                            </TranslatableText><br />
                            <TranslatableText dictionary={{
                                en: "dry grapefruit flavour.",
                                fr: "bulles aux saveurs naturelles " }}>
                            </TranslatableText><br />
                            <TranslatableText dictionary={{
                                en: "",
                                fr: " de pamplemousse sec." }}>
                            </TranslatableText>
                        </p>
                        
                        {
                            this.props.isDesktop ? null
                            : 
                            <div id="img-wrap" className="img-wrap">
                                <img src={lv_bottle_pink} id="botl" className="bottle-pink bottle" alt="Little Victory™ - Dry Grapefruit" />
                                <img src={lv_bottle_pink} id="botl2" className="bottle-pink pink-2 bottle abs" alt="Little Victory™ - Dry Grapefruit" />
                                <img src={Grapefruit_3} className="slice-3" alt="grapefruit wedge" />
                            </div>
                        }

                        <ul id="standard-list" className="standard-list">
                            <li>
                                <PotencyLittleV />
                                <span>
                                <h4><TranslatableText dictionary={{
                                        en: "POTENCY",
                                        fr: "intensité" }}>
                                    </TranslatableText>
                                </h4>
                                    <p>
                                        <TranslatableText dictionary={{
                                            en: "1:1 LOW",
                                            fr: "1:1 faible" }}>
                                        </TranslatableText><br />
                                        <TranslatableText dictionary={{
                                            en: "2.5MG THC + 2.5MG CBD",
                                            fr: "2,5 mg THC : 2,5 mg CBD" }}>
                                        </TranslatableText>
                                    </p>
                                </span>

                            </li>
                            <li>
                                <Calendar />
                                <span>
                                    <h4>
                                        <TranslatableText dictionary={{
                                            en: "OCCASIONS",
                                            fr: "OCCASIONS" }}>
                                        </TranslatableText>
                                    </h4>

                                    <p>
                                        <TranslatableText dictionary={{
                                            en: "Calling mom back. ",
                                            fr: "Pour rappeler sa mère. " }}>
                                        </TranslatableText><br />  
                                        <TranslatableText dictionary={{
                                            en: "Actually checking your voicemail.",
                                            fr: "En écoutant finalement sa boîte vocale." }}>
                                        </TranslatableText>    
                                    </p>
                                </span>
                            </li>
                            <li>
                                <RocksGlass />  
                                <span>
                                    <h4><TranslatableText dictionary={{
                                            en: "SERVING SUGGESTION",
                                            fr: "NOTES DE SERVICE" }}>
                                        </TranslatableText>
                                    </h4>
                                    <p>
                                        <TranslatableText dictionary={{
                                            en: "Serve over ice.",
                                            fr: "Servir sur glace." }}>
                                        </TranslatableText><br />
                                        <TranslatableText dictionary={{
                                            en: "Add a slice of grapefruit.",
                                            fr: "Ajouter une tranche de pamplemousse." }}>
                                        </TranslatableText>
                                    </p>
                                </span>
                            </li>
                            <li>
                                <Highlights />
                                <span>
                                    <h4><TranslatableText dictionary={{
                                        en: "HIGHLIGHTS",
                                        fr: "INFORMATIONS" }}>
                                    </TranslatableText>
                                </h4>
                                <p>
                                    <TranslatableText dictionary={{
                                        en: "All natural flavours. ",
                                        fr: "Saveurs entièrement naturelles." }}>
                                    </TranslatableText>
                                </p>
                                </span>
                            </li>
                            <li>
                                <InfoIcon />
                                <span>
                                    <h4>
                                        <TranslatableText dictionary={{
                                            en: "NUTRITIONAL INFORMATION",
                                            fr: "INFORMATIONS NUTRITIVE" }}>
                                        </TranslatableText>
                                    </h4>
                                    <div className="paragraph">
                                        <NutritionPopUp beverage="dry-grapefruit" />
                                    </div>
                                </span>
                            </li>
                        </ul>
                    </div> 
                </div>

            </ContainerContent>
        </div>
        )
    }
}
export default PinkBottles;