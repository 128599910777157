import React from 'react';

const VerywellLinesStatic = () => {
  return (
    <div className="svg-bg">
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 544.088 544.088" preserveAspectRatio="xMidYMid meet">
        <defs>
          <g id="VerywellWave">
            <path vectorEffect="non-scaling-stroke" d="M199.3,116.4c-6.4,5.8-8.8,14.8-6.2,23.1c3.1,9.6-2.2,19.8-11.8,22.9c-0.5,0.2-1.1,0.3-1.6,0.4
        c-8.4,1.9-15,8.5-16.9,16.9c-2.1,9.8-11.8,16-21.7,13.9c-0.5-0.1-1.1-0.3-1.6-0.4c-8.2-2.6-17.2-0.2-23.1,6.2
        c-6.8,7.4-18.3,8-25.7,1.2c-0.4-0.4-0.8-0.8-1.2-1.2c-5.8-6.4-14.8-8.8-23.1-6.2c-9.6,3.1-19.8-2.2-22.9-11.8
        c-0.2-0.5-0.3-1.1-0.4-1.6c-1.9-8.4-8.5-15-16.9-16.9c-9.8-2.1-16-11.8-13.9-21.7c0.1-0.5,0.3-1.1,0.4-1.6
        c2.6-8.2,0.2-17.2-6.2-23.1c-7.4-6.8-8-18.3-1.2-25.7c0.4-0.4,0.8-0.8,1.2-1.2c6.4-5.8,8.8-14.8,6.2-23.1
        c-3.1-9.6,2.2-19.8,11.8-22.9c0.5-0.2,1.1-0.3,1.6-0.4c8.4-1.9,15-8.5,16.9-16.9c2.1-9.8,11.8-16,21.7-13.9
        c0.5,0.1,1.1,0.3,1.6,0.4c8.2,2.6,17.2,0.2,23.1-6.2c6.8-7.4,18.3-8,25.7-1.2c0.4,0.4,0.8,0.8,1.2,1.2c5.8,6.4,14.8,8.8,23.1,6.2
        c9.6-3.1,19.8,2.2,22.9,11.8c0.2,0.5,0.3,1.1,0.4,1.6c1.9,8.4,8.4,15,16.9,16.9c9.8,2.1,16,11.8,13.9,21.7
        c-0.1,0.5-0.3,1.1-0.4,1.6c-2.6,8.2-0.2,17.2,6.2,23.1c7.4,6.8,8,18.3,1.2,25.7C200.2,115.6,199.8,116,199.3,116.4L199.3,116.4z" fill="none" stroke="#e8a684" strokeMiterlimit="10" strokeWidth="1" />
          </g>
        </defs>
        <use href="#VerywellWave" transform="translate(272.044,272.044) scale(0.3) translate(-105,-105)" opacity="0.9996" />  
        <use href="#VerywellWave" transform="translate(272.044,272.044) scale(0.45454545) translate(-105,-105)" opacity="0.9408" /> 
        <use href="#VerywellWave" transform="translate(272.044,272.044) scale(0.60909091) translate(-105,-105)" opacity="0.882 " /> 
        <use href="#VerywellWave" transform="translate(272.044,272.044) scale(0.76363636) translate(-105,-105)" opacity="0.8232" /> 
        <use href="#VerywellWave" transform="translate(272.044,272.044) scale(0.91818182) translate(-105,-105)" opacity="0.7644" /> 
        <use href="#VerywellWave" transform="translate(272.044,272.044) scale(1.07272728) translate(-105,-105)" opacity="0.7056" /> 
        <use href="#VerywellWave" transform="translate(272.044,272.044) scale(1.22727273) translate(-105,-105)" opacity="0.6468" /> 
        <use href="#VerywellWave" transform="translate(272.044,272.044) scale(1.38181818) translate(-105,-105)" opacity="0.588" /> 
        <use href="#VerywellWave" transform="translate(272.044,272.044) scale(1.53636364) translate(-105,-105)" opacity="0.5292" /> 
        <use href="#VerywellWave" transform="translate(272.044,272.044) scale(1.6909091 ) translate(-105,-105)" opacity="0.4704" /> 
        <use href="#VerywellWave" transform="translate(272.044,272.044) scale(1.84545455) translate(-105,-105)" opacity="0.4116" /> 
        <use href="#VerywellWave" transform="translate(272.044,272.044) scale(2.0) translate(-105,-105)" opacity="0.3528" /> 
        <use href="#VerywellWave" transform="translate(272.044,272.044) scale(2.15454546) translate(-105,-105)" opacity="0.294 " /> 
        <use href="#VerywellWave" transform="translate(272.044,272.044) scale(2.30909091) translate(-105,-105)" opacity="0.2352" /> 
        <use href="#VerywellWave" transform="translate(272.044,272.044) scale(2.46363637) translate(-105,-105)" opacity="0.1764" /> 
        <use href="#VerywellWave" transform="translate(272.044,272.044) scale(2.61818182) translate(-105,-105)" opacity="0.1176" /> 
      </svg>
    </div>
  )
}

export default VerywellLinesStatic;