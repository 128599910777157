import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const Cocktail3 = (className, fill) => {
    
    return(

        <span className="icon">
            <svg className="icon-cocktail" id="Group_39" data-name="Group 39" xmlns="http://www.w3.org/2000/svg" width="40.799" height="64.477" viewBox="0 0 40.799 64.477">
                <g id="Group_37" data-name="Group 37" transform="translate(2.626 21.555)">
                    <rect id="Rectangle_7" data-name="Rectangle 7" width="35.545" height="2.683" transform="translate(0 16.901)"  />
                    <rect id="Rectangle_8" data-name="Rectangle 8" width="27.106" height="2.683" transform="translate(4.219)"  />
                </g>
                <g id="Group_38" data-name="Group 38">
                    <path id="Path_44" data-name="Path 44" d="M10328.551,4149.577a2.019,2.019,0,0,0,2.021-2.02v-.978a2.15,2.15,0,0,0-.253-1.014l-1.4-2.614a.422.422,0,0,0-.743,0l-1.4,2.614a2.162,2.162,0,0,0-.253,1.014v.978A2.02,2.02,0,0,0,10328.551,4149.577Z" transform="translate(-10300.642 -4142.73)"  />
                    <path id="Path_45" data-name="Path 45" d="M10314.551,4149.577a2.02,2.02,0,0,0,2.021-2.02v-.978a2.162,2.162,0,0,0-.253-1.014l-1.4-2.614a.422.422,0,0,0-.743,0l-1.4,2.614a2.151,2.151,0,0,0-.254,1.014v.978A2.02,2.02,0,0,0,10314.551,4149.577Z" transform="translate(-10294.152 -4142.73)"  />
                    <path id="Path_46" data-name="Path 46" d="M10300.55,4149.577a2.02,2.02,0,0,0,2.021-2.02v-.978a2.162,2.162,0,0,0-.253-1.014l-1.4-2.614a.422.422,0,0,0-.743,0l-1.4,2.614a2.162,2.162,0,0,0-.253,1.014v.978A2.02,2.02,0,0,0,10300.55,4149.577Z" transform="translate(-10287.664 -4142.73)"  />
                    <path id="Path_47" data-name="Path 47" d="M10318.344,4188.254a3.005,3.005,0,0,0-2.271-1.035h-3.361l1.589-15.888a3.116,3.116,0,0,0-3.1-3.427h-25.048a3.116,3.116,0,0,0-3.1,3.427l1.588,15.888h-3.361a3,3,0,0,0-2.966,3.473l3.526,19.861a9.748,9.748,0,0,0,7.776,8.145,3.1,3.1,0,0,0,1.012.176h16.1a3.094,3.094,0,0,0,1.01-.176,9.725,9.725,0,0,0,7.77-8.1l3.54-19.95A3,3,0,0,0,10318.344,4188.254Zm-32.515-17.525a.432.432,0,0,1,.322-.143h25.048a.434.434,0,0,1,.323.143.439.439,0,0,1,.11.334l-1.616,16.155h-22.682l-1.616-16.154A.436.436,0,0,1,10285.829,4170.729Zm-1.344,39.4-3.526-19.86a.315.315,0,0,1,.076-.256.311.311,0,0,1,.242-.11h3.63l2.5,25A7.076,7.076,0,0,1,10284.485,4210.128Zm22.674,5.671a.43.43,0,0,1-.18.3,6.989,6.989,0,0,1-1.061.087h-14.486a6.989,6.989,0,0,1-1.061-.087.429.429,0,0,1-.18-.3l-2.59-25.9h22.146Zm5.7-5.627a7.011,7.011,0,0,1-2.915,4.731l2.5-25h3.628a.319.319,0,0,1,.244.11.3.3,0,0,1,.081.211Z" transform="translate(-10278.278 -4154.397)" />
                </g>
            </svg>

        </span>

    )
}
export default Cocktail3;

Cocktail3.propTypes = {
    className: PropTypes.string,
    fill: PropTypes.string,
};
  
Cocktail3.defaultProps = {
    className: undefined,
    fill: '#333',
};
  