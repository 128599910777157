import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const PotencyVvExhale = (className, fill) => {
    
    return(

        <span className="icon">

            <svg xmlns="http://www.w3.org/2000/svg" width="108.757" height="108.698" viewBox="0 0 108.757 108.698">
                <g id="Group_839" data-name="Group 839" transform="translate(-884.763 -704.818)">
                    <g id="Group_724" data-name="Group 724">
                    <path id="Path_692" data-name="Path 692" d="M947.162,767.385a5.439,5.439,0,0,0,5.441-5.44v-2.631a5.8,5.8,0,0,0-.682-2.729l-3.757-7.036a1.134,1.134,0,0,0-2,0l-3.758,7.036a5.8,5.8,0,0,0-.683,2.729v2.631A5.439,5.439,0,0,0,947.162,767.385Z" fill="#d69568"/>
                    <path id="Path_693" data-name="Path 693" d="M931.121,767.385a5.439,5.439,0,0,0,5.441-5.44v-2.631a5.793,5.793,0,0,0-.683-2.729l-3.757-7.036a1.133,1.133,0,0,0-2,0l-3.758,7.036a5.793,5.793,0,0,0-.683,2.729v2.631A5.439,5.439,0,0,0,931.121,767.385Z" fill="#d69568"/>
                    </g>
                    <g id="Group_739" data-name="Group 739">
                    <g id="Group_731" data-name="Group 731">
                        <g id="Group_727" data-name="Group 727">
                        <g id="Group_726" data-name="Group 726">
                            <g id="Group_725" data-name="Group 725">
                            <path id="Path_694" data-name="Path 694" d="M939.129,798.079a38.908,38.908,0,0,1-35.514-54.726,37.953,37.953,0,0,1,35.527-23.072v5a33.071,33.071,0,0,0-30.958,20.1,33.888,33.888,0,1,0,44.742-17.175l2.033-4.568a38.91,38.91,0,0,1-15.83,74.436Z" fill="rgba(214,149,104,0.3)"/>
                            </g>
                        </g>
                        </g>
                        <g id="Group_730" data-name="Group 730">
                        <g id="Group_729" data-name="Group 729">
                            <g id="Group_728" data-name="Group 728">
                            <path id="Path_695" data-name="Path 695" d="M952.926,728.211a32.352,32.352,0,0,0-13.784-2.93v-5a37.121,37.121,0,0,1,15.817,3.362Z" fill="#d69568"/>
                            </g>
                        </g>
                        </g>
                    </g>
                    <g id="Group_738" data-name="Group 738">
                        <g id="Group_734" data-name="Group 734">
                        <g id="Group_733" data-name="Group 733">
                            <g id="Group_732" data-name="Group 732">
                            <path id="Path_696" data-name="Path 696" d="M939.063,813.516a54.348,54.348,0,0,1-49.572-76.454c8.99-20.19,27.55-32.244,49.651-32.244v5c-20.068,0-36.921,10.945-45.082,29.277a49.349,49.349,0,1,0,65.155-25.01l2.033-4.568a54.349,54.349,0,0,1-22.185,104Z" fill="rgba(214,149,104,0.3)"/>
                            </g>
                        </g>
                        </g>
                        <g id="Group_737" data-name="Group 737">
                        <g id="Group_736" data-name="Group 736">
                            <g id="Group_735" data-name="Group 735">
                            <path id="Path_697" data-name="Path 697" d="M959.215,714.085a47.089,47.089,0,0,0-20.073-4.267v-5a51.871,51.871,0,0,1,22.106,4.7Z" fill="rgba(214,149,104,0.3)"/>
                            </g>
                        </g>
                        </g>
                    </g>
                    </g>
                </g>
            </svg>


        </span>

    )
}
export default PotencyVvExhale;

PotencyVvExhale.propTypes = {
    className: PropTypes.string,
    fill: PropTypes.string,
  };
  
PotencyVvExhale.defaultProps = {
    className: undefined,
    fill: '#333',
};
  
