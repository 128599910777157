import React from 'react';
import './style.scss';
import ContainerContent from '../../../../templates/ContainerContent/ContainerContent';
import { TranslatableText } from '../../../../providers/LanguageProvider';
import { LanguageConsumer } from '../../../../providers/LanguageProvider';
import * as ScrollMagic from "scrollmagic"; // Or use scrollmagic-with-ssr to avoid server rendering problems
import { TimelineMax, Linear } from "gsap"; // Also works with TweenLite and TimelineLite
import vv_bottle_strawberry from '../../i/veryvell_1.png';
// import vv_bottle_strawberry_badge from '../../i/veryvell_1_badge.png';
// import vv_bottle_strawberry_badge_fr from '../../i/veryvell_1_badge_fr.png';
import vv_bottle_strawberry_badge from '../../i/veryvell_1.png';
import vv_bottle_strawberry_badge_fr from '../../i/veryvell_1.png';
import Calendar from '../../../../components/svgIcons/Calendar';
import Bottle from '../../../../components/svgIcons/Bottle';
//import PotencyLow from '../../../../components/svgIcons/PotencyLow';
import PotencyVeryvell from '../../../../components/svgIcons/PotencyVeryvell';
import Highlights from '../../../../components/svgIcons/Highlights';
import InfoIcon from '../../../../components/svgIcons/InfoIcon';
import VerywellLinesStatic from '../../i/VerywellLinesStatic';
import NutritionPopUp from '../../../../components/NutritionPopUp/NutritionPopUp';

class StrawberryHibiscus extends React.Component {
    constructor(props){
        super(props)
        // basic initialization
        this.controller = new ScrollMagic.Controller();
        
    }
    componentWillUnmount() {
        this.controller.destroy();
    }
    componentDidMount() {
    
        var title = document.getElementById("title-anim");
        var list = document.getElementById("beverage-info");
        var imgWrap = document.getElementById("img-wrap");
                            
        var timeline = new TimelineMax()
        timeline.to(title, 1, {y:110, /* textShadow: "1px 1px 1px rgb(204, 46, 76)" ,*/  ease: Linear.easeNone} )
        //timeline.to(list, 1, {y:95,   ease: Linear.easeNone}, 0)
        // timeline.from(list, 1, {y:95,   ease: Linear.easeNone}, 0)
        //timeline.to(imgWrap, 1, {y:105,   ease: Linear.easeNone}, 0)

        // build scenes
        new ScrollMagic.Scene({triggerElement: ".second", triggerHook: 1, duration: '100%',tweenChanges: true})
            //.setPin("#pin")
            .setTween(timeline)
           // .setClassToggle(".img-wrap", "anim")
            .on("enter leave", this.addBottleAnimation)
            .on("progress", function (event) {
                //console.log("Scene progress changed to " + event.progress);
            })
            //.addIndicators() // add indicators (requires plugin)
            .addTo(this.controller);
        
        // scrollTo 

    }
    
    addBottleAnimation = () => {
        let bottles =  document.getElementById('img-wrap');
        bottles.classList.add('anim');   
    }

    render() {
        let vv_strawberry_badge;
        this.props.lang === 'fr' ? vv_strawberry_badge = vv_bottle_strawberry_badge_fr : vv_strawberry_badge = vv_bottle_strawberry_badge;
        return (
            <React.Fragment>
                <div className="blockwrap title-wrap">
                    <ContainerContent>
                        <h2>
                            <TranslatableText dictionary={{
                                en: "BEVERAGES",
                                fr: "BOISSONS" }}>
                            </TranslatableText>
                        </h2>
                    </ContainerContent>
                </div>
            
                <div className="blockwrap second" id={this.props.bevId}>
                    
                    <VerywellLinesStatic />
                    <ContainerContent>

                        {   this.props.isDesktop ? 
                                <div id="img-wrap" className="img-wrap">
                                    <img src={vv_bottle_strawberry} id="botl" className="bottle-strawberry bottle" alt="Veryvell™ - Strawberry" />
                                    <img src={vv_strawberry_badge} id="botl2" className="bottle-strawberry strawberry-2 bottle abs" alt="Veryvell™ - Strawberry" />
                                </div>
                        
                            : null
                        }
                        <div className="info-wrap">

                            <h2 id="title-anim">
                                <TranslatableText dictionary={{
                                    en: "Looking to chill?",
                                    fr: "Envie de relaxer?" }}>
                                </TranslatableText><br />
                                <TranslatableText dictionary={{
                                    en: "Veryvell, then.",
                                    fr: "Veryvell." }}>
                                </TranslatableText>
                                
                            </h2>
                            <div id="beverage-info" className="beverage-info" >
                                
                                <LanguageConsumer>
                                    {(context) => (
                                        <React.Fragment>
                                            {context.language !== 'fr' ? 
                                                    <h3>Veryvell™<br />Strawberry Hibiscus<br />Sparkling Water</h3>
                                                : 
                                                    <h3>Veryvell™<br />Eau pétillante à<br />la fraise et à l’hibiscus</h3>
                                            }
                                        </React.Fragment>
                                    )}
                                </LanguageConsumer>
                            
                                <p> 
                                    <TranslatableText dictionary={{
                                        en: "Lightly carbonated with floral hints",
                                        fr: "Légèrement effervescente, avec une" }}>
                                    </TranslatableText><br />
                                    <TranslatableText dictionary={{
                                        en: "and subtle notes of strawberry.",
                                        fr: "touche florale et des notes subtiles" }}>
                                    </TranslatableText><br />
                                    <TranslatableText dictionary={{
                                        en: "",
                                        fr: "de fraises." }}>
                                    </TranslatableText>
                                </p>
                                
                                {
                                    this.props.isDesktop ? null
                                    : 
                                    <div id="img-wrap" className="img-wrap">
                                        <img src={vv_bottle_strawberry} id="botl" className="bottle-strawberry bottle" alt="Veryvell™ - Strawberry" />
                                        <img src={vv_strawberry_badge} id="botl2" className="bottle-strawberry strawberry-2 bottle abs" alt="Veryvell™ - Strawberry" />
                                    </div>
                                }
                                
                                <ul id="standard-list" className="standard-list">
                                    <li>
                                        <PotencyVeryvell />
                                        <span>
                                        <h4><TranslatableText dictionary={{
                                                en: "POTENCY",
                                                fr: "intensité" }}>
                                            </TranslatableText>
                                        </h4>
                                            <p>
                                                <TranslatableText dictionary={{
                                                    en: "15MG CBD + 0.5MG THC",
                                                    fr: "15 mg CBD : 0,5 mg THC " }}>
                                                </TranslatableText>
                                            </p>
                                        </span>
                                    </li>
                                    <li>
                                        <Calendar />
                                        <span>
                                            <h4>
                                                <TranslatableText dictionary={{
                                                    en: "OCCASIONS",
                                                    fr: "OCCASIONS" }}>
                                                </TranslatableText>
                                            </h4>

                                            <p>
                                                <TranslatableText dictionary={{
                                                    en: "Sundays.",
                                                    fr: "Les dimanches." }}>
                                                </TranslatableText><br />  
                                                <TranslatableText dictionary={{
                                                    en: "Post-yoga dazes.",
                                                    fr: "Après le yoga." }}>
                                                </TranslatableText> <br />  
                                                <TranslatableText dictionary={{
                                                    en: "Park strolls.",
                                                    fr: "Au parc." }}>
                                                </TranslatableText>    
                                            </p>
                                        </span>
                                    </li>
                                    <li>
                                        <Bottle />
                                        <span>
                                            <h4><TranslatableText dictionary={{
                                                    en: "SERVING SUGGESTION",
                                                    fr: "NOTES DE SERVICE" }}>
                                                </TranslatableText>
                                            </h4>
                                            <p>
                                                <TranslatableText dictionary={{
                                                    en: "Unpop and enjoy.",
                                                    fr: "Ouvrir et savourer." }}>
                                                </TranslatableText>
                                            </p>
                                        </span>
                                    </li>
                                    <li>
                                    <Highlights />
                                        <span>
                                            <h4><TranslatableText dictionary={{
                                                en: "HIGHLIGHTS",
                                                fr: "INFORMATIONS" }}>
                                            </TranslatableText>
                                        </h4>
                                        <p>
                                            <TranslatableText dictionary={{
                                                en: "All natural flavours. ",
                                                fr: "Saveurs entièrement naturelles." }}>
                                            </TranslatableText>
                                        </p>
                                        </span>
                                    </li>
                                    <li>
                                        <InfoIcon />
                                        <span>
                                            <h4>
                                                <TranslatableText dictionary={{
                                                    en: "NUTRITIONAL INFORMATION",
                                                    fr: "INFORMATIONS NUTRITIVE" }}>
                                                </TranslatableText>
                                            </h4>
                                            <div className="paragraph">
                                                <NutritionPopUp beverage="strawberry-hibiscus" />
                                            </div>
                                        </span>
                                    </li>
                                </ul>

                            </div>

                        </div>

                    </ContainerContent>

                </div>
            </React.Fragment>
        )
    }
}
export default StrawberryHibiscus;