import React from "react";
import "./style.scss";
import { TweenMax, TimelineMax, Linear } from "gsap"; // Also works with TweenLite and TimelineLite

class VeryLines extends React.Component {
  componentDidMount = () => {
    this.createLoopingAnim();
    TweenMax.fromTo(".page-2>svg", 1.0, { opacity: 0 }, { opacity: 1 });
  };

  createLoopingAnim = () => {
    var rings = document.querySelectorAll(".wavePath");
    var totalTime = 35;
    for (var i = 0; i < rings.length; i++) {
      var thisRing = rings[i];
      var thisTL = new TimelineMax({ paused: true, repeat: -1 });
      thisTL.add([
        TweenMax.from(thisRing, totalTime * 0.5, { opacity: 0 }),
        TweenMax.fromTo(
          rings[i],
          totalTime,
          {
            scale: 0.1,
            transformOrigin: "center center",
            ease: Linear.easeNone,
          },
          { scale: 2.65, ease: Linear.easeNone }
        ),
      ]);
      thisTL.to(
        thisRing,
        totalTime * 0.3,
        { opacity: 0 },
        "-=" + totalTime * 0.3
      );
      thisTL.seek(thisTL.duration() * (i / rings.length));
      thisTL.play();
    }
  };

  createRing = (range, i) => {
    let t = 169;
    return (
      <use
        href="#VerywellWave"
        className="wavePath"
        transform={`translate(${t},${t})`}
        opacity="0.8"
        key={i}
      />
    );
  };

  render() {
    const nRings = 10;
    return (
      <div id="Veryvell-Container">
        {/*  <div className="background"></div> */}
        <div className="svg page-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 544.088 544.088"
            preserveAspectRatio="xMidYMid meet"
          >
            <defs>
              <g id="VerywellWave">
                <path
                  vectorEffect="non-scaling-stroke"
                  d="M199.3,116.4c-6.4,5.8-8.8,14.8-6.2,23.1c3.1,9.6-2.2,19.8-11.8,22.9c-0.5,0.2-1.1,0.3-1.6,0.4
                    c-8.4,1.9-15,8.5-16.9,16.9c-2.1,9.8-11.8,16-21.7,13.9c-0.5-0.1-1.1-0.3-1.6-0.4c-8.2-2.6-17.2-0.2-23.1,6.2
                    c-6.8,7.4-18.3,8-25.7,1.2c-0.4-0.4-0.8-0.8-1.2-1.2c-5.8-6.4-14.8-8.8-23.1-6.2c-9.6,3.1-19.8-2.2-22.9-11.8
                    c-0.2-0.5-0.3-1.1-0.4-1.6c-1.9-8.4-8.5-15-16.9-16.9c-9.8-2.1-16-11.8-13.9-21.7c0.1-0.5,0.3-1.1,0.4-1.6
                    c2.6-8.2,0.2-17.2-6.2-23.1c-7.4-6.8-8-18.3-1.2-25.7c0.4-0.4,0.8-0.8,1.2-1.2c6.4-5.8,8.8-14.8,6.2-23.1
                    c-3.1-9.6,2.2-19.8,11.8-22.9c0.5-0.2,1.1-0.3,1.6-0.4c8.4-1.9,15-8.5,16.9-16.9c2.1-9.8,11.8-16,21.7-13.9
                    c0.5,0.1,1.1,0.3,1.6,0.4c8.2,2.6,17.2,0.2,23.1-6.2c6.8-7.4,18.3-8,25.7-1.2c0.4,0.4,0.8,0.8,1.2,1.2c5.8,6.4,14.8,8.8,23.1,6.2
                    c9.6-3.1,19.8,2.2,22.9,11.8c0.2,0.5,0.3,1.1,0.4,1.6c1.9,8.4,8.4,15,16.9,16.9c9.8,2.1,16,11.8,13.9,21.7
                    c-0.1,0.5-0.3,1.1-0.4,1.6c-2.6,8.2-0.2,17.2,6.2,23.1c7.4,6.8,8,18.3,1.2,25.7C200.2,115.6,199.8,116,199.3,116.4L199.3,116.4z"
                  fill="none"
                  stroke="#e8a684"
                  strokeMiterlimit="10"
                  strokeWidth="1"
                />
              </g>
            </defs>
            {/* <line x1="50%" x2="50%" y1="0%" y2="100%" stroke="yellow" /> */}
            {/* <line x1="0%"   x2="100%" y1="50%" y2="50%" stroke="yellow" /> */}
            {/* the following code is useful to generate the saves in an even way. */}
            {/* <g>
              {[...Array(nRings)].map((e, i) => this.createRing(nRings, i))}
            </g> */}
            <g>
              <use
                href="#VerywellWave"
                className="wavePath"
                transform="translate(169,169)"
                opacity="0.8"
              ></use>
              <use
                href="#VerywellWave"
                className="wavePath"
                transform="translate(169,169)"
                opacity="0.8"
              ></use>
              <use
                href="#VerywellWave"
                className="wavePath"
                transform="translate(169,169)"
                opacity="0.8"
              ></use>
              <use
                href="#VerywellWave"
                className="wavePath"
                transform="translate(169,169)"
                opacity="0.8"
              ></use>
              <use
                href="#VerywellWave"
                className="wavePath"
                transform="translate(169,169)"
                opacity="0.8"
              ></use>
              <use
                href="#VerywellWave"
                className="wavePath"
                transform="translate(169,169)"
                opacity="0.8"
              ></use>
              <use
                href="#VerywellWave"
                className="wavePath"
                transform="translate(169,169)"
                opacity="0.8"
              ></use>
              <use
                href="#VerywellWave"
                className="wavePath"
                transform="translate(169,169)"
                opacity="0.8"
              ></use>
              <use
                href="#VerywellWave"
                className="wavePath"
                transform="translate(169,169)"
                opacity="0.8"
              ></use>
              <use
                href="#VerywellWave"
                className="wavePath"
                transform="translate(169,169)"
                opacity="0.8"
              ></use>
            </g>
          </svg>
        </div>
      </div>
    );
  }
}
export default VeryLines;
