import React from 'react';
import './style.scss';
import { Link } from 'react-router-dom';
import ContainerContent from '../../templates/ContainerContent/ContainerContent';
import { TranslatableText } from '../../providers/LanguageProvider';
import Drawer from '../../components/Drawer/Drawer';
import Checkbox from '../../components/Checkbox/Checkbox';
import Footer from '../../components/Footer/Footer';
import data from './data.json';


// json with holiday version
// import data from './data_holidays.json';

// import data from
// let numbers;
// let listItems =[];
let hasSearched = false;
 
 

const OPTIONS = data.options;
//console.log('Options ',data.options);
 
let optionsList = OPTIONS.map( (option) => {
   //console.log('all options ',option.unique_id)
    return option.unique_id;
});

let occassionList = OPTIONS.filter(function (option) {
                        return option.type === "occasion";
                    }).map((option) => {
                         return ({
                             "name_en": option.name_en,
                             "name_fr": option.name_fr,
                             "unique_id": option.unique_id
                         });
                        //return option.unique_id
                    });
//console.log('OccasionList ',occassionList);

let flavoursList = OPTIONS.filter( (option) => {
                        return option.type === 'flavour';
                    })
                    .map((option) => {
                        return ({
                            "name_en": option.name_en,
                            "name_fr": option.name_fr,
                            "unique_id": option.unique_id
                        });
                        //return option.name;
                    });
                    //console.log('FlavoursList ',flavoursList);
let typesList = OPTIONS.filter( (option) => {
                    return option.type === 'type';
                })
                .map((option) => {
                    return option.name;
                });

let potencyList = OPTIONS.filter((option) => {
                    return option.type === 'potency';
                })
                .map((option) => {
                    return ({
                        "name_en": option.name_en,
                        "name_fr": option.name_fr,
                        "unique_id": option.unique_id
                    });
                    //return option.name;
                });


//console.log('newTestList ',testNewList);

let theBeverages;
let scrollAmount;

class FindMyBeverage extends React.Component {
    constructor(props){
        super(props)
        //console.log('mybev props ',props.lang);
        this.state = {
            lang: props.lang,
            allResults: data.beverages,
            searchResults: data.beverages,
            hasSearched: false,
            result: '',
            checkboxes: optionsList.reduce(
                (options, option) => {
                    return({
                  ...options,
                  [option]: false
                })},
                {}
            )    
        };
         
    }
      
    createCheckbox = (option) => {
        let checkboxLabel;
        this.state.lang === 'fr' ? checkboxLabel = 'name_fr' : checkboxLabel = 'name_en';
          //console.log('optionFr ',option.name_fr);
          //console.log('optionFR en ',option.name_en);
        //console.log('optionFR en ',option.unique_id);
        return(
            <Checkbox
                label={option[checkboxLabel]}
                filterName={option.unique_id}
                isSelected={this.state.checkboxes[option.unique_id]}
                onCheckboxChange={this.handleCheckboxChange} 
                key={option.unique_id}
            />
        )
    };

    occassionCheckboxes = () => occassionList.map(this.createCheckbox);
    flavourCheckboxes = () => flavoursList.map(this.createCheckbox);
    //typesCheckboxes = () => typesList.map(this.createCheckbox);
    potencyCheckboxes = () => potencyList.map(this.createCheckbox);
   

    handleCheckboxChange = changeEvent => {
        //const { name } = changeEvent.target;
        const name  = changeEvent.target.dataset.filtername;
        //console.log('click ',name);
        
        this.setState((prevState, checkboxes) => {
           return {
                checkboxes: {
                  ...prevState.checkboxes,
                  [name]: !prevState.checkboxes[name]
                }
              }
        }, () => this.filterResults());

    };

    selectAllCheckboxes = isSelected => {
        Object.keys(this.state.checkboxes).forEach(checkbox => {
          // BONUS: Can you explain why we pass updater function to setState instead of an object?
          this.setState(prevState => ({
            checkboxes: {
              ...prevState.checkboxes,
              [checkbox]: isSelected
            }
          }));
        });
        this.setState(prevState => ({
            searchResults: data.beverages
        }),() =>  this.displayResults() )
        //this.filterResults()
        //this.displayResults();
    };

    deselectAll = () => this.selectAllCheckboxes(false);

    filterResults = () => {
        let searchArr= [];
        Object.keys(this.state.checkboxes)
            .filter(checkbox => this.state.checkboxes[checkbox])
            .forEach(checkbox => {
                //console.log(checkbox, "is selected.");
                // Filter the beverage results 
                searchArr.push(checkbox);
        });
        
        
        this.searchBeverages(searchArr);
    }

    searchBeverages = (searchArr) => {
        hasSearched = true;
         
		let result = data.beverages.filter((a)=>{
			for(let i=0; i < searchArr.length; i++) {
				if (!a['tags'].includes(searchArr[i]))
					return false;
			}
			return true;
		});

        let searchTerms = searchArr;
       //console.log('search-',searchTerms);


        if(result.length === 0){
          //  result = this.state.allResults
        }
        this.setState((prevState) => {
            return {
                searchResults: result,
                searchFor: searchArr,
                hasSearched: searchArr
               }
         }/* , () => this.renderBeverages(result) */);
    }


    displayResults = () => {

        const images = require.context('./i', true);
 
        let searchRes = this.state.searchResults;
        const resultsTotal = this.state.searchResults.length;
       
        
        // take searchRes and return two arrays. One odd, one even.
        // create vars and save the mapped arrays
        // return two rows
        let evenNums = searchRes.filter((a,i)=>i%2===1);
        let oddNums = searchRes.filter((a,i)=>i%2===0);

        let topRow = oddNums.map((beverage, index) => {
                // Set path to images (this is needed for dynamic paths)
                const images = require.context('./i', true);
                 // Set image
                let img = images('./' + beverage.image);
                
                return (
                    <React.Fragment  key={beverage.id}>
                      
                        <Link className="item" to={`${this.state.lang === 'fr' ? '/fr' : ''}${beverage.href}`}>
                            <h3>{beverage.category}</h3>
                            <img src={img} alt="" />
                            <p>
                                <TranslatableText dictionary={{
                                    en: `${beverage.name_en}`,
                                    fr: `${beverage.name_fr}` }}>
                                </TranslatableText>
                            </p>
                        </Link> 
                    
                     </React.Fragment>
                )
            }) 

        let bottomRow = evenNums.map((beverage, index) => {
            // Set path to images (this is needed for dynamic paths)
            const images = require.context('./i', true);
             // Set image
            let img = images('./' + beverage.image);
            
            return (
                <React.Fragment key={beverage.id} >
                
                    <Link className="item" to={`${this.state.lang === 'fr' ? '/fr' : ''}${beverage.href}`}>
                        <h3>{beverage.category}</h3>
                        <img src={img} alt="" />
                        <p>
                            <TranslatableText dictionary={{
                                en: `${beverage.name_en}`,
                                fr: `${beverage.name_fr}` }}>
                            </TranslatableText>
                        </p>
                    </Link>
                
                 </React.Fragment>
            )
        }) 
        

        return (
            <React.Fragment>
                {resultsTotal === 0 ? (
                <div className="no-results">
                    <h2> 
                        <TranslatableText dictionary={{
                        en: "Sorry, nothing matches that combo yet.",
                        fr: "Désolé, aucun résultat ne correspond à votre recherche." }}>
                    </TranslatableText>
                    <span>
                    <TranslatableText dictionary={{
                            en: "Try filtering again.",
                            fr: "Veuillez essayer d’autres filtres." }}>
                        </TranslatableText>
                    </span>
                    </h2>
                </div>
                ) : ''}
                <div className="row" key="a">{topRow}</div>
                <div className="row" key="b">{bottomRow}</div>
            </React.Fragment>
            
        )
        
    } 
    
    slideIt = (direction) => {
        var container = document.getElementById('filter-results');
        this.sideScroll(container,direction,25,100,10);
    }

    sideScroll = (element,direction,speed,distance,step) => {
        scrollAmount = 0;
        var slideTimer = setInterval(function(){
            if(direction == 'left'){
                element.scrollLeft -= step;
            } else {
                element.scrollLeft += step;
            }
            scrollAmount += step;
            if(scrollAmount >= distance){
                window.clearInterval(slideTimer);
            }
        }, speed);
    }

    
    render() {
       
        
        return (
           <React.Fragment>
            <div className={`page-wrap brandline`}>
                <ContainerContent>
                    <div className="filter-heading">
                        <h1>
                            <TranslatableText dictionary={{
                                en: "Find me a product",
                                fr: "Trouvez-moi un produit" }}>
                            </TranslatableText>
                        </h1>
                        <div className="slide-btn-wrap">
                            <button onClick={() => this.slideIt('left')} className="left"><i className="la la-angle-left"></i></button>
                            <button onClick={() => this.slideIt('right')}className="right"><i className="la la-angle-right"></i></button> 
                        </div>
                    </div>
                   
                    <div className="filter-wrap">
                        
                        <div className="filter-options">
                            <div className="options-title-wrap">
                                <span>
                                    <TranslatableText dictionary={{
                                        en: "Filter by:",
                                        fr: "Filtrer par :" }}>
                                    </TranslatableText>
                                </span> 
                               
                                <span> <button
                                    type="button"
                                    className="btn btn-outline-primary"
                                    onClick={this.deselectAll}
                                >
                                <TranslatableText dictionary={{
                                    en: "Reset",
                                    fr: "Recommencer" }}>
                                </TranslatableText>
                                
                                </button>|&nbsp;&nbsp; <TranslatableText dictionary={{
                                    en: "Showing:",
                                    fr: "Produits" }}>
                                </TranslatableText> {this.state.searchResults.length} <TranslatableText dictionary={{
                                    en: "of:",
                                    fr: "de" }}>
                                </TranslatableText> {this.state.allResults.length}</span>
                            </div>
                            
                            <form id="filter-form">
                                   
                                    <Drawer 
                                        title={<TranslatableText dictionary={{
                                                en: "Occasion",
                                                fr: "Occasion" }}>
                                            </TranslatableText>
                                        } 
                                        icon={<i className="la la-plus"></i>} 
                                        handleInputChange={() => this.handleInputChange} 
                                        key="k1"
                                    >
                                        <ul>
                                            {this.occassionCheckboxes()} 
                                        </ul>
                                    </Drawer>
                                    <Drawer 
                                        title={<TranslatableText dictionary={{
                                            en: "Flavour",
                                            fr: "Saveurs" }}>
                                        </TranslatableText>
                                    } 
                                        icon={<i className="la la-plus"></i>}
                                        handleInputChange={() => this.handleInputChange} 
                                        key="k2"
                                    >
                                        <ul>
                                            {this.flavourCheckboxes()}
                                        </ul>
                                    </Drawer>
                                    {/* <Drawer title="Type" icon={<i className="la la-plus"></i>}handleInputChange={() => this.handleInputChange} key="k3">
                                        <ul>
                                            {this.typesCheckboxes()}
                                        </ul>
                                    </Drawer> */}
                                    <Drawer 
                                        title={<TranslatableText dictionary={{
                                            en: "Potency",
                                            fr: "Intensité" }}>
                                        </TranslatableText>
                                        }  
                                        icon={<i className="la la-plus"></i>}
                                        handleInputChange={() => this.handleInputChange} 
                                        key="k4"
                                    >
                                        <ul>
                                            {this.potencyCheckboxes()}
                                        </ul>

                                        <div className="potency-legend">
                                            <h5>
                                                <TranslatableText dictionary={{
                                                    en: "Low:",
                                                    fr: "Faible" }}>
                                                </TranslatableText>
                                            </h5>
                                            <p>
                                                <TranslatableText dictionary={{
                                                    en: "2.5mg THC : 2.5mg CBD (1:1) ",
                                                    fr: "2,5 mg THC : 2,5 mg CBD (1:1) 0,5 mg  " }}>
                                                </TranslatableText><br />
                                                <TranslatableText dictionary={{
                                                    en: "0.5mg THC : 15mg CBD (CBD dominant)",
                                                    fr: "THC : 15 mg CBD (dominante CBD)" }}>
                                                </TranslatableText>
                                            </p>
                                            <h5>
                                                <TranslatableText dictionary={{
                                                    en: "Medium:",
                                                    fr: "Moyenne :" }}>
                                                </TranslatableText>
                                            </h5>
                                            <p>
                                                <TranslatableText dictionary={{
                                                    en: "5mg THC: 5mg CBD (1:1) ",
                                                    fr: "5 mg THC: 5 mg CBD (1:1)" }}>
                                                </TranslatableText>
                                            </p>
                                            <h5>
                                                <TranslatableText dictionary={{
                                                    en: "High:",
                                                    fr: "Élevée :" }}>
                                                </TranslatableText>
                                            </h5>
                                            <p>
                                                <TranslatableText dictionary={{
                                                    en: "10mg THC (THC dominant)",
                                                    fr: "10 mg THC (dominante THC)" }}>
                                                </TranslatableText>
                                            </p>
                                            <h5>
                                                <TranslatableText dictionary={{
                                                    en: "Control your dose:",
                                                    fr: "Controlez votre dose :" }}>
                                                </TranslatableText>
                                            </h5>
                                            <p>
                                                <TranslatableText dictionary={{
                                                    en: "2 drops = ~1mg cannabinoids",
                                                    fr: "2 gouttes  = env. 1 mg cannabinoïdes" }}>
                                                </TranslatableText>
                                            </p>
                                            
                                        </div>
                                    </Drawer>
                                
                            </form>
                        </div>

                        <div className="filter-results" id="filter-results">
                       {/*  {theBeverages} */}
                        { this.displayResults() }
                        </div>
                    </div>
                    
                </ContainerContent>
            </div> 
            <Footer />
            </React.Fragment>
        )
    }
}
export default FindMyBeverage;