import React from 'react';
import './style.scss';
import {TranslatableText, LanguageConsumer} from '../../providers/LanguageProvider';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import EmailForm from './EmailForm/EmailForm';
import EmailFormCM from './EmailFormCM/EmailFormCM';
import successCheck from '../../assets_global/images/checked-big.png';

class EmailPopUp extends React.Component {
    constructor(props){
        super(props)
        
        this.state = {
            checked: false,
            show: true,
            showSuccess: false
        }
    }
   
    handleShowPopUp = () => {
        this.setState({ show: true });
    }
    handleHidePopUp = () => {
        console.log('hide');
        this.setState({ show: false });
    }
    // handleChange = () => {
    //     this.setState({
    //         checked: !this.state.checked
    //     })
    // }
    showSuccessModal = () => {
        // show sucess modal
        this.setState({ show: false, showSuccess: true });
    }
    hideSuccessModal = () => {
        this.setState({ 
          showSuccess: false 
        });
        
        console.log('test success close');
      };
    render() {
        return (
            <div>
                {/* <Button className="open-email-btn" variant="primary" onClick={this.handleShowPopUp}>
                    <TranslatableText dictionary={{
                        en: "Sign up for updates ",
                        fr: "S'incrire" }}>
                    </TranslatableText>
                </Button> */}

                <Modal backdrop={false} className="email-form" show={this.state.show} onHide={this.handleHidePopUp}>
                    <div className="sign-up" id="sign-up">
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <h5>
                                    <TranslatableText dictionary={{
                                        en: "Wonder what's coming next?",
                                        fr: "Vous vous demandez quelle sera la prochaine merveille?" }}>
                                    </TranslatableText>
                                </h5>
                                <p className="sign-up-text">
                                    <TranslatableText dictionary={{
                                        en: "Join our mailing list and you’ll be the first to hear about all the things we have in store.",
                                        fr: "Inscrivez-vous à notre liste de diffusion pour connaître en primeur tous les produits qu’on offre en magasin." }}>
                                    </TranslatableText>
                                </p>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            
                            <LanguageConsumer>
                                {(context) => (   
                                    //<EmailForm successSubmit={this.showSuccessModal} lang={context.language} /> 
                                    <EmailFormCM successSubmit={this.showSuccessModal} lang={context.language} />
                                )}
                            </LanguageConsumer>
                       
                        <p className="legal">
                            <TranslatableText dictionary={{
                                en: "By opting in, I consent to receive marketing communications from Truss Beverage Co.™  I acknowledge that I can opt out at any time by clicking on the link at the bottom of the email.",
                                fr: "En m’inscrivant, je consens à recevoir les communications de Truss Beverage Co.™ Je reconnais que je peux me désabonner en tout temps en cliquant sur le lien au bas de ce courriel." }}>
                            </TranslatableText>
                        </p>
                        </Modal.Body>
                    </div>
                </Modal>        

                <Modal backdrop={false} className="email-form email-form-success" show={this.state.showSuccess} onHide={this.hideSuccessModal}>
              <div className="sign-up sign-up-success">

                <Modal.Header closeButton>
                 
                </Modal.Header>
                    <Modal.Body>
                        <div className="success-copy">
                            {/* <h5>
                            <TranslatableText dictionary={{
                                en: "Thanks for signing up, we’ll see you in your inbox!",
                                fr: "Merci." }}>
                            </TranslatableText>
                            </h5> */}
                            
                            <p>
                            <TranslatableText dictionary={{
                                en: "Thanks for signing up, we’ll see you in your inbox!",
                                fr: "Merci de vous être inscrit. On se revoit bientôt dans votre boîte de courriel." }}>
                            </TranslatableText>
                            </p>
                        </div>
                        <div className="success-image">
                            <img src={successCheck} alt="checkmark" />
                        </div>
                    </Modal.Body>
      
                </div> 
              </Modal>
           
        
            </div>
        )
    }
}
export default EmailPopUp;