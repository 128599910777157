import React from 'react';
import './style.scss';
import ContainerContent from '../../templates/ContainerContent/ContainerContent';
import Footer from '../../components/Footer/Footer';
import { TranslatableText } from '../../providers/LanguageProvider';
import bottle_myrcene from './i/hot_1.png';
import bottle_lemonene from './i/hot_2.png';
import hot_drinks from './i/house-of-terpenes-group.png';
import MyrceneTonic from './scenes/MyrceneTonic/MyrceneTonic';
import LemoneneTonic from './scenes/LemoneneTonic/LemoneneTonic';
import TerpsLinesStatic from './i/TerpLinesStatic';
import flowers_1 from './i/flowers-1.png';
import flowers_2 from './i/flowers-2.png';

class HouseOfTerpenes extends React.Component {
    constructor(props){
        super(props)

        this.state ={
            isDesktop: props.info.isDesktop,
        }
        
    }
    render() {

        return (
            <React.Fragment>
          
            <div className={`page-wrap`}>

                <div className="blockwrap first">
                    <div className="bg-fruits">
                        
                        <img src={flowers_1} className="slice-1" alt="flowers orange" />
                        <img src={flowers_2} className="slice-2" alt="flowers red" />
                    </div>
                    <ContainerContent>

                        <span className="heading-wrap">
                            <h1> 
                                <TranslatableText dictionary={{
                                    en: "A range of",
                                    fr: "Une gamme de" }}>
                                </TranslatableText><br />
                                <TranslatableText dictionary={{
                                    en: "terpene-forward",
                                    fr: "BOISSONS TONIQUES" }}>
                                </TranslatableText><br />
                                <TranslatableText dictionary={{
                                    en: "sparkling tonics",
                                    fr: "axée sur les terpènes" }}>
                                </TranslatableText><br />
                                <TranslatableText dictionary={{
                                    en: "that celebrate",
                                    fr: "pour souligner les" }}>
                                </TranslatableText><br />
                                <TranslatableText dictionary={{
                                    en: "the flavours of",
                                    fr: "saveurs du cannabis." }}>
                                </TranslatableText><br />
                                <TranslatableText dictionary={{
                                    en: "cannabis. Reach",
                                    fr: "Un goût qui fait" }}>
                                </TranslatableText><br />
                                <TranslatableText dictionary={{
                                    en: "a higher taste.",
                                    fr: "son effet." }}>
                                </TranslatableText>
                            </h1>  
                        </span>
                        <div className="img-wrap">
                            <img src={bottle_myrcene} className="bottle-myrcene-tonic bottle bottle-bounce-1" alt="House of Terpenes - Myrcene Tonic" />
                            <img src={bottle_lemonene} className="bottle-lemonene-tonic bottle abs bottle-bounce-2" alt="House of Terpenes - Lemonene Tonic" /> 
                        </div>
                        
                    </ContainerContent>

                </div>

                <LemoneneTonic isDesktop={this.state.isDesktop} bevId="beverage-3" />
                <MyrceneTonic isDesktop={this.state.isDesktop} bevId="beverage-2" lang={this.props.lang} />

                <div className="blockwrap brandline">
                    <TerpsLinesStatic />
                    <ContainerContent>
                        <h2>
                            <TranslatableText dictionary={{
                                en: "What I’ll look like ",
                                fr: "Voici à quoi je ressemble lorsque " }}>
                            </TranslatableText><br />
                            <TranslatableText dictionary={{
                                en: "when you bring me home.",
                                fr: "vous m'apporterez à la maison." }}>
                            </TranslatableText>
                        </h2>

                        <img src={hot_drinks} alt="House of Terpenes - Family of Products" />
                    </ContainerContent>
                </div>
                <Footer instagram="houseofterpenes" />

            </div>
            </React.Fragment>
        )
    }
}
export default HouseOfTerpenes;