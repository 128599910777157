import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const PotencyHouseOt = (className, fill) => {
    
    return(

        <span className="icon">

            <svg xmlns="http://www.w3.org/2000/svg" width="108.888" height="108.89" viewBox="0 0 108.888 108.89">
                <g id="Group_836" data-name="Group 836" transform="translate(-1459.453 -343.57)">
                    <g id="Group_789" data-name="Group 789">
                    <path id="Path_721" data-name="Path 721" d="M1561.049,425.22l-4.33-2.5A46.919,46.919,0,0,0,1563.341,398a49.489,49.489,0,0,0-49.432-49.433v-5A54.494,54.494,0,0,1,1568.341,398,52.345,52.345,0,0,1,1561.049,425.22Z" fill="#fff"/>
                    </g>
                    <g id="Group_792" data-name="Group 792">
                    <g id="Group_790" data-name="Group 790" opacity="0.3">
                        <path id="Path_722" data-name="Path 722" d="M1556.719,422.72a49.433,49.433,0,0,1-85.62-49.433,48.309,48.309,0,0,1,42.81-24.717v-5a53.2,53.2,0,0,0-47.14,27.217,54.47,54.47,0,0,0,47.052,81.673A54.728,54.728,0,0,0,1528,450.581a54.07,54.07,0,0,0,33.051-25.361Z" fill="#fff"/>
                        <path id="Path_723" data-name="Path 723" d="M1485,381.313a32.627,32.627,0,0,1,28.912-16.694v-5a37.512,37.512,0,0,0-33.242,19.194A38.384,38.384,0,1,0,1547.15,417.2l-4.33-2.5A33.385,33.385,0,1,1,1485,381.313Z" fill="#fff"/>
                    </g>
                    <g id="Group_791" data-name="Group 791">
                        <path id="Path_724" data-name="Path 724" d="M1547.15,417.2l-4.33-2.5a31.689,31.689,0,0,0,4.473-16.692,33.424,33.424,0,0,0-33.385-33.386v-5a38.429,38.429,0,0,1,38.385,38.386A36.905,36.905,0,0,1,1547.15,417.2Z" fill="#fff"/>
                    </g>
                    </g>
                </g>
            </svg>


        </span>

    )
}
export default PotencyHouseOt;

PotencyHouseOt.propTypes = {
    className: PropTypes.string,
    fill: PropTypes.string,
  };
  
PotencyHouseOt.defaultProps = {
    className: undefined,
    fill: '#333',
};
  
