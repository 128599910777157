import React from 'react';
import { Link } from 'react-router-dom';
import  { LanguageConsumer } from '../../providers/LanguageProvider';
import { TranslatableText} from '../../providers/LanguageProvider'
import './style.scss';

const WhereToBuy = (props) => {
    
    return (
        <LanguageConsumer>
            {(context) => ( 
                <Link className={`btn-truss where-to-buy ${props.btnPage} ${props.show} ${props.isFixed ? 'fixed' : ''}`} to={`${context.language === 'fr' ? '/fr' : ''}${props.linkTo}`}>
                    <TranslatableText dictionary={{
                        en: "Where to buy",
                        fr: "Trouvez-moi une boisson" }}>
                    </TranslatableText>
                </Link> 
                )
            }
        </LanguageConsumer>
        // <Link className={`btn-truss where-to-buy ${props.btnPage} ${props.show} ${props.isFixed ? 'fixed' : ''}`} to={props.linkTo}>
        //      <TranslatableText dictionary={{
        //         en: "Where to buy",
        //         fr: "Trouvez-moi une boisson" }}>
        //     </TranslatableText>
        // </Link> 

    )

}
export default WhereToBuy;