import React from 'react';
import './style.scss';
import ContainerContent from '../../templates/ContainerContent/ContainerContent';
import { TranslatableText, LanguageConsumer } from '../../providers/LanguageProvider';
import imgContact from './i/contact.png';
import imgContactMb from './i/contact-mb.png';
import Footer from '../../components/Footer/Footer';


const Contact = (props) => {
    
    return(
        <React.Fragment>
        <div className={`page-wrap brandline`}>
            <ContainerContent>
                <div className="info-wrap">
                    <h1>
                        <TranslatableText dictionary={{
                            en: "Get in touch with Truss Beverage Co.",
                            fr: "N’hésitez pas à communiquer avec Truss Beverage Co." }}>
                        </TranslatableText>
                    </h1>
                    
                    <h2>33 Carlingview Dr,<br />Etobicoke, ON<br />M9W 5E4</h2>

                    {/* <h3>
                        <TranslatableText dictionary={{
                            en: "Contact us.",
                            fr: "Contactez-nous" }}>
                        </TranslatableText>
                    </h3> */}
                    <p>
                        <TranslatableText dictionary={{
                            en: "If you want to say hi: ",
                            fr: "Si vous voulez nous dire bonjour : " }}>
                        </TranslatableText><br /><a className="email" href="mailto:hello@trussbeverages.com" target="_blank" title="email hello@trussbeverages.com">hello@trussbeverages.com </a>
                    </p>
                    
                    <h2 className="contact-break">
                        <TranslatableText dictionary={{
                            en: "Got a product question?",
                            fr: "Vous avez une question sur nos produits?" }}>
                        </TranslatableText><br />
                        <TranslatableText dictionary={{
                            en: "Contact customer service at Truss/HEXO CIB:",
                            fr: "Veuillez contacter le service à la clientère de Truss/HEXO :" }}>
                        </TranslatableText>
                    </h2> 
                    <p>
                        <TranslatableText dictionary={{
                            en: "Email: ",
                            fr: "Courriel :" }}>
                        </TranslatableText><a href="mailto:info@hexo.com" className="email" target="_blank">info@hexo.com</a><br />
                        <TranslatableText dictionary={{
                            en: "Call: ",
                            fr: "Téléphone :" }}>
                        </TranslatableText><a href="tel:1-888-429-4375" className="telephone">1-888-429-4375</a><br />
                        
                    </p>
                
                </div>
                <div className="img-wrap">
                    {
                        props.info.isDesktop ?
                            <img src={imgContact} alt="fresh leaves" />
                        :
                            <img src={imgContactMb} alt="mobile" />
                    }
                </div>
                
            </ContainerContent>
            
        </div> 
        <Footer />
        </React.Fragment>
    )
}
export default Contact;