import React from 'react';
import './style.scss';
import ContainerContent from '../../../../templates/ContainerContent/ContainerContent';
import { TranslatableText } from '../../../../providers/LanguageProvider';
import { LanguageConsumer } from '../../../../providers/LanguageProvider';
import * as ScrollMagic from "scrollmagic"; // Or use scrollmagic-with-ssr to avoid server rendering problems
import { TimelineMax, Linear } from "gsap"; // Also works with TweenLite and TimelineLite
import xmg_bottle_mango from '../../i/xmg_2.png';
import Calendar from '../../../../components/svgIcons/Calendar';
import Can from '../../../../components/svgIcons/Can';
//import PotencyLow from '../../../../components/svgIcons/PotencyLow';
import PotencyXmg from '../../../../components/svgIcons/PotencyXmg';
import Highlights from '../../../../components/svgIcons/Highlights';
import InfoIcon from '../../../../components/svgIcons/InfoIcon';
import NutritionPopUp from '../../../../components/NutritionPopUp/NutritionPopUp';

class MangoPineapple extends React.Component {
    constructor(props){
        super(props)

        this.controller = new ScrollMagic.Controller();
    }
    componentWillUnmount() {
        this.controller.destroy();
    }
    componentDidMount = () => {
    
        var title = document.getElementById("title-anim-3");
        //var list = document.getElementById("beverage-info-3");
        //var imgWrap = document.getElementById("img-wrap-3");
                        
        var timeline2 = new TimelineMax()
        timeline2.to(title, 1, {y:110,/*  textShadow: "1px 1px 1px rgb(204, 46, 76)", */  ease: Linear.easeNone} )
        // timeline.to(list, 1, {y:95,   ease: Linear.easeNone}, 0)
        // timeline2.from(list, 1, {y:95,   ease: Linear.easeNone}, 0)
        //timeline.to(imgWrap, 1, {y:105,   ease: Linear.easeNone}, 0)

        // build scenes
        let scene2 = new ScrollMagic.Scene({triggerElement: ".three", triggerHook: 1, duration: '100%', tweenChanges: true});
            scene2 .setTween(timeline2)
            .on("enter leave", this.addBottleAnimation)
            .on("progress", function (event) {
                //console.log("Scene progress changed to " + event.progress);
            })
            //.addIndicators() // add indicators (requires plugin)
            .addTo(this.controller);

    }

    addBottleAnimation = () => { 
       let bottles =  document.getElementById('img-wrap-3');
       bottles.classList.add('anim');   
    }
    render() {
        return (
            <div className="blockwrap three" id={this.props.bevId}>

                <ContainerContent>
                    {
                        this.props.isDesktop ?
                            <div className="img-wrap" id="img-wrap-3">
                                <img src={xmg_bottle_mango} className="bottle-yellow bottle" alt="XMG - Mango Pineapple"  />
                                <img src={xmg_bottle_mango} className="bottle-yellow yellow-2 bottle abs" alt="XMG - Mango Pineapple" />
                            </div>
                        :
                        null
                    }
                    <div className="info-wrap">

                        <h2 id="title-anim-3">
                            <TranslatableText dictionary={{
                                en: "Find your X.",
                                fr: "Trouvez votre X." }}>
                            </TranslatableText>
                        </h2>

                        <div className="beverage-info" id="beverage-info-3">
                            

                            <LanguageConsumer>
                                {(context) => (
                                    <React.Fragment>
                                        {context.language !== 'fr' ? 
                                                <h3>XMG™ Mango Pineapple</h3>
                                            : 
                                                <h3>XMG™<br />Mangue ananas</h3>
                                        }
                                    </React.Fragment>
                                )}
                            </LanguageConsumer>

                            <p> 
                              
                                <TranslatableText dictionary={{
                                    en: "No messing around with the high-",
                                    fr: "Il n’y a pas de flafla avec cette potion" }}>
                                </TranslatableText><br />
                                <TranslatableText dictionary={{
                                    en: "intensity flavour of this mango pineapple",
                                    fr: "aux saveurs à intensité élevée de" }}>
                                </TranslatableText><br />
                                <TranslatableText dictionary={{
                                    en: "flavoured potion.",
                                    fr: "mangue et d’ananas." }}>
                                </TranslatableText>
                            </p>

                            {
                                this.props.isDesktop ?
                                    null
                                :
                                    <div className="img-wrap" id="img-wrap-3">
                                        <img src={xmg_bottle_mango} className="bottle-yellow bottle" alt="XMG™ - Mango Pineapple" />
                                        <img src={xmg_bottle_mango} className="bottle-yellow yellow-2 bottle abs" alt="XMG™ - Mango Pineapple" />
                                    </div>
                            }

                            <ul className="standard-list">
                                <li>
                                    <PotencyXmg />
                                    <span>
                                        <h4>
                                            <TranslatableText dictionary={{
                                                en: "POTENCY",
                                                fr: "intensité" }}>
                                            </TranslatableText>
                                        </h4>
                                        <p>
                                            <TranslatableText dictionary={{
                                                en: "HIGH",
                                                fr: "Élevée 10+" }}>
                                            </TranslatableText><br />
                                            <TranslatableText dictionary={{
                                                en: "10MG THC",
                                                fr: "10 mg THC" }}>
                                            </TranslatableText>
                                        </p>
                                    </span>
                                </li>
                                <li>
                                    <Calendar />
                                    <span>
                                        <h4>
                                            <TranslatableText dictionary={{
                                                en: "OCCASIONS",
                                                fr: "Occasions" }}>
                                            </TranslatableText>
                                        </h4>
                                        <p>
                                            <TranslatableText dictionary={{
                                                en: "Deep bass. ",
                                                fr: "Pendant un show qui brasse un peu." }}>
                                            </TranslatableText><br />
                                            <TranslatableText dictionary={{
                                                en: "Changing perspective.",
                                                fr: "Pour changer de perspective." }}>
                                            </TranslatableText><br />
                                            <TranslatableText dictionary={{
                                                en: "Time travel.",
                                                fr: "Pour voyager dans le temps." }}>
                                            </TranslatableText>
                                        </p>
                                    </span>
                                </li>
                                <li>
                                    <Can />
                                    <span>
                                        <h4>
                                            <TranslatableText dictionary={{
                                                en: "SERVING SUGGESTION",
                                                fr: "NOTES DE SERVICE" }}>
                                            </TranslatableText>
                                        </h4>
                                        <p> 
                                            <TranslatableText dictionary={{
                                                en: "Straight up, right out of the can.",
                                                fr: "À boire directement à la canette." }}>
                                            </TranslatableText>
                                        </p>
                                    </span>
                                </li>
                                <li>
                                    <Highlights />
                                    <span>
                                        <h4>
                                            <TranslatableText dictionary={{
                                                en: "HIGHLIGHTS",
                                                fr: "INFORMATIONS" }}>
                                            </TranslatableText>
                                        </h4>
                                        <p>
                                            <TranslatableText dictionary={{
                                                en: "All natural flavours. ",
                                                fr: "Saveurs entièrement naturelles." }}>
                                            </TranslatableText>
                                        </p>
                                    </span>
                                </li>
                                <li>
                                    <InfoIcon />
                                    <span>
                                        <h4>
                                            <TranslatableText dictionary={{
                                                en: "NUTRITIONAL INFORMATION",
                                                fr: "INFORMATIONS NUTRITIVE" }}>
                                            </TranslatableText>
                                        </h4>
                                        <div className="paragraph">
                                            <NutritionPopUp beverage="mango-pineapple" />
                                        </div>
                                    </span>
                                </li>
                            </ul>

                        </div>

                    </div>
                </ContainerContent>
                
            </div>
        )
    }
}
export default MangoPineapple;