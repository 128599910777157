import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const PotencyVvIcedTea = (className, fill) => {
    
    return(

        <span className="icon">
            
            <svg xmlns="http://www.w3.org/2000/svg" width="108.878" height="108.854" viewBox="0 0 108.878 108.854">
                <g id="Group_840" data-name="Group 840" transform="translate(-601.235 -704.74)">
                    <g id="Group_798" data-name="Group 798">
                    <path id="Path_733" data-name="Path 733" d="M647.05,791.422A33.385,33.385,0,0,1,639,730.262a31.694,31.694,0,0,1,16.692-4.473v-5a36.91,36.91,0,0,0-19.192,5.143,38.385,38.385,0,1,0,52.434,14.05l-4.33,2.5a33.385,33.385,0,0,1-37.553,48.94Z" fill="rgba(214,149,104,0.3)"/>
                    <path id="Path_734" data-name="Path 734" d="M702.831,731.957l-4.33,2.5a49.432,49.432,0,1,1-67.526-18.094,46.919,46.919,0,0,1,24.716-6.623v-5a52.336,52.336,0,0,0-27.216,7.293,54.433,54.433,0,1,0,74.356,19.924Z" fill="rgba(214,149,104,0.3)"/>
                    </g>
                    <g id="Group_799" data-name="Group 799">
                    <path id="Path_735" data-name="Path 735" d="M655.691,704.74v5a48.309,48.309,0,0,1,42.81,24.717l4.33-2.5A53.2,53.2,0,0,0,655.691,704.74Z" fill="#d69568"/>
                    <path id="Path_736" data-name="Path 736" d="M655.691,720.789v5A32.626,32.626,0,0,1,684.6,742.482l4.33-2.5A37.513,37.513,0,0,0,655.691,720.789Z" fill="#d69568"/>
                    </g>
                </g>
            </svg>

        </span>

    )
}
export default PotencyVvIcedTea;

PotencyVvIcedTea.propTypes = {
    className: PropTypes.string,
    fill: PropTypes.string,
  };
  
PotencyVvIcedTea.defaultProps = {
    className: undefined,
    fill: '#333',
};
  
