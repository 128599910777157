import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const PotencyMollo25 = (className, fill) => {
    
    return(

        <span className="icon">

            {/* <svg xmlns="http://www.w3.org/2000/svg" width="50.367" height="50.367" viewBox="0 0 50.367 50.367">
                <g className="icon-chart-low" data-name="Group 427" transform="translate(1 1)">
                    <g id="Group_426" data-name="Group 426" opacity="0.3">
                    <g id="Group_425" data-name="Group 425">
                        <circle id="Ellipse_369" data-name="Ellipse 369" cx="16.854" cy="16.854" r="16.854" transform="translate(7.33 7.33)" fill="none" strokeMiterlimit="10" strokeWidth="2"/>
                        <circle id="Ellipse_370" data-name="Ellipse 370" cx="24.183" cy="24.183" r="24.183" fill="none"  strokeMiterlimit="10" strokeWidth="2"/>
                    </g>
                    </g>
                    <path id="Path_627" data-name="Path 627" d="M620.447,518.765a16.851,16.851,0,0,0-16.854-16.851" transform="translate(-579.411 -494.583)" fill="none"  strokeMiterlimit="10" strokeWidth="2"/>
                    <path id="Path_628" data-name="Path 628" d="M627.778,510.265a24.182,24.182,0,0,0-24.183-24.181" transform="translate(-579.412 -486.082)" fill="none"  strokeMiterlimit="10" strokeWidth="2"/>
                </g>
            </svg> */}

            <svg xmlns="http://www.w3.org/2000/svg" width="108.878" height="108.854" viewBox="0 0 108.878 108.854">
                <g id="Group_835" data-name="Group 835" transform="translate(-1173.733 -344.466)">
                    <g id="Group_795" data-name="Group 795" opacity="0.3">
                    <path id="Path_729" data-name="Path 729" d="M1219.549,431.147a33.384,33.384,0,0,1-8.052-61.159,31.7,31.7,0,0,1,16.692-4.473v-5A36.913,36.913,0,0,0,1209,365.658a38.385,38.385,0,1,0,52.435,14.05l-4.33,2.5a33.384,33.384,0,0,1-37.553,48.939Z" fill="#d5ac65"/>
                    <path id="Path_730" data-name="Path 730" d="M1275.329,371.683l-4.33,2.5a49.433,49.433,0,1,1-67.525-18.094,46.913,46.913,0,0,1,24.715-6.623v-5a52.333,52.333,0,0,0-27.215,7.293,54.432,54.432,0,1,0,74.355,19.924Z" fill="#d5ac65"/>
                    </g>
                    <g id="Group_796" data-name="Group 796">
                    <path id="Path_731" data-name="Path 731" d="M1228.189,344.466v5A48.308,48.308,0,0,1,1271,374.183l4.33-2.5A53.2,53.2,0,0,0,1228.189,344.466Z" fill="#d5ac65"/>
                    <path id="Path_732" data-name="Path 732" d="M1228.189,360.515v5a32.627,32.627,0,0,1,28.913,16.693l4.33-2.5A37.514,37.514,0,0,0,1228.189,360.515Z" fill="#d5ac65"/>
                    </g>
                </g>
            </svg>


        </span>

    )
}
export default PotencyMollo25;

PotencyMollo25.propTypes = {
    className: PropTypes.string,
    fill: PropTypes.string,
  };
  
PotencyMollo25.defaultProps = {
    className: undefined,
    fill: '#333',
};
  
