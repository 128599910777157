import React from 'react';
import './style.scss';
import ContainerContent from '../../../../templates/ContainerContent/ContainerContent';
import { TranslatableText } from '../../../../providers/LanguageProvider';
import { LanguageConsumer } from '../../../../providers/LanguageProvider';
import * as ScrollMagic from "scrollmagic"; // Or use scrollmagic-with-ssr to avoid server rendering problems
import { TimelineMax, Linear } from "gsap"; // Also works with TweenLite and TimelineLite
import vv_bottle_cbdthc from '../../i/veryvell_6.png';
import Calendar from '../../../../components/svgIcons/Calendar';
import Cocktail3 from '../../../../components/svgIcons/Cocktail3';
//import PotencyLow from '../../../../components/svgIcons/PotencyLow';
import PotencyVvTingle from '../../../../components/svgIcons/PotencyVvTingle';
import Highlights from '../../../../components/svgIcons/Highlights';
import VerywellLinesStatic from '../../i/VerywellLinesStatic';

class MixDrops extends React.Component {
    constructor(props){
        super(props)

        this.controller = new ScrollMagic.Controller();
    }
    componentWillUnmount() {
        this.controller.destroy();
    }
    componentDidMount = () => {
    
        var title = document.getElementById("title-anim-7");
        //var list = document.getElementById("beverage-info-7");
        //var imgWrap = document.getElementById("img-wrap-7");
                        
        var timeline2 = new TimelineMax()
        timeline2.to(title, 1, {y:110,/*  textShadow: "1px 1px 1px rgb(204, 46, 76)", */  ease: Linear.easeNone} )
        // timeline.to(list, 1, {y:95,   ease: Linear.easeNone}, 0)
        // timeline2.from(list, 1, {y:95,   ease: Linear.easeNone}, 0)
        //timeline.to(imgWrap, 1, {y:105,   ease: Linear.easeNone}, 0)
        
        // build scenes
        let scene2 = new ScrollMagic.Scene({triggerElement: ".seven", triggerHook: 1, duration: '100%', tweenChanges: true});
            scene2 .setTween(timeline2)
            .on("enter leave", this.addBottleAnimation)
            .on("progress", function (event) {
                //console.log("Scene progress changed to " + event.progress);
            })
            //.addIndicators() // add indicators (requires plugin)
            .addTo(this.controller);

    }

    addBottleAnimation = () => { 
       let bottles =  document.getElementById('img-wrap-7');
       bottles.classList.add('anim');   
    }
    render() {
        return (
            <div className="blockwrap seven" id={this.props.bevId}>
                <VerywellLinesStatic />
                 <ContainerContent>
                    {
                        this.props.isDesktop ?
                            <div id="img-wrap-7" className="img-wrap">
                                <img src={vv_bottle_cbdthc} className="bottle-yellow bottle" alt="Veryvell™ - Mix Drops" />
                               {/*  <img src={lv_bottle_yellow} className="bottle-yellow yellow-2 bottle abs" /> */}
                            </div>
                        :
                        null
                    }

                    <div className="info-wrap">

                        <h2 id="title-anim-7">
                            <TranslatableText dictionary={{
                                en: "Get the drop on",
                                fr: "Une goutte de plaisir." }}>
                            </TranslatableText><br />
                            <TranslatableText dictionary={{
                                en: "pleasure.",
                                fr: "" }}>
                            </TranslatableText>
                        </h2>  

                        <div id="beverage-info-7" className="beverage-info">
                            
                            <LanguageConsumer>
                                {(context) => (
                                    <React.Fragment>
                                        {context.language !== 'fr' ? 
                                                <h3>Veryvell™<br /> Tingle Drops</h3>
                                            : 
                                                <h3>Veryvell™<br />L’allumeur Gouttes de Boisson</h3>
                                        }
                                    </React.Fragment>
                                )}
                            </LanguageConsumer>
                            
                            <p>
                                <TranslatableText dictionary={{
                                    en: "Drop it and see where the night takes you. ",
                                    fr: "Un goût d’aventure." }}>
                                </TranslatableText>
                            </p>


                            {
                                this.props.isDesktop ?
                                    null
                                :
                                    <div id="img-wrap-7" className="img-wrap">
                                        <img src={vv_bottle_cbdthc} className="bottle-yellow bottle" alt="Veryvell™ - Tingle Drops" />
                                    {/*  <img src={vv_bottle_cbd} className="bottle-yellow yellow-2 bottle abs" /> */}
                                    </div>
                            }

                            <ul className="standard-list">
                                <li>
                                    <PotencyVvTingle />
                                    <span>
                                        <h4>
                                            <TranslatableText dictionary={{
                                                en: "POTENCY",
                                                fr: "intensité" }}>
                                            </TranslatableText>
                                        </h4>
                                        <p> 

                                            <TranslatableText dictionary={{
                                                en: "2 DROPS = ~ 0.5MG THC + ~ 0.5MG CBD",
                                                fr: "2 gouttes = ENV. 0,5 mg THC" }}>
                                            </TranslatableText><br />
                                            <TranslatableText dictionary={{
                                                en: "",
                                                fr: ": ENV. 0,5 mg CBD" }}>
                                            </TranslatableText>
                                        </p>
                                    </span>
                                </li>
                                <li>
                                    <Calendar />
                                    <span>
                                        <h4>
                                            <TranslatableText dictionary={{
                                                en: "OCCASIONS",
                                                fr: "OCCASIONS" }}>
                                            </TranslatableText>
                                        </h4>
                                        <p>
                                            <TranslatableText dictionary={{
                                                en: "Enjoying some me time. ",
                                                fr: "Pour passer du temps avec soi-même." }}>
                                            </TranslatableText><br />
                                            <TranslatableText dictionary={{
                                                en: "Hump days.",
                                                fr: "Les mercredis." }}>
                                            </TranslatableText><br />
                                            <TranslatableText dictionary={{
                                                en: "Empty house.",
                                                fr: "Quand la maison est calme." }}>
                                            </TranslatableText>
                                        </p>
                                    </span>
                                </li>
                                <li>
                                    <Cocktail3 />
                                    <span>
                                        <h4>
                                            <TranslatableText dictionary={{
                                                en: "SERVING SUGGESTION",
                                                fr: "NOTES DE SERVICE" }}>
                                            </TranslatableText>
                                        </h4>
                                        <p>
                                            <TranslatableText dictionary={{
                                                en: "Add to your glass when you’re in the mood. ",
                                                fr: "Ajouter à votre verre quand vous en" }}>
                                            </TranslatableText><br />
                                            <TranslatableText dictionary={{
                                                en: "",
                                                fr: "avez le goût." }}>
                                            </TranslatableText>
                                        </p>
                                    </span>
                                </li>
                                <li>
                                    <Highlights />
                                    <span>
                                        <h4>
                                            <TranslatableText dictionary={{
                                                en: "HIGHLIGHTS",
                                                fr: "INFORMATIONS" }}>
                                            </TranslatableText>
                                        </h4>
                                        <p>
                                            <TranslatableText dictionary={{
                                                en: "Water-soluble with a subtle hint of ",
                                                fr: "Soluble dans l’eau, avec une légère" }}>
                                            </TranslatableText><br />
                                            <TranslatableText dictionary={{
                                                en: "ginseng extract. Freedom to choose ",
                                                fr: " touche d’extrait de ginseng." }}>
                                            </TranslatableText><br />
                                            <TranslatableText dictionary={{
                                                en: "your dose.",
                                                fr: "Libre de choisir sa dose." }}>
                                            </TranslatableText>
                                        </p>
                                    </span>
                                </li>
                            </ul>


                        </div>

                    </div>

                 </ContainerContent>

            </div>
        )
    }
}
export default MixDrops;