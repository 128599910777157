import React from 'react';
import './style.scss';
import { LanguageContext } from '../../providers/LanguageProvider';
import { TranslatableText } from '../../providers/LanguageProvider';
import Modal from 'react-bootstrap/Modal';
import ContainerContent from '../../templates/ContainerContent/ContainerContent';
//import LemoneneTonic from './i/limonene-tonic.png';
import Helmet from 'react-helmet';
import data from './data.json';

class NutritionPopUp extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            showInfo: false,
            beverages: data.beverages,
            singleBeverage: null
        }

    }

    static contextType = LanguageContext;

    componentDidMount = () => {
        this.fetchNutritionInfo(this.props.beverage);
    }

    nutritionToggle = () => {
        this.setState((prevState) => {
            return { showInfo: !prevState.showInfo };
        })
    }

    closePopUpHandler = () => {
        this.setState((prevState) => {
            return { showInfo: false };
        })
    }

    fetchNutritionInfo = (keyname) => {
        let drink = this.state.beverages.filter(function (option) {
                        return option.keyname === keyname;
                    });
          
        this.setState({
            singleBeverage: drink[0]
        })      
    }

    render() {
        let nutritionInfo = null;
        let showClass = '';
        let  { singleBeverage, showInfo } = this.state;
        let langName;
        let lang = this.context.language;
        lang === 'fr' ? langName = 'name_fr' : langName = 'name_en';
       

        if(this.state.showInfo && singleBeverage){
            const images = require.context('./i', true);
            // Set image
            let img = images('./' + singleBeverage.image);

            nutritionInfo = (
                <Modal show={this.state.showInfo} onHide={this.closePopUpHandler} className={`nutrition-modal`}  animation="false" backdropClassName="nutrition-backdrop">
                    <Modal.Body>
                        <div className="img-wrap">
                            <img src={img} className="product-img" alt={singleBeverage[langName]} />
                        </div>
                       
                        <div className="nutrition-pop-up">
                            <div className="container">
                                
                                <div className="header">
                                    <h5>{singleBeverage[langName]}</h5><button className="close-info" onClick={this.closePopUpHandler}><i className="lar la-times-circle"></i></button>
                                </div>
                                

                                <div className="nutrition-info-wrap">
                                    <h6>
                                        <TranslatableText dictionary={{
                                            en: "Nutrition Facts",
                                            fr: "Valeur nutrinionelle" }}>
                                        </TranslatableText>
                                    </h6>
                                    <ul>
                                        <li>
                                            <TranslatableText dictionary={{
                                                en: "Per ",
                                                fr: "Pour " }}>
                                            </TranslatableText>
                                        {/* {singleBeverage.container} */}
                                            <TranslatableText dictionary={{
                                                en: `${singleBeverage.container_en}`,
                                                fr: `${singleBeverage.container_fr}` }}>
                                            </TranslatableText> {singleBeverage.container_size}<span></span>
                                        </li>
                                        <li><span>Calories <strong>{singleBeverage.calories}</strong></span><span><TranslatableText dictionary={{
                                                en: "DV%",
                                                fr: "% VQ*" }}>
                                            </TranslatableText>{singleBeverage.calories_percentage}</span></li>
                                        <li>
                                            <span><TranslatableText dictionary={{
                                                en: "Fat",
                                                fr: "Lipides" }}>
                                            </TranslatableText> {singleBeverage.fat} g</span>
                                            <span>{singleBeverage.fat_percentage}%</span>
                                        </li>
                                        <li className="rows">
                                            <div className="row">
                                                <span>&nbsp;&nbsp;<TranslatableText dictionary={{
                                                    en: "Saturated",
                                                    fr: "Saturés" }}>
                                                </TranslatableText> {singleBeverage.saturated} g</span>
                                            </div>
                                            <div className="row">
                                                <span>&nbsp;&nbsp;<TranslatableText dictionary={{
                                                    en: "+ Trans",
                                                    fr: "+ Trans" }}>
                                                </TranslatableText> {singleBeverage.trans} g</span>
                                                <span className="total">{singleBeverage.saturated_percentage}%</span>
                                            </div>
                                        </li>
                                        <li>
                                            <span><TranslatableText dictionary={{
                                                en: "Carbohydrate",
                                                fr: "Glucides" }}>
                                            </TranslatableText> {singleBeverage.carbohydrate} g</span> 
                                        </li>
                                        <li>
                                            <span>&nbsp;<TranslatableText dictionary={{
                                                en: "Fibre",
                                                fr: "Fibres" }}>
                                            </TranslatableText> {singleBeverage.fibre} g</span>
                                            <span>{singleBeverage.fibre_percentage}%</span>
                                        </li>
                                        <li>
                                            <span>&nbsp;&nbsp;<TranslatableText dictionary={{
                                                en: "Sugars",
                                                fr: "Sucres" }}>
                                            </TranslatableText> {singleBeverage.sugars} g</span>
                                            <span>{singleBeverage.sugars_percentage}%</span>
                                        </li>
                                        <li>
                                            <span>&nbsp;&nbsp;<TranslatableText dictionary={{
                                                en: "Protein",
                                                fr: "Protéines" }}>
                                            </TranslatableText> {singleBeverage.protein} g</span>
                                        </li>
                                        <li>
                                            <span><TranslatableText dictionary={{
                                                en: "Cholesterol",
                                                fr: "Cholestérol" }}>
                                            </TranslatableText> {singleBeverage.cholesterol} mg</span> 
                                        </li>
                                        <li>
                                            <span>Sodium {singleBeverage.sodium} mg</span>
                                            <span>{singleBeverage.sodium_percentage}%</span>
                                        </li>
                                        <li>
                                            <span>Potassium {singleBeverage.potassium} mg</span>
                                            <span>{singleBeverage.potassium_percentage}%</span>
                                        </li>
                                        <li>
                                            <span>Calcium {singleBeverage.calcium} mg</span>
                                            <span>{singleBeverage.calcium_percentage}%</span>
                                        </li>
                                        <li>
                                            <span>Iron {singleBeverage.iron} mg</span>
                                            <span>{singleBeverage.iron_percentage}%</span>
                                        </li>
                                        <li>
                                            <span>&nbsp;<TranslatableText dictionary={{
                                                en: "* DV = Daily Value /",
                                                fr: "*VQ = valeur quotidienne" }}>
                                            </TranslatableText></span>
                                        </li>
                                    </ul>
                                </div>
                                
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            )
        }
        return (
        
            <div>
                
                {this.state.showInfo && <Helmet htmlAttributes={{ class : 'lock'}}></Helmet>}

                {nutritionInfo}

                <button className={`nutrional-info`} onClick={this.nutritionToggle}>
                    <TranslatableText dictionary={{
                        en: "See information +",
                        fr: "Consulter la valeur nutritive +" }}>
                    </TranslatableText>
                </button>
            </div>
        )
    }
}
export default NutritionPopUp;