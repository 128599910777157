import React from 'react';
import './style.scss';
import { LanguageContext } from '../../providers/LanguageProvider';
import {TranslatableText, LanguageConsumer} from '../../providers/LanguageProvider';
import axios from 'axios';
import './style.scss';
import successCheck from '../../assets_global/images/checked-big.png';
import { Textbox } from 'react-inputs-validation';

const API_PATH = 'http://trussbeverages.rethinkcanada.ca/api/campaign-monitor/index.php'; // stg site
//const API_PATH = 'https://trussbeverages.com/api/campaign-monitor/index.php'; // live site
class CampaignMonitorForm extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            name: '',
            email: '',
            checked: false,
            show: true,
            showSuccess: false,
            mailSent: false,
            error: null,
            placeholderName: 'Name',
            placeholderEmail: 'Email'
        }
    }

    // Grab the context!
    static contextType = LanguageContext;

    componentDidMount(){
         
        if (this.context.language === 'fr'){
            // Set the Lang State
            this.setState({
                placeholderName: "Nom",
                placeholderEmail: "Courriel"
            })
        }
    }

    handleFormSubmit = ( event ) => {
        //const { successSubmit } =  this.props;
        event.preventDefault();
        //console.log(this.state);

        axios({
            method: 'post',
            url: `${API_PATH}`,
            headers: { 'content-type': 'application/json' },
            data: this.state
        })
        .then(result => {
            //console.log('axios result ',result);
            this.setState({
              mailSent: result.data
            })
            // On success 
            this.props.successSubmit();
        })
        .catch(error => this.setState({ error: error.message }));
    }

    handleCheckboxChange = () => {
        this.setState({ checked: !this.state.checked }) 
    }


    render() {
        const disabledToggle = this.state.checked ? null : 'disabled';
        const {placeholderName, placeholderEmail, checked } = this.state;
        return (
            <div>
                {!this.state.mailSent && <form id="campaign-monitor" action="" method="post">
                    {/* <label htmlFor="name">Name</label> */}
                    {/* <input id="name" type="text" name="name" placeholder="Name" onChange={e => this.setState({ name: e.target.value })} /> */}
                    <Textbox 
                        tabIndex="1" 
                        id="Name"
                        name="Name" 
                        type="text"
                        placeholder={placeholderName}
                        value={this.state.name}
                        onChange={(name, e) => {
                            this.setState({ name });
                            //console.log(e);
                            }}
                            onBlur={e => {}}  //Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
                            validationOption={{
                            name: 'Name', // Optional.[String].Default: "". To display in the Error message. i.e Please enter your {name}.
                            check: true, // Optional.[Bool].Default: true. To determin if you need to validate.
                            required: true, // Optional.[Bool].Default: true. To determin if it is a required field.
                            msgOnError: <TranslatableText dictionary={{ 
                                            en: "Please enter a valid first name.",
                                            fr: "Veuillez entrer un nom valide."}}>
                                        </TranslatableText>

                        }}
                    />

                    <Textbox
                        tabIndex="2" 
                        id="email"
                        name = 'EMAIL'
                        type = 'text'
                        placeholder = {placeholderEmail}
                        value={this.state.email}
                        onChange={(email, e) => {
                            this.setState({ email });
                            //console.log(e);
                        }} // Required.[Func].Default: () => {}. Will return the value.
                        onBlur={e => {}} //Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
                        validationOption={{
                            name: 'Email', //Optional.[String].Default: "". To display in the Error message. i.e Please enter your ${name}.
                            check: true, //Optional.[Bool].Default: true. To determin if you need to validate.,
                            required: true, //Optional.[Bool].Default: true. To determin if it is a required field.
                            msgOnError: <TranslatableText dictionary={{ 
                                            en: "Please enter a valid email address.",
                                            fr: "Veuillez entrer une adresse courriel valide."}}>
                                        </TranslatableText>,
                            customFunc: email => {
                                const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                                if (reg.test(String(email).toLowerCase())) {
                                    return true;
                                } else {
                                    return 'Please provide a valid email.';
                                }
                            }
                        }}
                    />

                    {/* <label htmlFor="email">Email</label> */}
                    {/* <input id="email" type="email" name="email" placeholder="Email" onChange={e => this.setState({ email: e.target.value })} /> */}
                    
                    {/* <p className="legal">
                        <TranslatableText dictionary={{ 
                            en: "By opting in, I consent to receive marketing communications from Truss Beverage Co.™  I acknowledge that I can opt out at any time by clicking on the link at the bottom of the email.",
                            fr: "En m’inscrivant, je consens à recevoir les communications de Truss Beverage Co.™ Je reconnais que je peux me désabonner en tout temps en cliquant sur le lien au bas de ce courriel."}}>
                        </TranslatableText>
                    </p> */}

                    <div className="input-container">
                        <input name="check-required" id="check-required" type="checkbox" checked={this.state.checked} onChange={this.handleCheckboxChange} /> <label htmlFor="check-required"></label>
                        <p className="checked-text">
                            <TranslatableText dictionary={{
                                en: "I would like to receive email communications from Truss.",
                                fr: "J'aimerais recevoir les courriels de Truss." }}>
                            </TranslatableText>
                        </p>
                    </div>

                    <button type="submit" onClick={e => this.handleFormSubmit(e)} disabled={this.state.checked ? '' : true} >Sign Up</button>
                </form>}

               
                {this.state.mailSent &&
                    <div className="message">
                        <div>
                            <p>
                                <TranslatableText dictionary={{
                                    en: "Thanks for signing up, we’ll see you in your inbox!",
                                    fr: "Merci de vous être inscrit. On se revoit bientôt dans votre boîte de courriel." }}>
                                </TranslatableText>
                            </p>
                        </div>
                        <div className="success-image">
                            <img src={successCheck} alt="checkmark" />
                        </div>
                    </div>
                }
                
            </div>
        )
    }
}
export default CampaignMonitorForm;