import React from 'react';
import './style.scss';
import Particles from 'react-particles-js';
import bubble from './i/bubble.png';

const particleOptions = {
    particles: {
        collisions: {
            enabled: false
        },
        shape: {
            type: 'image',
            "image": 
                {
                    src: bubble,
                    width: 400,
                    height: 400
                }
            
        },
        number: {
            value: 128,
            density: {
                enable: false
            }
        },
        size: {
            value: 30,
            random: true,
            anim: {
                speed: 4,
                size_min: 0.1,
                sync: false
            }
        },
        line_linked: {
            enable: false
        },
        opacity: {
            value: 0.3,
            random: false,
            anim: {
              enable: false,
              speed: 1,
              opacity_min: 0.1,
              sync: false
            }
          },
        move: {
            enable: true,
            speed: 2.0,
            direction: "none",
            random: true,
            bounce: false,
            // attract: {
            //   enable: true,
            //   rotateX: 80,
            //   rotateY: 80
            // }
          }
    },
    interactivity: {
        events: {
            onhover: {
                enable: false,
                mode: "bubble"
            },
            onclick: {
                enable: false,
                mode: "repulse"
            }
        },
        modes: {
            bubble: {
                distance: 250,
                duration: 2,
                size: 0,
                opacity: 0
            },
            repulse: {
                distance: 400,
                duration: 4
            }
        }
    },
    "retina_detect": true
}


class Bubbles extends React.Component {
    constructor(props){
        super(props)
        this.state = { 
            width: "0px",
            height: "0px"
        };
      
    }
   
    componentDidMount(startPaused = false) {
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions);

    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions);
    }
    updateWindowDimensions = () => {
        this.setState({
            width: `${window.innerWidth}px`,
            height: `${window.innerHeight}px`
        });
    };


    render() {
        const { width, height } = this.state;
        return (
        
            <div id="BannerContainer">
                {/* <div className="background"></div> */}
                <Particles width="100%" height="100vh" params={particleOptions} />
            </div>
       
        )
    }
}
export default Bubbles;