import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const Mug = (className, fill) => {
    
    return(

        <span className="icon">

            <svg className="icon-mug" xmlns="http://www.w3.org/2000/svg" width="40.499" height="61.989" viewBox="0 0 40.499 61.989">
                <g id="Group_772" data-name="Group 772" transform="translate(-1412.56 -621.856)">
                    <g id="Group_771" data-name="Group 771" transform="translate(1414.485 621.856)" opacity="0.25">
                    <path id="Path_853" data-name="Path 853" d="M1418,670.022h-1.631v2.525H1418a1.187,1.187,0,0,1,1.186,1.186v13.912a1.187,1.187,0,0,1-1.186,1.186h-1.631v2.525H1418a3.715,3.715,0,0,0,3.711-3.711V673.733A3.715,3.715,0,0,0,1418,670.022Z" transform="translate(-1416.371 -645.698)" fill="#d5ac65"/>
                    <path id="Path_854" data-name="Path 854" d="M1465.382,672.547h1.631v-2.525h-1.631a3.715,3.715,0,0,0-3.71,3.711v13.912a3.715,3.715,0,0,0,3.71,3.711h1.631v-2.525h-1.631a1.187,1.187,0,0,1-1.185-1.186V673.733A1.187,1.187,0,0,1,1465.382,672.547Z" transform="translate(-1438.795 -645.698)" fill="#d5ac65"/>
                    <path id="Path_855" data-name="Path 855" d="M1440.7,670.022h-3.114a3.715,3.715,0,0,0-3.71,3.711v13.912a3.715,3.715,0,0,0,3.71,3.711h3.114a3.715,3.715,0,0,0,3.711-3.711V673.733A3.715,3.715,0,0,0,1440.7,670.022Zm1.186,17.623a1.187,1.187,0,0,1-1.186,1.186h-3.114a1.186,1.186,0,0,1-1.185-1.186V673.733a1.186,1.186,0,0,1,1.185-1.186h3.114a1.187,1.187,0,0,1,1.186,1.186Z" transform="translate(-1425.037 -645.698)" fill="#d5ac65"/>
                    <rect id="Rectangle_1771" data-name="Rectangle 1771" width="28.038" height="2.525" transform="translate(0.173 14.483)" fill="#d5ac65"/>
                    <path id="Path_856" data-name="Path 856" d="M1441.84,632.034h2.525v-2.02a5.514,5.514,0,0,0-4.187-5.345,5.185,5.185,0,0,0-4.606-2.813h-2.8a5.183,5.183,0,0,0-4.52,2.654h-5.374a5.511,5.511,0,0,0-5.5,5.5v2.02h24.463Zm-18.958-5h7.241a2.653,2.653,0,0,1,2.653-2.654h2.8a2.654,2.654,0,0,1,2.653,2.654h.636a2.979,2.979,0,0,1,2.979,2.979H1419.9A2.98,2.98,0,0,1,1422.882,627.035Z" transform="translate(-1416.869 -621.856)" fill="#d5ac65"/>
                    </g>
                    <path id="Path_857" data-name="Path 857" d="M1444.773,649.141h-.283v-8.293a3.171,3.171,0,0,0-3.167-3.167h-25.595a3.171,3.171,0,0,0-3.167,3.167v45.9a4.936,4.936,0,0,0,4.931,4.93h22.068a4.936,4.936,0,0,0,4.93-4.93v-6.529h.283a8.3,8.3,0,0,0,8.286-8.286V657.427A8.3,8.3,0,0,0,1444.773,649.141Zm-2.808,37.607a2.4,2.4,0,0,1-2.405,2.405h-22.068a2.406,2.406,0,0,1-2.406-2.405v-45.9a.643.643,0,0,1,.642-.642h25.595a.642.642,0,0,1,.642.642Zm2.525-30.21h.326a1.187,1.187,0,0,1,1.186,1.186v13.912a1.187,1.187,0,0,1-1.186,1.186h-.326Zm6.044,15.395a5.768,5.768,0,0,1-5.761,5.761h-.283v-2.347h.326a3.715,3.715,0,0,0,3.711-3.711V657.724a3.715,3.715,0,0,0-3.711-3.711h-.326v-2.347h.283a5.767,5.767,0,0,1,5.761,5.761Z" transform="translate(0 -7.833)" fill="#d5ac65"/>
                </g>
            </svg>



        </span>

    )
}
export default Mug;

Mug.propTypes = {
    className: PropTypes.string,
    fill: PropTypes.string,
};
  
Mug.defaultProps = {
    className: undefined,
    fill: '#333',
};
  