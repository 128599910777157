import React from 'react';
import './style.scss';
import ContainerContent from '../../../../templates/ContainerContent/ContainerContent';
import { TranslatableText } from '../../../../providers/LanguageProvider';
import { LanguageConsumer } from '../../../../providers/LanguageProvider';
import * as ScrollMagic from "scrollmagic"; // Or use scrollmagic-with-ssr to avoid server rendering problems
import { TimelineMax, Linear } from "gsap"; // Also works with TweenLite and TimelineLite
import mollo_bottle_25 from '../../i/mollo_1.png';
//import mollo_bottle_25_badge from '../../i/mollo_1_badge.png';
//import mollo_bottle_25_badge_fr from '../../i/mollo_1_badge_fr.png';

// quickly remove badges temporarily
import mollo_bottle_25_badge from '../../i/mollo_1.png';
import mollo_bottle_25_badge_fr from '../../i/mollo_1.png';
import Calendar from '../../../../components/svgIcons/Calendar';
import PotencyMollo25 from '../../../../components/svgIcons/PotencyMollo25';
import Highlights from '../../../../components/svgIcons/Highlights';
import InfoIcon from '../../../../components/svgIcons/InfoIcon';
import ServingsMollo from '../../../../components/svgIcons/ServingsMollo';
import MolloLinesStatic from '../../i/MolloLinesStatic';
import bottle_cap_1 from '../../i/silver-bottle-cap.png';
import NutritionPopUp from '../../../../components/NutritionPopUp/NutritionPopUp';

class Mollo25Brew extends React.Component {
    constructor(props){
        super(props)
        // basic initialization
        this.controller = new ScrollMagic.Controller();
    }
    componentWillUnmount() {
        this.controller.destroy();
    }
    componentDidMount = () => {
    
        var title = document.getElementById("title-anim");
        var list = document.getElementById("beverage-info");
        var imgWrap = document.getElementById("img-wrap");
        
                               
        var timeline = new TimelineMax()
        timeline.to(title, 1, {y:110, /* textShadow: "1px 1px 1px rgb(204, 46, 76)" ,*/  ease: Linear.easeNone} )
        //timeline.to(list, 1, {y:95,   ease: Linear.easeNone}, 0)
        
       // timeline.from(list, 1, {y:95,   ease: Linear.easeNone}, 0)
        //timeline.to(imgWrap, 1, {y:105,   ease: Linear.easeNone}, 0)

        
        // build scenes
        new ScrollMagic.Scene({triggerElement: ".second", triggerHook: 1, duration: '100%',tweenChanges: true})
            //.setPin("#pin")
            .setTween(timeline)
           // .setClassToggle(".img-wrap", "anim")
            .on("enter leave", this.addBottleAnimation)
            .on("progress", function (event) {
                //console.log("Scene progress changed to " + event.progress);
            })
            //.addIndicators() // add indicators (requires plugin)
            .addTo(this.controller);
        
        // scrollTo 

    }
    
    addBottleAnimation = () => {
        let bottles =  document.getElementById('img-wrap');
        bottles.classList.add('anim');   
    }
    render() {
        let mollo_badge;
        this.props.lang === 'fr' ?  mollo_badge = mollo_bottle_25_badge_fr : mollo_badge = mollo_bottle_25_badge;

        return (
            <div className="blockwrap second" id={this.props.bevId}>
                <MolloLinesStatic />
               
                <ContainerContent>
                    {   this.props.isDesktop ? 
                            <div id="img-wrap" className="img-wrap">
                                <img src={mollo_bottle_25} id="botl" className="bottle-strawberry bottle" alt="Mollo - Strawberry" />
                                <img src={mollo_badge} id="botl2" className="bottle-strawberry strawberry-2 bottle abs" alt="Mollo - Strawberry"/>
                                <img src={bottle_cap_1} className="slice-1" alt="bottle cap silver" />
                                <img src={bottle_cap_1} className="slice-2" alt="bottle cap silver" />
                            </div>
                    
                        : null
                    }

                    <div className="info-wrap">
                     
                        <h2 id="title-anim">
                            <TranslatableText dictionary={{
                                en: "Crack open,",
                                fr: "Ouvrez-la." }}>
                            </TranslatableText><br /> 
                            <TranslatableText dictionary={{
                                en: "pour and chill.",
                                fr: "Servez-la. Relaxez." }}>
                            </TranslatableText>
                        </h2>

                        <div id="beverage-info" className="beverage-info">
                            
                            <LanguageConsumer>
                                {(context) => (
                                    <React.Fragment>
                                        {context.language !== 'fr' ? 
                                                <h3>Mollo™ 2.5</h3>
                                            : 
                                                <h3>Mollo™ 2.5</h3>
                                        }
                                    </React.Fragment>
                                )}
                            </LanguageConsumer>
                            <p> 
                                <TranslatableText dictionary={{
                                    en: "Crisp with an easy drinking taste.",
                                    fr: "Un goût vif et facile à boire." }}>
                                </TranslatableText>
                            </p>

                            {
                                this.props.isDesktop ? null
                                : 
                                <div id="img-wrap" className="img-wrap">
                                    <img src={mollo_bottle_25} id="botl" className="bottle-strawberry bottle"  alt="Mollo™ - Strawberry" />
                                    <img src={mollo_badge} id="botl2" className="bottle-strawberry strawberry-2 bottle abs"  alt="Mollo™ - Strawberry" />
                                    <img src={bottle_cap_1} className="slice-1" alt="bottle cap silver" />
                                    <img src={bottle_cap_1} className="slice-2" alt="bottle cap silver" />
                                </div>
                            }

                            <ul id="standard-list" className="standard-list">
                                <li>
                                    <PotencyMollo25 />
                                    <span>
                                    <h4><TranslatableText dictionary={{
                                            en: "POTENCY",
                                            fr: "intensité" }}>
                                        </TranslatableText>
                                    </h4>
                                        <p>
                                            <TranslatableText dictionary={{
                                                en: "1:1 LOW",
                                                fr: "1:1 faible" }}>
                                            </TranslatableText><br />
                                            <TranslatableText dictionary={{
                                                en: "2.5MG THC + 2.5MG CBD",
                                                fr: "2,5 mg THC : 2,5 mg CBD" }}>
                                            </TranslatableText>
                                        </p>
                                    </span>
                                </li>
                                <li>
                                    <Calendar />
                                    <span>
                                        <h4>
                                            <TranslatableText dictionary={{
                                                en: "OCCASIONS",
                                                fr: "OCCASIONS" }}>
                                            </TranslatableText>
                                        </h4>

                                        <p>
                                            <TranslatableText dictionary={{
                                                en: "After escaping the city.",
                                                fr: "À la campagne." }}>
                                            </TranslatableText><br />  
                                            <TranslatableText dictionary={{
                                                en: "Post-rec league.",
                                                fr: "Après une partie de balle-molle." }}>
                                            </TranslatableText><br />  
                                            <TranslatableText dictionary={{
                                                en: "Flannel time.",
                                                fr: "En pyjama de flanelle." }}>
                                            </TranslatableText><br />  
                                            <TranslatableText dictionary={{
                                                en: "Man cave.",
                                                fr: "Dans le sous-sol." }}>
                                            </TranslatableText><br />  
                                            <TranslatableText dictionary={{
                                                en: "Sunsets.",
                                                fr: "Devant un coucher de soleil." }}>
                                            </TranslatableText>
                                        </p>
                                    </span>
                                </li>
                                
                                <li>
                                    <ServingsMollo />
                                    <span>
                                        <h4><TranslatableText dictionary={{
                                                en: "SERVING SUGGESTION",
                                                fr: "NOTES DE SERVICE" }}>
                                            </TranslatableText>
                                        </h4>
                                        <p>
                                            <TranslatableText dictionary={{
                                                en: "Pour into a chilled glass.",
                                                fr: "Verser dans un bock glacé." }}>
                                            </TranslatableText>
                                        </p>
                                    </span>
                                </li>
                                
                                <li>
                                    <Highlights />
                                    <span>
                                        <h4><TranslatableText dictionary={{
                                            en: "HIGHLIGHTS",
                                            fr: "INFORMATIONS" }}>
                                        </TranslatableText>
                                    </h4>
                                    <p>
                                        <TranslatableText dictionary={{
                                            en: "All natural flavours. ",
                                            fr: "Saveurs entièrement naturelles." }}>
                                        </TranslatableText>
                                    </p>
                                    </span>
                                </li>
                                <li>
                                    <InfoIcon />
                                    <span>
                                        <h4>
                                            <TranslatableText dictionary={{
                                                en: "NUTRITIONAL INFORMATION",
                                                fr: "INFORMATIONS NUTRITIVE" }}>
                                            </TranslatableText>
                                        </h4>
                                        <div className="paragraph">
                                            <NutritionPopUp beverage="mollo-25" />
                                        </div>
                                    </span>
                                </li>
                            </ul>

                        </div>

                    </div>

                </ContainerContent>

            </div>
        )
    }
}
export default Mollo25Brew;