import React from 'react';
import './style.scss';
import { LanguageContext } from '../../../providers/LanguageProvider';
import { TranslatableText } from '../../../providers/LanguageProvider';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import { Textbox } from "react-inputs-validation";

class EmailForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: false,
            disabled: 'disabled',
            name:"",
            email: "",
            signUpLang:"",
            placeholderName: "Name",
            placeholderEmail: "Email",
            theLang: props.lang
          };
        console.log('email form props ',props);

    }
    // Grab the context!
    static contextType = LanguageContext;

    componentDidMount(){
         
        if (this.context.language === 'fr'){
            // Set the Lang State
            this.setState({
                placeholderName: "Nom",
                placeholderEmail: "Courriel"
            })
        }
    }
    handleCheckboxChange = () => {
        this.setState({ checked: !this.state.checked }) 
    }
    nameChange = (event) => {
        this.setState({name: event.target.value});
    }
    emailChange = (event) => {
        this.setState({name: event.target.value});
    }

    render() {
        const disabledToggle = this.state.checked ? null : 'disabled';
        const {placeholderName, placeholderEmail, theLang, checked} = this.state;
        const CustomForm = ({ status, message, onValidated}) => {
            let email, name, signUpLang;
            const submit = () =>
            email &&
            name &&
            signUpLang &&
            email.value.indexOf("@") > -1 &&
            onValidated({
                EMAIL: email.value,
                FNAME: name.value,
                LANGUAGE: signUpLang.value,
            }, (this.props.successSubmit()));

  

            return (

            <React.Fragment>

                {status === "sending" && <div style={{ color: "white" }}>
                <TranslatableText dictionary={{ 
                        en: "Sending...",
                        fr: "Envoi en cours..."}}>
                    </TranslatableText>
                </div>}
                {/* {status === "error" && (
                <div
                    style={{ color: "red" }}
                    dangerouslySetInnerHTML={{ __html: message }}
                />
                )} */}
                {status === "success" && (
                  console.log('success submit'),
               
              
                <div
                    style={{ color: "white" }}
                   // dangerouslySetInnerHTML={{ __html: message }}
                >
                    <TranslatableText dictionary={{ 
                        en: "Thanks for signing up, we’ll see you in your inbox!",
                        fr: "Merci de vous être inscrit. On se revoit bientôt dans votre boîte de courriel."}}>
                    </TranslatableText>
                </div>
                )}
                
                {/* HIDDEN FORM FIELDS SUBMIT TO MAILCHIMP */}
                <div style={{display:'none'}}>

                    <input
                    
                        ref={node => (name = node)}
                        name="FNAME"
                        type="text"
                        placeholder="Name"
                        //onChange={this.nameChange}
                        defaultValue={this.state.name}
                    /> 
                
                    
                    <input
                  
                        ref={node => (email = node)}
                        type="email"
                        name="EMAIL"
                        placeholder={placeholderEmail}
                    // onChange={this.emailChange}
                        defaultValue={this.state.email}
                    />

                    <input 
                        ref={node => (signUpLang = node)}
                        type="hidden"
                        id="language"
                        name="LANGUAGE"
                        value={theLang} 
                    />

                </div>

                <div className="input-container">
                    <input name="check-required" id="check-required" type="checkbox" checked={checked} onChange={this.handleCheckboxChange} /> <label htmlFor="check-required"></label>
                    <p className="checked-text">
                        <TranslatableText dictionary={{
                            en: "I would like to receive email communications from Truss.",
                            fr: "J'aimerais recevoir les courriels de Truss." }}>
                        </TranslatableText>
                    </p>
                </div>
                <button type="submit" disabled={disabledToggle} onClick={submit}>
                    <TranslatableText dictionary={{
                        en: "Sign Up",
                        fr: "S'inscrire" }}>
                    </TranslatableText>
                </button>
        
            </React.Fragment>
        
            );
        };
        const url = "https://gmail.us20.list-manage.com/subscribe/post?u=a99f98409c1398887ae165fa3&amp;id=3e42b37053";

        return (
            <div>
                <MailchimpSubscribe
                    url={url}
                    render={({ subscribe, status, message }) => (
                        <div>
                            {/* FIELDS THAT SET STATE & SHOW ERROR MSG'S */}

                            <Textbox
                              id="fname"
                                name = 'FNAME'
                                type = 'text'
                                placeholder = {placeholderName}
                            
                                value={this.state.name} // Optional.[String].Default: "".
                                onChange={(name, e) => {
                                    this.setState({ name });
                                    console.log(e);
                                }} // Required.[Func].Default: () => {}. Will return the value.
                                onBlur={(e) => {console.log(e)}} // Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.
                                validationOption={{
                                    name: 'Name', // Optional.[String].Default: "". To display in the Error message. i.e Please enter your {name}.
                                    check: true, // Optional.[Bool].Default: true. To determin if you need to validate.
                                    required: true, // Optional.[Bool].Default: true. To determin if it is a required field.
                                    msgOnError: <TranslatableText dictionary={{ 
                                                    en: "Please enter a valid first name.",
                                                    fr: "Veuillez entrer un nom valide."}}>
                                                </TranslatableText>

                                }}
                            />

                            <Textbox
                              id="email"
                                name = 'EMAIL'
                                type = 'text'
                                placeholder = {placeholderEmail}
                                value={this.state.email}
                                onChange={(email, e) => {
                                    this.setState({ email });
                                    console.log(e);
                                }} // Required.[Func].Default: () => {}. Will return the value.
                                onBlur={e => {}} //Optional.[Func].Default: none. In order to validate the value on blur, you MUST provide a function, even if it is an empty function. Missing this, the validation on blur will not work.

                                validationOption={{
                                    name: 'Email', //Optional.[String].Default: "". To display in the Error message. i.e Please enter your ${name}.
                                    check: true, //Optional.[Bool].Default: true. To determin if you need to validate.,
                                    required: true, //Optional.[Bool].Default: true. To determin if it is a required field.
                                    msgOnError: <TranslatableText dictionary={{ 
                                                    en: "Please enter a valid email address.",
                                                    fr: "Veuillez entrer une adresse courriel valide."}}>
                                                </TranslatableText>,
                                    customFunc: email => {
                                        const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                                        if (reg.test(String(email).toLowerCase())) {
                                            return true;
                                        } else {
                                            return 'Please provide a valid email.';
                                        }
                                    }
                                }}
                            />
                            <input type="hidden" id="language" name="LANGUAGE" value={this.state.theLang} />

                            {/* FORM / FIELDS SUBMIT TO MAILCHIMP */} 
                            <CustomForm
                                status={status}
                                message={message}
                                onValidated={formData => subscribe(formData)}
                                onSubmitted={console.log('test')}
                            />
                           
                        </div>
                    )}
                />

            </div>
        )
    }
}
export default EmailForm;