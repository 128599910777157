import React from 'react';
import { Link, NavLink } from "react-router-dom";
import './style.scss';
import Logo from '../Logo/Logo';
import SearchIcon from '../../assets_global/images/search-icon.svg';
import MagnifyGlass from '../../components/MagnifyGlass/MagnifyGlass';
import BurgerButton from '../BurgerButton/BurgerButton';
import Backdrop from '../Backdrop/Backdrop';
import NavIndicators from '../NavIndicators/NavIndicators';
import WhereToBuy from '../WhereToBuy/WhereToBuy';
import Droplet from '../Droplet/Droplet';
import _ from 'lodash';
 
import { LanguageConsumer } from '../../providers/LanguageProvider';


class Header extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            delta: 5,
            didScroll: true,
            lastScrollTop: 0,
            navClass: 'nav-down',      
            hideIndicators: false, 
            hideBtn: true  
        }
        this.throttleOnScroll = _.throttle(this.throttleOnScroll.bind(this), 150); // debouncing function to 200ms and binding this
    }
// returns the current scroll position (number)
componentDidUpdate(){
    //console.log('header update');
}
componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
}

componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
}

hideHeader() {
    this.setState({
        navClass: 'nav-up'
    })
}

showHeader() {
    this.setState({
        navClass: 'nav-down'
    })
}

hideWhereToBuy(){
    this.setState({
        hideBtn: true
    })
}

showWhereToBuy(){
    this.setState({
        hideBtn: false
    })
}

getDocHeight() {
    return Math.max(
        document.body.scrollHeight, document.documentElement.scrollHeight,
        document.body.offsetHeight, document.documentElement.offsetHeight,
        document.body.clientHeight, document.documentElement.clientHeight
    );
}

hasScrolled = (e) => {
   
    const st = window.scrollY;
   
    // Make sure they scroll more than delta
    if(Math.abs(this.state.lastScrollTop - st) <= 5)
        return;

    // If the user scrolls hide the indicators immediately. 
    // Only show if they are at the top of the page.

    if(st > 10 && this.state.hideIndicators !== true){
         
        this.setState({
            hideIndicators: true
        });
       
    }else if(st < 10){
        this.setState({
            hideIndicators: false
        });
    } 



    // If they scrolled down and are past the navbar, add class .nav-up.
    // This is necessary so you never see what is "behind" the navbar.
    if (st > this.state.lastScrollTop && st > 138 ){
        // Scroll Down
        this.hideHeader();
    } else {
        // Scroll Up
        // if(st + window.outerHeight < this.getDocHeight()) {
        //   this.showHeader();
        // }
        if(st < this.getDocHeight()) {
          this.showHeader();
        }
    }

    // This hides the button when scrolling up
    // if(st > this.state.lastScrollTop && st > 300){
    //     this.showWhereToBuy()
    // }else{
    //     this.hideWhereToBuy()
    // }

    if(st > 300){
        this.showWhereToBuy()
    }else{
        this.hideWhereToBuy()
    }

    this.setState({
        lastScrollTop: st
    })

}



throttleOnScroll(value) {
    this.hasScrolled(); 
}


handleScroll = (event) => {
    
    this.setState({
        didScroll: true
    })

   this.throttleOnScroll()

}

    render() {
        return (
            <React.Fragment>
            <WhereToBuy btnPage={this.props.currentPage} show={`${this.state.hideBtn ? 'off' : ''}   `} linkTo="/where-to-buy" isFixed={true}  />

            <header id="header" className={`${this.state.navClass} ${this.state.hideIndicators && this.state.lastScrollTop > 138 ? 'minimize' : ''}`}>
            <Backdrop show={this.props.menuShow}  menuToggle={this.props.menuToggle} />
            <NavIndicators pageLinks={this.props.pageLinks} show={this.state.hideIndicators} currentPage={this.props.currentPage} />
            <Droplet show={this.state.hideIndicators} currentPage={this.props.currentPage} /> 
            
            <div className={`container ${this.props.currentPage}`}>
                <div className="content">
                    <Logo />
                    <nav>
                    <LanguageConsumer>
                        {(context) => (
                            <React.Fragment>
                              
                                    <ul id="main">
                                        <li  className="main">
                                            <NavLink to={`${context.language == 'fr' ? '/fr' : ''}/house-of-terpenes`}>House of Terpenes</NavLink>  
                                        </li>
                                        <li className="main">
                                            <NavLink to={`${context.language == 'fr' ? '/fr' : ''}/little-victory`} >Little Victory</NavLink>
                                           {/*  <NavLink to="{}/little-victory">Little Victory</NavLink> */}
                                        </li>
                                        <li className="main">
                                            <NavLink to={`${context.language == 'fr' ? '/fr' : ''}/mollo`} >Mollo</NavLink>
                                        </li>
                                        <li className="main">
                                            <NavLink to={`${context.language == 'fr' ? '/fr' : ''}/veryvell`} >Veryvell</NavLink>
                                        </li>
                                        <li className="main">
                                            <NavLink to={`${context.language == 'fr' ? '/fr' : ''}/xmg`} >XMG</NavLink>
                                        </li>
                                       
                                        {/* <li className="main">
                                            <NavLink to={`${context.language == 'fr' ? '/fr' : ''}/flow-glow`} >Flow Glow</NavLink>
                                        </li> */}
                                        <li className="search">
                                          {/*   <NavLink to={`${context.language == 'fr' ? '/fr' : ''}/find-my-beverage`}><img src={SearchIcon} className="search-icon" /></NavLink> */}
                                          <NavLink to={`${context.language == 'fr' ? '/fr' : ''}/find-my-product`}><MagnifyGlass /></NavLink>
                                         </li>
                                        <li>
                                            <BurgerButton menuToggle={this.props.menuToggle} show={this.props.menuShow} />
                                        </li>
                                    </ul>
                               
                            </React.Fragment>
                        )}
                    </LanguageConsumer>
                    </nav>
                    
                </div>
            </div>
        </header>
        </React.Fragment>
        )
    }
}
export default Header;