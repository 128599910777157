import React from 'react';
import './style.scss';
import { TweenMax, TimelineMax, Linear, TimelineLite, Power4 } from "gsap"; // Also works with TweenLite and TimelineLite

let tl, startPaused;

class TerpsLines extends React.Component {
    constructor(){
        super()
    }
    componentDidMount = () => {
         this.constructTimeline(false)
    }
    constructTimeline = (startPaused) => {
        tl = new TimelineMax({ id: "MainTimeline", paused: startPaused });
        tl.from("#Terps-Container", 0.5, { opacity: 0 });
        tl.add([
          TweenMax.fromTo(
            "#CrossHorizontal",
            1.0,
            { drawSVG: "50% 50%", ease: Power4.easeInOut },
            { drawSVG: "0% 100%", delay: 0.3 }
          ),
          TweenMax.fromTo(
            "#CrossVertical",
            1.0,
            { drawSVG: "100% 100%", ease:  Power4.easeInOut },
            { drawSVG: "0% 100%" }
          ),
          TweenMax.fromTo(
            "#SquareBottom",
            0.5,
            { drawSVG: "50% 50%", ease:  Power4.easeInOut },
            { drawSVG: "0% 100%" }
          ),
          TweenMax.fromTo(
            "#TriangleLeft, #TriangleRight, #IntersectionLeft, #IntersectionRight",
            0.5,
            { drawSVG: "0% 0%", ease:  Power4.easeInOut },
            { drawSVG: "0% 100%", delay: 0.5 }
          ),
          TweenMax.fromTo(
            "#SquareTop",
            0.25,
            { drawSVG: "50% 50%", ease: Power4.easeInOut },
            { drawSVG: "0% 100%", delay: 1.0 }
          )
        ]);
        tl.add([
          TweenMax.set("#Circle", {
            rotation: 314.5,
            transformOrigin: "center center"
          }),
          TweenMax.fromTo(
            "#Circle",
            1.5,
            {
              drawSVG: "100% 100%",
              ease: Power4.easeInOut
            },
            { drawSVG: "100% 0%" }
          ),
          TweenMax.fromTo(
            "#SquareLeft",
            1.0,
            { drawSVG: "0%", ease: Power4.easeInOut },
            { drawSVG: "100%", delay: 0.5 }
          ),
          TweenMax.fromTo(
            "#SquareRight",
            1.0,
            { drawSVG: "0%", ease: Power4.easeInOut },
            { drawSVG: "100%", delay: 0.5 }
          ),
          TweenMax.fromTo(
            "#ThickV",
            0.5,
            { drawSVG: "50% 50%", ease: Power4.easeInOut },
            { drawSVG: "0% 100%" }
          )
        ]);
    }
    render() {
        return (
            <div id="Terps-Container">
                <div className="svg page-3">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                        viewBox="0 0 570 570"
                        id="Page3"
                        preserveAspectRatio="xMidYMid meet"
                    >
                        <g transform="translate(0 300) scale(1.025)" opacity="0.15" vectorEffect="non-scaling-stroke">
                        <circle
                            id="Circle"
                            cx="277.5"
                            cy="-14"
                            r="275"
                            fill="none"
                            stroke="#fff"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <g transform="translate(471.671 -208.388)">
                            <path
                            id="SquareRight"
                            d="M0,0V388.559"
                            fill="none"
                            stroke="#fff"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            />
                        </g>
                        <g transform="translate(83.11 -208.388)">
                            <path
                            id="SquareLeft"
                            d="M0,0V388.559"
                            fill="none"
                            stroke="#fff"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            />
                        </g>

                        <line
                            id="SquareTop"
                            x1="84"
                            y1="-208"
                            x2="470"
                            y2="-208"
                            stroke="#fff"
                            fill="none"
                            strokeWidth="0.75"
                            opacity="0.5"
                        />
                        <line
                            id="CrossHorizontal"
                            x1="84"
                            y1="-13"
                            x2="470"
                            y2="-13"
                            stroke="#fff"
                            fill="none"
                            strokeWidth="0.75"
                            opacity="0.5"
                        />
                        <line
                            id="CrossVertical"
                            x1="277"
                            y1="-208"
                            x2="277"
                            y2="180"
                            stroke="#fff"
                            fill="none"
                            strokeWidth="0.75"
                            opacity="0.5"
                        />
                        <line
                            id="SquareBottom"
                            x1="84"
                            y1="181"
                            x2="470"
                            y2="181"
                            stroke="#fff"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="0.75"
                            opacity="0.5"
                        />
                        <line
                            id="TriangleLeft"
                            x1="84"
                            y1="181"
                            x2="277"
                            y2="-208"
                            stroke="#fff"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="0.75"
                            opacity="0.5"
                        />
                        <line
                            id="TriangleRight"
                            x1="470"
                            y1="181"
                            x2="277"
                            y2="-208"
                            stroke="#fff"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="0.75"
                            opacity="0.5"
                        />
                        <line
                            id="IntersectionLeft"
                            x1="84"
                            y1="181"
                            x2="470"
                            y2="-208"
                            stroke="#fff"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="0.75"
                            opacity="0.5"
                        />
                        <line
                            id="IntersectionRight"
                            x1="470"
                            y1="181"
                            x2="84"
                            y2="-208"
                            stroke="#fff"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="0.75"
                            opacity="0.5"
                        />

                        <g transform="translate(84 -208.39)">
                            <path
                            id="ThickV"
                            d="M0,0,193.39,195.55,387.48,0"
                            fill="none"
                            stroke="#fff"
                            strokeWidth="2"
                            />
                        </g>
                        </g>
                    </svg>
                </div>
            </div>
        )
    }
}
export default TerpsLines; 