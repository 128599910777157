import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const ServingsMollo = (className, fill) => {
    
    return(

        <span className="icon">
            
            <svg xmlns="http://www.w3.org/2000/svg" width="34.346" height="56.129" viewBox="0 0 34.346 56.129">
                <g id="Group_843" data-name="Group 843" transform="translate(-1709.243 -635.356)">
                    <rect id="Rectangle_1768" data-name="Rectangle 1768" width="31" height="3" transform="translate(1711.242 646.485)" fill="rgba(213,172,101,0.3)"/>
                    <path id="Path_658" data-name="Path 658" d="M1742.732,636.446a3.321,3.321,0,0,0-2.457-1.09h-27.716a3.314,3.314,0,0,0-3.3,3.612l3.766,49.533a3.3,3.3,0,0,0,3.3,2.984h20.19a3.313,3.313,0,0,0,3.3-3.016L1743.572,639A3.321,3.321,0,0,0,1742.732,636.446Zm-5.607,51.787a.613.613,0,0,1-.613.554h-20.19a.607.607,0,0,1-.61-.522l-3.766-49.532a.616.616,0,0,1,.613-.677h27.716a.612.612,0,0,1,.457.2.635.635,0,0,1,.153.506Z" transform="translate(0 0)" fill="#d5ac65"/>
                </g>
            </svg>

        </span>

    )
}
export default ServingsMollo;

ServingsMollo.propTypes = {
    className: PropTypes.string,
    fill: PropTypes.string,
  };
  
ServingsMollo.defaultProps = {
    className: undefined,
    fill: '#333',
};
  
