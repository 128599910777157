import React from "react";
import './style.scss';

const Checkbox = ({ label, filterName, isSelected, onCheckboxChange }) => (
  <div className="form-check">
     <label className={`checkbox ${isSelected ? 'active' : ''}`}>
        <input
            type="checkbox"
            name={label}
            data-filtername={filterName}
            checked={isSelected}
            onChange={onCheckboxChange}
            className="form-check-input"
        />
        <span className="overlay">
            <svg xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 18 18"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="svg-icon">
                    <polyline points="20 6 9 17 4 12"/>
            </svg>
        </span>
        {label}
    </label>
  </div>
);

export default Checkbox;