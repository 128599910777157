import React from 'react';
import ContainerContent from '../../templates/ContainerContent/ContainerContent';
import './style.scss';
import Footer from '../../components/Footer/Footer';
import { TranslatableText } from '../../providers/LanguageProvider';
import { LanguageConsumer } from '../../providers/LanguageProvider';
import Drawer from '../../components/Drawer/Drawer';
import { scrollTo, scrollIntoView } from 'scroll-js';

class Faq extends React.Component {
    constructor(props){
        super(props)
        this.drawerElement = React.createRef();
        
    }

    componentDidMount(){
        // Is there a hash?
        this.handleOpenDrawer();
        console.log('mount faq');
    }

    getAnchor = () => {
        var currentUrl = document.URL,
        urlParts   = currentUrl.split('#');
            
        return (urlParts.length > 1) ? urlParts[1] : null;
    }

    handleOpenDrawer = () => {
        // Check URL for hash question
        const anchorID = this.getAnchor();
        // IF there's a hash, there's a Question
        if(anchorID){
            this.drawerElement.current.drawerToggle(); 
        }
         
    }

    render() {
        return (
            <div className={`page-wrap`}>
                <div className="blockwrap brandline">
                    <ContainerContent>
                        <h1>
                            <TranslatableText dictionary={{
                                en: "Frequently ",
                                fr: "Foire " }}>
                            </TranslatableText><br className="mobile"/>
                            <TranslatableText dictionary={{
                                en: "asked ",
                                fr: "aux " }}>
                            </TranslatableText><br className="mobile"/>
                            <TranslatableText dictionary={{
                                en: "questions.",
                                fr: "questions" }}>
                            </TranslatableText><br className="mobile"/>
                        </h1>  
                        
                        
                        <div className="questions-list">
                            <h2>
                                <TranslatableText dictionary={{
                                    en: "Beverages ",
                                    fr: "Boissons" }}>
                                </TranslatableText>
                            </h2>
                            
                            <Drawer 
                                title={<TranslatableText dictionary={{
                                    en: "What do they taste like?",
                                    fr: "À quoi ressemble leur goût?" }}>
                                </TranslatableText>} 
                                icon={<i className="la la-plus"></i>} 
                                key="k4"
                            >
                                <p>
                                    <TranslatableText dictionary={{
                                        en: "We love cannabis in other forms, but as beverage specialists, we understand that taste matters.  You can expect many different flavours, formats, and experiences across all our beverages.",
                                        fr: "Même si on aime le cannabis sous ses autres formes, comme on est des spécialistes en boissons, on comprend que le goût est important. Vous pouvez vous attendre à plusieurs saveurs, formats et expériences selon les différents produits." }}>
                                    </TranslatableText>
                                </p>
                            </Drawer>
                            <Drawer 
                                title={<TranslatableText dictionary={{
                                    en: "What dose is right for me?",
                                    fr: "Quelle dose me convient?" }}>
                                </TranslatableText>} 
                                icon={<i className="la la-plus"></i>} 
                                key="k5"
                            >
                                <p>
                                    <TranslatableText dictionary={{
                                        en: "Everyone’s experience and relationship with cannabis will be a personal one. So for a safe and enjoyable experience we recommend starting low and making a note of your journey, adjusting as you feel necessary.",
                                        fr: "Le relation avec le cannabis et son expérience, c’est propre à chacun. Alors pour une expérience sécuritaire et agréable, on recommande de commencer avec une petite dose, de noter comment se passe votre expérience, et d’ajuster si ça vous semble nécessaire." }}>
                                    </TranslatableText>
                                </p>
                            </Drawer>
                            <Drawer 
                                title={<TranslatableText dictionary={{
                                    en: "How long will it take for the beverage experience to start?",
                                    fr: "Ça va prendre combien de temps avant que l’expérience de la boisson agisse sur moi?" }}>
                                </TranslatableText>} 
                                icon={<i className="la la-plus"></i>} 
                                key="k6"
                            >
                                <p>
                                    <TranslatableText dictionary={{
                                        en: "We believe onset time can be as little as 15 minutes* or as long as 2 hours per Health Canada guidelines.",
                                        fr: "Le temps de réaction peut être aussi rapide que 15 minutes* ou aussi long que 2 heures, selon les lignes directrices de Santé Canada." }}>
                                    </TranslatableText>
                                    <br />
                                    <span className="legal">
                                        <TranslatableText dictionary={{
                                            en: "*Reported effects and timing are anecdotal and can vary by person. (e.g. up to 2 hours)",
                                            fr: "*Les effets rapportés et le temps estimé sont à titre indicatif et peuvent varier selon les personnes (p. ex. jusqu’à deux heures)." }}>
                                        </TranslatableText>
                                    </span>
                                </p>
                                <p>
                                    <span className="legal">
                                    <TranslatableText dictionary={{
                                        en: "Please refer to Health Canada guidelines for further information.",
                                        fr: "Veuillez vous référer aux lignes directrices de Santé Canada." }}>
                                    </TranslatableText>
                                    </span>
                                </p>
                                <p>
                                    <TranslatableText dictionary={{
                                        en: "These effects can last up to 12 hours, but can last as long as 24 hours.",
                                        fr: "Ces effets peuvent durer jusqu’à 12 heures, mais peuvent également durer jusqu’à  24 heures." }}>
                                    </TranslatableText>
                                </p>
                            </Drawer>
                            <Drawer 
                                title={<TranslatableText dictionary={{
                                    en: "Can I mix these drinks with alcohol?",
                                    fr: "Est-ce que je peux mélanger ces boissons avec de l’alcool?" }}>
                                </TranslatableText>} 
                                icon={<i className="la la-plus"></i>} 
                                key="k7"
                            >
                                <p>
                                    <TranslatableText dictionary={{
                                        en: "The intent of our cannabis beverages is that they do not require mixing with any other products. In line with guidance from Health Canada, we do not recommend the use of cannabis beverages and alcohol together.",
                                        fr: "L’intention, avec nos boissons au cannabis, c’est qu’elles ne requièrent aucun mélange avec d’autres produits. Conformément aux lignes directrices de Santé Canada, on ne recommande pas de consommer des boissons au cannabis en même temps que de l’alcool." }}>
                                    </TranslatableText>
                                </p>
                                <p>
                                    <TranslatableText dictionary={{
                                        en: "For further info, please refer to the Health Canada site ",
                                        fr: "Pour plus d’information, veuillez consulter cette page du site de " }}>
                                    </TranslatableText>
                                    <LanguageConsumer>
                                        {(context) => (
                                            <React.Fragment>
                                                {context.language !== 'fr' ? 
                                                        <a href="https://www.canada.ca/content/dam/hc-sc/documents/services/drugs-medication/cannabis/laws-regulations/regulations-support-cannabis-act/consumer-information/Cannabis_Consumer_Information_Sheet-Edibles-EN.pdf" target="_blank">here</a>
                                                    : 
                                                        <a href="https://www.canada.ca/content/dam/hc-sc/documents/services/drugs-medication/cannabis/laws-regulations/regulations-support-cannabis-act/consumer-information/Cannabis_Consumer_Information_Sheet-Edibles-FR.pdf" target="_blank">Santé Canada :</a>
                                                }
                                            </React.Fragment>
                                        )}
                                </LanguageConsumer>
                                </p>
                            </Drawer>
                            <Drawer 
                                title={<TranslatableText dictionary={{
                                    en: "Where are your beverages made?",
                                    fr: "Où vos boissons sont-elles fabriquées?" }}>
                                </TranslatableText>} 
                                icon={<i className="la la-plus"></i>} 
                                key="k8"
                            >
                                <p>
                                    <TranslatableText dictionary={{
                                        en: "We’re bottling wonder in Belleville, ON.",
                                        fr: "On embouteille nos merveilles à Belleville, en Ontario." }}>
                                    </TranslatableText>
                                </p>
                            </Drawer>
                        </div> 
        
                        
                        <div className="questions-list">
                            <h2>
                                <TranslatableText dictionary={{
                                    en: "Cannabis Extracts ",
                                    fr: "Cannabis Extracts" }}>
                                </TranslatableText>
                            </h2>
                            <Drawer 
                                title={<TranslatableText dictionary={{
                                    en: "What are Veryvell™ Drops?",
                                    fr: "Les gouttes Veryvell(MC), c’est quoi?" }}>
                                </TranslatableText>} 
                                icon={<i className="la la-plus"></i>} 
                                key="k9"
                            >
                                <p>
                                    <TranslatableText dictionary={{
                                        en: "Veryvell™ Drops are small in format (30g bottles) and water-soluble.These drops can be added to your favourite glass, teacup or mug; drop in and enjoy.",
                                        fr: "Les gouttes Veryvell(MC) viennent en petit format (bouteilles de 30 g) et sont solubles dans l’eau. Vous n’avez qu’à ajouter ces gouttes à votre tasse ou verre préféré et en profiter." }}>
                                    </TranslatableText>
                                </p>
                                <p>
                                    <TranslatableText dictionary={{
                                        en: "Available in 3 variants: Exhale, Tingle and Yawn. The built in dosing cap gives you the option to control your cannabis experience.",
                                        fr: "Trois variantes sont offertes : L’allumeur, Détente et Bâillement. Le système de dosage intégré vous permet de contrôler votre expérience du cannabis." }}>
                                    </TranslatableText>
                                </p>
                            </Drawer>
                            <Drawer 
                                title={<TranslatableText dictionary={{
                                    en: "How do I use the Drops?",
                                    fr: "Comment utilise-t-on les gouttes?" }}>
                                </TranslatableText>} 
                                icon={<i className="la la-plus"></i>} 
                                key="k10"
                            >
                                <p>
                                    <TranslatableText dictionary={{
                                        en: "It's super simple. They are ready to drop into your favourite glass.",
                                        fr: "C’est hyper simple. Elles sont prêtes à être ajouter à votre boisson préférée." }}>
                                    </TranslatableText>
                                </p>
                            </Drawer>
                            <Drawer 
                                ref={this.drawerElement}
                                
                                //ref={this.myQuestionRef}
                                id="question-info"
                                title={<TranslatableText dictionary={{
                                    en: "What dose is right for me?",
                                    fr: "Quelle dose me convient?" }}>
                                </TranslatableText>} 
                                icon={<i className="la la-plus"></i>} 
                                key="k11"
                            >
                                <p>
                                    <TranslatableText dictionary={{
                                        en: "We believe your relationship with Veryvell™ Drops is personal. We recommend starting low with 1-2 drops, and making a note of your journey, adjusting as you feel necessary. We want you to stay safe with your cannabis experience.",
                                        fr: "On croit que la relation avec les gouttes Veryvell(MC) est quelque chose de personnel. On recommande de commencer avec une faible dose (1 à 2 gouttes), de noter comment se passe votre expérience, et d’ajuster si ça vous semble nécessaire. On veut que vous vous sentiez en sécurité pendant votre expérience." }}>
                                    </TranslatableText>
                                </p>
                            </Drawer>
                            <Drawer 
                                title={<TranslatableText dictionary={{
                                    en: "Can I mix these drops with alcohol?",
                                    fr: "Peut-on mélanger ces gouttes avec de l’alcool?" }}>
                                </TranslatableText>} 
                                icon={<i className="la la-plus"></i>} 
                                key="k12"
                            >
                                <p>
                                    <TranslatableText dictionary={{
                                        en: "Just like any cannabis product, you should not mix Veryvell™ Drops and alcohol.",
                                        fr: "Comme n’importe quel autre produit de cannabis, vous ne devriez pas mélanger les gouttes Veryvell(MC) avec de l’alcool. " }}>
                                    </TranslatableText>
                                </p>
                                <p>
                                    <TranslatableText dictionary={{
                                        en: "Please refer to the Health Canada site for more information ",
                                        fr: "Pour plus d’information, veuillez consulter cette page du site de " }}>
                                    </TranslatableText>
                                    <LanguageConsumer>
                                        {(context) => (
                                            <React.Fragment>
                                                {context.language !== 'fr' ? 
                                                        <a href="https://www.canada.ca/content/dam/hc-sc/documents/services/drugs-medication/cannabis/laws-regulations/regulations-support-cannabis-act/consumer-information/Cannabis_Consumer_Information_Sheet-Edibles-EN.pdf" target="_blank">here</a>
                                                    : 
                                                        <a href="https://www.canada.ca/content/dam/hc-sc/documents/services/drugs-medication/cannabis/laws-regulations/regulations-support-cannabis-act/consumer-information/Cannabis_Consumer_Information_Sheet-Edibles-FR.pdf" target="_blank">Santé Canada :</a>
                                                }
                                            </React.Fragment>
                                        )}
                                </LanguageConsumer>
                                </p>
                            </Drawer>
                        </div>

                        <div className="questions-list">
                            <h2>
                            <TranslatableText dictionary={{
                                    en: "Sip Responsibly",
                                    fr: "Cannabis Extracts" }}>
                                </TranslatableText> 
                            </h2>
                            <Drawer 
                                ref={this.drawerElement}
                                id="faq-sip-responsibly"
                                title={<TranslatableText dictionary={{
                                    en: "What does it mean to sip responsibly?",
                                    fr: "Siroter de façon responsable, ça veut dire quoi?" }}>
                                </TranslatableText>} 
                                icon={<i className="la la-plus"></i>} 
                                key="k13"
                            >
                                <p className="faq-subtitle">
                                    <TranslatableText dictionary={{
                                        en: "Start Low",
                                        fr: "Commencer à faible intensité" }}>
                                    </TranslatableText>
                                </p>
                                <p>
                                    <TranslatableText dictionary={{
                                        en: "Starting low is the best way to go. Choose a lower dose beverage (2.5mg of THC+CBD or just CBD), and consider splitting a serving with a friend.",
                                        fr: "Commencer tranquillement, c’est la meilleure façon de faire. Choisissez une boisson à faible intensité (2,5 mg de THC ou CBD) et partagez-la avec un ami." }}>
                                    </TranslatableText>
                                </p>
                                <p className="faq-subtitle">
                                    <TranslatableText dictionary={{
                                        en: "Don't Mix",
                                        fr: "Ne pas mélanger" }}>
                                    </TranslatableText>
                                </p>
                                <p>
                                    <TranslatableText dictionary={{
                                        en: "As with all cannabis, do not mix with alcohol.",
                                        fr: "Comme n’importe quel autre produit de cannabis, ne faites aucun mélange avec de l’alcool." }}>
                                    </TranslatableText>
                                </p>
                                <p className="faq-subtitle">
                                    <TranslatableText dictionary={{
                                        en: "Store Safely",
                                        fr: "Conserver le produit de façon sécuritaire" }}>
                                    </TranslatableText>
                                </p>
                                <p>
                                    <TranslatableText dictionary={{
                                        en: "Keep your beverages in a cool, dry place. And be sure that they are away from children and pets.",
                                        fr: "Conservez vos boissons dans un endroit frais et sec. Et assurez-vous qu’elles ne soient pas à la portée des enfants ou des animaux." }}>
                                    </TranslatableText>
                                </p>
                                <p className="faq-subtitle">
                                    <TranslatableText dictionary={{
                                        en: "Cannabis with Friends / Hosting Tips",
                                        fr: "Le cannabis entre amis / Conseils pour recevoir" }}>
                                    </TranslatableText>
                                </p>
                                <p>
                                    <TranslatableText dictionary={{
                                        en: "Everyone’s different, and cannabis isn't for everyone. So it’s a good idea to have a variety of beverages on hand with different potencies, along with a range of non-cannabis and non-alcoholic beverages, so that people have options. And finally, always ensure your guests have a safe ride home - cannabis and driving never mix.",
                                        fr: "Chaque personne est différente et le cannabis, ce n’est pas pour tout le monde. C’est donc une bonne idée d’avoir en réserve une variété de boissons qui ont différentes intensités, et aussi des boissons sans cannabis ni alcool. Les gens pourront donc choisir ce qui leur convient. Et finalement, assurez-vous que vos invités rentrent sécuritairement à la maison. Le cannabis et le volant, ça ne va pas ensemble." }}>
                                    </TranslatableText>
                                </p>
                            </Drawer>
                        </div>
                    </ContainerContent>
                </div>
            <Footer />
        </div>
   
   
        )
    }
}
export default Faq;