import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const PotencyLow = (className, fill) => {
    
    return(

        <span className="icon">

            <svg xmlns="http://www.w3.org/2000/svg" width="50.367" height="50.367" viewBox="0 0 50.367 50.367">
                <g className="icon-chart-low" data-name="Group 427" transform="translate(1 1)">
                    <g id="Group_426" data-name="Group 426" opacity="0.3">
                    <g id="Group_425" data-name="Group 425">
                        <circle id="Ellipse_369" data-name="Ellipse 369" cx="16.854" cy="16.854" r="16.854" transform="translate(7.33 7.33)" fill="none" strokeMiterlimit="10" strokeWidth="2"/>
                        <circle id="Ellipse_370" data-name="Ellipse 370" cx="24.183" cy="24.183" r="24.183" fill="none"  strokeMiterlimit="10" strokeWidth="2"/>
                    </g>
                    </g>
                    <path id="Path_627" data-name="Path 627" d="M620.447,518.765a16.851,16.851,0,0,0-16.854-16.851" transform="translate(-579.411 -494.583)" fill="none"  strokeMiterlimit="10" strokeWidth="2"/>
                    <path id="Path_628" data-name="Path 628" d="M627.778,510.265a24.182,24.182,0,0,0-24.183-24.181" transform="translate(-579.412 -486.082)" fill="none"  strokeMiterlimit="10" strokeWidth="2"/>
                </g>
            </svg>

        </span>

    )
}
export default PotencyLow;

PotencyLow.propTypes = {
    className: PropTypes.string,
    fill: PropTypes.string,
  };
  
PotencyLow.defaultProps = {
    className: undefined,
    fill: '#333',
};
  
