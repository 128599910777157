import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const PotencyXmg = (className, fill) => {
    
    return(

        <span className="icon">
            
<svg xmlns="http://www.w3.org/2000/svg" width="49.568" height="49.569" viewBox="0 0 49.568 49.569">
  <g id="Group_728" data-name="Group 728" transform="translate(0 0)">
    <path id="Path_14" data-name="Path 14" d="M8993.369,4084.964a22.5,22.5,0,1,0,38.977-22.5,21.988,21.988,0,0,0-19.488-11.251v-2.276a24.214,24.214,0,0,1,21.459,12.39,24.8,24.8,0,0,1-21.418,37.18,24.9,24.9,0,0,1-6.453-.855A24.615,24.615,0,0,1,8991.4,4086.1Z" transform="translate(-8988.079 -4048.933)"/>
    <g id="Group_8" data-name="Group 8">
      <path id="Path_15" data-name="Path 15" d="M9032.761,4073.719a17.075,17.075,0,0,0-15.132-8.737,17.494,17.494,0,0,0-17.474,17.474,16.809,16.809,0,0,0,2.341,8.737,17.361,17.361,0,0,0,10.61,8.141,17.581,17.581,0,0,0,4.551.6,17.485,17.485,0,0,0,15.1-26.218Zm-7.532,21.9a15.2,15.2,0,0,1-20.762-5.563,14.433,14.433,0,0,1-2.036-7.6,15.216,15.216,0,0,1,15.2-15.2,14.853,14.853,0,0,1,13.161,7.6A15.215,15.215,0,0,1,9025.229,4095.618Z" transform="translate(-8992.85 -4057.677)" fill="#8c4242"/>
      <path id="Path_16" data-name="Path 16" d="M9008.886,4051.209v-2.276a24.807,24.807,0,0,0-24.778,24.779,23.828,23.828,0,0,0,3.32,12.389l1.971-1.138a21.363,21.363,0,0,1-3.014-11.251A22.528,22.528,0,0,1,9008.886,4051.209Z" transform="translate(-8984.107 -4048.933)" fill="#8c4242"/>
    </g>
  </g>
</svg>



        </span>

    )
}
export default PotencyXmg;

PotencyXmg.propTypes = {
    className: PropTypes.string,
    fill: PropTypes.string,
  };
  
PotencyXmg.defaultProps = {
    className: undefined,
    fill: '#333',
};
  
