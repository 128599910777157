import React from 'react';
import './assets_global/scss/styles.scss';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import LanguageProvider from './providers/LanguageProvider';
import RouterApp from './container/RouterApp/RouterApp';
import { BrowserRouter as Router } from "react-router-dom";
import { isDesktop } from './helpers';

class App extends React.Component {
    static propTypes = { 
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props){
        super(props)
        //console.log('app constructor props ', props);
        const { cookies } = props;
        this.state = {
            //ageCookie: cookies.get('ageGate') ? true : false,
            isDesktop: isDesktop() ? true : false
            
        };
        
    }

    componentDidMount = () =>{
        this.checkOrSetCookie();
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.handleResize);
    }

    checkOrSetCookie = () => {
        const { cookies } = this.props;
        // Age Gate cookie
        let theCookie = cookies.get('trussAOM');
        // Basic - accept the site uses cookies
        let siteCookie = cookies.get('trusssitecookie');
        //console.log('cookie is ',theCookie);
        // does cookie exist? 
        if(theCookie){
         
          this.setState({
            ageCookie: theCookie
          });
        }else{
         
        }

        if(siteCookie){
            this.setState({ siteCookie: true })
        }
        else {
            this.setState({ siteCookie: false })
        }
      
    }

    handleResize = () => {
        let viewPort = isDesktop();
        if (viewPort !== this.state.isDesktop){
            this.setState(prevState => ({
               isDesktop: viewPort
              }));
        }
    }

    closeCookiePopUpHandler = () => {
        const { cookies } = this.props;
        let expiriyDate = 0;
        expiriyDate  = new Date();
        expiriyDate.setDate(expiriyDate.getDate() + 30);

        cookies.set('trusssitecookie', true, { path: '/', expires: expiriyDate  });

        this.setState({
            siteCookie: true
        })
    }

    render() {
        
        return (
           <LanguageProvider>
               <Router> 
                   <RouterApp isDesktop={this.state.isDesktop} siteCookie={this.state.siteCookie} closeCookiePopUp={this.closeCookiePopUpHandler} />
                </Router>
           </LanguageProvider>
        )
    }
}
export default withCookies(App);


 