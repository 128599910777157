import React from 'react';
import './style.scss';

const QuebecMessage = (props) => {
    
    return (

        <div className={`quebec-message ${props.show ? null : 'hide' }`}>
            <h1>Désolé. Ce contenu <br />n’est pas disponible <br />au Québec dû aux <br />règlements en vigueur.</h1>
            <p>Désolés! This content is unavailable <br className="desktop" />in Quebec due to current regulations.</p>
        </div>

    )
}
export default QuebecMessage;