import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const PotencyVvTingle = (className, fill) => {
    
    return(

        <span className="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="109.871" height="109.866" viewBox="0 0 109.871 109.866">
                <g id="Group_842" data-name="Group 842" transform="translate(-1451.143 -704.234)">
                    <g id="Group_758" data-name="Group 758">
                    <path id="Path_704" data-name="Path 704" d="M1514.1,768.2a5.439,5.439,0,0,0,5.441-5.44v-2.631a5.8,5.8,0,0,0-.682-2.729l-3.757-7.037a1.135,1.135,0,0,0-2,0l-3.758,7.037a5.805,5.805,0,0,0-.683,2.729v2.631A5.439,5.439,0,0,0,1514.1,768.2Z" fill="#d69568"/>
                    <path id="Path_705" data-name="Path 705" d="M1498.057,768.2a5.44,5.44,0,0,0,5.441-5.44v-2.631a5.792,5.792,0,0,0-.683-2.729l-3.757-7.037a1.134,1.134,0,0,0-2,0L1493.3,757.4a5.792,5.792,0,0,0-.683,2.729v2.631A5.439,5.439,0,0,0,1498.057,768.2Z" fill="#d69568"/>
                    </g>
                    <g id="Group_773" data-name="Group 773">
                    <g id="Group_765" data-name="Group 765">
                        <g id="Group_761" data-name="Group 761">
                        <g id="Group_760" data-name="Group 760">
                            <g id="Group_759" data-name="Group 759">
                            <path id="Path_706" data-name="Path 706" d="M1506.081,798.053a38.891,38.891,0,1,1-.009-77.783v5a33.883,33.883,0,1,0,7.046.74l1.04-4.89a38.892,38.892,0,0,1-8.077,76.933Z" fill="rgba(214,149,104,0.3)"/>
                            </g>
                        </g>
                        </g>
                        <g id="Group_764" data-name="Group 764">
                        <g id="Group_763" data-name="Group 763">
                            <g id="Group_762" data-name="Group 762">
                            <path id="Path_707" data-name="Path 707" d="M1513.118,726.01a32.217,32.217,0,0,0-7.046-.74v-5a36.885,36.885,0,0,1,8.086.85Z" fill="#d69568"/>
                            </g>
                        </g>
                        </g>
                    </g>
                    <g id="Group_772" data-name="Group 772">
                        <g id="Group_768" data-name="Group 768">
                        <g id="Group_767" data-name="Group 767">
                            <g id="Group_766" data-name="Group 766">
                            <path id="Path_708" data-name="Path 708" d="M1506.182,814.1a55,55,0,0,1-53.833-66.361,54.509,54.509,0,0,1,53.723-43.505v5a49.924,49.924,0,1,0,10.38,1.091l1.039-4.891A54.937,54.937,0,0,1,1506.182,814.1Z" fill="rgba(214,149,104,0.3)"/>
                            </g>
                        </g>
                        </g>
                        <g id="Group_771" data-name="Group 771">
                        <g id="Group_770" data-name="Group 770">
                            <g id="Group_769" data-name="Group 769">
                            <path id="Path_709" data-name="Path 709" d="M1516.452,710.325a47.346,47.346,0,0,0-10.38-1.091v-5a52.113,52.113,0,0,1,11.419,1.2Z" fill="#d69568"/>
                            </g>
                        </g>
                        </g>
                    </g>
                    </g>
                </g>
                </svg>

        </span>

    )
}
export default PotencyVvTingle;

PotencyVvTingle.propTypes = {
    className: PropTypes.string,
    fill: PropTypes.string,
  };
  
PotencyVvTingle.defaultProps = {
    className: undefined,
    fill: '#333',
};
  
