import React from "react";
import "./style.scss";
import ParticleField from "./ParticleField";

class LittleVictory extends React.Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     width: null,
  //     height: null,
  //   };
  // }

  

  // componentDidMount(startPaused = false) {
  //   this.updateWindowDimensions();
  //   window.addEventListener("resize", this.updateWindowDimensions);
  // }
  // componentWillUnmount() {
  //   window.removeEventListener("resize", this.updateWindowDimensions);
  // }
  // updateWindowDimensions = () => {
  //   this.setState({
  //     width: `${window.innerWidth}px`,
  //     height: `${window.innerHeight}px`,
  //   });
  // };

  render() {
    // let { width, height } = this.state;
    return (
      <div id="BannerContainer">
        < ParticleField />
      </div>
    );
  }
}
export default LittleVictory;
