import React from 'react';
import './style.scss';
import ContainerContent from '../../../../templates/ContainerContent/ContainerContent';
import { TranslatableText } from '../../../../providers/LanguageProvider';
import { LanguageConsumer } from '../../../../providers/LanguageProvider';
import * as ScrollMagic from "scrollmagic"; // Or use scrollmagic-with-ssr to avoid server rendering problems
import { TimelineMax, Linear } from "gsap"; // Also works with TweenLite and TimelineLite
import mollo_bottle_5 from '../../i/mollo_2.png';
import Calendar from '../../../../components/svgIcons/Calendar';
import Mug from '../../../../components/svgIcons/Mug';
import PotencyLow from '../../../../components/svgIcons/PotencyLow';
import PotencyMollo5 from '../../../../components/svgIcons/PotencyMollo5';
import Highlights from '../../../../components/svgIcons/Highlights';
import InfoIcon from '../../../../components/svgIcons/InfoIcon';
import ServingsMollo from '../../../../components/svgIcons/ServingsMollo';
import MolloLinesStatic from '../../i/MolloLinesStatic';
import bottle_cap_2 from '../../i/gold-bottle-cap.png';
import NutritionPopUp from '../../../../components/NutritionPopUp/NutritionPopUp';

class Mollo5Brew extends React.Component {
    constructor(props){
        super(props)

        this.controller = new ScrollMagic.Controller();
    }
    componentWillUnmount() {
        this.controller.destroy();
    }
    componentDidMount = () => {
    
        var title = document.getElementById("title-anim-3");
        var list = document.getElementById("beverage-info-3");
        var imgWrap = document.getElementById("img-wrap-3");
        
                               
        var timeline2 = new TimelineMax()
        timeline2.to(title, 1, {y:110,/*  textShadow: "1px 1px 1px rgb(204, 46, 76)", */  ease: Linear.easeNone} )
       // timeline.to(list, 1, {y:95,   ease: Linear.easeNone}, 0)
       // timeline2.from(list, 1, {y:95,   ease: Linear.easeNone}, 0)
        //timeline.to(imgWrap, 1, {y:105,   ease: Linear.easeNone}, 0)

        
        // build scenes
        let scene2 = new ScrollMagic.Scene({triggerElement: ".three", triggerHook: 1, duration: '100%', tweenChanges: true});
            scene2 .setTween(timeline2)
            .on("enter leave", this.addBottleAnimation)
            .on("progress", function (event) {
                //console.log("Scene progress changed to " + event.progress);
            })
            //.addIndicators() // add indicators (requires plugin)
            .addTo(this.controller);

    }

    addBottleAnimation = () => { 
       let bottles =  document.getElementById('img-wrap-3');
       bottles.classList.add('anim');   
    }
    render() {
        return (
            <div className="blockwrap three mollo-two-five" id={this.props.bevId}>
               <MolloLinesStatic />
               <ContainerContent>
                    {
                        this.props.isDesktop ?
                            <div className="img-wrap" id="img-wrap-3">
                                <img src={mollo_bottle_5} className="bottle-yellow bottle" alt="Mollo - 5 Brew" />
                                <img src={mollo_bottle_5} className="bottle-yellow yellow-2 bottle abs" alt="Mollo - 5 Brew" />
                                <img src={bottle_cap_2} className="slice-1" alt="bottle cap gold" />
                                <img src={bottle_cap_2} className="slice-2" alt="bottle cap gold" />
                            </div>
                        :
                        null
                    }

                    <div className="info-wrap">

                        <h2 id="title-anim-3">    
                            <TranslatableText dictionary={{
                                en: "Mollo-out with",
                                fr: "Prenez ça Mollo™ " }}>
                            </TranslatableText><br />
                            <TranslatableText dictionary={{
                                en: "this crisp, cold taste.",
                                fr: "avec ce goût vif" }}>
                            </TranslatableText><br />
                            <TranslatableText dictionary={{
                                en: "",
                                fr: "et froid." }}>
                            </TranslatableText>
                        </h2>
                        

                        <div className="beverage-info" id="beverage-info-3">

                            <h3>Mollo™ 5</h3>
                            <p>
                                <TranslatableText dictionary={{
                                    en: "Crisp with an easy drinking taste.",
                                    fr: "Un goût vif et facile à boire." }}>
                                </TranslatableText>
                            </p>

                            {
                                this.props.isDesktop ?
                                    null
                                :
                                    <div className="img-wrap" id="img-wrap-3">
                                        <img src={mollo_bottle_5} className="bottle-yellow bottle" alt="Mollo™ - 5 Brew" />
                                        <img src={mollo_bottle_5} className="bottle-yellow yellow-2 bottle abs" alt="Mollo™ - 5 Brew" />
                                        <img src={bottle_cap_2} className="slice-1" alt="bottle cap gold" />
                                        <img src={bottle_cap_2} className="slice-2" alt="bottle cap gold" />
                                    </div>
                            }

                            <ul className="standard-list">
                                <li>
                                    <PotencyMollo5 />
                                    <span>
                                        <h4>
                                            <TranslatableText dictionary={{
                                                en: "POTENCY",
                                                fr: "intensité" }}>
                                            </TranslatableText>
                                        </h4>
                                        <p>
                                            <TranslatableText dictionary={{
                                                en: "1:1 MEDIUM",
                                                fr: "1:1 moyenne" }}>
                                            </TranslatableText><br />
                                            <TranslatableText dictionary={{
                                                en: "5MG THC + 5MG CBD",
                                                fr: "5 mg THC : 5 mg CBD" }}>
                                            </TranslatableText>
                                        </p>
                                    </span>
                                </li>
                                <li>
                                    <Calendar />
                                    <span>
                                        <h4>
                                            <TranslatableText dictionary={{
                                                en: "OCCASIONS",
                                                fr: "OCCASIONS" }}>
                                            </TranslatableText>
                                        </h4>
                                        <p> 
                                            <TranslatableText dictionary={{
                                                en: "After filing your taxes.",
                                                fr: "Après avoir fait ses impôts." }}>
                                            </TranslatableText><br />
                                            <TranslatableText dictionary={{
                                                en: "A BBQ with friends.",
                                                fr: "À un barbecue avec des amis." }}>
                                            </TranslatableText><br />
                                            <TranslatableText dictionary={{
                                                en: "Fantasy football drafts.",
                                                fr: "En montant son pool de hockey." }}>
                                            </TranslatableText>
                                        </p>
                                    </span>
                                </li>
                                <li>
                                    <ServingsMollo />
                                    <span>
                                        <h4>
                                            <TranslatableText dictionary={{
                                                en: "SERVING SUGGESTION",
                                                fr: "NOTES DE SERVICE" }}>
                                            </TranslatableText>
                                        </h4>
                                        <p> 
                                            <TranslatableText dictionary={{
                                                en: "Pour into a chilled glass. ",
                                                fr: "Verser dans un bock givré." }}>
                                            </TranslatableText>
                                        </p>
                                    </span>
                                </li>
                                <li>
                                    <Highlights />
                                    <span>
                                        <h4>
                                            <TranslatableText dictionary={{
                                                en: "HIGHLIGHTS",
                                                fr: "INFORMATIONS" }}>
                                            </TranslatableText>
                                        </h4>
                                        <p> 
                                            <TranslatableText dictionary={{
                                                en: "All natural flavours.",
                                                fr: "Saveurs entièrement naturelles." }}>
                                            </TranslatableText>
                                        </p>
                                    </span>
                                </li>
                                <li>
                                    <InfoIcon />
                                    <span>
                                        <h4>
                                            <TranslatableText dictionary={{
                                                en: "NUTRITIONAL INFORMATION",
                                                fr: "INFORMATIONS NUTRITIVE" }}>
                                            </TranslatableText>
                                        </h4>
                                        <div className="paragraph">
                                            <NutritionPopUp beverage="mollo-5" />
                                        </div>
                                    </span>
                                </li>
                            </ul>


                        </div>

                    </div>

                </ContainerContent>

            </div>
        )
    }
}
export default Mollo5Brew;