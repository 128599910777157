import React from 'react';
import './style.scss';
import ContainerContent from '../../templates/ContainerContent/ContainerContent';
import { LanguageConsumer } from '../../providers/LanguageProvider'
import { TranslatableText } from '../../providers/LanguageProvider'
import Footer from '../../components/Footer/Footer';
import lv_bottle_pink from './i/bottle_1.png';
import lv_bottle_yellow from './i/bottle_2.png';
import lv_drinks from './i/little-victory-group.png';
import PinkBottles from './scenes/PinkBottles/PinkBottles';
import YellowBottles from './scenes/YellowBottles/YellowBottles';
import PurpleBottles from './scenes/PurpleBottles/PurpleBottles';
import OrangeBottles from './scenes/OrangeBottles/OrangeBottles';
import KindAwardsBadge from './i/kind-awards-badge.png';

//import { isDesktop } from '../../helpers';
import WhereToBuy from '../../components/WhereToBuy/WhereToBuy';

class LittleVictory extends React.Component {
    constructor(props){
        super(props);

        this.state ={
            isDesktop: props.info.isDesktop,
        }
    }

    render() {
        return (
           
            <div className={`page-wrap`}>
               
                <div className="blockwrap first">
                    <ContainerContent>
                       {/*  <WhereToBuy btnPage="little-victory" linkTo="/where-to-buy" isFixed={true} /> */}
                        <span className="heading-wrap">
                            <h1>
                                <TranslatableText dictionary={{
                                    en: "Vibrant, naturally",
                                    fr: "Des boissons" }}>
                                </TranslatableText><br />
                                <TranslatableText dictionary={{
                                    en: "flavoured sparkling",
                                    fr: "pétillantes avec" }}>
                                </TranslatableText><br />
                                <TranslatableText dictionary={{
                                    en: "beverages to toast",
                                    fr: "de vives saveurs" }}>
                                </TranslatableText><br />
                                <TranslatableText dictionary={{
                                    en: "to any of life’s",
                                    fr: "naturelles pour" }}>
                                </TranslatableText><br />
                                <TranslatableText dictionary={{
                                    en: "little victories.",
                                    fr: "célébrer chaque" }}>
                                </TranslatableText><br />
                                <TranslatableText dictionary={{
                                    en: "Celebrate a little.",
                                    fr: "petite victoire." }}>
                                </TranslatableText>
                             </h1>  
                            
                        </span>
                        <div className="img-wrap">
                            <img src={KindAwardsBadge} alt="Kind Awards Badge" className="awards-badge" />
                            <img src={lv_bottle_pink} alt="bottle" className="bottle-pink bottle bottle-bounce-1" />
                            <img src={lv_bottle_yellow} alt="bottle" className="bottle-yellow bottle abs bottle-bounce-2" />
                        </div>
                    </ContainerContent>
                </div>

                <PinkBottles isDesktop={this.state.isDesktop} bevId="beverage-2"  /> 
                <YellowBottles isDesktop={this.state.isDesktop} bevId="beverage-3" />
                <PurpleBottles isDesktop={this.state.isDesktop} bevId="beverage-4" />
                <OrangeBottles isDesktop={this.state.isDesktop} bevId="beverage-5"/>

                <div className="blockwrap brandline">
                    <ContainerContent>
                        <h2>
                            <TranslatableText dictionary={{
                                en: "What I’ll look like ",
                                fr: "Voici à quoi je ressemble lorsque " }}>
                            </TranslatableText><br />
                            <TranslatableText dictionary={{
                                en: "when you bring me home.",
                                fr: "vous m'apporterez à la maison." }}>
                            </TranslatableText>
                        </h2>
    
                        <img src={lv_drinks} />
                    </ContainerContent>
                </div>
                <Footer instagram="littlevictorybeverages"/>

            </div>
        
        )
    }
}
export default LittleVictory;