import React from 'react';
import './style.scss';
import ContainerContent from '../../templates/ContainerContent/ContainerContent';
import Footer from '../../components/Footer/Footer';
import { TranslatableText } from '../../providers/LanguageProvider';
import StrawberryHibiscus from './scenes/StrawberryHibiscus/StrawberryHibiscus';
import SicilianLemon from './scenes/SicilianLemon/SicilianLemon';
import LemonBlackTea from './scenes/LemonBlackTea/LemonBlackTea';
import CbdDrops from './scenes/CbdDrops/CbdDrops';
import ThcDrops from './scenes/ThcDrops/ThcDrops';
import MixDrops from './scenes/MixDrops/MixDrops';
import lv_bottle_lemon from './i/veryvell_2.png';
import can_lemon_tea from './i/veryvell_3.png';
import vv_drinks from './i/veryvell-group.png';
import VerywellLinesStatic from './i/VerywellLinesStatic';


class VeryvellProducts extends React.Component {
    constructor(props){
        super(props)

        this.state ={
            isDesktop: props.info.isDesktop
        }
        
    }
    render(){
    
        return (
            <React.Fragment>
            <div className={`page-wrap`}>

                <div className="blockwrap first">

                    <ContainerContent>

                        <span className="heading-wrap">
                            <h1>

                                <TranslatableText dictionary={{
                                    en: "A complete line",
                                    fr: "Une gamme" }}>
                                </TranslatableText><br />
                                <TranslatableText dictionary={{
                                    en: " of products",
                                    fr: "complète de" }}>
                                </TranslatableText><br />
                                <TranslatableText dictionary={{
                                    en: "to support",
                                    fr: "produits pour vous" }}>
                                </TranslatableText><br />
                                <TranslatableText dictionary={{
                                    en: "your self-care",
                                    fr: "accompagner dans" }}>
                                </TranslatableText><br />
                                <TranslatableText dictionary={{
                                    en: "journey.",
                                    fr: "vos expériences" }}>
                                </TranslatableText><br />
                                <TranslatableText dictionary={{
                                    en: "",
                                    fr: "personnelles." }}>
                                </TranslatableText>

                            </h1>  
                        </span>
                        <div className="img-wrap">
                            <img src={can_lemon_tea} className="bottle-strawberry bottle veryvell-bounce-1" alt="Veryvell - Strawberry" />
                            
                            {/* <img src={lv_bottle_stawberry} className="bottle-strawberry bottle bottle-bounce-1" alt="Veryvell - Strawberry" />  */}
                            <img src={lv_bottle_lemon} className="bottle-lemon bottle abs bottle-bounce-2" alt="Veryvell - Lemon" /> 
                        </div>
                        
                    </ContainerContent>

                </div>


                <StrawberryHibiscus isDesktop={this.state.isDesktop} bevId="beverage-2" lang={this.props.lang} />
                <SicilianLemon isDesktop={this.state.isDesktop} bevId="beverage-3"/>
                <LemonBlackTea isDesktop={this.state.isDesktop} bevId="beverage-4" lang={this.props.lang} />
                <CbdDrops isDesktop={this.state.isDesktop} bevId="beverage-5" />
                <MixDrops isDesktop={this.state.isDesktop} bevId="beverage-7" />
                <ThcDrops isDesktop={this.state.isDesktop} bevId="beverage-6" />

                <div className="blockwrap brandline dark">
                    <VerywellLinesStatic />
                    <ContainerContent>
                        <h2>
                            <TranslatableText dictionary={{
                                en: "What I’ll look like ",
                                fr: "Voici à quoi je ressemble lorsque " }}>
                            </TranslatableText><br />
                            <TranslatableText dictionary={{
                                en: "when you bring me home.",
                                fr: "vous m'apporterez à la maison." }}>
                            </TranslatableText>
                        </h2>

                        <img src={vv_drinks} alt="Veryvell - Family of Beverages" />
                    </ContainerContent>
                </div>

                <Footer instagram="veryvellthen" addClass="dark"/>

            </div>

        </React.Fragment>
        )
    }
}
export default VeryvellProducts;
