import React from 'react';

const MolloLinesStatic = () => {
  return (
    <div className="svg-bg">
      <svg xmlns="http://www.w3.org/2000/svg"  width="100%" height="100%" viewBox="0 0 358.781 546.128" preserveAspectRatio="xMinYMin meet">
                        <g transform="scale(1) translate(0 0)"  opacity="0.4">
                        <g transform="translate(0 0)">
                        <g transform="translate(-1007.683 -362.672)">
                            <g transform="translate(1009.059 363.922)">
                                <path d="M1062.233,363.922h85.434a10.488,10.488,0,0,1,9.059,5.23l42.717,73.988a10.489,10.489,0,0,1,0,10.461l-42.717,73.988a10.488,10.488,0,0,1-9.059,5.23h-85.434a10.489,10.489,0,0,1-9.059-5.23L1010.456,453.6a10.491,10.491,0,0,1,0-10.461l42.717-73.988A10.489,10.489,0,0,1,1062.233,363.922Z" transform="translate(-1009.059 -363.922)" fill="none" stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.5"/>
                                <path d="M1076.981,393.759a8.635,8.635,0,0,1,7.456-4.305h76.052a8.635,8.635,0,0,1,7.456,4.305l38.027,65.863a8.632,8.632,0,0,1,0,8.61L1167.946,534.1a8.631,8.631,0,0,1-7.456,4.3h-76.052a8.631,8.631,0,0,1-7.456-4.3l-38.027-65.864a8.628,8.628,0,0,1,0-8.61Z" transform="translate(-1026.573 -379.479)" fill="none" stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.5"/>
                                <path d="M1067.456,476.105l33.336-57.74a6.775,6.775,0,0,1,5.853-3.379h66.672a6.774,6.774,0,0,1,5.852,3.379l33.336,57.74a6.771,6.771,0,0,1,0,6.758l-33.336,57.74a6.775,6.775,0,0,1-5.852,3.379h-66.672a6.776,6.776,0,0,1-5.853-3.379l-33.336-57.74A6.775,6.775,0,0,1,1067.456,476.105Z" transform="translate(-1044.09 -395.035)" fill="none" stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.5"/>
                                <path d="M1128.849,549.563a4.918,4.918,0,0,1-4.249-2.454l-28.646-49.615a4.914,4.914,0,0,1,0-4.907l28.646-49.616a4.918,4.918,0,0,1,4.249-2.454h57.292a4.918,4.918,0,0,1,4.249,2.454l28.646,49.616a4.921,4.921,0,0,1,0,4.907L1190.39,547.11a4.919,4.919,0,0,1-4.249,2.454Z" transform="translate(-1061.604 -410.592)" fill="none" stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.5"/>
                                <path d="M1151.057,555.145a3.063,3.063,0,0,1-2.646-1.528l-23.956-41.491a3.065,3.065,0,0,1,0-3.055l23.956-41.492a3.063,3.063,0,0,1,2.646-1.528h47.91a3.062,3.062,0,0,1,2.646,1.528l23.956,41.492a3.063,3.063,0,0,1,0,3.055l-23.956,41.491a3.063,3.063,0,0,1-2.646,1.528Z" transform="translate(-1079.121 -426.149)" fill="none" stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.5"/>
                            </g>
                            <g transform="translate(1064.965 414.006)">
                                <path d="M0,0H44.738" transform="translate(17.615)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="2.5"/>
                                <path d="M0,0H56.482" transform="translate(11.744 10.17)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="2.5"/>
                                <path d="M0,0H68.226" transform="translate(5.872 20.34)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="2.5"/>
                                <path d="M0,0H79.969" transform="translate(0 30.51)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="2.5"/>
                                <path d="M0,0H77.764" transform="translate(1.103 40.681)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="2.5"/>
                                <path d="M0,0H66.02" transform="translate(6.974 50.851)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="2.5"/>
                                <path d="M0,0H54.277" transform="translate(12.846 61.021)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="2.5"/>
                                <path d="M0,0H41.038" transform="translate(19.466 71.191)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="2.5"/>
                            </g>
                        </g>
                        </g>
                        <g transform="translate(156 91)">
                        <g transform="translate(-1007.683 -362.672)">
                            <g transform="translate(1009.059 363.922)">
                                <path d="M1062.233,363.922h85.434a10.488,10.488,0,0,1,9.059,5.23l42.717,73.988a10.489,10.489,0,0,1,0,10.461l-42.717,73.988a10.488,10.488,0,0,1-9.059,5.23h-85.434a10.489,10.489,0,0,1-9.059-5.23L1010.456,453.6a10.491,10.491,0,0,1,0-10.461l42.717-73.988A10.489,10.489,0,0,1,1062.233,363.922Z" transform="translate(-1009.059 -363.922)" fill="none" stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.5"/>
                                <path d="M1076.981,393.759a8.635,8.635,0,0,1,7.456-4.305h76.052a8.635,8.635,0,0,1,7.456,4.305l38.027,65.863a8.632,8.632,0,0,1,0,8.61L1167.946,534.1a8.631,8.631,0,0,1-7.456,4.3h-76.052a8.631,8.631,0,0,1-7.456-4.3l-38.027-65.864a8.628,8.628,0,0,1,0-8.61Z" transform="translate(-1026.573 -379.479)" fill="none" stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.5"/>
                                <path d="M1067.456,476.105l33.336-57.74a6.775,6.775,0,0,1,5.853-3.379h66.672a6.774,6.774,0,0,1,5.852,3.379l33.336,57.74a6.771,6.771,0,0,1,0,6.758l-33.336,57.74a6.775,6.775,0,0,1-5.852,3.379h-66.672a6.776,6.776,0,0,1-5.853-3.379l-33.336-57.74A6.775,6.775,0,0,1,1067.456,476.105Z" transform="translate(-1044.09 -395.035)" fill="none" stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.5"/>
                                <path d="M1128.849,549.563a4.918,4.918,0,0,1-4.249-2.454l-28.646-49.615a4.914,4.914,0,0,1,0-4.907l28.646-49.616a4.918,4.918,0,0,1,4.249-2.454h57.292a4.918,4.918,0,0,1,4.249,2.454l28.646,49.616a4.921,4.921,0,0,1,0,4.907L1190.39,547.11a4.919,4.919,0,0,1-4.249,2.454Z" transform="translate(-1061.604 -410.592)" fill="none" stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.5"/>
                                <path d="M1151.057,555.145a3.063,3.063,0,0,1-2.646-1.528l-23.956-41.491a3.065,3.065,0,0,1,0-3.055l23.956-41.492a3.063,3.063,0,0,1,2.646-1.528h47.91a3.062,3.062,0,0,1,2.646,1.528l23.956,41.492a3.063,3.063,0,0,1,0,3.055l-23.956,41.491a3.063,3.063,0,0,1-2.646,1.528Z" transform="translate(-1079.121 -426.149)" fill="none" stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.5"/>
                            </g>
                            <g transform="translate(1064.965 414.006)">
                                <path d="M0,0H44.738" transform="translate(17.615)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="2.5"/>
                                <path d="M0,0H56.482" transform="translate(11.744 10.17)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="2.5"/>
                                <path d="M0,0H68.226" transform="translate(5.872 20.34)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="2.5"/>
                                <path d="M0,0H79.969" transform="translate(0 30.51)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="2.5"/>
                                <path d="M0,0H77.764" transform="translate(1.103 40.681)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="2.5"/>
                                <path d="M0,0H66.02" transform="translate(6.974 50.851)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="2.5"/>
                                <path d="M0,0H54.277" transform="translate(12.846 61.021)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="2.5"/>
                                <path d="M0,0H41.038" transform="translate(19.466 71.191)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="2.5"/>
                            </g>
                        </g>
                        </g>
                        <g transform="translate(0 182)">
                        <g transform="translate(-1007.683 -362.672)">
                            <g transform="translate(1009.059 363.922)">
                                <path d="M1062.233,363.922h85.434a10.488,10.488,0,0,1,9.059,5.23l42.717,73.988a10.489,10.489,0,0,1,0,10.461l-42.717,73.988a10.488,10.488,0,0,1-9.059,5.23h-85.434a10.489,10.489,0,0,1-9.059-5.23L1010.456,453.6a10.491,10.491,0,0,1,0-10.461l42.717-73.988A10.489,10.489,0,0,1,1062.233,363.922Z" transform="translate(-1009.059 -363.922)" fill="none" stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.5"/>
                                <path d="M1076.981,393.759a8.635,8.635,0,0,1,7.456-4.305h76.052a8.635,8.635,0,0,1,7.456,4.305l38.027,65.863a8.632,8.632,0,0,1,0,8.61L1167.946,534.1a8.631,8.631,0,0,1-7.456,4.3h-76.052a8.631,8.631,0,0,1-7.456-4.3l-38.027-65.864a8.628,8.628,0,0,1,0-8.61Z" transform="translate(-1026.573 -379.479)" fill="none" stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.5"/>
                                <path d="M1067.456,476.105l33.336-57.74a6.775,6.775,0,0,1,5.853-3.379h66.672a6.774,6.774,0,0,1,5.852,3.379l33.336,57.74a6.771,6.771,0,0,1,0,6.758l-33.336,57.74a6.775,6.775,0,0,1-5.852,3.379h-66.672a6.776,6.776,0,0,1-5.853-3.379l-33.336-57.74A6.775,6.775,0,0,1,1067.456,476.105Z" transform="translate(-1044.09 -395.035)" fill="none" stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.5"/>
                                <path d="M1128.849,549.563a4.918,4.918,0,0,1-4.249-2.454l-28.646-49.615a4.914,4.914,0,0,1,0-4.907l28.646-49.616a4.918,4.918,0,0,1,4.249-2.454h57.292a4.918,4.918,0,0,1,4.249,2.454l28.646,49.616a4.921,4.921,0,0,1,0,4.907L1190.39,547.11a4.919,4.919,0,0,1-4.249,2.454Z" transform="translate(-1061.604 -410.592)" fill="none" stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.5"/>
                                <path d="M1151.057,555.145a3.063,3.063,0,0,1-2.646-1.528l-23.956-41.491a3.065,3.065,0,0,1,0-3.055l23.956-41.492a3.063,3.063,0,0,1,2.646-1.528h47.91a3.062,3.062,0,0,1,2.646,1.528l23.956,41.492a3.063,3.063,0,0,1,0,3.055l-23.956,41.491a3.063,3.063,0,0,1-2.646,1.528Z" transform="translate(-1079.121 -426.149)" fill="none" stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.5"/>
                            </g>
                            <g transform="translate(1064.965 414.006)">
                                <path d="M0,0H44.738" transform="translate(17.615)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="2.5"/>
                                <path d="M0,0H56.482" transform="translate(11.744 10.17)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="2.5"/>
                                <path d="M0,0H68.226" transform="translate(5.872 20.34)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="2.5"/>
                                <path d="M0,0H79.969" transform="translate(0 30.51)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="2.5"/>
                                <path d="M0,0H77.764" transform="translate(1.103 40.681)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="2.5"/>
                                <path d="M0,0H66.02" transform="translate(6.974 50.851)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="2.5"/>
                                <path d="M0,0H54.277" transform="translate(12.846 61.021)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="2.5"/>
                                <path d="M0,0H41.038" transform="translate(19.466 71.191)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="2.5"/>
                            </g>
                        </g>
                        </g>
                        <g transform="translate(156 273)">
                        <g transform="translate(-1007.683 -362.672)">
                            <g transform="translate(1009.059 363.922)">
                                <path d="M1062.233,363.922h85.434a10.488,10.488,0,0,1,9.059,5.23l42.717,73.988a10.489,10.489,0,0,1,0,10.461l-42.717,73.988a10.488,10.488,0,0,1-9.059,5.23h-85.434a10.489,10.489,0,0,1-9.059-5.23L1010.456,453.6a10.491,10.491,0,0,1,0-10.461l42.717-73.988A10.489,10.489,0,0,1,1062.233,363.922Z" transform="translate(-1009.059 -363.922)" fill="none" stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.5"/>
                                <path d="M1076.981,393.759a8.635,8.635,0,0,1,7.456-4.305h76.052a8.635,8.635,0,0,1,7.456,4.305l38.027,65.863a8.632,8.632,0,0,1,0,8.61L1167.946,534.1a8.631,8.631,0,0,1-7.456,4.3h-76.052a8.631,8.631,0,0,1-7.456-4.3l-38.027-65.864a8.628,8.628,0,0,1,0-8.61Z" transform="translate(-1026.573 -379.479)" fill="none" stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.5"/>
                                <path d="M1067.456,476.105l33.336-57.74a6.775,6.775,0,0,1,5.853-3.379h66.672a6.774,6.774,0,0,1,5.852,3.379l33.336,57.74a6.771,6.771,0,0,1,0,6.758l-33.336,57.74a6.775,6.775,0,0,1-5.852,3.379h-66.672a6.776,6.776,0,0,1-5.853-3.379l-33.336-57.74A6.775,6.775,0,0,1,1067.456,476.105Z" transform="translate(-1044.09 -395.035)" fill="none" stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.5"/>
                                <path d="M1128.849,549.563a4.918,4.918,0,0,1-4.249-2.454l-28.646-49.615a4.914,4.914,0,0,1,0-4.907l28.646-49.616a4.918,4.918,0,0,1,4.249-2.454h57.292a4.918,4.918,0,0,1,4.249,2.454l28.646,49.616a4.921,4.921,0,0,1,0,4.907L1190.39,547.11a4.919,4.919,0,0,1-4.249,2.454Z" transform="translate(-1061.604 -410.592)" fill="none" stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.5"/>
                                <path d="M1151.057,555.145a3.063,3.063,0,0,1-2.646-1.528l-23.956-41.491a3.065,3.065,0,0,1,0-3.055l23.956-41.492a3.063,3.063,0,0,1,2.646-1.528h47.91a3.062,3.062,0,0,1,2.646,1.528l23.956,41.492a3.063,3.063,0,0,1,0,3.055l-23.956,41.491a3.063,3.063,0,0,1-2.646,1.528Z" transform="translate(-1079.121 -426.149)" fill="none" stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.5"/>
                            </g>
                            <g transform="translate(1064.965 414.006)">
                                <path d="M0,0H44.738" transform="translate(17.615)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="2.5"/>
                                <path d="M0,0H56.482" transform="translate(11.744 10.17)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="2.5"/>
                                <path d="M0,0H68.226" transform="translate(5.872 20.34)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="2.5"/>
                                <path d="M0,0H79.969" transform="translate(0 30.51)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="2.5"/>
                                <path d="M0,0H77.764" transform="translate(1.103 40.681)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="2.5"/>
                                <path d="M0,0H66.02" transform="translate(6.974 50.851)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="2.5"/>
                                <path d="M0,0H54.277" transform="translate(12.846 61.021)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="2.5"/>
                                <path d="M0,0H41.038" transform="translate(19.466 71.191)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="2.5"/>
                            </g>
                        </g>
                        </g>
                        <g transform="translate(0 364)">
                        <g transform="translate(-1007.683 -362.672)">
                            <g transform="translate(1009.059 363.922)">
                                <path d="M1062.233,363.922h85.434a10.488,10.488,0,0,1,9.059,5.23l42.717,73.988a10.489,10.489,0,0,1,0,10.461l-42.717,73.988a10.488,10.488,0,0,1-9.059,5.23h-85.434a10.489,10.489,0,0,1-9.059-5.23L1010.456,453.6a10.491,10.491,0,0,1,0-10.461l42.717-73.988A10.489,10.489,0,0,1,1062.233,363.922Z" transform="translate(-1009.059 -363.922)" fill="none" stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.5"/>
                                <path d="M1076.981,393.759a8.635,8.635,0,0,1,7.456-4.305h76.052a8.635,8.635,0,0,1,7.456,4.305l38.027,65.863a8.632,8.632,0,0,1,0,8.61L1167.946,534.1a8.631,8.631,0,0,1-7.456,4.3h-76.052a8.631,8.631,0,0,1-7.456-4.3l-38.027-65.864a8.628,8.628,0,0,1,0-8.61Z" transform="translate(-1026.573 -379.479)" fill="none" stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.5"/>
                                <path d="M1067.456,476.105l33.336-57.74a6.775,6.775,0,0,1,5.853-3.379h66.672a6.774,6.774,0,0,1,5.852,3.379l33.336,57.74a6.771,6.771,0,0,1,0,6.758l-33.336,57.74a6.775,6.775,0,0,1-5.852,3.379h-66.672a6.776,6.776,0,0,1-5.853-3.379l-33.336-57.74A6.775,6.775,0,0,1,1067.456,476.105Z" transform="translate(-1044.09 -395.035)" fill="none" stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.5"/>
                                <path d="M1128.849,549.563a4.918,4.918,0,0,1-4.249-2.454l-28.646-49.615a4.914,4.914,0,0,1,0-4.907l28.646-49.616a4.918,4.918,0,0,1,4.249-2.454h57.292a4.918,4.918,0,0,1,4.249,2.454l28.646,49.616a4.921,4.921,0,0,1,0,4.907L1190.39,547.11a4.919,4.919,0,0,1-4.249,2.454Z" transform="translate(-1061.604 -410.592)" fill="none" stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.5"/>
                                <path d="M1151.057,555.145a3.063,3.063,0,0,1-2.646-1.528l-23.956-41.491a3.065,3.065,0,0,1,0-3.055l23.956-41.492a3.063,3.063,0,0,1,2.646-1.528h47.91a3.062,3.062,0,0,1,2.646,1.528l23.956,41.492a3.063,3.063,0,0,1,0,3.055l-23.956,41.491a3.063,3.063,0,0,1-2.646,1.528Z" transform="translate(-1079.121 -426.149)" fill="none" stroke="#707070" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2.5"/>
                            </g>
                            <g transform="translate(1064.965 414.006)">
                                <path d="M0,0H44.738" transform="translate(17.615)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="2.5"/>
                                <path d="M0,0H56.482" transform="translate(11.744 10.17)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="2.5"/>
                                <path d="M0,0H68.226" transform="translate(5.872 20.34)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="2.5"/>
                                <path d="M0,0H79.969" transform="translate(0 30.51)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="2.5"/>
                                <path d="M0,0H77.764" transform="translate(1.103 40.681)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="2.5"/>
                                <path d="M0,0H66.02" transform="translate(6.974 50.851)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="2.5"/>
                                <path d="M0,0H54.277" transform="translate(12.846 61.021)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="2.5"/>
                                <path d="M0,0H41.038" transform="translate(19.466 71.191)" fill="none" stroke="#707070" strokeLinecap="round" strokeWidth="2.5"/>
                            </g>
                        </g>
                        </g>
                        </g>
                    </svg>
  </div>
  )
}

export default MolloLinesStatic;