import React, { useRef, useMemo } from "react";
import { Canvas, useFrame } from "react-three-fiber";

import { DoubleSide } from "three";

function getRandomArbitrary(min, max) {
  return Math.random() * (max - min) + min;
}

function getRandomColor() {
  const colors = ["#DA536C", "#F67C77", "#58B6AB", "#EEDB9B", "#E85936"];
  return colors[Math.floor(Math.random() * colors.length)];
}

const bounds = {
  top: 6,
  left: -6,
  right: 6,
  bottom: -6,
};

const Confetti = (props) => {
  // console.log('rerendering?')
  const mesh = useRef();
  const speedRange = 0.01;
  const rotSpeed = useMemo(() => {
    return {
      x: getRandomArbitrary(-speedRange, speedRange),
      y: getRandomArbitrary(-speedRange, speedRange),
      z: getRandomArbitrary(-speedRange, speedRange),
    };
  }, []);
  const randScale = useMemo(() => getRandomArbitrary(0.1, 0.3), []);
  const moveSpeed = useMemo(() => {
    return {
      y: getRandomArbitrary(-0.01, -0.04) * randScale,
      x: getRandomArbitrary(-0.004, 0.004) * randScale,
    };
  }, []);
  const startPos = useMemo(
    () => [
      getRandomArbitrary(bounds.left, bounds.right),
      getRandomArbitrary(bounds.bottom, bounds.top),
      1 * randScale + 0.2,
    ],
    []
  );

  const startRot = useMemo(
    () => [
      getRandomArbitrary(0, 360),
      getRandomArbitrary(0, 360),
      getRandomArbitrary(0, 360),
    ],
    []
  );

  const cColor = useMemo(() => getRandomColor(), []);

  useFrame(() => {
    mesh.current.rotation.x += rotSpeed.x;
    mesh.current.rotation.y += rotSpeed.y;
    mesh.current.rotation.z += rotSpeed.z;
    mesh.current.position.y += moveSpeed.y;
    mesh.current.position.x += moveSpeed.x;

    if (mesh.current.position.y <= bounds.bottom - 1) {
      mesh.current.position.y = bounds.top;
      mesh.current.position.x = getRandomArbitrary(bounds.left, bounds.right);
    }
  });

  return (
    <mesh
      {...props}
      ref={mesh}
      position={startPos}
      rotation={startRot}
      scale={[randScale, randScale, randScale]}
    >
      <planeGeometry attach="geometry" args={[2, 0.5, 1]} />
      <meshBasicMaterial attach="material" color={cColor} side={DoubleSide} />
    </mesh>
  );
};

const ParticleField = () => {
  const nConfetti = 60;
  return (
    <Canvas>
      <ambientLight />
      {[...Array(nConfetti)].map((e, i) => (
        <Confetti key={i} />
      ))}
    </Canvas>
  );
};

export default ParticleField;
