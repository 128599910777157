import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const PotencyLittleV = (className, fill) => {
    
    return(

        <span className="icon">

            <svg xmlns="http://www.w3.org/2000/svg" width="108.878" height="108.854" viewBox="0 0 108.878 108.854">
                <g id="Group_832" data-name="Group 832" transform="translate(-316.559 -347.966)">
                    <path id="Path_725" data-name="Path 725" d="M362.374,434.647a33.384,33.384,0,0,1-8.051-61.159,31.7,31.7,0,0,1,16.692-4.472v-5a36.9,36.9,0,0,0-19.192,5.142,38.386,38.386,0,0,0,9.257,70.32,38.652,38.652,0,0,0,10,1.324,38.4,38.4,0,0,0,33.179-57.594l-4.33,2.5a33.384,33.384,0,0,1-37.553,48.939Z" fill="#cc2e4c" opacity="0.3"/>
                    <path id="Path_726" data-name="Path 726" d="M418.155,375.183l-4.33,2.5A49.432,49.432,0,1,1,346.3,359.589a46.918,46.918,0,0,1,24.716-6.623v-5a52.336,52.336,0,0,0-27.216,7.293,54.433,54.433,0,1,0,74.356,19.924Z" fill="#cc2e4c" opacity="0.3"/>
                    <path id="Path_727" data-name="Path 727" d="M371.015,347.966v5a48.309,48.309,0,0,1,42.81,24.717l4.33-2.5A53.2,53.2,0,0,0,371.015,347.966Z" fill="#cc2e4c"/>
                    <path id="Path_728" data-name="Path 728" d="M371.015,364.016v5a32.627,32.627,0,0,1,28.912,16.692l4.33-2.5A37.512,37.512,0,0,0,371.015,364.016Z" fill="#cc2e4c"/>
                </g>
            </svg>

        </span>

    )
}
export default PotencyLittleV;

PotencyLittleV.propTypes = {
    className: PropTypes.string,
    fill: PropTypes.string,
  };
  
PotencyLittleV.defaultProps = {
    className: undefined,
    fill: '#333',
};
  
