import React from 'react';
import './style.scss';
import ContainerContent from '../../../../templates/ContainerContent/ContainerContent';
import { TranslatableText } from '../../../../providers/LanguageProvider';
import { LanguageConsumer } from '../../../../providers/LanguageProvider';
import * as ScrollMagic from "scrollmagic"; // Or use scrollmagic-with-ssr to avoid server rendering problems
import { TimelineMax, Linear } from "gsap"; // Also works with TweenLite and TimelineLite
import hot_bottle_lemonene from '../../i/hot_1.png';
import Calendar from '../../../../components/svgIcons/Calendar';
import Cocktail4 from '../../../../components/svgIcons/Cocktail4';
import InfoIcon from '../../../../components/svgIcons/InfoIcon';
//import PotencyLow from '../../../../components/svgIcons/PotencyLow';
import PotencyHouseOt from '../../../../components/svgIcons/PotencyHouseOt';
import Highlights from '../../../../components/svgIcons/Highlights';
import TerpsLinesStatic from '../../i/TerpLinesStatic';
import flowers_2 from '../../i/flowers-2.png';
import NutritionPopUp from '../../../../components/NutritionPopUp/NutritionPopUp';

class LemoneneTonic extends React.Component {
    constructor(props){
        super(props)

        this.controller = new ScrollMagic.Controller();
    }
    componentWillUnmount() {
        this.controller.destroy();
    }
    componentDidMount = () => {
    
        var title = document.getElementById("title-anim-3");
        //var list = document.getElementById("beverage-info-3");
        //var imgWrap = document.getElementById("img-wrap-3");
        
                               
        var timeline2 = new TimelineMax()
        timeline2.to(title, 1, {y:110,/*  textShadow: "1px 1px 1px rgb(204, 46, 76)", */  ease: Linear.easeNone} )
        // timeline.to(list, 1, {y:95,   ease: Linear.easeNone}, 0)
        // timeline2.from(list, 1, {y:95,   ease: Linear.easeNone}, 0)
        //timeline.to(imgWrap, 1, {y:105,   ease: Linear.easeNone}, 0)

        // build scenes
        let scene2 = new ScrollMagic.Scene({triggerElement: ".three", triggerHook: 1, duration: '100%', tweenChanges: true});
            scene2 .setTween(timeline2)
            .on("enter leave", this.addBottleAnimation)
            .on("progress", function (event) {
                //console.log("Scene progress changed to " + event.progress);
            })
            //.addIndicators() // add indicators (requires plugin)
            .addTo(this.controller);

    }

    addBottleAnimation = () => { 
       let bottles =  document.getElementById('img-wrap-3');
       bottles.classList.add('anim');   
    }

    render() {
        return (
            <div className="blockwrap three" id={this.props.bevId}>
                <TerpsLinesStatic />
                <div className="bg-fruits">
                    <img src={flowers_2} className="slice-1" alt="flowers orange" />
                </div>
                <ContainerContent>

                    {
                        this.props.isDesktop ?
                            <div className="img-wrap" id="img-wrap-3">
                                <img src={hot_bottle_lemonene} className="bottle-yellow bottle" alt="House of Terpenes - Lemonene" />
                                <img src={hot_bottle_lemonene} className="bottle-yellow yellow-2 bottle abs" alt="House of Terpenes - Lemonene" />
                            </div>
                        :
                        null
                    }

                    <div className="info-wrap">
                     
                        <h2 id="title-anim-3">
                            <TranslatableText dictionary={{
                                en: "A citrusy sip",
                                fr: "Une gorgée qui sort de" }}>
                            </TranslatableText><br />
                            <TranslatableText dictionary={{
                                en: "of the unfamiliar.",
                                fr: "l’ordinaire." }}>
                            </TranslatableText>
                        </h2>

                        <div className="beverage-info" id="beverage-info-3">

                            <LanguageConsumer>
                                {(context) => (
                                    <React.Fragment>
                                        {context.language !== 'fr' ? 
                                                <h3>House of Terpenes™<br /> Limonene and Sparkling Tonic</h3>
                                            : 
                                                <h3>House of Terpenes™<br /> Tonique pétillant au limonène</h3>
                                        }
                                    </React.Fragment>
                                )}
                            </LanguageConsumer>
                                 
                            <p> 
                                <TranslatableText dictionary={{
                                    en: "Limonene is bright and citrusy with notes",
                                    fr: "Limonène est une boisson aux notes" }}>
                                </TranslatableText><br />
                                <TranslatableText dictionary={{
                                    en: "of lemon, tangerine & thyme. West Coast ",
                                    fr: "de citron, de tangerine et de thym." }}>
                                </TranslatableText><br />
                                <TranslatableText dictionary={{
                                    en: "hop flavours elevate the citrus elements,",
                                    fr: "Les saveurs de houblon sec de la côte" }}>
                                </TranslatableText><br />
                                <TranslatableText dictionary={{
                                    en: "adding a complex botanical finish that is",
                                    fr: "ouest accentuent les arômes d’agrumes" }}>
                                </TranslatableText><br />
                                <TranslatableText dictionary={{
                                    en: "not overly sweet.",
                                    fr: "et ajoutent une finale botanique " }}>
                                </TranslatableText><br />
                                <TranslatableText dictionary={{
                                    en: "",
                                    fr: "complexe qui n’est pas trop sucrée." }}>
                                </TranslatableText>
                            </p>

                            {
                                this.props.isDesktop ?
                                    null
                                :
                                    <div className="img-wrap" id="img-wrap-3">
                                        <img src={hot_bottle_lemonene} className="bottle-yellow bottle" alt="House of Terpenes - Lemonene" />
                                        <img src={hot_bottle_lemonene} className="bottle-yellow yellow-2 bottle abs" alt="House of Terpenes - Lemonene" />
                                    </div>
                            }

                            <ul className="standard-list">
                                <li>
                                    <PotencyHouseOt />
                                    <span>
                                        <h4>
                                            <TranslatableText dictionary={{
                                                en: "POTENCY",
                                                fr: "intensité" }}>
                                            </TranslatableText>
                                        </h4>

                                        <p>
                                            <TranslatableText dictionary={{
                                                en: "1:1 MEDIUM",
                                                fr: "1:1 moyenne" }}>
                                            </TranslatableText><br />
                                            <TranslatableText dictionary={{
                                                en: "5MG THC + 5MG CBD",
                                                fr: "5 mg THC : 5 mg CBD" }}>
                                            </TranslatableText>
                                        </p>

                                    </span>
                                </li>
                                <li>
                                    <Calendar />
                                    <span>
                                        <h4>
                                            <TranslatableText dictionary={{
                                                en: "OCCASIONS",
                                                fr: "OCCASIONS" }}>
                                            </TranslatableText>
                                        </h4>

                                        <p>
                                            <TranslatableText dictionary={{
                                                en: "Kicking off a dinner party.",
                                                fr: "À l’apéro avec des amis" }}>
                                            </TranslatableText><br />
                                            <TranslatableText dictionary={{
                                                en: "Doing things differently.",
                                                fr: "Pour faire les choses différemment." }}>
                                            </TranslatableText><br />
                                            <TranslatableText dictionary={{
                                                en: "Feeling experimental.",
                                                fr: "Quand on se sent aventurier." }}>
                                            </TranslatableText>
                                        </p>
                                    </span>
                                </li>
                                <li>
                                    <Cocktail4 />
                                    <span>
                                        <h4>
                                            <TranslatableText dictionary={{
                                                en: "SERVING SUGGESTION",
                                                fr: "NOTES DE SERVICE" }}>
                                            </TranslatableText>
                                        </h4>

                                        <p>
                                            <TranslatableText dictionary={{
                                                en: "Serve on ice.",
                                                fr: "Servir sur glace." }}>
                                            </TranslatableText><br />
                                            <TranslatableText dictionary={{
                                                en: "Twist of lemon rind & a sprig of thyme.",
                                                fr: "Ajouter de l’écorce de citron et une" }}>
                                            </TranslatableText><br />
                                            <TranslatableText dictionary={{
                                                en: "",
                                                fr: "branche de thym." }}>
                                            </TranslatableText>

                                        </p>
                                    </span>
                                </li>
                                <li>
                                    <Highlights />
                                    <span>
                                        <h4>
                                            <TranslatableText dictionary={{
                                                en: "HIGHLIGHTS",
                                                fr: "INFORMATIONS" }}>
                                            </TranslatableText>
                                        </h4>
                                        <p>
                                            <TranslatableText dictionary={{
                                                en: "All natural flavours. ",
                                                fr: "Saveurs entièrement naturelles." }}>
                                            </TranslatableText><br />
                                            <TranslatableText dictionary={{
                                                en: "Botanically sourced terpene ",
                                                fr: "Saveurs de terpène d’origine " }}>
                                            </TranslatableText> <br />
                                            <TranslatableText dictionary={{
                                                en: "flavours.",
                                                fr: "botanique." }}>
                                            </TranslatableText> 
                                        </p>
                                    </span>
                                </li>
                                <li>
                                    <InfoIcon />
                                    <span>
                                        <h4>
                                            <TranslatableText dictionary={{
                                                en: "NUTRITIONAL INFORMATION",
                                                fr: "INFORMATIONS NUTRITIVE" }}>
                                            </TranslatableText>
                                        </h4>
                                        <div className="paragraph">
                                            <NutritionPopUp beverage="limonene-tonic" />
                                            {/* <button className={`nutrional-info`}>
                                                <TranslatableText dictionary={{
                                                    en: "See information. ",
                                                    fr: "Consulter la valeur nutritive." }}>
                                                </TranslatableText>
                                            </button> */}
                                            
                                        </div>
                                    </span>
                                </li>
                            </ul>

                        </div>

                    </div>

                </ContainerContent>

            </div>
        )
    }
}
export default LemoneneTonic;