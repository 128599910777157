import React from 'react';
import './style.scss';
import Modal from 'react-bootstrap/Modal';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { getLang } from '../../helpers';
import { LanguageConsumer } from '../../providers/LanguageProvider';
import { TranslatableText } from '../../providers/LanguageProvider';
//import SelectAge from './SelectAge/SelectAge';
import Logo from '../Logo/Logo';
import AgeForm from './AgeForm/AgeForm';
import ContainerContent from '../../templates/ContainerContent/ContainerContent';
import imgLogo from '../../assets_global/images/truss-logo.png';
import QuebecMessage from './QuebecMessage/QuebecMessage';

class AgeGate extends React.Component {
    static propTypes = { 
        cookies: instanceOf(Cookies).isRequired
      };
    constructor(props, context) {
        super(props, context);
       // this.validateSubmit = this.validateSubmit.bind(this);
        //this.handleSubmit = this.handleSubmit.bind(this);

        const { cookies } = props;
        // Does AgeGate cookie exist? 
        let hideShow = cookies.get('trussAOM') ? false : true;
       
        //console.log('ageGate props ',props);
        //hideShow = true;
        // If no cookie show AgeGate
        this.state = {
            show: hideShow,
            isValid: false,
            minAge: 17, 
            theLang: getLang(),
            isQuebec: false
        };

        
    }  

    handleShow = () => {
        this.setState({ show: true });
    }

    handleHide = (expires, props) => {
       // console.log('handle hide expires ',expires);
        const { cookies } = this.props;
      
        let expiriyDate = 0;
        if(expires){
            expiriyDate  = new Date();
            expiriyDate.setDate(expiriyDate.getDate() + 30);
        }
        // Set session cookie for AgeGate 
        cookies.set('trussAOM', true, { path: '/', expires: expiriyDate  });
        // Hide AgeGate
        this.setState({ show: false });


        // Call func from Router to set State and show Email Pop Up
       this.props.emailPopUpToggle();
    }

    isQuebec = (bool) => {
        if(bool){
            this.setState((prevState) => {
                return {
                    isQuebec: true
                }
            })
        }
    }

    componentDidMount(){
        //console.log('did mount age gate');
        // Set the Lang State
        this.setState({
            theLang: getLang()
        })
    }
    
    render() {
        return (
            <Modal show={this.state.show} onHide={this.handleHide} className={`age-gate-popup ${this.state.isQuebec ? 'quebec' : null}`} backdrop="static" animation="false" backdropClassName="age-gate">
            <Modal.Header className={this.state.theLang}>
                <ContainerContent>
                    {/* <Link to="/">English</Link>&nbsp;|&nbsp;<Link to="/fr">French</Link> */}
                    {/* <img src={imgLogo} className="img-logo" alt="Truss Beverages" /> */}
                    <Logo />
                    <span className="lang-wrap">
                        <LanguageConsumer>
                            {(context) => (
                                <React.Fragment>
                                    <a onClick={context.updateLanguage} href="/" data-lang="en" className={context.language === 'en' ? 'active': null}>EN</a>&nbsp;|&nbsp;
                                    <a onClick={context.updateLanguage} href="/fr" data-lang="fr" className={context.language === 'fr' ? 'active': null}>FR</a>
                                </React.Fragment>
                            )}
                        </LanguageConsumer>
                    </span>
                    {/* <a onClick={() => this.handleHide()} className="btn-accept-age">I AM OF AGE</a> */}
                </ContainerContent>
            </Modal.Header>
            <Modal.Body>
                <ContainerContent>
                  
                    <QuebecMessage show={this.state.isQuebec} />    
                    { !this.state.isQuebec && <AgeForm handleHide={this.handleHide} handleQuebec={this.isQuebec} lang={this.props.lang}/> }
                   
                    
                </ContainerContent>
            </Modal.Body>
        </Modal>  
        )
    }
}

export default withCookies(AgeGate);