import React from 'react';
import Bubbles from '../Animations/Bubbles/Bubbles';
import LittleVictory from '../Animations/LittleVictory/LittleVictory';
import VeryLines from '../Animations/VeryLines/VeryLines';
import TerpsLines from '../Animations/TerpsLines/TerpsLines';
import XmgLines from '../Animations/XmgLines/XmgLines';
import MolloLines from '../Animations/MolloLines/MolloLines';

class Animations extends React.Component {
    constructor(props){
        super(props)
        //console.log('Anim props ',props);
        this.state = {
            show: false
        }
    }

    componentDidMount = () => {
       
    }

    render() {
        //console.log('currentPage:');
        //console.log(this.props.currentPage)
    
        return (
            <React.Fragment>

                {this.props.currentPage === 'home' && <Bubbles /> } 
                {this.props.currentPage === 'little-victory' && <LittleVictory />}
                {this.props.currentPage === 'veryvell' && <VeryLines /> } 
                {this.props.currentPage === 'house-of-terpenes' && <TerpsLines />}
                {this.props.currentPage === 'xmg' && <XmgLines /> }
                {this.props.currentPage === 'mollo' && <MolloLines />}
                
            </React.Fragment>
        )
    }
}
export default Animations;