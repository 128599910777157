import React from 'react';
import './style.scss';
import ContainerContent from '../../../../templates/ContainerContent/ContainerContent';
import { TranslatableText } from '../../../../providers/LanguageProvider';
import { LanguageConsumer } from '../../../../providers/LanguageProvider';
import * as ScrollMagic from "scrollmagic"; // Or use scrollmagic-with-ssr to avoid server rendering problems
import { TimelineMax, Linear } from "gsap"; // Also works with TweenLite and TimelineLite
import vv_can_blacktea from '../../i/veryvell_3.png';
import Calendar from '../../../../components/svgIcons/Calendar';
import Cocktail2 from '../../../../components/svgIcons/Cocktail2';
//import PotencyLow from '../../../../components/svgIcons/PotencyLow';
import PotencyVvIcedTea from '../../../../components/svgIcons/PotencyVvIcedTea';
import Highlights from '../../../../components/svgIcons/Highlights';
import VerywellLinesStatic from '../../i/VerywellLinesStatic';

class LemonBlackTea extends React.Component {
    constructor(props){
        super(props)
        this.controller = new ScrollMagic.Controller();
    }
    componentWillUnmount() {
        this.controller.destroy();
    }
    componentDidMount = () => {

        var title = document.getElementById("title-anim-4");
        //var list = document.getElementById("beverage-info-4");
        //var imgWrap = document.getElementById("img-wrap-4");
                           
        var timeline = new TimelineMax()
        timeline.to(title, 1, {y:110, /* textShadow: "1px 1px 1px rgb(204, 46, 76)", */  ease: Linear.easeNone} )
        // timeline.to(list, 1, {y:95,   ease: Linear.easeNone}, 0)
        // timeline.from(list, 1, {y:95,   ease: Linear.easeNone}, 0)
        //timeline.to(imgWrap, 1, {y:105,   ease: Linear.easeNone}, 0)

        // build scenes
       new ScrollMagic.Scene({triggerElement: ".four", triggerHook: 1, duration: '100%', tweenChanges: true})
            .setTween(timeline)
            .on("enter leave", this.addBottleAnimation)
            .on("progress", function (event) {
                //console.log("Scene progress changed to " + event.progress);
            })
            //.addIndicators() // add indicators (requires plugin)
            .addTo(this.controller);
    }

    addBottleAnimation = () => { 
        let bottles =  document.getElementById('img-wrap-4');
        bottles.classList.add('anim');   
    }
    render() {
        return (
            <div className="blockwrap four" id={this.props.bevId}>
                <VerywellLinesStatic />
                <ContainerContent>
                    {
                        this.props.isDesktop ?
                            <div className="img-wrap" id="img-wrap-4">
                                <img src={vv_can_blacktea} className="can-purple bottle" alt="Veryvell™ - Lemon Black Tea" />
                                <img src={vv_can_blacktea} className="can-purple purple-2 bottle abs" alt="Veryvell™ - Lemon Black Tea" />
                            </div>
                        :
                        null
                    }
                    <div className="info-wrap">
                        <h2  id="title-anim-4"> 
                            <TranslatableText dictionary={{
                                en: "High tea?",
                                fr: "C’est l’heure du" }}>
                            </TranslatableText><br />
                            <TranslatableText dictionary={{
                                en: "Veryvell then.",
                                fr: "thé? Veryvell." }}>
                            </TranslatableText>
                        </h2>  

                        <div className="beverage-info" id="beverage-info-4">
  
                            <LanguageConsumer>
                                {(context) => (
                                    <React.Fragment>
                                        {context.language !== 'fr' ? 
                                                <h3>Veryvell™<br /> Lemon Black Iced Tea</h3>
                                            : 
                                                <h3>Veryvell™<br /> Thé noir glacé au citron</h3>
                                        }
                                    </React.Fragment>
                                )}
                            </LanguageConsumer>

                            <p>    
                                <TranslatableText dictionary={{
                                    en: "Black iced tea with a subtle hint",
                                    fr: "Du thé noir glacé avec une touche" }}>
                                </TranslatableText><br />
                                <TranslatableText dictionary={{
                                    en: "of lemon flavour to refresh your day.",
                                    fr: "subtile de saveurs citronnées pour" }}>
                                </TranslatableText><br />
                                <TranslatableText dictionary={{
                                    en: "",
                                    fr: "rafraîchir votre journée." }}>
                                </TranslatableText>  
                            </p>

                            {
                                this.props.isDesktop ?
                                    null
                                :
                                    <div className="img-wrap" id="img-wrap-4">
                                        <img src={vv_can_blacktea} className="can-purple bottle" alt="Veryvell™ - Lemon Black Tea" />
                                        <img src={vv_can_blacktea} className="can-purple purple-2 bottle abs" alt="Veryvell™ - Lemon Black Tea" />
                                    </div>
                            }

                            <ul className="standard-list">
                                <li>
                                    <PotencyVvIcedTea />
                                    <span>
                                        <h4>
                                            <TranslatableText dictionary={{
                                                en: "POTENCY",
                                                fr: "intensité" }}>
                                            </TranslatableText>
                                        </h4>
                                        <p>
                                            <TranslatableText dictionary={{
                                                en: "1:1 LOW",
                                                fr: "1:1 modérée" }}>
                                            </TranslatableText><br />
                                            <TranslatableText dictionary={{
                                                en: "2.5MG THC + 2.5MG CBD",
                                                fr: "2,5 mg THC : 2,5 mg CBD" }}>
                                            </TranslatableText>
                                        </p>
                                    </span>
                                </li>
                                <li>
                                    <Calendar />
                                    <span>
                                        <h4>
                                            <TranslatableText dictionary={{
                                                en: "OCCASIONS",
                                                fr: "OCCASIONS" }}>
                                            </TranslatableText> 
                                        </h4>
                                        <p>
                                            <TranslatableText dictionary={{
                                                en: "Cutting back on coffee dates.",
                                                fr: "En terminant une discussion autour d’un café." }}>
                                            </TranslatableText><br />
                                            <TranslatableText dictionary={{
                                                en: "Reading a good book.",
                                                fr: "En lisant un bon livre." }}>
                                            </TranslatableText><br />
                                            <TranslatableText dictionary={{
                                                en: "Backyard hangouts.",
                                                fr: "Entre amis dans la cour arrière." }}>
                                            </TranslatableText>
                                        </p>
                                    </span>
                                </li>
                                <li>
                                    <Cocktail2 />
                                    <span>
                                        <h4>
                                            <TranslatableText dictionary={{
                                                en: "SERVING SUGGESTION",
                                                fr: "NOTES DE SERVICE" }}>
                                            </TranslatableText>
                                        </h4>
                                        <p>
                                            <TranslatableText dictionary={{
                                                en: "Serve on ice. Add a lemon slice.",
                                                fr: "Servir sur glace. Ajouter une tranche de citron." }}>
                                            </TranslatableText>
                                        </p>
                                    </span>
                                </li>
                                <li>
                                   <Highlights />
                                    <span>
                                        <h4>
                                            <TranslatableText dictionary={{
                                                en: "HIGHLIGHTS",
                                                fr: "INFORMATIONS" }}>
                                            </TranslatableText>
                                        </h4>
                                        <p>
                                            <TranslatableText dictionary={{
                                                en: "All natural flavours.",
                                                fr: "Saveurs entièrement naturelles." }}>
                                            </TranslatableText>
                                        </p>
                                    </span>
                                </li>
                            </ul>

                        </div>

                    </div>

                </ContainerContent>

            </div>
        )
    }
}
export default LemonBlackTea;