import React from 'react';
import './style.scss';
import ContainerContent from '../../../../templates/ContainerContent/ContainerContent';
import { TranslatableText } from '../../../../providers/LanguageProvider';
import { LanguageConsumer } from '../../../../providers/LanguageProvider';
//import lv_can_purple from '../../i/can_1.png';
//import lv_can_purple from '../../i/bottle_purple_1.png';
import lv_bottle_purple from '../../i/bottle_purple_1.png';
import * as ScrollMagic from "scrollmagic"; // Or use scrollmagic-with-ssr to avoid server rendering problems
import { TimelineMax, Linear } from "gsap"; // Also works with TweenLite and TimelineLite
import Calendar from '../../../../components/svgIcons/Calendar';
import Cocktail from '../../../../components/svgIcons/Cocktail';
import PotencyLittleV from '../../../../components/svgIcons/PotencyLittleV';
import Highlights from '../../../../components/svgIcons/Highlights';
import InfoIcon from '../../../../components/svgIcons/InfoIcon';
import cherry_1 from '../../i/cherry-1.png';
import cherry_2 from '../../i/cherry-2.png';
import cherry_3 from '../../i/cherry-3.png';
import NutritionPopUp from '../../../../components/NutritionPopUp/NutritionPopUp';

class PurpleCans extends React.Component {
    constructor(props){
        super(props)
        this.controller = new ScrollMagic.Controller();
    }
    componentWillUnmount() {
        this.controller.destroy();
    }
    componentDidMount(){

        var title = document.getElementById("title-anim-4");
       //var list = document.getElementById("beverage-info-4");
        //var imgWrap = document.getElementById("img-wrap-4");
        
                               
        var timeline = new TimelineMax()
        timeline.to(title, 1, {y:110, /* textShadow: "1px 1px 1px rgb(204, 46, 76)", */  ease: Linear.easeNone} )
       // timeline.to(list, 1, {y:95,   ease: Linear.easeNone}, 0)
       // timeline.from(list, 1, {y:95,   ease: Linear.easeNone}, 0)
        //timeline.to(imgWrap, 1, {y:105,   ease: Linear.easeNone}, 0)

        
        // build scenes
       new ScrollMagic.Scene({triggerElement: ".four", triggerHook: 1, duration: '100%', tweenChanges: true})
            .setTween(timeline)
            .on("enter leave", this.addBottleAnimation)
            .on("progress", function (event) {
                //console.log("Scene progress changed to " + event.progress);
            })
            //.addIndicators() // add indicators (requires plugin)
            .addTo(this.controller);
    }

    addBottleAnimation = () => { 
        let bottles =  document.getElementById('img-wrap-4');
        bottles.classList.add('anim');   
    }

    render() {
        return (
            <div className="blockwrap four" id={this.props.bevId}>

                <div className="bg-fruits">
                    <img src={cherry_1} className="slice-1" alt="cherry half" />
                    <img src={cherry_2} className="slice-2" alt="cherry slices" />
                </div>

                <ContainerContent>
                    
                    {
                        this.props.isDesktop ?
                            <div className="img-wrap" id="img-wrap-4">
                                <img src={lv_bottle_purple} className="can-purple bottle" alt="Little Victory™ - Sparkling Dark Cherry" />
                                <img src={lv_bottle_purple} className="can-purple purple-2 bottle abs" alt="Little Victory™ - Sparkling Dark Cherry" />
                                <img src={cherry_3} className="slice-3" alt="cherry slices" />
                            </div>
                        :
                        null
                    }
                    
                    <div className="info-wrap">
                        <LanguageConsumer>
                                {(context) => (
                                    <React.Fragment>
                                        {context.language !== 'fr' ? 
                                                <h2 id="title-anim-4">Cheers to that<br /> twenty you found in<br /> your winter coat.</h2>
                                            : 
                                                <h2 id="title-anim-4">Un toast au 20 $ trouvé<br /> dans les poches de<br />votre manteau d’hiver.</h2>
                                                
                                        }
                                    </React.Fragment>
                                )}
                        </LanguageConsumer>
                        
                        <div className="beverage-info" id="beverage-info-4">
                           
                            <LanguageConsumer>
                                {(context) => (
                                    <React.Fragment>
                                        {context.language !== 'fr' ? 
                                                <h3>Little Victory™<br /> Sparkling Dark Cherry</h3>
                                            : 
                                                <h3>Little Victory™<br /> Cerise noire pétillante </h3>
                                        }
                                    </React.Fragment>
                                )}
                            </LanguageConsumer>

                            <p> 
                                <TranslatableText dictionary={{
                                    en: "Cherry pick your moments with this",
                                    fr: "Cette boisson intense aux saveurs" }}>
                                </TranslatableText><br />
                                <TranslatableText dictionary={{
                                    en: "vibrant, naturally flavoured beverage.",
                                    fr: "naturelles, c’est la cerise sur le gâteau." }}>
                                </TranslatableText> 
                            </p>

                            {
                                this.props.isDesktop ?
                                    null
                                :
                                    <div className="img-wrap" id="img-wrap-4">
                                        <img src={lv_bottle_purple} className="can-purple bottle" alt="Little Victory™ - Sparkling Dark Cherry" />
                                        <img src={lv_bottle_purple} className="can-purple purple-2 bottle abs" alt="Little Victory™ - Sparkling Dark Cherry" />
                                        <img src={cherry_3} className="slice-3" alt="cherry slices" />
                                    </div>
                            }

                            <ul className="standard-list">
                                <li>
                                    <PotencyLittleV />
                                    <span>
                                        <h4>
                                            <TranslatableText dictionary={{
                                                en: "POTENCY",
                                                fr: "intensité" }}>
                                            </TranslatableText>
                                        </h4>
                                        <p>
                                            <TranslatableText dictionary={{
                                                en: "1:1 LOW",
                                                fr: "1:1 faible" }}>
                                            </TranslatableText><br />
                                            <TranslatableText dictionary={{
                                                en: "2.5MG THC + 2.5MG CBD",
                                                fr: "2,5 mg THC : 2,5 mg CBD" }}>
                                            </TranslatableText>
                                        </p>
                                    </span>
                                </li>
                                <li>
                                   <Calendar />
                                    <span>
                                        <h4>
                                            <TranslatableText dictionary={{
                                                en: "OCCASIONS",
                                                fr: "OCCASIONS" }}>
                                            </TranslatableText> 
                                        </h4>
                                        <p>
                                            <TranslatableText dictionary={{
                                                en: "Pulling off bedhead.",
                                                fr: "En regardant disparaître les marques d’oreiller." }}>
                                            </TranslatableText><br />
                                            <TranslatableText dictionary={{
                                                en: "Not checking your ex’s profile.",
                                                fr: "En n’allant pas voir le profil de votre ex." }}>
                                            </TranslatableText><br />
                                            <TranslatableText dictionary={{
                                                en: "Crushing it.",
                                                fr: "En cassant la baraque." }}>
                                            </TranslatableText>  
                                        </p>
                                    </span>
                                </li>
                                <li>
                                    <Cocktail />
                                    <span>
                                        <h4>
                                            <TranslatableText dictionary={{
                                                en: "SERVING SUGGESTION",
                                                fr: "NOTES DE SERVICE" }}>
                                            </TranslatableText>
                                        </h4>
                                        <p>
                                            <TranslatableText dictionary={{
                                                en: "Serve over ice.",
                                                fr: "Servir sur glace." }}>
                                            </TranslatableText><br />
                                            <TranslatableText dictionary={{
                                                en: "Cherry garnish.",
                                                fr: "Ajouter une cerise." }}>
                                            </TranslatableText>
                                        </p>
                                    </span>
                                </li>
                                <li>
                                    <Highlights />
                                    <span>
                                        <h4>
                                            <TranslatableText dictionary={{
                                                en: "HIGHLIGHTS",
                                                fr: "INFORMATIONS" }}>
                                            </TranslatableText>
                                        </h4>
                                        <p>
                                            {/* <TranslatableText dictionary={{
                                                en: "35 calories and lightly sweetened with cane sugar.",
                                                fr: "30 calories et légèrement sucrée avec du sucre de canne." }}>
                                            </TranslatableText><br className="desktop" /> */}
                                            <TranslatableText dictionary={{
                                                en: "Made with 5% real cherry juice from concentrate.",
                                                fr: "Faite avec 5 % de vrai jus de cerise à base de concentré." }}>
                                            </TranslatableText>
                                        </p>
                                    </span>
                                </li>
                                <li>
                                    <InfoIcon />
                                    <span>
                                        <h4>
                                            <TranslatableText dictionary={{
                                                en: "NUTRITIONAL INFORMATION",
                                                fr: "INFORMATIONS NUTRITIVE" }}>
                                            </TranslatableText>
                                        </h4>
                                        <div className="paragraph">
                                            <NutritionPopUp beverage="dark-cherry" />
                                        </div>
                                    </span>
                                </li>
                            </ul>
                        </div> 
                    </div>
                </ContainerContent>
            </div>

        )
    }
}
export default PurpleCans;