import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const PotencyMollo5 = (className, fill) => {
    
    return(

        <span className="icon">

            {/* <svg xmlns="http://www.w3.org/2000/svg" width="50.367" height="50.367" viewBox="0 0 50.367 50.367">
                <g className="icon-chart-low" data-name="Group 427" transform="translate(1 1)">
                    <g id="Group_426" data-name="Group 426" opacity="0.3">
                    <g id="Group_425" data-name="Group 425">
                        <circle id="Ellipse_369" data-name="Ellipse 369" cx="16.854" cy="16.854" r="16.854" transform="translate(7.33 7.33)" fill="none" strokeMiterlimit="10" strokeWidth="2"/>
                        <circle id="Ellipse_370" data-name="Ellipse 370" cx="24.183" cy="24.183" r="24.183" fill="none"  strokeMiterlimit="10" strokeWidth="2"/>
                    </g>
                    </g>
                    <path id="Path_627" data-name="Path 627" d="M620.447,518.765a16.851,16.851,0,0,0-16.854-16.851" transform="translate(-579.411 -494.583)" fill="none"  strokeMiterlimit="10" strokeWidth="2"/>
                    <path id="Path_628" data-name="Path 628" d="M627.778,510.265a24.182,24.182,0,0,0-24.183-24.181" transform="translate(-579.412 -486.082)" fill="none"  strokeMiterlimit="10" strokeWidth="2"/>
                </g>
            </svg> */}

            <svg xmlns="http://www.w3.org/2000/svg" width="108.889" height="108.89" viewBox="0 0 108.889 108.89">
                <g id="Group_834" data-name="Group 834" transform="translate(-888.003 -346.659)">
                    <g id="Group_783" data-name="Group 783" opacity="0.3">
                    <path id="Path_713" data-name="Path 713" d="M985.27,425.809a49.433,49.433,0,1,1-85.62-49.433,48.307,48.307,0,0,1,42.809-24.717v-5a53.194,53.194,0,0,0-47.139,27.217,54.471,54.471,0,0,0,47.051,81.673,54.717,54.717,0,0,0,14.177-1.879A54.078,54.078,0,0,0,989.6,428.309Z" fill="#d5ac65"/>
                    <path id="Path_714" data-name="Path 714" d="M913.546,384.4a32.628,32.628,0,0,1,28.912-16.693v-5A37.513,37.513,0,0,0,909.216,381.9,38.385,38.385,0,1,0,975.7,420.286l-4.33-2.5A33.385,33.385,0,1,1,913.546,384.4Z" fill="#d5ac65"/>
                    </g>
                    <g id="Group_784" data-name="Group 784">
                    <path id="Path_715" data-name="Path 715" d="M942.459,346.659v5a49.49,49.49,0,0,1,49.433,49.433,46.919,46.919,0,0,1-6.622,24.717l4.33,2.5a52.358,52.358,0,0,0,7.292-27.217A54.494,54.494,0,0,0,942.459,346.659Z" fill="#d5ac65"/>
                    <path id="Path_716" data-name="Path 716" d="M942.458,362.708v5a33.424,33.424,0,0,1,33.385,33.386,31.689,31.689,0,0,1-4.473,16.692l4.33,2.5a36.909,36.909,0,0,0,5.143-19.192A38.429,38.429,0,0,0,942.458,362.708Z" fill="#d5ac65"/>
                    </g>
                </g>
            </svg>



        </span>

    )
}
export default PotencyMollo5;

PotencyMollo5.propTypes = {
    className: PropTypes.string,
    fill: PropTypes.string,
  };
  
PotencyMollo5.defaultProps = {
    className: undefined,
    fill: '#333',
};
  