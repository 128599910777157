import React from 'react';
import './style.scss';
import ContainerContent from '../../../../templates/ContainerContent/ContainerContent';
import { TranslatableText } from '../../../../providers/LanguageProvider';
import { LanguageConsumer } from '../../../../providers/LanguageProvider';
import * as ScrollMagic from "scrollmagic"; // Or use scrollmagic-with-ssr to avoid server rendering problems
import { TimelineMax, Linear } from "gsap"; // Also works with TweenLite and TimelineLite
import lv_bottle_yellow from '../../i/bottle_2.png';
import Calendar from '../../../../components/svgIcons/Calendar';
import RocksGlass from '../../../../components/svgIcons/RocksGlass';
//import PotencyLow from '../../../../components/svgIcons/PotencyLow';
import PotencyLittleV from '../../../../components/svgIcons/PotencyLittleV';
import Highlights from '../../../../components/svgIcons/Highlights';
import InfoIcon from '../../../../components/svgIcons/InfoIcon';
import lemon_1 from '../../i/lemon-slice-1.png';
import lemon_2 from '../../i/lemon-slice-2.png';
import lemon_3 from '../../i/lemon-slice-3.png';
import NutritionPopUp from '../../../../components/NutritionPopUp/NutritionPopUp';

// Including the following more than 1 time in a project throws weird ScrollMagic errors.  
// I have the following line declared in PinkBottles the first "floor"
//ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

class YellowBottles extends React.Component {
    constructor(props){
        super(props)

        this.controller2 = new ScrollMagic.Controller();
    }
    componentWillUnmount() {
        this.controller2.destroy();
    }
    componentDidMount() {
    
        var title = document.getElementById("title-anim-3");
        //var list = document.getElementById("beverage-info-3");
        //var imgWrap = document.getElementById("img-wrap-3");
                         
        var timeline2 = new TimelineMax()
        timeline2.to(title, 1, {y:110,/*  textShadow: "1px 1px 1px rgb(204, 46, 76)", */  ease: Linear.easeNone} )
        // timeline.to(list, 1, {y:95,   ease: Linear.easeNone}, 0)
        // timeline2.from(list, 1, {y:95,   ease: Linear.easeNone}, 0)
        //timeline.to(imgWrap, 1, {y:105,   ease: Linear.easeNone}, 0)

        // build scenes
        let scene2 = new ScrollMagic.Scene({triggerElement: ".three", triggerHook: 1, duration: '100%', tweenChanges: true});
            scene2 .setTween(timeline2)
            .on("enter leave", this.addBottleAnimation)
            .on("progress", function (event) {
                //console.log("Scene progress changed to " + event.progress);
            })
            //.addIndicators() // add indicators (requires plugin)
            .addTo(this.controller2);

    }

    addBottleAnimation = () => { 
       let bottles =  document.getElementById('img-wrap-3');
       bottles.classList.add('anim');   
    }

    render() {
        return (
            <div className="blockwrap three" id={this.props.bevId}>

                <div className="bg-fruits">
                    <img src={lemon_1} className="slice-1" alt="lemon half" />
                    <img src={lemon_2} className="slice-2" alt="lemon slices" />
                </div>

                <ContainerContent>
                    
                    {
                        this.props.isDesktop ?
                            <div className="img-wrap" id="img-wrap-3">
                                <img src={lv_bottle_yellow} className="bottle-yellow bottle" alt="Little Victory™ - Dry Lemon" />
                                <img src={lv_bottle_yellow} className="bottle-yellow yellow-2 bottle abs"  alt="Little Victory™ - Dry Lemon" />
                                <img src={lemon_3} className="slice-3" alt="lemon wedge" />
                            </div>
                        :
                        null
                    }
                   
                    <div className="info-wrap">

                        <LanguageConsumer>
                            {(context) => (
                                <React.Fragment>
                                    {context.language !== 'fr' ? 
                                            <h2 id="title-anim-3">Now, we can cheers<br /> to taking the stairs.</h2>
                                        : 
                                            <h2 id="title-anim-3">On peut maintenant <br />porter un toast quand on <br />décide de prendre les<br /> escaliers.</h2>
                                            
                                    }
                                </React.Fragment>
                            )}
                        </LanguageConsumer>
                        
                        <div className="beverage-info" id="beverage-info-3">
                            <LanguageConsumer>
                                {(context) => (
                                    <React.Fragment>
                                        {context.language !== 'fr' ? 
                                                <h3>Little Victory™<br /> Dry Lemon</h3>
                                            : 
                                                <h3>Little Victory™<br />Boisson sec au citron</h3>
                                        }
                                    </React.Fragment>
                                )}
                            </LanguageConsumer>
                            
                         
                            <p>
                                <TranslatableText dictionary={{
                                    en: "Take a citrusy slice out of life with this ",
                                    fr: "Avec cette bouteille de bulles aux " }}>
                                </TranslatableText><br />
                                <TranslatableText dictionary={{
                                    en: "dry lemon flavoured, bubbly beverage.",
                                    fr: "saveurs naturelles de citron sec," }}>
                                </TranslatableText><br />
                                <TranslatableText dictionary={{
                                    en: "",
                                    fr: "vous aurez une tranche de vie bien" }}>
                                </TranslatableText><br />
                                <TranslatableText dictionary={{
                                    en: "",
                                    fr: "juteuse à raconter." }}>
                                </TranslatableText>
                            </p>
                
                            {
                                this.props.isDesktop ?
                                    null
                                :
                                    <div className="img-wrap" id="img-wrap-3">
                                        <img src={lv_bottle_yellow} className="bottle-yellow bottle" alt="Little Victory™ - Dry Lemon" />
                                        <img src={lv_bottle_yellow} className="bottle-yellow yellow-2 bottle abs" alt="Little Victory™ - Dry Lemon" />
                                        <img src={lemon_3} className="slice-3" alt="lemon wedge" />
                                    </div>
                            }

                            <ul className="standard-list">
                                <li>
                                    <PotencyLittleV />
                                    <span>
                                        <h4>
                                            <TranslatableText dictionary={{
                                                en: "POTENCY",
                                                fr: "intensité" }}>
                                            </TranslatableText>
                                        </h4>
                                        <p> 
                                            <TranslatableText dictionary={{
                                                en: "1:1 LOW",
                                                fr: "1:1 faible" }}>
                                            </TranslatableText><br /> 
                                            <TranslatableText dictionary={{
                                                en: "2.5MG THC + 2.5MG CBD",
                                                fr: "2,5 mg THC : 2,5 mg CBD" }}>
                                            </TranslatableText>
                                        </p>
                                    </span>
                                </li>
                                <li>
                                    <Calendar />
                                    <span>
                                        <h4>
                                            <TranslatableText dictionary={{
                                                en: "OCCASIONS",
                                                fr: "OCCASIONS" }}>
                                            </TranslatableText>
                                        </h4>
                                        <p>
                                            <TranslatableText dictionary={{
                                                en: "Resisting texting back.",
                                                fr: "En résistant à l’envie de répondre à un texto." }}>
                                            </TranslatableText><br />
                                            <TranslatableText dictionary={{
                                                en: "Feeling yourself.",
                                                fr: "Tsé, quand la journée va bien." }}>
                                            </TranslatableText><br />
                                            <TranslatableText dictionary={{
                                                en: "Having an EXTRA day.",
                                                fr: "Quand on décide de se payer la traite." }}>
                                            </TranslatableText>
                                        </p>
                                    </span>
                                </li>
                                <li>
                                    <RocksGlass />
                                    <span>
                                        <h4>
                                            <TranslatableText dictionary={{
                                                en: "SERVING SUGGESTION",
                                                fr: "NOTES DE SERVICE" }}>
                                            </TranslatableText>
                                        </h4>
                                        <p> 
                                            <TranslatableText dictionary={{
                                                en: "Serve over ice. ",
                                                fr: "Servir sur glace." }}>
                                            </TranslatableText><br /> 
                                            <TranslatableText dictionary={{
                                                en: "Add a slice of lemon.",
                                                fr: "Ajouter une tranche de citron." }}>
                                            </TranslatableText>
                                        </p>
                                    </span>
                                </li>
                                <li>
                                    <Highlights />
                                    <span>
                                        <h4>
                                            <TranslatableText dictionary={{
                                                en: "HIGHLIGHTS",
                                                fr: "INFORMATIONS" }}>
                                            </TranslatableText>
                                        </h4>
                                        <p> 
                                            <TranslatableText dictionary={{
                                                en: "All natural flavours. ",
                                                fr: "Saveurs entièrement naturelles." }}>
                                            </TranslatableText>
                                        </p>
                                    </span>
                                </li>
                                <li>
                                    <InfoIcon />
                                    <span>
                                        <h4>
                                            <TranslatableText dictionary={{
                                                en: "NUTRITIONAL INFORMATION",
                                                fr: "INFORMATIONS NUTRITIVE" }}>
                                            </TranslatableText>
                                        </h4>
                                        <div className="paragraph">
                                            <NutritionPopUp beverage="dry-lemon" />
                                        </div>
                                    </span>
                                </li>
                            </ul>
                        </div> 
                    </div>

                </ContainerContent>
            </div>
        )
    }
}
export default YellowBottles;