import React from 'react';
import './style.scss';
import ContainerContent from '../../templates/ContainerContent/ContainerContent';
import { TranslatableText } from '../../providers/LanguageProvider';
import Footer from '../../components/Footer/Footer';
import imgAbout1 from './i/about-truss-1.png';
import imgAbout2 from './i/about-truss-2.png';
import imgAbout3 from './i/about-truss-3.png';
import imgAbout4 from './i/about-truss-4.png';
//import imgAbout5 from './i/about-truss-5.png';

const About = (props) => (

    <div className={`page-wrap`}>
        <div className="blockwrap first">
            <ContainerContent>
                <h1>
                    <TranslatableText dictionary={{
                        en: "Cannabis",
                        fr: "Des boissons" }}>
                    </TranslatableText><br />
                    <TranslatableText dictionary={{
                        en: "beverages.",
                        fr: "au cannabis," }}>
                    </TranslatableText><br />
                    <TranslatableText dictionary={{
                        en: "That and only",
                        fr: "c’est juste ça" }}>
                    </TranslatableText><br />
                    <TranslatableText dictionary={{
                        en: "that, is our thing.",
                        fr: "qu’on fait…" }}>
                    </TranslatableText><br />
                    <TranslatableText dictionary={{
                        en: "",
                        fr: "et on adore notre job." }}>
                    </TranslatableText>
                </h1>  
            </ContainerContent>
        </div>
        
        <div className="blockwrap about-information">
            <ContainerContent>
                <div className="img-wrap">
                   <img src={imgAbout1} alt="orange slices" />
                </div>
                <div className="info-wrap">
                    <h3>
                        <TranslatableText dictionary={{
                            en: "We Do One Thing",
                            fr: "On fait une chose et" }}>
                        </TranslatableText><br />
                        <TranslatableText dictionary={{
                            en: "and We Do it Well.",
                            fr: "on la fait bien." }}>
                        </TranslatableText>
                    </h3>
                    <p> 
                    <TranslatableText dictionary={{
                            en: "Hey, we’re Truss Beverage Co.™",
                            fr: "Allô. On se présente, Truss Beverage Co.🅪" }}>
                        </TranslatableText><br /><br />
                        <TranslatableText dictionary={{
                            en: "We’re not a cannabis company. We’re a beverage company.",
                            fr: "On n’est pas des producteurs de cannabis, on est des producteurs de boissons au cannabis." }}>
                        </TranslatableText><br /><br />
                        <TranslatableText dictionary={{
                            en: "We’re crafting a new space at the intersection of the beverage world and the cannabis frontier.",
                            fr: "On est en train de créer un nouvel espace à la frontière des boissons et du cannabis." }}>
                        </TranslatableText><br /><br />
                        <TranslatableText dictionary={{
                            en: "Deep down, we’re craftspeople, dedicated to creating a whole new world of drinks as diverse as the population that will enjoy them. We’ve put a lot of thought into our products and believe our beverages are a great way to explore cannabis. Our meticulous attention to detail leads us every day to wonder; and then, we bottle it.",
                            fr: "Plus précisément, on est des artisans qui veulent créer un tout nouveau monde en matière de boissons. Un monde aussi diversifié que la population qui va les savourer. On a vraiment pensé à nos produits et on croit que nos boissons sont parfaites pour explorer le cannabis. Notre attention particulière aux détails nous permet de créer des merveilles, et ensuite, de les embouteiller." }}>
                        </TranslatableText>
                    </p>
                </div>
            </ContainerContent>                        
        </div>

        <div className="blockwrap about-information-flip">
            <ContainerContent>
                <div className="img-wrap">
                   <img src={imgAbout2} alt="Lime slices" />
                </div>
                <div className="info-wrap">
                    <h3>
                        <TranslatableText dictionary={{
                            en: "Bottling Wonder in Belleville.",
                            fr: "Embouteiller de petites" }}>
                        </TranslatableText><br />
                        <TranslatableText dictionary={{
                            en: "",
                            fr: "merveilles à Belleville." }}>
                        </TranslatableText>
                    </h3>
                    <p> 
                        <TranslatableText dictionary={{
                            en: "Laying our roots down in the town of Belleville, Ontario, we are proudly reclaiming and reenergizing 183,600 square feet of once-abandoned space to craft the world’s best cannabis beverages. Supported by our partners at Molson Coors and HEXO, our dynamic bottling plant is filled with a team of mixologists who dedicate themselves to dream up and craft wonderful beverages. ",
                            fr: "On a décidé de s’établir à Belleville, en Ontario, là où on a fièrement redonné vie à 183 600 pieds carrés d’espace abandonné en créant les meilleures boissons au cannabis au monde. Soutenue par nos partenaires MolsonCoors et HEXO, notre dynamique usine d’embouteillage est remplie de mixologues qui rêvent grand et qui veulent concevoir de vraies petites merveilles en bouteilles (et en canettes)." }}>
                        </TranslatableText>
                    </p>
                </div>
            </ContainerContent>                        
        </div>

        <div className="blockwrap about-information">
            <ContainerContent>
                <div className="img-wrap">
                   <img src={imgAbout3} alt="Tricomes close up" />
                </div>
                <div className="info-wrap">
                    <h3>
                        <TranslatableText dictionary={{
                            en: "A Predictable Experience.",
                            fr: "Une expérience prévisible." }}>
                        </TranslatableText><br />
                        <TranslatableText dictionary={{
                            en: "And That’s How We Like It.",
                            fr: "C’est comme ça qu’on" }}>
                        </TranslatableText><br />
                        <TranslatableText dictionary={{
                            en: "",
                            fr: "aime ça." }}>
                        </TranslatableText>
                    </h3>
                    <p> 
                        <TranslatableText dictionary={{
                            en: "We believe the cannabis experience should be consistent. Always. In other words, people should know what to expect. The risks of overconsumption due to wait times in the typical edible experience just does not work for us. So, we do things a little bit differently, breaking down our cannabis into tiny drops that are water soluble and absorbed by the body more efficiently. This way, the effects may be felt faster than a typical edibles experience (as little as 15-20 minutes*). As people explore the cannabis beverage space, we believe that there is a way to provide a more controlled and predictable experience. Please sip responsibly.",
                            fr: "Chez Truss, on croit que l’expérience du cannabis devrait être constante. Toujours. En d’autres mots, les gens devraient savoir à quoi s’attendre. Les risques de surconsommation de produits de cannabis comestibles dus au temps d’attente, on n’en veut pas de ça. C’est pourquoi on fait les choses différemment en décomposant notre cannabis en fines gouttes qui sont solubles dans l’eau et absorbées par le corps plus efficacement. Comme ça, les effets sont ressentis plus rapidement qu’avec les autres formes de cannabis comestibles (soit de 15 à 20 minutes*). Alors que les gens explorent le monde des boissons à base de cannabis, on croit qu’il y a une façon de leur offrir une expérience qu’ils pourront plus contrôler et prévoir. Veillez siroter avec modération." }}>
                        </TranslatableText>
                    </p>
                    <p className="legal">
                        <TranslatableText dictionary={{
                            en: "*Reported effects and timing are anecdotal and can vary by person. (e.g. up to 2 hours). Refer to Health Canada’s website for guidance.",
                            fr: "*Les effets rapportés et le temps estimé sont à titre indicatif et peuvent varier selon les personnes (p. ex. jusqu’à 2 heures). Pour plus d’information, veuillez vous référer au site web de Santé Canada." }}>
                        </TranslatableText>
                    </p>
                </div>
            </ContainerContent>                        
        </div>
       
        <div className="blockwrap about-information-flip brandline">
            <ContainerContent>
                <div className="img-wrap">
                   <img src={imgAbout4} alt="cannabis facility" />
                </div>
                <div className="info-wrap">
                    <h3>
                        <TranslatableText dictionary={{
                            en: "Taste Above Everything",
                            fr: "Le goût avant tout." }}>
                        </TranslatableText>
                    </h3>
                    <p> 
                        <TranslatableText dictionary={{
                            en: "Have you ever heard someone describe cannabis as delicious? Well, that’s what we’re after every day. Taste matters to us. And as beverage specialists we think the cannabis beverage experience should taste great.",
                            fr: "Avez-vous déjà entendu dire que le goût du cannabis était vraiment bon? Eh bien nous, on travaille là-dessus à tous les jours. Le goût est important pour nous. On est des spécialistes en matière de boissons et on pense que celles à base de cannabis doivent avoir bon goût." }}>
                        </TranslatableText><br /><br />
                        <TranslatableText dictionary={{
                            en: "The drinks we make are bright in flavour, with surprising hints of naturally sourced botanicals and fruit flavours. Everyone has their own tastes and we know taste is personal, which is why everything we make, we make delicious.",
                            fr: "Nos boissons sont pleines de saveurs avec des notes naturelles d’origine botanique et des saveurs de fruits. On sait que le goût est quelque chose de très personnel, et c’est pourquoi tout ce qu’on fait, on le fait avec goût." }}>
                        </TranslatableText>
                    </p>
                    
                </div>
            </ContainerContent>                        
        </div>
        <Footer />


        {/* <div className="blockwrap about-information">
            <ContainerContent>
                <div className="img-wrap">
                   <img src={imgAbout5} alt="water" />
                </div>
                <div className="info-wrap">
                    <h3>
                        <TranslatableText dictionary={{
                            en: "Cannabis beverages.That and only that, is our thing.",
                            fr: "FR Cannabis beverages.That and only that, is our thing." }}>
                        </TranslatableText>
                    </h3>
                    <p> 
                        <TranslatableText dictionary={{
                            en: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi utodo consequat. Lorem ipsum dolor sit amet.",
                            fr: "FR Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi utodo consequat. Lorem ipsum dolor sit amet." }}>
                        </TranslatableText>
                    </p>
                </div>
            </ContainerContent>                        
        </div> */}

    </div>

)
export default About;