import React from 'react';
import './style.scss';
import { NavLink } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import { LanguageConsumer } from '../../providers/LanguageProvider';
import ContainerContent from '../../templates/ContainerContent/ContainerContent';

const NavIndicators = (props) => {
    //console.log('nav ind props ',props);
    return(
        <LanguageConsumer>
            {(context) => (
                <div className={`nav-indicators-wrap ${ props.show ? 'hide' : ''} ${props.currentPage}`}>
                    <ContainerContent>
                        <div className="nav-indicators">
                            <ul>
                                <li>
                                    <NavLink  exact to={`${context.language == 'fr' ? '/fr' : '/'}`}></NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${context.language == 'fr' ? '/fr' : ''}/house-of-terpenes`}></NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${context.language == 'fr' ? '/fr' : ''}/little-victory`}></NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${context.language == 'fr' ? '/fr' : ''}/mollo`}></NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${context.language == 'fr' ? '/fr' : ''}/veryvell`}></NavLink>
                                </li>
                                <li>
                                    <NavLink to={`${context.language == 'fr' ? '/fr' : ''}/xmg`}></NavLink>
                                </li>
                                {/* <li>
                                    <NavLink to="/flow-glow"></NavLink>
                                </li> */}
                            </ul>
                        </div>
        
                        <div className="nav-arrows">
                            <NavLink to={props.pageLinks.prevPage} className={`left ${props.currentPage}`}><i className="la la-chevron-circle-left"></i></NavLink>
                            <NavLink to={props.pageLinks.nextPage} className={`right ${props.currentPage}`}><i className="la la-chevron-circle-right"></i></NavLink>
                        </div>
                    </ContainerContent>
                    {/* <Footer /> */}
                </div>
            )}
        </LanguageConsumer>
        

    )
}
export default NavIndicators;