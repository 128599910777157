import React from 'react';

const Highlights = (props) => {
    
    return(

        <span className="icon">

            <svg className="icon-highlights" data-name="Group 555" xmlns="http://www.w3.org/2000/svg" width="46.676" height="46.67" viewBox="0 0 46.676 46.67">
                <g id="Group_554" data-name="Group 554">
                    <path id="Path_647" data-name="Path 647" d="M1591.027,937.288a8.834,8.834,0,1,0,4.478,11.664,8.438,8.438,0,0,0,.764-3.593A8.621,8.621,0,0,0,1591.027,937.288Zm2.7,10.874a6.893,6.893,0,1,1-3.493-9.1,6.726,6.726,0,0,1,4.089,6.3A6.575,6.575,0,0,1,1593.73,948.162Z" transform="translate(-1549.592 -922.022)" />
                    <path id="Path_648" data-name="Path 648" d="M1520.838,948.952a8.438,8.438,0,0,0,.764-3.593,8.621,8.621,0,0,0-5.242-8.071,8.834,8.834,0,1,0,4.478,11.664Zm-10.874,2.7a6.892,6.892,0,1,1,5.607-12.593,6.726,6.726,0,0,1,4.089,6.3,6.575,6.575,0,0,1-.6,2.8A6.9,6.9,0,0,1,1509.963,951.655Z" transform="translate(-1503.927 -922.022)"/>
                    <path id="Path_649" data-name="Path 649" d="M1546.5,916.1a8.834,8.834,0,1,0,7.187-16.141,8.428,8.428,0,0,0-3.593-.764,8.622,8.622,0,0,0-8.071,5.241A8.836,8.836,0,0,0,1546.5,916.1Zm-2.7-10.874a6.726,6.726,0,0,1,6.3-4.089,6.574,6.574,0,0,1,2.8.6,6.892,6.892,0,1,1-9.1,3.493Z" transform="translate(-1526.758 -899.196)" />
                    <path id="Path_650" data-name="Path 650" d="M1553.691,974.626l-.395.887.394-.887a8.431,8.431,0,0,0-3.593-.764,8.622,8.622,0,0,0-8.071,5.241,8.834,8.834,0,1,0,11.664-4.478Zm2.7,10.874a6.892,6.892,0,1,1-12.592-5.607,6.726,6.726,0,0,1,6.3-4.089,6.584,6.584,0,0,1,2.8.6h0A6.9,6.9,0,0,1,1556.394,985.5Z" transform="translate(-1526.758 -944.86)" />
                </g>
                <g id="Group_555-2" data-name="Group 555" transform="translate(4.935 4.932)">
                    <path id="Path_651" data-name="Path 651" d="M1596.174,952.16v-2.932h-1.942v2.932H1591.3V954.1h2.932v2.932h1.942V954.1h2.932V952.16Z" transform="translate(-1562.298 -934.727)" />
                    <path id="Path_652" data-name="Path 652" d="M1521.508,949.228h-1.942v2.932h-2.932V954.1h2.932v2.932h1.942V954.1h2.932V952.16h-2.932Z" transform="translate(-1516.634 -934.727)" />
                    <path id="Path_653" data-name="Path 653" d="M1558.841,911.894H1556.9v2.932h-2.932v1.942h2.932V919.7h1.942v-2.932h2.932v-1.942h-2.932Z" transform="translate(-1539.466 -911.894)"/>
                    <path id="Path_654" data-name="Path 654" d="M1558.841,986.561H1556.9v2.932h-2.932v1.942h2.932v2.932h1.942v-2.932h2.932v-1.942h-2.932Z" transform="translate(-1539.466 -957.559)"/>
                </g>
            </svg>

        </span>

    )
}
export default Highlights;