import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const Cocktail2 = (className, fill) => {
    
    return(

        <span className="icon">

            <svg className="icon-cocktail" id="Group_774" data-name="Group 774" xmlns="http://www.w3.org/2000/svg" width="37.239" height="55.498" viewBox="0 0 37.239 55.498">
                <g  id="Group_773" data-name="Group 773" transform="translate(2.364 14.994)" opacity="0.25">
                    <path id="Path_855" data-name="Path 855" d="M931.513,486.263v-1.971a3.326,3.326,0,0,0-3.322-3.321h-5.92a3.325,3.325,0,0,0-3.321,3.321v1.971h-5.578v2.357h5.578v1.593a3.325,3.325,0,0,0,3.321,3.321h5.92a3.325,3.325,0,0,0,3.322-3.321V488.62h9.207v-2.357Zm-2.357,0h-5.008v2.357h5.008v1.593a.966.966,0,0,1-.965.965h-5.92a.966.966,0,0,1-.965-.965v-5.92a.966.966,0,0,1,.965-.965h5.92a.966.966,0,0,1,.965.965Z" transform="translate(-913.372 -480.971)" />
                    <path id="Path_856" data-name="Path 856" d="M944.375,529.592h-6.643a2.963,2.963,0,0,0-2.96,2.96v6.643a2.963,2.963,0,0,0,2.96,2.96h6.643a2.964,2.964,0,0,0,2.96-2.96v-6.643A2.963,2.963,0,0,0,944.375,529.592Zm.6,9.6a.6.6,0,0,1-.6.6h-6.643a.6.6,0,0,1-.6-.6v-6.643a.6.6,0,0,1,.6-.6h6.643a.6.6,0,0,1,.6.6Z" transform="translate(-924.686 -506.677)" />
                    <path id="Path_857" data-name="Path 857" d="M952.7,507.884h-3.222a2.963,2.963,0,0,0-2.96,2.959v3.222a2.963,2.963,0,0,0,2.96,2.96H952.7a2.963,2.963,0,0,0,2.959-2.96v-3.222A2.962,2.962,0,0,0,952.7,507.884Zm.6,6.181a.6.6,0,0,1-.6.6h-3.222a.6.6,0,0,1-.6-.6v-3.222a.6.6,0,0,1,.6-.6H952.7a.6.6,0,0,1,.6.6Z" transform="translate(-930.898 -495.2)" />
                </g>
                <path id="Path_858" data-name="Path 858" d="M936.056,449.158a9.552,9.552,0,0,0-9.541,9.541v.008h-13.6a3.243,3.243,0,0,0-3.239,3.239v3.6a3.6,3.6,0,0,0,.07.7,4.963,4.963,0,0,0-1.39,3.459v29.948a5,5,0,0,0,5,5h20.2a5,5,0,0,0,5-5V469.71a4.965,4.965,0,0,0-1.39-3.459,3.6,3.6,0,0,0,.069-.7v-3.6a3.228,3.228,0,0,0-.127-.89H945.6V458.7A9.552,9.552,0,0,0,936.056,449.158Zm0,2.357h0Zm-1.178.1v4.634l-3.224-3.224A7.138,7.138,0,0,1,934.877,451.619Zm-4.827,3.14,3.94,3.94h-5.119A7.148,7.148,0,0,1,930.051,454.759Zm6.146,44.9a2.642,2.642,0,0,1-2.642,2.642h-20.2a2.642,2.642,0,0,1-2.642-2.642V469.71a2.639,2.639,0,0,1,.774-1.868l1.207-1.208h.006a1.227,1.227,0,0,1-.667-1.088v-3.6a.883.883,0,0,1,.882-.882h21.078a.883.883,0,0,1,.882.882v3.6a1.226,1.226,0,0,1-.667,1.088h.006l1.208,1.208a2.641,2.641,0,0,1,.773,1.868Zm1.037-48.038a7.142,7.142,0,0,1,3.224,1.411l-3.224,3.224Zm.887,7.08,3.939-3.94a7.147,7.147,0,0,1,1.18,3.94Z" transform="translate(-908.357 -449.158)" />
            </svg>


        </span>

    )
}
export default Cocktail2;

Cocktail2.propTypes = {
    className: PropTypes.string,
    fill: PropTypes.string,
};
  
Cocktail2.defaultProps = {
    className: undefined,
    fill: '#333',
};
  