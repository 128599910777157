import React from 'react';
import { NavLink, Link } from "react-router-dom";
import './style.scss';
//import PageWrap from '../../templates/PageWrap/PageWrap';
import ContainerContent from '../../templates/ContainerContent/ContainerContent';
//import Bubbles from '../../components/Animations/Bubbles/Bubbles';
//import MoreBubbles from '../../components/Animations/MoreBubbles/MoreBubbles';
import MagnifyGlass from '../../components/MagnifyGlass/MagnifyGlass';
import Footer from '../../components/Footer/Footer';
import { LanguageConsumer } from '../../providers/LanguageProvider';
import { TranslatableText } from '../../providers/LanguageProvider';

//gsap.registerPlugin(Draggable);
import holidayWreath from './i/holiday-wreath.png';

class HomePage extends React.Component {
    constructor(props){
        super(props)
        
        this.state = {
            props: this.props
        }

    }
    

    render() {
        //console.log('home props ',this.props);
        return (
            <React.Fragment>
               {/*  <Bubbles /> */} 
               {/*   <MoreBubbles />  */} 
                <div className={`page-wrap`} id="page-wrap">

                    <ContainerContent>

                        { /*  REGULAR HOMEPAGE MARKUP  */}
                        <LanguageConsumer>
                            {(context) => (
                                <React.Fragment>
                                    {context.language !== 'fr' ? 
                                        <>
                                            <h1>Cannabis <br className="mobile" />Beverages.<br />That and only that,<br /> is our thing.</h1>
                                            <p>We take pride in being specialists, not generalists, focused exclusively on doing one thing well: creating the world’s best crafted cannabis beverages.<br /><Link to={`/faq#faq-sip-responsibly`} className="learn-about">Sip responsibly</Link>.</p>
                                        </>
                                        : 
                                        <>
                                            <h1>Des boissons <br />au cannabis,<br />c’est juste ça <br />qu’on fait…<br />et on adore notre job.</h1>
                                            <p>On est fiers d’être des spécialistes, pas des généralistes, et on se concentre nos efforts sur une seule chose : créer les meilleures boissons au cannabis au monde. <br /><Link to={`/fr/faq#faq-sip-responsibly`} className="learn-about">Sirotez de façon responsable</Link>.</p>
                                        </>
                                    }
                                
                        
                                    <NavLink to={`${context.language == 'fr' ? '/fr' : ''}/find-my-product`} className="find-my-bev btn-truss peach">
                                        <TranslatableText dictionary={{
                                            en: "Find me a product",
                                            fr: "Trouvez-moi un produit" }}>
                                        </TranslatableText><MagnifyGlass />
                                    </NavLink>
    
                                </React.Fragment>

                            )}
                        </LanguageConsumer> 


                        { /*  HOLIDAY MARKUP  */}
                        {/* <LanguageConsumer>
                            {(context) => (
                                <React.Fragment>
                                    {context.language !== 'fr' ? 
                                        <>
                                            <h1>Sip into<br />something<br/>different.</h1>
                                            <p>The wonder of this holiday season is going to look a bit different this year. So why not try something different? No matter what you're doing this season, whether you are celebrating or not, do it at your own pace and embrace the wonder of the&nbsp;holidays.<br /><Link to={`/faq#faq-sip-responsibly`} className="learn-about">Sip responsibly</Link>.</p>
                                        </>
                                        : 
                                        <>
                                            <h1>Sirotez la <br />différence.</h1>
                                            <p>La magie des Fêtes va être légèrement différente cette année. Alors pourquoi ne pas essayer quelque chose de différent? Peu importe ce que vous faites, ce que vous célébrez (ou non), faites-le à votre rythme et vivez la magie des&nbsp;Fêtes. <br /><Link to={`/fr/faq#faq-sip-responsibly`} className="learn-about">Sirotez de façon responsable</Link>.</p>
                                        </>
                                    }
                                
                        
                                    <NavLink to={`${context.language == 'fr' ? '/fr' : ''}/find-my-product`} className="find-my-bev btn-truss green">
                                        <TranslatableText dictionary={{
                                            en: "Find me a product",
                                            fr: "Trouvez-moi un produit" }}>
                                        </TranslatableText><MagnifyGlass />
                                    </NavLink>
    
                                </React.Fragment>

                            )}
                        </LanguageConsumer>

                        <img src={holidayWreath} className="holiday-wreath rotate" alt="holiday wreath" /> */}
                       
                    </ContainerContent>
                   
                </div>
                <div className="blockwrap">
                    <ContainerContent>
                        <Footer addClass="home" />
                    </ContainerContent>
                </div>
                
              
            </React.Fragment>
        )
    }
}
export default HomePage;