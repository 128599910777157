import React from 'react';
import { Route, Switch, withRouter } from "react-router-dom";
import '../../assets_global/scss/styles.scss';
import { LanguageContext } from '../../providers/LanguageProvider';
import Helmet from 'react-helmet';
import HomePage from '../../pages/HomePage/HomePage';
import LittleVictory from '../../pages/LittleVictory/LittleVictory';
import Veryvell from '../../pages/Veryvell/Veryvell';
import VeryvellProducts from '../../pages/VeryvellProducts/VeryvellProducts';
import HouseOfTerpenes from '../../pages/HouseOfTerpenes/HouseOfTerpenes';
import Xmg from '../../pages/Xmg/Xmg';
import Mollo from '../../pages/Mollo/Mollo';
import Faq from '../../pages/Faq/Faq';
//import FindMyBeverageEn from '../../pages/FindMyBeverageEn/FindMyBeverageEn';
import FindMyBeverage from '../../pages/FindMyBeverage/FindMyBeverage';
import About from '../../pages/About/About';
import Contact from '../../pages/Contact/Contact';
import WhereToBuy from '../../pages/WhereToBuy/WhereToBuy';
import Subscribe from '../../pages/Subscribe/Subscribe';
import Header from '../../components/Header/Header';
import AgeGate from '../../components/AgeGate/AgeGate';
import Animations from '../../components/Animations/Animations';
import EmailPopUp from '../../components/EmailPopUp/EmailPopUp';
import {TransitionGroup, CSSTransition } from 'react-transition-group';
import { scrollTo, scrollIntoView } from 'scroll-js';
import _ from 'lodash';
import CookiePopUp from '../../components/CookiePopUp/CookiePopUp';
import {Swipeable} from 'react-swipeable';
//import Container from './components/Container/Container';

// New order
// HOT
// Little Vic
// Mollo
// Veryvell
// XMG
const pages = [
    { path: '/', name: 'home', order: 1 },
    { path: '/house-of-terpenes', name: 'house-of-terpenes', order: 2 },
    { path: '/little-victory', name: 'little-victory', order: 3 },
    { path: '/mollo', name: 'mollo', order: 4 },
    { path: '/veryvell', name: 'veryvell', order: 5 },
    { path: '/xmg', name: 'xmg', order: 6 },
   // { path: '/flow-glow', name: 'flow-glow', order: 7 },
    { path: '/faq', name: 'faq', order: 7},
    { path: '/find-my-product', name: 'find-my-product', order: 8 },
    { path: '/about', name: 'about', order: 9 },
    { path: '/contact', name: 'contact', order: 10 },
    { path: '/where-to-buy', name: 'where-to-buy', order: 11 },
    { path: '/subscribe', name: 'subscribe', order: 12 }
]

class RouterApp extends React.Component {
    constructor(props){
        super(props)
       // console.log('constructor props ', props);
        this.state = {
            currentPage: this.setPage(this.props.location.pathname),
            curPageOrder: this.setCurrentOrder(this.props.location.pathname.replace(/(?:fr\/)|(?:(?:fr))/,"")),
            newPageOrder:  null,
            direction: 'right',
            menuShow: false,
            pageLinks: {
                prevPage: this.onLoadFindPrevPage(this.setPage(this.props.location.pathname))[0].path,
                nextPage: this.onLoadFindNextPage(this.setPage(this.props.location.pathname))[0].path,
            },
            showEmailPopUp: false
        }
        //console.log('router app constructor ',props);
        
    }

    static contextType = LanguageContext;

    componentDidMount() {

        this.handleScrollToAnchor(200);

    }

    componentWillUnmount() {
        //window.removeEventListener('scroll', e => this.handleScrollpage(e));
        this.touchScreen.kill();
    }
    

    componentDidUpdate(prevProps, prevState){
       // console.log('Component did update');
       
        let newPage = this.setPage(this.props.location.pathname);
        let newPageOrder = pages.filter(function (pages) {
            //console.log('filter newPage'+newPage);
            //return newPage;
            return pages.name === newPage;
        });
        let curPage = this.state.currentPage;
        let curPageOrder = pages.filter(function (pages) {
            //console.log('filter curPage'+newPage);
            //return newPage;
            return pages.name === curPage;
        });

      
        if( newPage !== curPage){
            //console.log('new page ',newPage);
            let direction = curPageOrder[0].order < newPageOrder[0].order ? 'left' : 'right';
            // Set State
            this.setState({
                currentPage: newPage,
                pageDirection: direction,
                curPageOrder: curPageOrder[0].order,
                newPageOrder: newPageOrder[0].order,
                /* pageLinks: {
                    prevPage: prevPage[0].path,
                    nextPage:  nextPage[0].path
                }, */
                 pageLinks: {
                    prevPage: this.findPrevPage(newPageOrder[0].order)[0].path,
                    nextPage: this.findNextPage(newPageOrder[0].order)[0].path
                },
                showAnim: false 
               
            },() => {
                document.documentElement.style['scroll-behavior'] = 'auto';
                
                scrollTo(document.documentElement, { top: 0, duration:0 }).then(() => {
                    document.documentElement.style['scroll-behavior'] = 'smooth';
                    //.log('snapping to top');
                    this.handleScrollToAnchor(1000);
                });
                
            });
           
        }
        else {
            //console.log('done mounting');
            
        }

        // Is there a hash? 
       // console.log('what  hash ',this.props.location);
        if(this.props.location.hash){
           // console.log('yes hash ',this.props.location.hash);
            let position = this.props.location.hash.replace(/^#/, "")
            const element = document.getElementById(position);
            this.handleScrollToAnchor(200); // this should work even on page load now

        }
        
    }

    handleScrollToAnchor = (delay) => {
        const anchorID = this.getAnchor();
       // console.log('handle scroll page ',this.state.currentPage);
 
        if(anchorID) {
            const ele = document.getElementById(anchorID);
            setTimeout(() => {
                scrollIntoView(ele, document.documentElement, {behavior:'smooth', duration: 5000, easing: 'ease-in-out'})
            },delay);
        }
        
        
    }

    getAnchor = () => {
        var currentUrl = document.URL,
        urlParts   = currentUrl.split('#');
            
        return (urlParts.length > 1) ? urlParts[1] : null;
    }

    

    onLoadFindPrevPage = (page) => {
        let thePage =  pages.filter(function (pages) {
            return pages.name === page;
        });  
        
        if(thePage[0] === undefined){
            return this.findPrevPage(1);
        }
        return this.findPrevPage(thePage[0].order);
    }

    onLoadFindNextPage = (page) => {
        let thePage =  pages.filter(function (pages) {
            return pages.name === page;
        });  
        
        if(thePage[0] === undefined){
            return this.findNextPage(1);
        }
        return this.findNextPage(thePage[0].order);
    }

    findPrevPage = (newPageOrder) => {
        return pages.filter(function (pages) {
            if( (newPageOrder - 1) > 0){
                return pages.order === (newPageOrder - 1);
            }
            else {
                return pages.order === 1;
            }
            
        }); 
    } 
    findNextPage = (newPageOrder) => {
        return  pages.filter(function (pages) {
            if(newPageOrder + 1 <= 7){
                return pages.order === (newPageOrder + 1);
            }
            else{
                return pages.order === 1;
            }
            
        });  


        
    } 

    setCurrentOrder = (path) => {
        let curPageOrder = pages.filter(function (pages) {
            //console.log('filter curPage'+newPage);
            //return newPage;
            return pages.path === path;
        });

        if (curPageOrder[0] === undefined) {
            return 1;
        }else {
            return curPageOrder[0].order;
        }
       
    }

    setPage = (pathname, mode) => {

        let pageType = mode || 'theme';
        //This is for CSS classes
        let page = null;
        let hexColor = null;
        switch (pathname){
            case('/'):
                page = 'home';
                hexColor = '#C7859F';
                break;
            case('/fr'):
                page = 'home';
                hexColor = '#C7859F';
                break;
            case('/little-victory'):
                page = 'little-victory';
                hexColor = '#F4BDC2';
                break;
            case('/fr/little-victory'):
                page = 'little-victory';
                hexColor = '#F4BDC2';
                break;
            case('/veryvell'):
                page = 'veryvell';
                hexColor = '#244032';
                break;
            case('/fr/veryvell'):
                page = 'veryvell';
                hexColor = '#244032';
                break;
            case('/house-of-terpenes'):
                page = 'house-of-terpenes';
                break;
            case('/fr/house-of-terpenes'):
                page = 'house-of-terpenes';
                break;
            case('/xmg'):
                page = 'xmg'
                break;
            case('/fr/xmg'):
                page = 'xmg'
                break;
            case('/mollo'):
                page = 'mollo';
                break;
            case('/fr/mollo'):
                page = 'mollo';
                break;
            case('/flow-glow'):
                page = 'flow-glow'
                break;
            case('/fr/flow-glow'):
                page = 'flow-glow'
                break;
            case('/faq'):
                page = 'faq'
                break;
            case('/fr/faq'):
                page = 'faq'
                break;
            case('/find-my-product'):
                page = 'find-my-product'
                break;
            case('/fr/find-my-product'):
                page = 'find-my-product'
                break;
            case('/about'):
                page = 'about'
                break;
            case('/fr/about'):
                page = 'about'
                break;
            case('/where-to-buy'):
                page = 'where-to-buy'
                break;
            case('/fr/where-to-buy'):
                page = 'where-to-buy'
                break;
            case('/subscribe'):
                page = 'subscribe'
                break;
            case('/fr/subscribe'):
                page = 'subscribe'
                break;
            case('/contact'):
                page = 'contact'
                break;
            case('/fr/contact'):
                page = 'contact'
                break;
            default:
                page = 'home';
            
        }
       
        return page;
    }
    setThemeColor = (pathname) => {
        //This is for CSS classes
        let hexColor = null;
        switch (pathname){
            case('/'):
                hexColor = '#C7859F';
                break;
            case('/fr'):
                hexColor = '#C7859F';
                break;
            case('/little-victory'):
                hexColor = '#F4BDC2';
                break;
            case('/fr/little-victory'):
                hexColor = '#F4BDC2';
                break;
            case('/veryvell'):
                hexColor = '#244032';
                break;
            case('/fr/veryvell'):
                hexColor = '#244032';
                break;
            case('/house-of-terpenes'):
                hexColor = '#475B73';
                break;
            case('/fr/house-of-terpenes'):
                hexColor = '#475B73';
                break;
            case('/xmg'):
                hexColor = '#58B6AB'
                break;
            case('/fr/xmg'):
                hexColor = '#58B6AB'
                break;
            case('/mollo'):
                hexColor = '#001D7B';
                break;
            case('/fr/mollo'):
                hexColor = '#001D7B';
                break;
            case('/flow-glow'):
                hexColor = 'flow-glow'
                break;
            case('/fr/flow-glow'):
                hexColor = 'flow-glow'
                break;
            case('/faq'):
                hexColor = '#FEFAF7'
                break;
            case('/fr/faq'):
                hexColor = '#FEFAF7'
                break;
            case('/find-my-product'):
                hexColor = '#FEFAF7'
                break;
            case('/fr/find-my-product'):
                hexColor = '#FEFAF7'
                break;
            case('/about'):
                hexColor = '#FEFAF7'
                break;
            case('/fr/about'):
                hexColor = '#FEFAF7'
                break;
            case('/where-to-buy'):
                hexColor = '#FEFAF7'
                break;
            case('/fr/where-to-buy'):
                hexColor = '#FEFAF7'
                break;
            case('/subscribe'):
                hexColor = '#FEFAF7'
                break;
            case('/fr/subscribe'):
                hexColor = '#FEFAF7'
                break;
            case('/contact'):
                hexColor = '#FEFAF7'
                break;
            case('/fr/contact'):
                hexColor = '#FEFAF7'
                break;
            default:
                hexColor = '#FEFAF7';
            
        }
       
        return hexColor;
    }

    menuToggle = () => {
       // console.log('toggle handler');
        this.setState((prevState) => {
            return { menuShow: !prevState.menuShow };
        })
    }

    emailPopUpToggle = () => {
        //console.log('toggle email handler');
        this.setState((prevState) => {
            return { showEmailPopUp: true };
        })
    }

    
    handleSwipes = (direction) => {
       
        const swipePages = ['home','house-of-terpenes','mollo','little-victory','veryvell','xmg'];
        let { currentPage } = this.state;
        let langPath = this.context.language;
        langPath === 'fr' ? langPath = '/fr' : langPath = '';

        if(!swipePages.includes(currentPage) || this.props.isDesktop === true){
            return
        }else{
            direction === 'right' ? this.handleSwipeRight(currentPage,langPath) : this.handleSwipeLeft(currentPage,langPath);
        }
        
    }

    handleSwipeRight = (page, langPath) => {

        if (page === 'home'){
            this.props.history.push(`${langPath}/xmg`);
            return;
        }
        this.props.history.push(`${langPath}${this.state.pageLinks.prevPage}`);

    }

    handleSwipeLeft = (page, langPath) => {

        if(page === 'xmg'){
            this.props.history.push(`${langPath}/`);
            return;
        }
        this.props.history.push(`${langPath}${this.state.pageLinks.nextPage}`);
        
    }

    render() {
        
        const { location } = this.props;
        //let currentKey = location.pathname.split("/")[1] || "/";
        let currentKey;
        this.state.lang === 'fr' ? currentKey = location.pathname.split("/")[2] : currentKey = location.pathname.split("/")[1] || "/";
        const timeout = { enter: 800, exit: 400 };
        //console.log('location ',location);
        return (
            <React.Fragment>
            <Swipeable onSwipedLeft={() => this.handleSwipes('left')} onSwipedRight={() =>  this.handleSwipes('right')}>
                <CookiePopUp show={this.props.siteCookie} closeCookiePopUp={this.props.closeCookiePopUp} lang={this.context.language} />
                <div className={`wrapper ${this.setPage(this.props.location.pathname)} ${this.state.menuShow ? 'menu-open' : null }`}>
                    
                    <Helmet 
                        htmlAttributes={{ id : this.setPage(this.props.location.pathname)}}
                    >
                    <meta name="theme-color" content={`${this.setThemeColor(this.props.location.pathname)}`} />
                    </Helmet>

                {/*  <Backdrop show={this.state.mobileMenuShow} /> */}
                {this.state.showEmailPopUp ? <EmailPopUp /> : <AgeGate ageCookie={this.props.ageCookie} emailPopUpToggle={this.emailPopUpToggle} lang={this.context.language} />}  
                
                    <Header currentPage={this.setPage(this.props.location.pathname)} id="header" menuToggle={this.menuToggle} menuShow={this.state.menuShow} pageLinks={this.state.pageLinks} />
                    <div className={`wrap ${currentKey} ${this.setPage(this.props.location.pathname)} ${this.state.fadeIn ? 'fade-bg' : ''}`} >
                        <Animations currentPage={this.state.currentPage} /> 
                        <TransitionGroup  className={`transition-group ${this.state.pageDirection}`}>
                            <CSSTransition
                                key={this.state.currentPage}
                                timeout={{ enter: 800, exit: 400 }}
                                classNames={'transition-wrap'}
                            >
                                
                                <section className={`route-section fade-it`}>
                                    <Switch location={location}>
                                        
                                        <Route exact path="/" component={() => <HomePage info={this.props} pageLinks={this.state.pageLinks} />} />
                                        <Route exact path="/fr" component={() => <HomePage info={this.props} swipeRight={this.swipeRight} swipeLeft={this.swipeLeft} />} />
                                        <Route path="/little-victory" component={() => <LittleVictory info={this.props} />} swipeRight={this.swipeRight} swipeLeft={this.swipeLeft} />
                                        <Route path="/fr/little-victory" component={() => <LittleVictory info={this.props} />} swipeRight={this.swipeRight} swipeLeft={this.swipeLeft} />
                                        <Route path="/veryvell" component={() => <VeryvellProducts info={this.props} lang={this.context.language} /> } />
                                        <Route path="/fr/veryvell" component={() => <VeryvellProducts info={this.props} lang={this.context.language} /> } />
                                        <Route path="/house-of-terpenes" component={() => <HouseOfTerpenes info={this.props} lang={this.context.language} /> } />
                                        <Route path="/fr/house-of-terpenes" component={() => <HouseOfTerpenes info={this.props} lang={this.context.language} /> } />
                                        <Route path="/xmg" component={() => <Xmg info={this.props} /> } />
                                        <Route path="/fr/xmg" component={() => <Xmg info={this.props} /> } />
                                        <Route path="/mollo" component={() => <Mollo info={this.props} lang={this.context.language}/> } />
                                        <Route path="/fr/mollo" component={() => <Mollo info={this.props} lang={this.context.language} /> } />
                                        {/* <Route path="/flow-glow" component={() => <FlowGlow info={this.props} /> } />
                                        <Route path="/fr/flow-glow" component={() => <FlowGlow info={this.props} /> } /> */}
        
                                        <Route path="/faq" component={() => <Faq info={this.props} /> } />
                                        <Route path="/fr/faq" component={() => <Faq info={this.props} /> } />
                                        <Route path="/find-my-product" component={() => <FindMyBeverage info={this.props} lang={this.context.language} /> } />
                                        <Route path="/fr/find-my-product" component={() => <FindMyBeverage info={this.props} lang={this.context.language} /> } />
                                        <Route path="/about" component={() => <About info={this.props} /> } />
                                        <Route path="/fr/about" component={() => <About info={this.props} /> } />
                                        <Route path="/contact" component={() => <Contact info={this.props} /> } />
                                        <Route path="/fr/contact" component={() => <Contact info={this.props} /> } />
                                        <Route path="/where-to-buy" component={() => <WhereToBuy info={this.props} /> } />
                                        <Route path="/fr/where-to-buy" component={() => <WhereToBuy info={this.props} /> } />
                                        <Route path="/subscribe" component={() => <Subscribe info={this.props} /> } />
                                        <Route path="/fr/subscribe" component={() => <Subscribe info={this.props} /> } />
                                        
                                        {/* <Route path="/third" component={() => <Veryvell /> } /> */}
                                    </Switch>
                                </section>
                            
                            </CSSTransition>
                        </TransitionGroup>
                    </div>
                </div>
            </Swipeable>
            </React.Fragment>
        )
    }
}
export default withRouter(RouterApp);


