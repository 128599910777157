import React from 'react';
import './style.scss';
import ContainerContent from '../../templates/ContainerContent/ContainerContent';
import { TranslatableText } from '../../providers/LanguageProvider';

const Footer = (props) => {
    
    return(
        <div className={`footer ${props.addClass}`}>
            <div className="blockwrap">
                <ContainerContent>
                    <div className="follow-us-wrapper">
                        {/* make instagram follow us button optional for pages without it*/}
                        {props.instagram ? 
                            <React.Fragment>
                                <span className="follow-us">
                                    <TranslatableText dictionary={{ 
                                    en: "Follow us:",
                                    fr: "Suivez-nous :" }}>
                                    </TranslatableText>
                                </span>
                                <a href={`https://www.instagram.com/${props.instagram}`} rel="noopener noreferrer" target="_blank" className="insta-link"><span className="icon"><i className="la la-instagram"></i></span></a>
                            </React.Fragment> : null}
                    </div>
                    <p className="footer-legal">
                    <TranslatableText dictionary={{
                        en: "© 2021 Truss Beverage Co.™, used under licence. ",
                        fr: "© 2021 Truss Beverage Co." }}>
                    </TranslatableText>
                    <sup>
                    <TranslatableText dictionary={{
                        en: "",
                        fr: "MC" }}>
                    </TranslatableText>
                    </sup>
                    <TranslatableText dictionary={{
                        en: "",
                        fr: ", utilisée sous licence. " }}>
                    </TranslatableText>
                    
                    <br className="mobile" />
                    <TranslatableText dictionary={{
                        en: "All rights reserved. ",
                        fr: "Tous droits réservées. " }}>
                    </TranslatableText><br />
                    <TranslatableText dictionary={{
                        en: "This site is intended for persons of legal age in the province in which it is accessed.",
                        fr: "Ce site est destiné aux personnes qui ont l’âge légal dans la province depuis laquelle il est accessible." }}>
                    </TranslatableText><br className="desktop" />
                    
                    </p>
                </ContainerContent>
            </div>
        </div> 
    ) 
}
export default Footer;