import React from 'react';
import './style.scss';
import ContainerContent from '../../templates/ContainerContent/ContainerContent';
import Footer from '../../components/Footer/Footer';
import { TranslatableText } from '../../providers/LanguageProvider';
import bottleMollo_1 from './i/mollo_1.png';
import bottleMollo_2 from './i/mollo_2.png';
import mollo_drinks from './i/mollo-group.png';
import Mollo25Brew from './scenes/Mollo25Brew/Mollo25Brew';
import Mollo5Brew from './scenes/Mollo5Brew/Mollo5Brew';
import MolloLinesStatic from './i/MolloLinesStatic';

class Xmg extends React.Component {
    constructor(props){
        super(props)

        this.state ={
            isDesktop: props.info.isDesktop,
        }
        
    }
    render() {

        return (
            <React.Fragment>
               
                <div className={`page-wrap`}>

                    <div className="blockwrap first">

                        <ContainerContent>

                            <span className="heading-wrap">
                                <h1>
                                    <TranslatableText dictionary={{
                                        en: "Crisp with an",
                                        fr: "Un goût vif et" }}>
                                    </TranslatableText><br />
                                    <TranslatableText dictionary={{
                                        en: "easy drinking taste.",
                                        fr: "facile à boire." }}>
                                    </TranslatableText><br />
                                    <TranslatableText dictionary={{
                                        en: "Vas-y mollo.",
                                        fr: "Allez-y mollo." }}>
                                    </TranslatableText>
                                </h1>  
                            </span>

                            <div className="img-wrap">
                                <img src={bottleMollo_1} className="bottle-brew-25 bottle bottle-bounce-1" alt="Mollo 2.5 Brew" />
                                <img src={bottleMollo_2} className="bottle-brew-5 bottle abs bottle-bounce-2" alt="Mollo 5 Brew" /> 
                            </div>
                            
                        </ContainerContent>

                    </div>

                    <Mollo25Brew isDesktop={this.state.isDesktop} bevId="beverage-2" lang={this.props.lang} />
                    <Mollo5Brew  isDesktop={this.state.isDesktop} bevId="beverage-3"/> 

                    
                    <div className="blockwrap brandline">
                        <MolloLinesStatic />
                        <ContainerContent>
                            <h2>
                                <TranslatableText dictionary={{
                                    en: "What I’ll look like ",
                                    fr: "Voici à quoi je ressemble lorsque " }}>
                                </TranslatableText><br />
                                <TranslatableText dictionary={{
                                    en: "when you bring me home.",
                                    fr: "vous m'apporterez à la maison." }}>
                                </TranslatableText>
                            </h2>
                            <img src={mollo_drinks} className="beverage-group" alt="Mollo Beverages Family" /> 
                        </ContainerContent>
                    </div>

                    <Footer instagram="mollo_out" />


                </div>
            </React.Fragment>
        )
    }
}
export default Xmg;

