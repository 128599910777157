import React from 'react';
import './style.scss';
import ContainerContent from '../../templates/ContainerContent/ContainerContent';
import { TranslatableText, LanguageConsumer } from '../../providers/LanguageProvider';
import Footer from '../../components/Footer/Footer';
import imgFresh from './i/fresh-leaves.png';
//import MailchimpSubscribeForm from '../../components/MailchimpSubscribeForm/MailchimpSubscribeForm';
import CampaignMonitorForm from '../../components/CampaignMonitorForm/CampaignMonitorForm';
//import FormCampaignMonitor from '../../components/EmailPopUp/FormCampaignMonitor/FormCampaignMonitor';


class Subscribe extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            show: true,
            showSuccess: false
        }
    }

    showSuccessModal = () => {
        // show sucess modal
        this.setState({ show: false, showSuccess: true });
    }
    hideSuccessModal = () => {
        this.setState({ 
          showSuccess: false 
        });
        
       
    };
    render() {
        return (
        
            <React.Fragment>
                <div className={`page-wrap brandline`}>
                    <ContainerContent>
                        <div className="form-wrap">
                            <h1>   
                                <TranslatableText dictionary={{
                                    en: "Wonder what’s",
                                    fr: "Vous vous demandez" }}>
                                </TranslatableText><br />
                                <TranslatableText dictionary={{
                                    en: "coming next?",
                                    fr: "quelle sera la prochaine " }}>
                                </TranslatableText><br />
                                <TranslatableText dictionary={{
                                    en: "",
                                    fr: "merveille?" }}>
                                </TranslatableText>
                            </h1>

                            <LanguageConsumer>
                                {(context) => (
                                    <React.Fragment>    
                                        {
                                            context.language == 'en' ?
                                            <div className={this.state.showSuccess ? 'hide' : ''}>
                                                <p>Join our mailing list and you’ll be<br />the first to hear about all the things<br />we have in store.</p>
                                            </div>
                                            :
                                            <div className={this.state.showSuccess ? 'hide' : ''}>
                                                <p>Inscrivez-vous à notre liste de diffusion pour connaître en primeur tous les produits qu’on offre en magasin.</p>
                                            </div>
                                            
                                        }
                                    </React.Fragment>
                                )}
                            </LanguageConsumer>

                            <LanguageConsumer>
                                {(context) => (
                                    <React.Fragment>    
                                        
                                        <div className="sign-up">
                                            <CampaignMonitorForm successSubmit={this.showSuccessModal} />
                                        </div>
                                       
                                    </React.Fragment>
                                )}
                            </LanguageConsumer>
                            
                        </div>
                        <div className="img-wrap">
                            <img src={imgFresh} alt="fresh leaves" />
                        </div>
                    </ContainerContent>
                </div> 
                <Footer />
            </React.Fragment>


        )
    }
}
export default Subscribe;