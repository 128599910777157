import React from 'react';
import './style.scss';
import ContainerContent from '../../../../templates/ContainerContent/ContainerContent';
import { TranslatableText } from '../../../../providers/LanguageProvider';
import { LanguageConsumer } from '../../../../providers/LanguageProvider';
import * as ScrollMagic from "scrollmagic"; // Or use scrollmagic-with-ssr to avoid server rendering problems
import { TimelineMax, Linear } from "gsap"; // Also works with TweenLite and TimelineLite
import xmg_can_tropical from '../../i/xmg_1.png';
import Calendar from '../../../../components/svgIcons/Calendar';
import Can from '../../../../components/svgIcons/Can';
//import PotencyLow from '../../../../components/svgIcons/PotencyLow';
import PotencyXmg from '../../../../components/svgIcons/PotencyXmg';
import Highlights from '../../../../components/svgIcons/Highlights';
import InfoIcon from '../../../../components/svgIcons/InfoIcon';
import NutritionPopUp from '../../../../components/NutritionPopUp/NutritionPopUp';

class TropicalFruit extends React.Component {
    constructor(props){
        super(props)
        // basic initialization
        this.controller = new ScrollMagic.Controller();
    }
    componentWillUnmount = () => {
        this.controller.destroy();
    }
    componentDidMount = () => {
    
        var title = document.getElementById("title-anim");
        //var list = document.getElementById("beverage-info");
        //var imgWrap = document.getElementById("img-wrap");
                           
        var timeline = new TimelineMax()
        timeline.to(title, 1, {y:110, /* textShadow: "1px 1px 1px rgb(204, 46, 76)" ,*/  ease: Linear.easeNone} )
        //timeline.to(list, 1, {y:95,   ease: Linear.easeNone}, 0)
        // timeline.from(list, 1, {y:95,   ease: Linear.easeNone}, 0)
        //timeline.to(imgWrap, 1, {y:105,   ease: Linear.easeNone}, 0)
   
        // build scenes
        new ScrollMagic.Scene({triggerElement: ".second", triggerHook: 1, duration: '100%',tweenChanges: true})
            //.setPin("#pin")
            .setTween(timeline)
           // .setClassToggle(".img-wrap", "anim")
            .on("enter leave", this.addBottleAnimation)
            .on("progress", function (event) {
                //console.log("Scene progress changed to " + event.progress);
            })
            //.addIndicators() // add indicators (requires plugin)
            .addTo(this.controller);
        
        // scrollTo 

    }
    
    addBottleAnimation = () => {
        let bottles =  document.getElementById('img-wrap');
        bottles.classList.add('anim');   
    }
    render() {
        return (
            <div className="blockwrap second" id={this.props.bevId}>
                
                <ContainerContent>

                    {   this.props.isDesktop ? 
                            <div id="img-wrap" className="img-wrap">
                                <img src={xmg_can_tropical} id="botl" className="bottle-strawberry bottle" alt="XMG - Tropical Fruit" />
                                <img src={xmg_can_tropical} id="botl2" className="bottle-strawberry strawberry-2 bottle abs" alt="XMG - Tropical Fruit" />
                            </div>
                    
                        : null
                    }
                    <div className="info-wrap">

                        <h2 id="title-anim">
                            <TranslatableText dictionary={{
                                en: "X hits the spot.",
                                fr: "X frappe dans le mille." }}>
                            </TranslatableText>
                        </h2>
                        <div id="beverage-info" className="beverage-info" >
                            
                            <LanguageConsumer>
                                {(context) => (
                                    <React.Fragment>
                                        {context.language !== 'fr' ? 
                                                <h3>XMG™ Tropical Fruit</h3>
                                            : 
                                                <h3>XMG™<br />Fruits tropicaux</h3>
                                        }
                                    </React.Fragment>
                                )}
                            </LanguageConsumer>

                            <p>
                                <TranslatableText dictionary={{
                                    en: "No messing around with this",
                                    fr: "Il n’y a pas de flafla avec cette potion" }}>
                                </TranslatableText><br />
                                <TranslatableText dictionary={{
                                    en: "tropical fruit, high-intensity flavoured",
                                    fr: "à intensité élevée et aux saveurs de" }}>
                                </TranslatableText><br />
                                <TranslatableText dictionary={{
                                    en: " potion.",
                                    fr: "fruits tropicaux." }}>
                                </TranslatableText>
                            </p>

                            {
                                this.props.isDesktop ? null
                                : 
                                <div id="img-wrap" className="img-wrap">
                                    <img src={xmg_can_tropical} id="botl" className="bottle-strawberry bottle" alt="XMG™ - Tropical Fruit" />
                                    <img src={xmg_can_tropical} id="botl2" className="bottle-strawberry strawberry-2 bottle abs" alt="XMG™ - Tropical Fruit" />
                                </div>
                            }
                            <ul id="standard-list" className="standard-list">
                                <li>
                                    <PotencyXmg />
                                    <span>
                                        <h4>
                                            <TranslatableText dictionary={{
                                                en: "POTENCY",
                                                fr: "intensité" }}>
                                            </TranslatableText>
                                        </h4>
                                        <p>
                                            <TranslatableText dictionary={{
                                                en: "HIGH",
                                                fr: "Élevée" }}>
                                            </TranslatableText><br />
                                            <TranslatableText dictionary={{
                                                en: "10MG THC",
                                                fr: "10 mg THC" }}>
                                            </TranslatableText>
                                        </p>
                                    </span>
                                </li>
                                <li>
                                    <Calendar />
                                    <span>
                                        <h4>
                                            <TranslatableText dictionary={{
                                                en: "OCCASIONS",
                                                fr: "Occasions" }}>
                                            </TranslatableText>
                                        </h4>
                                        <p>
                                            <TranslatableText dictionary={{
                                                en: "Staring into the fire.",
                                                fr: "Devant un feu." }}>
                                            </TranslatableText><br />
                                            <TranslatableText dictionary={{
                                                en: "Hitting go.",
                                                fr: "Quand ça vous tente de bouger." }}>
                                            </TranslatableText><br />
                                            <TranslatableText dictionary={{
                                                en: "Jam seshes.",
                                                fr: "À des sessions de jam." }}>
                                            </TranslatableText><br />
                                            <TranslatableText dictionary={{
                                                en: "Camping legend.",
                                                fr: "En camping." }}>
                                            </TranslatableText>
                                        </p>

                                    </span>
                                </li>
                                <li>
                                    <Can />
                                    <span>
                                        <h4>
                                            <TranslatableText dictionary={{
                                                en: "SERVING SUGGESTION",
                                                fr: "NOTES DE SERVICE" }}>
                                            </TranslatableText>
                                        </h4>
                                        <p> 
                                            <TranslatableText dictionary={{
                                                en: "Straight up, right out of the can.",
                                                fr: "À boire directement à la canette." }}>
                                            </TranslatableText>
                                        </p>
                                    </span>
                                </li>
                                <li>
                                    <Highlights />
                                    <span>
                                        <h4>
                                            <TranslatableText dictionary={{
                                                en: "HIGHLIGHTS",
                                                fr: "INFORMATIONS" }}>
                                            </TranslatableText>
                                        </h4>
                                        <p> 
                                            <TranslatableText dictionary={{
                                                en: "All natural flavours. ",
                                                fr: "Saveurs entièrement naturelles." }}>
                                            </TranslatableText>
                                        </p>
                                    </span>
                                </li>
                                <li>
                                    <InfoIcon />
                                    <span>
                                        <h4>
                                            <TranslatableText dictionary={{
                                                en: "NUTRITIONAL INFORMATION",
                                                fr: "INFORMATIONS NUTRITIVE" }}>
                                            </TranslatableText>
                                        </h4>
                                        <div className="paragraph">
                                            <NutritionPopUp beverage="tropical-fruit" />
                                        </div>
                                    </span>
                                </li>
                            </ul>

                        </div>

                    </div>
                    
                </ContainerContent>

            </div>
        )
    }
}
export default TropicalFruit;