export const PROV_OPTIONS_LIST = [
    { id: "", name: "Please Select a prov" },
    { name: "Alberta", id: "AB" },
    { name: "British Columbia", id: "BC" },
    { name: "Manitoba", id: "MB" },
    { name: "New Brunswick", id: "NB" },
    { name: "NewFoundland and Labrador", id: "NL" },
    { name: "Nova Scotia", id: "NS" },
    { name: "Northwest Territories", id: "NT" },
    { name: "Nunavut", id: "NU" },
    { name: "Ontario", id: "ON" },
    { name: "Prince Edward Island", id: "PE" },
    { name: "Quebec", id: "QC" },
    { name: "Saskatchewan", id: "SK" },
    { name: "Yukon", id: "YK" },
  ];

  export const FR_PROV_OPTIONS_LIST = [
    { id: "", name: "Veuillez sélectionner votre province" },
    { name: "Alberta", id: "AB" },
    { name: "Colombie-Britannique", id: "BC" },
    { name: "Manitoba", id: "MB" },
    { name: "Nouveau-Brunswick", id: "NB" },
    { name: "Terre-Neuve-et-Labrador", id: "NL" },
    { name: "Nouvelle-Écosse", id: "NS" },
    { name: "Territoires du Nord-Ouest", id: "NT" },
    { name: "Nunavut", id: "NU" },
    { name: "Ontario", id: "ON" },
    { name: "Île-du-Prince-Édouard", id: "PE" },
    { name: "Québec", id: "QC" },
    { name: "Saskatchewan", id: "SK" },
    { name: "Yukon", id: "YK" },

  ]
