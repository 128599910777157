import React from 'react';
import './style.scss';
import ContainerContent from '../../../../templates/ContainerContent/ContainerContent';
import { TranslatableText } from '../../../../providers/LanguageProvider';
import { LanguageConsumer } from '../../../../providers/LanguageProvider';
import * as ScrollMagic from "scrollmagic"; // Or use scrollmagic-with-ssr to avoid server rendering problems
import { TimelineMax, Linear } from "gsap"; // Also works with TweenLite and TimelineLite
import vv_bottle_lemon from '../../i/veryvell_2.png';
import Calendar from '../../../../components/svgIcons/Calendar';
import Bottle from '../../../../components/svgIcons/Bottle';
// import PotencyLow from '../../../../components/svgIcons/PotencyLow';
import PotencyVeryvell from '../../../../components/svgIcons/PotencyVeryvell';
import Highlights from '../../../../components/svgIcons/Highlights';
import InfoIcon from '../../../../components/svgIcons/InfoIcon';
import VerywellLinesStatic from '../../i/VerywellLinesStatic';
import NutritionPopUp from '../../../../components/NutritionPopUp/NutritionPopUp';

class SicilianLemon extends React.Component {
    constructor(props){
        super(props)

        this.controller2 = new ScrollMagic.Controller();
    }
    componentWillUnmount() {
        this.controller2.destroy();
    }
    componentDidMount = () => {
    
        var title = document.getElementById("title-anim-3");
        //var list = document.getElementById("beverage-info-3");
        //var imgWrap = document.getElementById("img-wrap-3");        
                               
        var timeline2 = new TimelineMax()
        timeline2.to(title, 1, {y:110,/*  textShadow: "1px 1px 1px rgb(204, 46, 76)", */  ease: Linear.easeNone} )
        // timeline.to(list, 1, {y:95,   ease: Linear.easeNone}, 0)
        // timeline2.from(list, 1, {y:95,   ease: Linear.easeNone}, 0)
        //timeline.to(imgWrap, 1, {y:105,   ease: Linear.easeNone}, 0)
        
        // build scenes
        let scene2 = new ScrollMagic.Scene({triggerElement: ".three", triggerHook: 1, duration: '100%', tweenChanges: true});
            scene2 .setTween(timeline2)
            .on("enter leave", this.addBottleAnimation)
            .on("progress", function (event) {
                //console.log("Scene progress changed to " + event.progress);
            })
            //.addIndicators() // add indicators (requires plugin)
            .addTo(this.controller2);

    }

    addBottleAnimation = () => { 
       let bottles =  document.getElementById('img-wrap-3');
       bottles.classList.add('anim');   
    }
    render() {
        return (
            <div className="blockwrap three" id={this.props.bevId}>
                <VerywellLinesStatic />
                <ContainerContent>
                    {
                        this.props.isDesktop ?
                            <div className="img-wrap" id="img-wrap-3">
                                <img src={vv_bottle_lemon} className="bottle-yellow bottle" alt="Veryvell - Sicilian Lemon" />
                                <img src={vv_bottle_lemon} className="bottle-yellow yellow-2 bottle abs" alt="Veryvell - Sicilian Lemon" />
                            </div>
                        :
                        null
                    }
                    <div className="info-wrap">
                     
                        <h2 id="title-anim-3">
                            <TranslatableText dictionary={{
                                en: "Calm waters?",
                                fr: "Envie d’être en eaux" }}>
                            </TranslatableText><br />
                            <TranslatableText dictionary={{
                                en: "Veryvell, then.",
                                fr: "calmes? Veryvell." }}>
                            </TranslatableText>
                        </h2>

                        <div className="beverage-info" id="beverage-info-3">

                            <LanguageConsumer>
                                {(context) => (
                                    <React.Fragment>
                                        {context.language !== 'fr' ? 
                                                <h3>Veryvell™<br /> Sicilian Lemon<br /> Sparkling Water</h3>
                                            : 
                                                <h3>Veryvell™<br />Eau pétillante àu<br />citron sicilien</h3>
                                        }
                                    </React.Fragment>
                                )}
                            </LanguageConsumer>

                            <p>     
                                <TranslatableText dictionary={{
                                    en: "Dim your phone and brighten your day",
                                    fr: "Déposez votre téléphone et ensoleillez" }}>
                                </TranslatableText><br />
                                <TranslatableText dictionary={{
                                    en: "with this sparkling water with a subtle",
                                    fr: "votre journée avec cette eau pétillante" }}>
                                </TranslatableText><br />
                                <TranslatableText dictionary={{
                                    en: "twist of Sicilian lemon flavour.",
                                    fr: "aux saveurs subtiles de citron de Sicile." }}>
                                </TranslatableText>
                            </p>

                            {
                                this.props.isDesktop ?
                                    null
                                :
                                    <div className="img-wrap" id="img-wrap-3">
                                        <img src={vv_bottle_lemon} className="bottle-yellow bottle" alt="Veryvell™ - Sicilian Lemon" />
                                        <img src={vv_bottle_lemon} className="bottle-yellow yellow-2 bottle abs" alt="Veryvell™ - Sicilian Lemon" />
                                    </div>
                            }

                            <ul className="standard-list">
                                <li>
                                    <PotencyVeryvell />
                                    <span>
                                        <h4>
                                            <TranslatableText dictionary={{
                                                en: "POTENCY",
                                                fr: "intensité" }}>
                                            </TranslatableText>
                                        </h4>
                                        <p>
                                            <TranslatableText dictionary={{
                                                en: "15MG CBD + 0.5MG THC",
                                                fr: "15 mg CBD : 0,5 mg THC" }}>
                                            </TranslatableText>
                                        </p>
                                    </span>
                                </li>
                                <li>
                                   <Calendar />
                                    <span>
                                        <h4>
                                            <TranslatableText dictionary={{
                                                en: "OCCASIONS",
                                                fr: "OCCASIONS" }}>
                                            </TranslatableText>
                                        </h4>
                                        <p>
                                            <TranslatableText dictionary={{
                                                en: "After sunset bike rides. ",
                                                fr: "Après un tour de vélo au coucher de soleil." }}>
                                            </TranslatableText><br />
                                            <TranslatableText dictionary={{
                                                en: "Farmers market shopping.",
                                                fr: "En faisant un tour au marché public." }}>
                                            </TranslatableText><br />
                                            <TranslatableText dictionary={{
                                                en: "Spring purges.",
                                                fr: "Pour faire le ménage du printemps." }}>
                                            </TranslatableText><br />
                                        </p>
                                    </span>
                                </li>
                                <li>
                                    <Bottle />
                                    <span>
                                        <h4>
                                            <TranslatableText dictionary={{
                                                en: "SERVING SUGGESTION",
                                                fr: "NOTES DE SERVICE" }}>
                                            </TranslatableText>
                                        </h4>
                                        <p>
                                            <TranslatableText dictionary={{
                                                en: "Unpop and enjoy. ",
                                                fr: "Ouvrir et savourer." }}>
                                            </TranslatableText>
                                        </p>
                                    </span>
                                </li>
                                <li>
                                    <Highlights />
                                    <span>
                                        <h4>
                                            <TranslatableText dictionary={{
                                                en: "HIGHLIGHTS",
                                                fr: "INFORMATIONS" }}>
                                            </TranslatableText>
                                        </h4>
                                        <p>
                                            <TranslatableText dictionary={{
                                                en: "All natural flavours. ",
                                                fr: "Saveurs entièrement naturelles." }}>
                                            </TranslatableText>
                                        </p>
                                    </span>
                                </li>
                                <li>
                                    <InfoIcon />
                                    <span>
                                        <h4>
                                            <TranslatableText dictionary={{
                                                en: "NUTRITIONAL INFORMATION",
                                                fr: "INFORMATIONS NUTRITIVE" }}>
                                            </TranslatableText>
                                        </h4>
                                        <div className="paragraph">
                                            <NutritionPopUp beverage="sicilian-lemon" />
                                        </div>
                                    </span>
                                </li>
                            </ul>

                        </div>

                    </div>

                </ContainerContent>

            </div>
        )
    }
}
export default SicilianLemon;