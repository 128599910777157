import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const Can = (className, fill) => {
    
    return(

        <span className="icon">

            <svg xmlns="http://www.w3.org/2000/svg" width="26.952" height="66.822" viewBox="0 0 26.952 66.822">
                <g className="icon-can" id="Group_772" data-name="Group 772" transform="translate(-1269.722 -627.482)">
                    <path id="Path_853" data-name="Path 853" d="M1295.178,662.467a4.05,4.05,0,0,0,.063-.711v-1.173a2.725,2.725,0,0,0-2.725-2.725h-4.2v-3.142a5.122,5.122,0,1,0-10.245,0v3.142h-4.2a2.725,2.725,0,0,0-2.725,2.725v1.172a4.044,4.044,0,0,0,.063.712,5.55,5.55,0,0,0-1.5,3.807v32.5a5.6,5.6,0,0,0,5.592,5.592h15.769a5.6,5.6,0,0,0,5.592-5.592v-32.5A5.552,5.552,0,0,0,1295.178,662.467Zm-14.377-7.751a2.4,2.4,0,1,1,4.795,0v3.131H1280.8Zm13.148,44.057a2.867,2.867,0,0,1-2.867,2.867h-15.769a2.867,2.867,0,0,1-2.867-2.867v-32.5a2.867,2.867,0,0,1,.84-2.027l1.311-1.31h.007a1.331,1.331,0,0,1-.724-1.181v-1.172h18.635v1.173a1.331,1.331,0,0,1-.723,1.18h.005l1.311,1.31a2.867,2.867,0,0,1,.84,2.027Z" transform="translate(0 -10.061)"/>
                    <path id="Path_854" data-name="Path 854" d="M1293.312,656.58a1.362,1.362,0,0,0-1.363,1.362v.423a1.362,1.362,0,1,0,2.725,0v-.423A1.362,1.362,0,0,0,1293.312,656.58Z" transform="translate(-10.113 -13.24)"/>
                    <g id="Group_771" data-name="Group 771" transform="translate(1270.343 627.482)" opacity="0.25">
                    <rect id="Rectangle_1771" data-name="Rectangle 1771" width="2.725" height="5.436" transform="translate(11.22)"/>
                    <rect id="Rectangle_1772" data-name="Rectangle 1772" width="2.725" height="6.289" transform="translate(0 6.399) rotate(-45)"/>
                    <rect id="Rectangle_1773" data-name="Rectangle 1773" width="6.289" height="2.725" transform="translate(18.792 8.919) rotate(-45)"/>
                    </g>
                </g>
            </svg>


        </span>

    )
}
export default Can;

Can.propTypes = {
    className: PropTypes.string,
    fill: PropTypes.string,
};
  
Can.defaultProps = {
    className: undefined,
    fill: '#333',
};
  