import React from 'react';
import './style.scss';
import ContainerContent from '../../../../templates/ContainerContent/ContainerContent';
import { TranslatableText } from '../../../../providers/LanguageProvider';
import { LanguageConsumer } from '../../../../providers/LanguageProvider';
import * as ScrollMagic from "scrollmagic"; // Or use scrollmagic-with-ssr to avoid server rendering problems
import { TimelineMax, Linear } from "gsap"; // Also works with TweenLite and TimelineLite
import vv_bottle_cbd from '../../i/veryvell_4.png';
import Calendar from '../../../../components/svgIcons/Calendar';
import Cocktail3 from '../../../../components/svgIcons/Cocktail3';
//import PotencyLow from '../../../../components/svgIcons/PotencyLow';
import PotencyVvExhale from '../../../../components/svgIcons/PotencyVvExhale';
import Highlights from '../../../../components/svgIcons/Highlights';
import VerywellLinesStatic from '../../i/VerywellLinesStatic';


class CbdDrops extends React.Component {
    constructor(props){
        super(props)

        this.controller = new ScrollMagic.Controller();
    }
    componentWillUnmount() {
        this.controller.destroy();
    }
    componentDidMount = () => {
    
        var title = document.getElementById("title-anim-5");
        //var list = document.getElementById("beverage-info-5");
        //var imgWrap = document.getElementById("img-wrap-5");        
                               
        var timeline2 = new TimelineMax()
        timeline2.to(title, 1, {y:110,/*  textShadow: "1px 1px 1px rgb(204, 46, 76)", */  ease: Linear.easeNone} )
        // timeline.to(list, 1, {y:95,   ease: Linear.easeNone}, 0)
        // timeline2.from(list, 1, {y:95,   ease: Linear.easeNone}, 0)
        //timeline.to(imgWrap, 1, {y:105,   ease: Linear.easeNone}, 0)

        // build scenes
        let scene2 = new ScrollMagic.Scene({triggerElement: ".five", triggerHook: 1, duration: '100%', tweenChanges: true});
            scene2 .setTween(timeline2)
            .on("enter leave", this.addBottleAnimation)
            .on("progress", function (event) {
                //console.log("Scene progress changed to " + event.progress);
            })
            //.addIndicators() // add indicators (requires plugin)
            .addTo(this.controller);

    }

    addBottleAnimation = () => { 
       let bottles =  document.getElementById('img-wrap-5');
       bottles.classList.add('anim');   
    }
    render() {
        return (
            <React.Fragment>
                <div className="blockwrap dark title-wrap">
                    <ContainerContent>
                        <h2>
                            <TranslatableText dictionary={{
                                en: "Cannabis Extracts",
                                fr: "Extraits de cannabis" }}>
                            </TranslatableText>
                        </h2> 
                    </ContainerContent>
                </div>
                <div className="blockwrap five dark"  id={this.props.bevId}>
                    <VerywellLinesStatic />
                    <ContainerContent>
                        {
                            this.props.isDesktop ?
                                <div className="img-wrap" id="img-wrap-5">
                                    <img src={vv_bottle_cbd} className="bottle-cbd bottle" alt="Veryvell™ - CBD Drops" />
                                {/*  <img src={lv_bottle_yellow} className="bottle-yellow yellow-2 bottle abs" /> */}
                                </div>
                            :
                            null
                        }

                        <div className="info-wrap">
                            <h2 id="title-anim-5">
                                <TranslatableText dictionary={{
                                    en: "Get the drop",
                                    fr: "Une goutte de sérénité." }}>
                                </TranslatableText><br />
                                <TranslatableText dictionary={{
                                    en: "on ease.",
                                    fr: "" }}>
                                </TranslatableText>
                            </h2>  
                            <div className="beverage-info" id="beverage-info-5">
                                
                                <LanguageConsumer>
                                    {(context) => (
                                        <React.Fragment>
                                            {context.language !== 'fr' ? 
                                                    <h3>Veryvell™<br /> Exhale Drops</h3>
                                                : 
                                                    <h3>Veryvell™<br /> Détente Gouttes de Boisson</h3>
                                            }
                                        </React.Fragment>
                                    )}
                                </LanguageConsumer>
                                
                                <p>
                                    <TranslatableText dictionary={{
                                        en: "Drop it and exhale.",
                                        fr: "Un goût très relax." }}>
                                    </TranslatableText>
                                </p>
                                {
                                    this.props.isDesktop ?
                                        null
                                    :
                                        <div className="img-wrap" id="img-wrap-5">
                                            <img src={vv_bottle_cbd} className="bottle-cbd bottle" alt="Veryvell™ - CBD Drops" />
                                        {/*  <img src={vv_bottle_cbd} className="bottle-yellow yellow-2 bottle abs" /> */}
                                        </div>
                                }
                                <ul className="standard-list">
                                    <li>
                                        <PotencyVvExhale />
                                        <span>
                                            <h4>
                                                <TranslatableText dictionary={{
                                                    en: "POTENCY",
                                                    fr: "intensité" }}>
                                                </TranslatableText>
                                            </h4>
                                            <p>
                                                <TranslatableText dictionary={{
                                                    en: "2 DROPS = ~ 1MG CBD",
                                                    fr: "2 gouttes =  ENV. 1 MG CBD" }}>
                                                </TranslatableText>
                                            </p>
                                        </span>
                                    </li>
                                    <li>
                                        <Calendar />
                                        <span>
                                            <h4>
                                                <TranslatableText dictionary={{
                                                    en: "OCCASIONS",
                                                    fr: "OCCASIONS" }}>
                                                </TranslatableText>
                                            </h4>
                                            <p>
                                                <TranslatableText dictionary={{
                                                    en: "When you’re seeking moments of calm. ",
                                                    fr: "Quand on cherche la tranquillité." }}>
                                                </TranslatableText><br />
                                                <TranslatableText dictionary={{
                                                    en: "Can’t catch up days.",
                                                    fr: "Les jours où on est débordé." }}>
                                                </TranslatableText>
                                            </p>
                                        </span>
                                    </li>
                                    <li>
                                        <Cocktail3 />
                                        <span>
                                            <h4>
                                                <TranslatableText dictionary={{
                                                    en: "SERVING SUGGESTION",
                                                    fr: "NOTES DE SERVICE" }}>
                                                </TranslatableText>
                                            </h4>
                                            <p>
                                                <TranslatableText dictionary={{
                                                    en: "Add to your teacup and exhale.",
                                                    fr: "Ajouter à une tasse de thé. Relaxer." }}>
                                                </TranslatableText>
                                            </p>
                                        </span>
                                    </li>
                                    <li>
                                        <Highlights />
                                        <span>
                                            <h4>
                                                <TranslatableText dictionary={{
                                                    en: "HIGHLIGHTS",
                                                    fr: "INFORMATIONS" }}>
                                                </TranslatableText>
                                            </h4>
                                            <p>
                                                <TranslatableText dictionary={{
                                                    en: "Water-soluble with a subtle hint of ",
                                                    fr: "Soluble dans l’eau, avec une légère" }}>
                                                </TranslatableText><br />
                                                <TranslatableText dictionary={{
                                                    en: "passionflower extract. Freedom to",
                                                    fr: "touche d’extrait de passiflore." }}>
                                                </TranslatableText><br />
                                                <TranslatableText dictionary={{
                                                    en: "choose your dose.",
                                                    fr: "Libre de choisir sa dose." }}>
                                                </TranslatableText>
                                            </p>
                                        </span>
                                    </li>
                                </ul>

                            </div>

                        </div>
                        

                    </ContainerContent>
                </div>
            </React.Fragment>
        )
    }
}
export default CbdDrops;