import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const Cocktail = (className, fill) => {
    
    return(

        <span className="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="34.107" height="55.738" viewBox="0 0 34.107 55.738">
                <g className="icon-cocktail" id="Group_779" data-name="Group 779" transform="translate(-1709.243 -635.356)">
                    <g id="Group_778" data-name="Group 778" transform="translate(1710.913 640.959)" opacity="0.25">
                    <path id="Path_862" data-name="Path 862" d="M1732.988,651.828v-2.241a3.781,3.781,0,0,0-3.776-3.777h-6.732a3.781,3.781,0,0,0-3.777,3.777v2.241h-6.343v2.68h6.343v1.81a3.781,3.781,0,0,0,3.777,3.777h6.732a3.781,3.781,0,0,0,3.776-3.777v-1.81h10.47v-2.68Zm-2.68,0h-5.694v2.68h5.694v1.81a1.1,1.1,0,0,1-1.1,1.1h-6.732a1.1,1.1,0,0,1-1.1-1.1v-6.732a1.1,1.1,0,0,1,1.1-1.1h6.732a1.1,1.1,0,0,1,1.1,1.1Z" transform="translate(-1712.359 -645.81)" fill="#cc2e4c"/>
                    <path id="Path_863" data-name="Path 863" d="M1738.679,696.431h-7.555a3.369,3.369,0,0,0-3.365,3.365v7.555a3.368,3.368,0,0,0,3.365,3.365h7.555a3.368,3.368,0,0,0,3.365-3.365V699.8A3.369,3.369,0,0,0,1738.679,696.431Zm.685,10.92a.686.686,0,0,1-.685.685h-7.555a.686.686,0,0,1-.686-.685V699.8a.686.686,0,0,1,.686-.686h7.555a.686.686,0,0,1,.685.686Z" transform="translate(-1719.505 -669.301)" fill="#cc2e4c"/>
                    <path id="Path_864" data-name="Path 864" d="M1746.538,674.722h-3.664a3.369,3.369,0,0,0-3.365,3.366v3.664a3.369,3.369,0,0,0,3.365,3.366h3.664a3.369,3.369,0,0,0,3.366-3.366v-3.664A3.37,3.37,0,0,0,1746.538,674.722Zm.686,7.029a.687.687,0,0,1-.686.686h-3.664a.687.687,0,0,1-.685-.686v-3.664a.687.687,0,0,1,.685-.686h3.664a.687.687,0,0,1,.686.686Z" transform="translate(-1724.958 -659.227)" fill="#cc2e4c"/>
                    </g>
                    <path id="Path_865" data-name="Path 865" d="M1742.5,636.438a3.3,3.3,0,0,0-2.44-1.082h-27.523a3.291,3.291,0,0,0-3.278,3.586l3.74,49.189a3.281,3.281,0,0,0,3.275,2.963h20.05a3.289,3.289,0,0,0,3.278-3l3.733-49.125A3.3,3.3,0,0,0,1742.5,636.438Zm-5.568,51.426a.609.609,0,0,1-.609.55h-20.05a.6.6,0,0,1-.605-.519l-3.739-49.187a.612.612,0,0,1,.608-.673h27.523a.607.607,0,0,1,.453.2.631.631,0,0,1,.152.5Z" fill="#cc2e4c"/>
                </g>
            </svg>

        </span>

    )
}
export default Cocktail;

Cocktail.propTypes = {
    className: PropTypes.string,
    fill: PropTypes.string,
};
  
Cocktail.defaultProps = {
    className: undefined,
    fill: '#333',
};
  