import React from 'react';
import './style.scss';
import ContainerContent from '../../../../templates/ContainerContent/ContainerContent';
import { TranslatableText } from '../../../../providers/LanguageProvider';
import { LanguageConsumer } from '../../../../providers/LanguageProvider';
import lv_can_orange from '../../i/can_2.png';
import lv_bottle_orange from '../../i/bottle_orange_2.png';
import * as ScrollMagic from "scrollmagic"; // Or use scrollmagic-with-ssr to avoid server rendering problems
import { TimelineMax, Linear } from "gsap"; // Also works with TweenLite and TimelineLite
//import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
import Calendar from '../../../../components/svgIcons/Calendar';
import Cocktail from '../../../../components/svgIcons/Cocktail';
import PotencyLittleV from '../../../../components/svgIcons/PotencyLittleV';
import Highlights from '../../../../components/svgIcons/Highlights';
import InfoIcon from '../../../../components/svgIcons/InfoIcon';
import Orange_1 from '../../i/blood-orange-1.png';
import Orange_2 from '../../i/blood-orange-2.png';
import Orange_3 from '../../i/blood-orange-3.png';
import NutritionPopUp from '../../../../components/NutritionPopUp/NutritionPopUp';

class OrangeCans extends React.Component {
    constructor(props){
        super(props)
        this.controller = new ScrollMagic.Controller();
    }
    componentWillUnmount() {
        this.controller.destroy();
    }
    componentDidMount(){

        var title = document.getElementById("title-anim-5");
        //var list = document.getElementById("beverage-info-5");
        //var imgWrap = document.getElementById("img-wrap-5");
        
                               
        var timeline = new TimelineMax()
        timeline.to(title, 1, {y:110, /* textShadow: "1px 1px 1px rgb(204, 46, 76)", */  ease: Linear.easeNone} )
       // timeline.to(list, 1, {y:95,   ease: Linear.easeNone}, 0)
        //timeline.from(list, 1, {y:95,   ease: Linear.easeNone}, 0)
        //timeline.to(imgWrap, 1, {y:105,   ease: Linear.easeNone}, 0)

        
        // build scenes
       new ScrollMagic.Scene({triggerElement: ".five", triggerHook: 1, duration: '100%', tweenChanges: true})
            .setTween(timeline)
            .on("enter leave", this.addBottleAnimation)
            .on("progress", function (event) {
                //console.log("Scene progress changed to " + event.progress);
            })
            //.addIndicators() // add indicators (requires plugin)
            .addTo(this.controller);
    }

    addBottleAnimation = () => { 
        let bottles =  document.getElementById('img-wrap-5');
        bottles.classList.add('anim');   
    }
    render() {
        return (
            <div className="blockwrap five" id={this.props.bevId}>

            <div className="bg-fruits">
                <img src={Orange_1} className="slice-1" alt="blood orange slice" />
                <img src={Orange_2} className="slice-2" alt="blood orange half" />
            </div>

                <ContainerContent>
                  
                    
                    <div className="info-wrap">
                        <LanguageConsumer>
                                {(context) => (
                                    <React.Fragment>
                                        {context.language !== 'fr' ? 
                                                <h2 id="title-anim-5">Watched that video<br />of your nephew’s<br />grad. Stayed awake.<br />Cheers to the little<br />successes.</h2>
                                            : 
                                                <h2 id="title-anim-5">Vous ne vous êtes pas<br />endormi en regardant<br />endormi en regardant<br />la vidéo de graduation<br />de votre neveu?<br />Célébrons les petites<br />victoires.</h2>
                                                
                                        }
                                    </React.Fragment>
                                )}
                        </LanguageConsumer>
                        
                        <div className="beverage-info" id="beverage-info-5">
                            
                            <LanguageConsumer>
                                {(context) => (
                                    <React.Fragment>
                                        {context.language !== 'fr' ? 
                                                <h3>Little Victory™<br /> Sparkling Blood Orange</h3>
                                            : 
                                                <h3>Little Victory™<br /> Orange sanguine pétillante</h3>
                                        }
                                    </React.Fragment>
                                )}
                            </LanguageConsumer>
                            <p>
                                <TranslatableText dictionary={{
                                    en: "Celebrate a little with this blood-",
                                    fr: "Célébrez un peu avec cette boisson" }}>
                                </TranslatableText><br />
                                <TranslatableText dictionary={{
                                    en: "orange flavoured, naturally sweetened,",
                                    fr: "pétillante à la saveur d’orange sanguine,"}}>
                                </TranslatableText><br />
                                <TranslatableText dictionary={{
                                    en: "fruit forward, sparkling beverage.",
                                    fr: "naturellement sucrée et fruitée."}}>
                                </TranslatableText>
                            </p>
                            {
                                this.props.isDesktop ?
                                   null
                                :
                                    <div className="img-wrap" id="img-wrap-5">
                                        <img src={lv_bottle_orange} className="can-orange bottle" alt="Little Victory™ - Sparkling Blood Orange" />
                                        <img src={lv_bottle_orange} className="can-orange orange-2 bottle abs" alt="Little Victory™ - Sparkling Blood Orange" />
                                        <img src={Orange_3} className="slice-3" alt="blood orange wedge" />
                                    </div>
                            }

                            <ul className="standard-list">
                                <li>
                                    <PotencyLittleV />
                                    <span>
                                        <h4>
                                            <TranslatableText dictionary={{
                                                en: "POTENCY",
                                                fr: "intensité"}}>
                                            </TranslatableText>
                                        </h4>
                                        <p>
                                            <TranslatableText dictionary={{
                                                en: "1:1 LOW",
                                                fr: "1:1 faible"}}>
                                            </TranslatableText><br />
                                            <TranslatableText dictionary={{
                                                en: "2.5MG THC + 2.5MG CBD",
                                                fr: "2,5 mg THC : 2,5 mg CBD"}}>
                                            </TranslatableText>
                                        </p>
                                    </span>
                                </li>
                                <li>
                                    <Calendar />
                                    <span>
                                        <h4>
                                            <TranslatableText dictionary={{
                                                en: "OCCASIONS",
                                                fr: "OCCASIONS"}}>
                                            </TranslatableText>
                                        </h4>
                                        <p>
                                            <TranslatableText dictionary={{
                                                en: "When that rice trick actually fixes your phone.",
                                                fr: "Parce que le truc du riz a marché pour votre téléphone."}}>
                                            </TranslatableText><br />
                                            <TranslatableText dictionary={{
                                                en: "No more laundry pile.",
                                                fr: "Quand la lessive est enfin terminée."}}>
                                            </TranslatableText><br /> 
                                            <TranslatableText dictionary={{
                                                en: "Not going over data.",
                                                fr: "Vous n’avez pas dépassé votre limite de données."}}>
                                            </TranslatableText>      
                                        </p>
                                    </span>
                                </li>
                                <li>
                                    <Cocktail />
                                    <span>
                                        <h4>
                                            <TranslatableText dictionary={{
                                                en: "SERVING SUGGESTION",
                                                fr: "NOTES DE SERVICE"}}>
                                            </TranslatableText>
                                        </h4>
                                        <p>
                                            <TranslatableText dictionary={{
                                                en: "Serve over ice.",
                                                fr: "Servir sur glace."}}>
                                            </TranslatableText><br /> 
                                            <TranslatableText dictionary={{
                                                en: "Blood orange garnish. ",
                                                fr: "Ajouter une tranche d’orange sanguine."}}>
                                            </TranslatableText>
                                        </p>
                                    </span>
                                </li>
                                <li>
                                    <Highlights />
                                    <span>
                                        <h4>
                                            <TranslatableText dictionary={{
                                                en: "HIGHLIGHTS",
                                                fr: "INFORMATIONS"}}>
                                            </TranslatableText>
                                        </h4>
                                        <p>
                                            <TranslatableText dictionary={{
                                                en: "Made with 5% real orange juice from concentrate.",
                                                fr: "Faite avec 5 % de vrai jus d’orange à base de concentré." }}>
                                            </TranslatableText>
                                        </p>
                                    </span>
                                </li>
                                <li>
                                    <InfoIcon />
                                    <span>
                                        <h4>
                                            <TranslatableText dictionary={{
                                                en: "NUTRITIONAL INFORMATION",
                                                fr: "INFORMATIONS NUTRITIVE" }}>
                                            </TranslatableText>
                                        </h4>
                                        <div className="paragraph">
                                            <NutritionPopUp beverage="blood-orange" />
                                        </div>
                                    </span>
                                </li>
                            </ul>
                        </div> 
                    </div>
                    {
                        this.props.isDesktop ?
                            <div className="img-wrap" id="img-wrap-5">
                                <img src={lv_bottle_orange} className="can-orange bottle" alt="Little Victory™ - Sparkling Blood Orange" />
                                <img src={lv_bottle_orange} className="can-orange orange-2 bottle abs" alt="Little Victory™ - Sparkling Blood Orange" />
                                <img src={Orange_3} className="slice-3" alt="blood orange wedge" />
                            </div>
                        :
                        null
                    }
                </ContainerContent>
            </div>
        )
    }
}
export default OrangeCans;