import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const Bottle = (className, fill) => {
    
    return(

        <span className="icon">
            <svg className="icon-bottle" xmlns="http://www.w3.org/2000/svg" width="37.142" height="61.853" viewBox="0 0 37.142 61.853">
                <g  data-name="Group 591" transform="translate(-9974.35 -4123.303)">
                    <g id="Group_34" data-name="Group 34" transform="translate(9981.986 4124.329)">
                    <path id="Path_41" data-name="Path 41" d="M10008.852,4155.675c-1.416-3.1-2.639-5.777-2.639-9.864v-2.381h-9.793v2.381c0,4.086-1.223,6.764-2.639,9.864-1.593,3.49-3.4,7.446-3.4,13.7V4191.3a4.4,4.4,0,0,0,4.394,4.394h13.081a4.4,4.4,0,0,0,4.393-4.394v-21.926C10012.25,4163.121,10010.444,4159.165,10008.852,4155.675Zm1.018,35.626a2.012,2.012,0,0,1-2.012,2.012h-13.081a2.013,2.013,0,0,1-2.013-2.012v-21.926c0-11.067,6.037-14.005,6.037-23.564h5.031c0,9.559,6.037,12.5,6.037,23.564Z" transform="translate(-9990.383 -4134.869)" />
                    <path id="Path_42" data-name="Path 42" d="M10010.469,4131.979h2.382v-3.2a3.323,3.323,0,0,0-3.319-3.319h-3.155a3.323,3.323,0,0,0-3.319,3.319v3.2h7.412Zm-5.03-3.2a.937.937,0,0,1,.938-.937h3.155a.937.937,0,0,1,.938.937v.823h-5.03Z" transform="translate(-9997.02 -4125.456)" />
                    </g>
                    <g id="Group_35" data-name="Group 35" transform="translate(9974.35 4123.303)" opacity="0.4">
                    <rect id="Rectangle_1" data-name="Rectangle 1" width="4.751" height="2.382" transform="translate(32.391 7.425)" />
                    <rect id="Rectangle_2" data-name="Rectangle 2" width="5.497" height="2.381" transform="translate(27.662 3.887) rotate(-45)" />
                    <rect id="Rectangle_3" data-name="Rectangle 3" width="2.381" height="5.497" transform="translate(27.662 13.345) rotate(-45)" />
                    <rect id="Rectangle_4" data-name="Rectangle 4" width="4.751" height="2.382" transform="translate(0 7.425)" />
                    <rect id="Rectangle_5" data-name="Rectangle 5" width="5.497" height="2.381" transform="translate(3.909 15.548) rotate(-45)" />
                    <rect id="Rectangle_6" data-name="Rectangle 6" width="2.381" height="5.497" transform="translate(3.909 1.684) rotate(-45)" />
                    </g>
                </g>
            </svg>

        </span>

    )
}
export default Bottle;

Bottle.propTypes = {
    className: PropTypes.string,
    fill: PropTypes.string,
  };
  
Bottle.defaultProps = {
    className: undefined,
    fill: '#333',
};
  