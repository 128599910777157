import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const PotencyVvYawn = (className, fill) => {
    
    return(

        <span className="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="108.757" height="108.698" viewBox="0 0 108.757 108.698">
                <g id="Group_841" data-name="Group 841" transform="translate(-1168.231 -704.818)">
                    <g id="Group_741" data-name="Group 741">
                    <path id="Path_698" data-name="Path 698" d="M1230.63,767.385a5.44,5.44,0,0,0,5.441-5.44v-2.631a5.8,5.8,0,0,0-.682-2.729l-3.757-7.036a1.134,1.134,0,0,0-2,0l-3.758,7.036a5.805,5.805,0,0,0-.683,2.729v2.631A5.439,5.439,0,0,0,1230.63,767.385Z" fill="#d69568"/>
                    <path id="Path_699" data-name="Path 699" d="M1214.589,767.385a5.44,5.44,0,0,0,5.441-5.44v-2.631a5.793,5.793,0,0,0-.683-2.729l-3.757-7.036a1.133,1.133,0,0,0-2,0l-3.758,7.036a5.793,5.793,0,0,0-.683,2.729v2.631A5.439,5.439,0,0,0,1214.589,767.385Z" fill="#d69568"/>
                    </g>
                    <g id="Group_756" data-name="Group 756">
                    <g id="Group_748" data-name="Group 748">
                        <g id="Group_744" data-name="Group 744">
                        <g id="Group_743" data-name="Group 743">
                            <g id="Group_742" data-name="Group 742">
                            <path id="Path_700" data-name="Path 700" d="M1222.6,798.079a38.908,38.908,0,0,1-35.514-54.726,37.953,37.953,0,0,1,35.527-23.072v5a33.071,33.071,0,0,0-30.958,20.1,33.888,33.888,0,1,0,44.742-17.175l2.033-4.568a38.91,38.91,0,0,1-15.83,74.436Z" fill="rgba(214,149,104,0.3)"/>
                            </g>
                        </g>
                        </g>
                        <g id="Group_747" data-name="Group 747">
                        <g id="Group_746" data-name="Group 746">
                            <g id="Group_745" data-name="Group 745">
                            <path id="Path_701" data-name="Path 701" d="M1236.394,728.211a32.352,32.352,0,0,0-13.784-2.93v-5a37.122,37.122,0,0,1,15.817,3.362Z" fill="rgba(214,149,104,0.3)"/>
                            </g>
                        </g>
                        </g>
                    </g>
                    <g id="Group_755" data-name="Group 755">
                        <g id="Group_751" data-name="Group 751">
                        <g id="Group_750" data-name="Group 750">
                            <g id="Group_749" data-name="Group 749">
                            <path id="Path_702" data-name="Path 702" d="M1222.531,813.516a54.348,54.348,0,0,1-49.572-76.454c8.99-20.19,27.55-32.244,49.651-32.244v5c-20.068,0-36.921,10.945-45.082,29.277a49.349,49.349,0,1,0,65.155-25.01l2.033-4.568a54.349,54.349,0,0,1-22.185,104Z" fill="rgba(214,149,104,0.3)"/>
                            </g>
                        </g>
                        </g>
                        <g id="Group_754" data-name="Group 754">
                        <g id="Group_753" data-name="Group 753">
                            <g id="Group_752" data-name="Group 752">
                            <path id="Path_703" data-name="Path 703" d="M1242.683,714.085a47.089,47.089,0,0,0-20.073-4.267v-5a51.871,51.871,0,0,1,22.106,4.7Z" fill="#d69568"/>
                            </g>
                        </g>
                        </g>
                    </g>
                    </g>
                </g>
                </svg>

        </span>

    )
}
export default PotencyVvYawn;

PotencyVvYawn.propTypes = {
    className: PropTypes.string,
    fill: PropTypes.string,
  };
  
PotencyVvYawn.defaultProps = {
    className: undefined,
    fill: '#333',
};
  
