import React from 'react';

const TerpLinesStatic = () => {
  return (
    <div className="svg-bg">
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 570 570" id="Page3" preserveAspectRatio="xMidYMid meet">
          <g transform="translate(0 300) scale(1.025)" opacity="0.15" vectorEffect="non-scaling-stroke">
              <circle id="Circle" cx="277.5" cy="-14" r="275" fill="none" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              <g transform="translate(471.671 -208.388)">
                  <path d="M0,0V388.559" fill="none" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </g>
              <g transform="translate(83.11 -208.388)">
                  <path d="M0,0V388.559" fill="none" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </g>

              <line x1="84" y1="-208" x2="470" y2="-208" stroke="#fff" fill="none" strokeWidth="0.75" opacity="0.5" />
              <line x1="84" y1="-13" x2="470" y2="-13" stroke="#fff" fill="none" strokeWidth="0.75" opacity="0.5" />
              <line x1="277" y1="-208" x2="277" y2="180" stroke="#fff" fill="none" strokeWidth="0.75" opacity="0.5" />
              <line x1="84" y1="181" x2="470" y2="181" stroke="#fff" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75" opacity="0.5" />
              <line x1="84" y1="181" x2="277" y2="-208" stroke="#fff" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75" opacity="0.5" />
              <line x1="470" y1="181" x2="277" y2="-208" stroke="#fff" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75" opacity="0.5" />
              <line x1="84" y1="181" x2="470" y2="-208" stroke="#fff" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75" opacity="0.5" />
              <line x1="470" y1="181" x2="84" y2="-208" stroke="#fff" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75" opacity="0.5" />

              <g transform="translate(84 -208.39)">
                  <path d="M0,0,193.39,195.55,387.48,0" fill="none" stroke="#fff" strokeWidth="2" />
              </g>
          </g>
      </svg>
  </div>
  )
}

export default TerpLinesStatic;