import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const Cocktail4 = (className, fill) => {
    
    return(

        <span className="icon">

            <svg className="icon-cocktail " id="Group_593" data-name="Group 593" xmlns="http://www.w3.org/2000/svg" width="47.908" height="60.109" viewBox="0 0 47.908 60.109">
                <g id="Group_48" data-name="Group 48" transform="translate(0.97 25.543)">
                    <path id="Path_62" data-name="Path 62" d="M10313.008,4420.7v-2.487a4.194,4.194,0,0,0-4.19-4.19h-7.47a4.2,4.2,0,0,0-4.189,4.19v2.487h-13.4v2.973h13.4v2.009a4.2,4.2,0,0,0,4.189,4.191h7.47a4.2,4.2,0,0,0,4.19-4.191v-2.009h5.255V4420.7Zm-2.974,4.982a1.217,1.217,0,0,1-1.217,1.217h-7.47a1.218,1.218,0,0,1-1.217-1.217v-2.009h6.318V4420.7h-6.318v-2.487a1.218,1.218,0,0,1,1.217-1.217h7.47a1.218,1.218,0,0,1,1.217,1.217Z" transform="translate(-10283.758 -4414.025)" fill="#a4aeba"/>
                    <path id="Path_63" data-name="Path 63" d="M10324.919,4443.509h-4.065a3.738,3.738,0,0,0-3.734,3.734v4.065a3.738,3.738,0,0,0,3.734,3.734h4.065a3.737,3.737,0,0,0,3.732-3.734v-4.065A3.737,3.737,0,0,0,10324.919,4443.509Zm.76,7.8a.761.761,0,0,1-.76.761h-4.065a.762.762,0,0,1-.761-.761v-4.065a.762.762,0,0,1,.761-.761h4.065a.761.761,0,0,1,.76.761Z" transform="translate(-10297.28 -4425.976)" fill="#a4aeba"/>
                </g>
                <path id="Path_64" data-name="Path 64" d="M10318,4378.751a12.053,12.053,0,0,0-12.038,12.037h-13.691v-3.932l4.136-2.068-1.331-2.659-2.8,1.4V4381.9l4.136-2.066-1.331-2.66-2.8,1.4v-1.631l4.136-2.068-1.331-2.659-2.8,1.4v-2.55h-2.973v2.55l-2.8-1.4-1.332,2.659,4.135,2.068v1.631l-2.8-1.4-1.332,2.66,4.135,2.067v1.631l-2.8-1.4-1.332,2.659,4.135,2.068v3.932h-3.438a3.734,3.734,0,0,0-3.729,3.73v30.856a5.812,5.812,0,0,0,5.8,5.806h25.987a5.812,5.812,0,0,0,5.8-5.806v-30.856a3.7,3.7,0,0,0-.077-.756h10.389v-2.973A12.052,12.052,0,0,0,10318,4378.751Zm-1.485,3.105v5.847l-4.069-4.067A9.015,9.015,0,0,1,10316.511,4381.857Zm-6.091,3.962,4.971,4.969h-6.459A9.023,9.023,0,0,1,10310.42,4385.819Zm6.329,39.555a2.831,2.831,0,0,1-2.831,2.833h-25.987a2.832,2.832,0,0,1-2.832-2.833v-30.856a.757.757,0,0,1,.757-.757h3.438v31.621h2.973v-31.621H10316a.756.756,0,0,1,.751.756Zm2.735-37.67v-5.847a9,9,0,0,1,4.067,1.78Zm1.118,3.085,4.97-4.97a9.019,9.019,0,0,1,1.489,4.97Z" transform="translate(-10282.126 -4371.071)" fill="#fff"/>
            </svg>

        </span>

    )
}
export default Cocktail4;

Cocktail4.propTypes = {
    className: PropTypes.string,
    fill: PropTypes.string,
};
  
Cocktail4.defaultProps = {
    className: undefined,
    fill: '#333',
};
  