import React from 'react';
import './style.scss';
import {TimelineMax, Linear, TweenLite } from "gsap"; // Also works with TweenLite and TimelineLite
import CSSRulePlugin from "gsap/CSSRulePlugin";
let tl;

class XmgLines extends React.Component {
    // constructor(){
    //     super()
    // }
    componentDidMount = () => {
        // this.constructTimeline(false);
    }
    createColorCycle = (selectorRule, indexOffset) => {
        var colorMix = ["#eb6838", "#e13d4e", "#ca216c", "#9f239e"];
        var c1 = (0 + indexOffset) % colorMix.length;
        var c2 = (1 + indexOffset) % colorMix.length;
        var c3 = (2 + indexOffset) % colorMix.length;
        var c4 = (3 + indexOffset) % colorMix.length;

        var colorBandTL = new TimelineMax({ repeat: -1 });
        colorBandTL.fromTo(
        selectorRule,
        7,
        { cssRule: { fill: colorMix[c1] } },
        { cssRule: { fill: colorMix[c2] }, ease: Linear.easeNone }
        );
        colorBandTL.fromTo(
        selectorRule,
        7,
        { cssRule: { fill: colorMix[c2] } },
        { cssRule: { fill: colorMix[c3] }, ease: Linear.easeNone }
        );
        colorBandTL.fromTo(
        selectorRule,
        7,
        { cssRule: { fill: colorMix[c3] } },
        { cssRule: { fill: colorMix[c4] }, ease: Linear.easeNone }
        );
        colorBandTL.fromTo(
        selectorRule,
        7,
        { cssRule: { fill: colorMix[c4] } },
        { cssRule: { fill: colorMix[c1] }, ease: Linear.easeNone }
        );
        colorBandTL.timeScale(3);
    }
    constructTimeline = (startPaused) => {
        tl = new TimelineMax({ id: "MainTimeline", paused: startPaused });
        //tl.from("#Xmg-Container", 0.5, { opacity: 0 }, "+=0.2");
        const color1Rule = CSSRulePlugin.getRule(".page-4 .color1");
        const color2Rule = CSSRulePlugin.getRule(".page-4 .color2");
        const color3Rule = CSSRulePlugin.getRule(".page-4 .color3");
        const color4Rule = CSSRulePlugin.getRule(".page-4 .color4");
        this.createColorCycle(color1Rule, 0);
        this.createColorCycle(color2Rule, 1);
        this.createColorCycle(color3Rule, 2);
        this.createColorCycle(color4Rule, 3);
    }

    render() {
        return (
            <div id="Xmg-Container">
                 <div className="svg page-4">
                     
                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
                        <defs>
                        <clipPath id="b">
                            <rect x="0" y="0" width="614" height="800" />
                        </clipPath>
                        <pattern id="diamonds" viewBox="0,0,614,800" width="100%" height="25%" patternUnits="userSpaceOnUse" preserveAspectRatio="xMidYMid slice">
                            <g clipPath="url(#b)">
                                <g transform="translate(-541.345 -529.415)" opacity="0.7">
                                        <g transform="translate(366.58 -126.868)">
                                        <path
                                            d="M482.6-.754,973.064,1078.27,482.6,2157.294-7.867,1078.27"
                                            transform="translate(-2.334 -21.687)"
                                            className="color3"
                                        />
                                        <path
                                            d="M472.4-.754,952.663,1055.829,472.4,2112.412-7.867,1055.829"
                                            transform="translate(7.867 0.754)"
                                            className="color4"
                                        />
                                        <path
                                            d="M457.846-.754,923.556,1023.811,457.846,2048.376-7.867,1023.811"
                                            transform="translate(22.42 32.772)"
                                            className="color3"
                                        />
                                        <path
                                            d="M443.292-.754,894.449,991.794,443.292,1984.341-7.867,991.794"
                                            transform="translate(36.004 64.789)"
                                            className="color2"
                                        />
                                        <path
                                            d="M428.739-.754l436.6,960.53-436.6,960.53L-7.867,959.776"
                                            transform="translate(51.527 96.807)"
                                            className="color1"
                                        />
                                        <path
                                            d="M414.185-.754l422.05,928.512-422.05,928.513L-7.867,927.758"
                                            transform="translate(65.111 128.825)"
                                            className="color2"
                                        />
                                        <path
                                            d="M399.632-.754l407.5,896.495-407.5,896.495L-7.867,895.741"
                                            transform="translate(79.664 160.842)"
                                            className="color3"
                                        />
                                        <path
                                            d="M385.078-.754,778.021,863.723,385.078,1728.2-7.867,863.723"
                                            transform="translate(94.217 192.86)"
                                            className="color4"
                                        />
                                        <path
                                            d="M370.524-.754l378.39,832.459-378.39,832.459L-7.867,831.705"
                                            transform="translate(108.771 224.878)"
                                            className="color3"
                                        />
                                        <path
                                            d="M355.971-.754,719.807,799.688,355.971,1600.128-7.867,799.688"
                                            transform="translate(123.324 256.895)"
                                            className="color2"
                                        />
                                        <path
                                            d="M341.417-.754,690.7,767.67,341.417,1536.094-7.867,767.67"
                                            transform="translate(137.878 288.913)"
                                            className="color1"
                                        />
                                        <path
                                            d="M326.864-.754l334.73,736.406-334.73,736.407L-7.867,735.652"
                                            transform="translate(152.431 320.931)"
                                            className="color2"
                                        />
                                        <path
                                            d="M312.311-.754,632.487,703.635,312.311,1408.025-7.867,703.635"
                                            transform="translate(166.985 352.949)"
                                            className="color3"
                                        />
                                        <path
                                            d="M297.757-.754,603.38,671.617,297.757,1343.989-7.867,671.617"
                                            transform="translate(181.538 384.966)"
                                            className="color4"
                                        />
                                        <path
                                            d="M283.2-.754,574.273,639.6,283.2,1279.953-7.867,639.6"
                                            transform="translate(196.092 416.984)"
                                            className="color3"
                                        />
                                        <path
                                            d="M268.65-.754,545.166,607.582,268.65,1215.918-7.867,607.582"
                                            transform="translate(210.645 449.002)"
                                            className="color2"
                                        />
                                        <path
                                            d="M254.1-.754,516.059,575.564,254.1,1151.883-7.867,575.564"
                                            transform="translate(225.199 481.019)"
                                            className="color1"
                                        />
                                        <path
                                            d="M239.543-.754l247.409,544.3-247.409,544.3-247.41-544.3"
                                            transform="translate(240.723 513.037)"
                                            className="color2"
                                        />
                                        <path
                                            d="M224.99-.754,457.845,511.529,224.99,1023.812-7.867,511.529"
                                            transform="translate(254.306 545.055)"
                                            className="color3"
                                        />
                                        <path
                                            d="M210.436-.754l218.3,480.265-218.3,480.265L-7.867,479.512"
                                            transform="translate(268.859 577.072)"
                                            className="color4"
                                        />
                                        <path
                                            d="M195.883-.754,399.631,447.494,195.883,895.741-7.867,447.494"
                                            transform="translate(283.413 609.09)"
                                            className="color2"
                                        />
                                        <path
                                            d="M181.329-.754l189.2,416.23-189.2,416.23-189.2-416.23"
                                            transform="translate(297.967 641.108)"
                                            className="color1"
                                        />
                                        <path
                                            d="M166.776-.754,341.417,383.458,166.776,767.67-7.867,383.458"
                                            transform="translate(312.52 673.126)"
                                            className="color2"
                                        />
                                        <path
                                            d="M152.222-.754,312.31,351.441,152.222,703.635-7.867,351.441"
                                            transform="translate(327.074 705.143)"
                                            className="color3"
                                        />
                                        <path
                                            d="M137.669-.754,283.2,319.423,137.669,639.6-7.867,319.423"
                                            transform="translate(341.627 737.16)"
                                            className="color4"
                                        />
                                        <path
                                            d="M123.115-.754,254.1,287.405,123.115,575.565-7.867,287.405"
                                            transform="translate(356.18 769.178)"
                                            className="color3"
                                        />
                                        <path
                                            d="M108.561-.754,224.989,255.388,108.561,511.529-7.867,255.388"
                                            transform="translate(370.734 801.196)"
                                            className="color2"
                                        />
                                        <path
                                            d="M94.008-.754,195.882,223.37,94.008,447.494-7.867,223.37"
                                            transform="translate(385.287 833.214)"
                                            className="color1"
                                        />
                                        <path
                                            d="M79.454-.754l87.321,192.106L79.454,383.458-7.867,191.352"
                                            transform="translate(399.841 865.232)"
                                            className="color3"
                                        />
                                        <path
                                            d="M64.9-.754l72.767,160.088L64.9,319.423-7.867,159.335"
                                            transform="translate(414.394 897.249)"
                                            className="color4"
                                        />
                                        <path
                                            d="M50.347-.754l58.214,128.071L50.347,255.388-7.867,127.317"
                                            transform="translate(428.948 929.267)"
                                            className="color3"
                                        />
                                        <path
                                            d="M35.794-.754,79.454,95.3l-43.66,96.053L-7.867,95.3"
                                            transform="translate(443.501 961.285)"
                                            className="color2"
                                        />
                                        <path
                                            d="M21.24-.754,50.347,63.281,21.24,127.317-7.867,63.281"
                                            transform="translate(458.055 993.302)"
                                            className="color1"
                                        />
                                        <path
                                            d="M6.687-.754,21.24,31.264,6.687,63.281-7.867,31.264"
                                            transform="translate(472.608 1025.32)"
                                            className="color2"
                                        />
                                        </g>
                                    </g>
                                </g>
                            </pattern>
                        </defs>
                        <rect x="0" y="0" height="100%" width="100%" fill="url(#diamonds)" opacity="0.5" />
                    </svg>
                    
                </div>
            </div>
        )
    }
}
export default XmgLines;