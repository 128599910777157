import React from 'react';
import {TransitionGroup, CSSTransition, Transition } from 'react-transition-group';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import './style.scss';

class Drawer extends React.Component {
    constructor(props){
        super(props)
        this.state ={
            drawerShow: false,
            id: props.id || '',
            title: props.title || ''            
        }
    }

    drawerToggle = () => {
        console.log('toggle drawer');
        this.setState((prevState) => {
            return { drawerShow: !prevState.drawerShow };
        })
    }
    render() {
        if (this.state.drawerShow) {
			var children = this.props.children;
		} else {
			var children = '';
		}
        return (
            <div id={this.state.id} className={`drawer  ${this.state.drawerShow ? 'open' : ''}`}>
                
                <div className="title-wrap" onClick={this.drawerToggle}>
                    <h4>{this.state.title}</h4>
                    <div className="icon">{this.props.icon}</div>
                </div>
             
                <CSSTransition
                    in={this.state.drawerShow}
                    classNames="drawer-transition"
                    timeout={{ enter: 400, exit: 400 }}
                >
                    <div className="drawer-transition-enter"> {this.props.children}</div>
                </CSSTransition>
               
            </div>
        )
    }
}
export default Drawer;