import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, Link } from 'react-router-dom';
import TrussLogo from '../../assets_global/images/truss-logo@2x.png';
import './style.scss';

const Logo = (className, fill) => (

  /*   <Link to="/" ><img className="logo" src={TrussLogo} alt="Truss Beverages" /></Link> */
    <Link to="/" className="logo-svg">
   <svg xmlns="http://www.w3.org/2000/svg" className={className} width="126.62" height="51.763" viewBox="0 0 126.62 51.763">
  <g id="Group_736" data-name="Group 736"  transform="translate(-10.723 -12.818)">
    <g id="Group_732" data-name="Group 732"  transform="translate(10.723 12.818)">
      <g id="Group_730" data-name="Group 730" transform="translate(27.734 42.462)">
        <path id="Path_938" className="pathfill" data-name="Path 938" d="M257.522,389.053a1.828,1.828,0,0,1,1.06,1.728,2.028,2.028,0,0,1-2.152,1.953h-2.843v-7.1h2.806a1.861,1.861,0,0,1,2,1.815,1.9,1.9,0,0,1-.874,1.6Zm-1.146-2.546h-1.907v2.128h1.907a1.065,1.065,0,1,0-.006-2.128Zm1.29,4.175a1.182,1.182,0,0,0-1.239-1.175H254.47v2.35h1.953a1.177,1.177,0,0,0,1.24-1.176Z" transform="translate(-253.586 -385.522)" fill="#fff"/>
        <path id="Path_939" className="pathfill" data-name="Path 939" d="M310.651,402h-4.672a1.862,1.862,0,0,0,1.929,1.681,1.893,1.893,0,0,0,1.706-.947h.91a3.009,3.009,0,1,1,.145-1.047C310.669,401.791,310.66,401.895,310.651,402ZM306,401.2H309.8a1.936,1.936,0,0,0-3.806,0Z" transform="translate(-299.211 -397.187)" fill="#fff"/>
        <path id="Path_940" className="pathfill" data-name="Path 940" d="M362.2,399.86l-2.311,5.484h-.785l-2.371-5.484h.9l1.861,4.357,1.823-4.357Z" transform="translate(-344.957 -398.123)" fill="#fff"/>
        <path id="Path_941" className="pathfill" data-name="Path 941" d="M413.034,402h-4.674a1.862,1.862,0,0,0,1.929,1.681,1.893,1.893,0,0,0,1.706-.946h.91a3.009,3.009,0,1,1,.145-1.047A2.887,2.887,0,0,1,413.034,402Zm-4.653-.8h3.806a1.936,1.936,0,0,0-3.806,0Z" transform="translate(-389.902 -397.187)" fill="#fff"/>
        <path id="Path_942" className="pathfill" data-name="Path 942" d="M470.152,399.609v.819H469.6a1.5,1.5,0,0,0-1.568,1.715v2.978h-.862v-5.484h.613l.141.835a1.994,1.994,0,0,1,1.752-.863Z" transform="translate(-442.772 -397.901)" fill="#fff"/>
        <path id="Path_943" className="pathfill" data-name="Path 943" d="M501.864,398.928v5.484h-.714l-.091-.83a2.859,2.859,0,1,1,.011-3.81l.107-.84Zm-.881,2.74a2.015,2.015,0,1,0-2.01,2.028,1.958,1.958,0,0,0,2.01-2.028Z" transform="translate(-468.381 -397.192)" fill="#fff"/>
        <path id="Path_944" className="pathfill" data-name="Path 944" d="M562.134,398.927v4.714a2.557,2.557,0,0,1-2.687,2.843,2.479,2.479,0,0,1-2.6-1.863h.862a1.747,1.747,0,0,0,1.733,1.074,1.77,1.77,0,0,0,1.843-1.943v-.335a2.719,2.719,0,1,1,.131-3.638l.137-.853Zm-.853,2.6a1.9,1.9,0,1,0-1.894,1.883,1.834,1.834,0,0,0,1.895-1.883Z" transform="translate(-521.994 -397.19)" fill="#fff"/>
        <path id="Path_945" className="pathfill" data-name="Path 945" d="M620.024,402h-4.672a1.862,1.862,0,0,0,1.929,1.681,1.893,1.893,0,0,0,1.706-.947h.91a3.008,3.008,0,1,1,.146-1.047A3.106,3.106,0,0,1,620.024,402Zm-4.652-.8h3.806a1.936,1.936,0,0,0-3.806,0Z" transform="translate(-573.255 -397.187)" fill="#fff"/>
        <path id="Path_946" className="pathfill" data-name="Path 946" d="M702.608,392a3.663,3.663,0,1,1,0-7.326,3.357,3.357,0,0,1,3.367,2.512h-.91a2.5,2.5,0,0,0-2.458-1.64,2.791,2.791,0,1,0,0,5.583,2.51,2.51,0,0,0,2.492-1.745h.909a3.38,3.38,0,0,1-3.4,2.616Z" transform="translate(-648.104 -384.668)" fill="#fff"/>
        <path id="Path_947" className="pathfill" data-name="Path 947" d="M766.786,401.637a2.906,2.906,0,1,1,2.906,2.861,2.805,2.805,0,0,1-2.906-2.861Zm2.906,2.028a2.028,2.028,0,1,0-2.025-2.028,1.959,1.959,0,0,0,2.025,2.028Z" transform="translate(-708.18 -397.16)" fill="#fff"/>
        <path id="Path_948" className="pathfill" data-name="Path 948" d="M819.935,438.412h.99v1.078h-.99Z" transform="translate(-755.26 -432.273)" fill="#fff"/>
      </g>
      <g id="Group_731" data-name="Group 731" transform="translate(0)">
        <path id="Path_949" className="pathfill" data-name="Path 949" d="M305.456,78.342a.38.38,0,0,0-.308-.155h-.916a.38.38,0,0,0-.308.155c-.352.479-1.47,2-2.688,3.6l.008.006a4.364,4.364,0,1,0,6.822-.108C306.879,80.274,305.8,78.81,305.456,78.342Z" transform="translate(-267.261 -70.722)" fill="#fff"/>
        <path id="Path_950" className="pathfill" data-name="Path 950" d="M192.12,98.447V83.219a1.147,1.147,0,0,1,.337-.812l2.987-2.987V78.233H181.03V79.42l2.987,2.987a1.149,1.149,0,0,1,.337.812V98.447a1.148,1.148,0,0,1-.336.812l-2.987,2.988v1.187h14.413v-1.187l-2.987-2.987a1.147,1.147,0,0,1-.337-.813Z" transform="translate(-161.581 -70.763)" fill="#fff"/>
        <path id="Path_951" className="pathfill" data-name="Path 951" d="M396.257,98.5V78.233H385.122V79.42l2.987,2.987a1.149,1.149,0,0,1,.337.812V95.662h0a3.831,3.831,0,0,1-3.976,3.825,3.89,3.89,0,0,1-3.681-3.92V78.233H369.65V79.42l2.987,2.987a1.148,1.148,0,0,1,.337.812v12.1h0a8.122,8.122,0,0,0,15.279,3.839.086.086,0,0,1,.161.042v4.245H399.55v-1.187L396.6,99.311a1.148,1.148,0,0,1-.34-.812Z" transform="translate(-328.661 -70.763)" fill="#fff"/>
        <path id="Path_952" className="pathfill" data-name="Path 952" d="M657.4,88.285l-4.591-1.842c-2.276-.9-3.48-2.337-3.48-4.142a2.75,2.75,0,0,1,2.839-2.9,2.977,2.977,0,0,1,2.479.943l6.723,6.761.014.014h1.2V78.185h-1.182l-1.991.844a1.155,1.155,0,0,1-.767.045,23,23,0,0,0-6.1-.889A12.583,12.583,0,0,0,645.874,80a6.564,6.564,0,0,0-3.22,5.686c0,3.676,2.11,6.286,6.449,7.979l3.51,1.475h0c3.443,1.367,3.927,3.186,3.906,4.147a3,3,0,0,1-3.268,2.9h-.027a3.3,3.3,0,0,1-2.468-.943l-6.723-6.761-.014-.014h-1.2V103.4H644l1.994-.846a1.144,1.144,0,0,1,.751-.051,29.727,29.727,0,0,0,6.94.9c4.309,0,9.524-1.134,9.9-6.535C663.865,92.75,661.844,89.943,657.4,88.285Z" transform="translate(-570.488 -70.721)" fill="#fff"/>
        <path id="Path_953" className="pathfill" data-name="Path 953" d="M862.3,88.285l-4.591-1.842c-2.276-.9-3.48-2.337-3.48-4.142a2.75,2.75,0,0,1,2.839-2.9,2.977,2.977,0,0,1,2.479.943l6.722,6.761.014.014h1.2V78.185H866.3l-1.991.844a1.154,1.154,0,0,1-.766.045,23,23,0,0,0-6.1-.889A12.583,12.583,0,0,0,850.773,80a6.564,6.564,0,0,0-3.22,5.686c0,3.676,2.11,6.286,6.449,7.979l3.51,1.475h0c3.442,1.367,3.927,3.186,3.906,4.147a3,3,0,0,1-3.268,2.9h-.026a3.294,3.294,0,0,1-2.468-.943l-6.723-6.761-.014-.014h-1.2V103.4H848.9l1.994-.844a1.143,1.143,0,0,1,.751-.051,29.732,29.732,0,0,0,6.94.9c4.31,0,9.524-1.134,9.9-6.535C868.764,92.75,866.744,89.943,862.3,88.285Z" transform="translate(-751.988 -70.721)" fill="#fff"/>
        <path id="Path_954" className="pathfill" data-name="Path 954" d="M165.161,269.37Z" transform="translate(-147.512 -240.072)" fill="#fff"/>
        <path id="Path_955" className="pathfill" data-name="Path 955" d="M28.233,40.11h0a3.821,3.821,0,0,1-6.792-2.065V21.894a.012.012,0,0,1,.012-.012h6.866a.012.012,0,0,0,.012-.012V20.3a.012.012,0,0,0-.012-.012H21.448a.012.012,0,0,1-.012-.012V12.83a.012.012,0,0,0-.012-.012H20.6a.777.777,0,0,0-.584.264l-6.107,6.942a.778.778,0,0,1-.584.264H10.735a.012.012,0,0,0-.012.012v1.574a.012.012,0,0,0,.012.012h2.91a.012.012,0,0,1,.012.012V37.211A8.232,8.232,0,0,0,21.868,45.5a8.123,8.123,0,0,0,6.345-3.177.756.756,0,0,0,.158-.468V40.165a.075.075,0,0,0-.023-.056A.081.081,0,0,0,28.233,40.11Z" transform="translate(-10.723 -12.818)" fill="#fff"/>
      </g>
    </g>
    <g id="Group_735" data-name="Group 735" transform="translate(128.939 19.822)">
      <g id="Group_733" data-name="Group 733" transform="translate(0)">
        <path id="Path_956" className="pathfill" data-name="Path 956" d="M1048.99,74.154h-3.065v.625h1.2v3.456h.67V74.779h1.2Z" transform="translate(-1045.925 -74.154)" fill="#fff"/>
      </g>
      <g id="Group_734" data-name="Group 734" transform="translate(3.256)">
        <path id="Path_957" className="pathfill" data-name="Path 957" d="M1079.595,78.238l-.657-4.084h-.668l-1.251,2.779-1.245-2.779h-.66l-.668,4.084h.684l.479-2.958,1.328,2.9h.171l1.328-2.9.475,2.96Z" transform="translate(-1074.447 -74.154)" fill="#fff"/>
      </g>
    </g>
  </g>
</svg>


     </Link>

)
export default Logo;

Logo.propTypes = {
    className: PropTypes.string,
    fill: PropTypes.string,
  };
  
Logo.defaultProps = {
    className: undefined,
    fill: '#333',
};
  
