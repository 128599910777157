import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const Calendar = (className, fill) => {
    
    return(

        <span className="icon">
            <svg xmlns="http://www.w3.org/2000/svg" className={className} width="47.367" height="40.533" viewBox="0 0 47.367 40.533">
                <g className="icon-calendar" data-name="Group 434" transform="translate(1)">
                    <path id="Path_629" data-name="Path 629" d="M1200.909,498.307s-10,0-10,10v16.7s0,10,10,10h25.361s10,0,10-10v-16.7s0-10-10-10Z" transform="translate(-1190.906 -495.483)" fill="none"   strokeMiterlimit="10" strokeWidth="2"/>
                    <line id="Line_72" data-name="Line 72" y2="5.768" transform="translate(15.213)" fill="none"   strokeMiterlimit="10" strokeWidth="2"/>
                    <line id="Line_73" data-name="Line 73" y2="5.768" transform="translate(30.154)" fill="none"  strokeMiterlimit="10" strokeWidth="2"/>
                    <g id="Group_433" data-name="Group 433" transform="translate(0.112 13.866)">
                    <g id="Group_432" data-name="Group 432" transform="translate(0)" opacity="0.3">
                        <g id="Group_431" data-name="Group 431">
                        <line id="Line_74" data-name="Line 74" x2="45.142" transform="translate(0 0.498)" fill="none"   strokeMiterlimit="10" strokeWidth="2"/>
                        <line id="Line_75" data-name="Line 75" y2="26.152" transform="translate(15.015)" fill="none"   strokeMiterlimit="10" strokeWidth="2"/>
                        <line id="Line_76" data-name="Line 76" y2="26.152" transform="translate(30.128)" fill="none"   strokeMiterlimit="10" strokeWidth="2"/>
                        <line id="Line_77" data-name="Line 77" x2="45.142" transform="translate(0 13.076)" fill="none"   strokeMiterlimit="10" strokeWidth="2"/>
                        </g>
                    </g>
                    </g>
                </g>
            </svg>
        </span>

    )
}
export default Calendar;

Calendar.propTypes = {
    className: PropTypes.string,
    fill: PropTypes.string,
  };
  
Calendar.defaultProps = {
    className: undefined,
    fill: '#333',
};
  