import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const RocksGlass = (className, fill) => {
    
    return(

        <span className="icon">

            <svg className="icon-rocksglass" id="Group_776" data-name="Group 776" xmlns="http://www.w3.org/2000/svg" width="39.241" height="50.077" viewBox="0 0 39.241 50.077">
                <g id="Group_775" data-name="Group 775" transform="translate(2.58 14.599)" opacity="0.25">
                    <path id="Path_859" data-name="Path 859" d="M1721.507,465.373V463.4a3.325,3.325,0,0,0-3.321-3.321h-5.92a3.325,3.325,0,0,0-3.321,3.321v1.971h-5.579l-.943,2.357h6.521v1.593a3.325,3.325,0,0,0,3.321,3.321h5.92a3.325,3.325,0,0,0,3.321-3.321v-1.593h12.035v-2.357Zm-2.357,0h-5.008v2.357h5.008v1.593a.966.966,0,0,1-.965.965h-5.92a.966.966,0,0,1-.964-.965V463.4a.966.966,0,0,1,.964-.965h5.92a.966.966,0,0,1,.965.965Z" transform="translate(-1702.423 -460.081)" />
                    <path id="Path_860" data-name="Path 860" d="M1743.754,486.993h-3.222a2.963,2.963,0,0,0-2.959,2.96v3.222a2.962,2.962,0,0,0,2.959,2.959h3.222a2.963,2.963,0,0,0,2.96-2.959v-3.222A2.963,2.963,0,0,0,1743.754,486.993Zm.6,6.182a.6.6,0,0,1-.6.6h-3.222a.6.6,0,0,1-.6-.6v-3.222a.6.6,0,0,1,.6-.6h3.222a.6.6,0,0,1,.6.6Z" transform="translate(-1721.007 -474.309)" />
                </g>
                <path id="Path_861" data-name="Path 861" d="M1726.65,429.106a9.552,9.552,0,0,0-9.542,9.541v0h-13.162a3.363,3.363,0,0,0-3.194,2.3l-1.166,3.5a45.968,45.968,0,0,0-2.637,15.308,20.592,20.592,0,0,0,4.631,13.294,17.113,17.113,0,0,0,13.142,6.126c.14,0,.28,0,.419,0a17.314,17.314,0,0,0,12.409-4.91,19.862,19.862,0,0,0,5.688-14.178v-.335a45.968,45.968,0,0,0-2.637-15.308L1729.453,441h6.737v-2.356A9.552,9.552,0,0,0,1726.65,429.106Zm1.178,2.461a7.14,7.14,0,0,1,3.224,1.411l-3.224,3.224Zm-2.357,0V436.2l-3.224-3.224A7.138,7.138,0,0,1,1725.472,431.567Zm-4.827,3.14,3.94,3.94h-5.12A7.146,7.146,0,0,1,1720.645,434.707Zm10.238,25.052v.335a17.5,17.5,0,0,1-4.981,12.5,15.1,15.1,0,0,1-11.132,4.233c-10.152-.2-15.464-8.738-15.464-17.064a43.177,43.177,0,0,1,2.516-14.563l1.167-3.5a1.008,1.008,0,0,1,.958-.69h22.3a1.009,1.009,0,0,1,.958.69l1.166,3.5A43.177,43.177,0,0,1,1730.883,459.759Zm-2.168-21.112,3.94-3.939a7.147,7.147,0,0,1,1.18,3.939Z" transform="translate(-1696.949 -429.106)" />
            </svg>

        </span>

    )
}
export default RocksGlass;

RocksGlass.propTypes = {
    className: PropTypes.string,
    fill: PropTypes.string,
  };
  
RocksGlass.defaultProps = {
    className: undefined,
    fill: '#333',
};
  



